import { getUrlParameter } from "../utils/jstools";
import { guardaRespuestas101 } from "../utils/services";
import { guardaRespuestas } from "../utils/services";

const LOGIN_URL = "http://localhost:3000/login";
const userId = getUrlParameter("userId");

// valida si la cookie ya se borro o no coincide se sale de la sesion
export const validateCookieUserId = async (valueCookie) => {
    console.log("cookie component: " + valueCookie);
    console.log("getURLParameter: " + userId);
    if ( valueCookie == userId ){
        console.log("cookie con id coinciden");        
    } else {
        console.log("cookie con id NO coinciden");   
        window.location.href = LOGIN_URL;     
    }
};

// valida si la cookie ya se borro para los cuestionarios
export const validateUserIdCookieEmpleado = async (valueCookie, sesion, perfil, respuestas, upDate) => {
    console.log("valueCookie metodo: " + valueCookie);
    switch ( upDate ){
        case "guardaRespuestas101":
            if ( valueCookie == userId ){
                console.log("cookie con id coinciden"); 
                guardaRespuestas101(sesion, perfil, respuestas); 
              }else{
                  console.log("cookie con id NO coinciden");   
                  window.location.href = LOGIN_URL;     
              }
        break;
        case "guardaRespuestas":
            if ( valueCookie == userId ){
                console.log("cookie con id coinciden"); 
                guardaRespuestas(sesion, perfil, respuestas); 
              }else{
                  console.log("cookie con id NO coinciden");   
                  window.location.href = LOGIN_URL;     
              }
        break;
    }
};