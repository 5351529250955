import React from 'react'
import { BoxLogin } from '../../components/xmex/login/BoxLogin'

export const LoginXmex = () => {
  return (
    <div>
        <BoxLogin/>
    </div>
  )
}
