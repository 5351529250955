import React, { useState, useEffect, useRef } from 'react';
import { actualizaAfiliado, getAfiliadoById } from '../../../utils/xmex/services';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CredencialPDF } from '../afiliacion/afiliacion/CredencialPDF';
import Cookies from 'universal-cookie';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Container, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { Btn_afiliarse } from '../afiliacion/Btn_afiliarse';
import { Btn_UpDateData } from '../btns/Btn_UpDateData';
import AvatarEditor from 'react-avatar-editor';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

// Instancia para cookies
const cookies = new Cookies();

const drawerWidth = 240;

function ResponsiveDrawer(props) {

  const [selectedSection, setSelectedSection] = useState('inicio');
    
  const [data, setData] = useState({
    nombreCompleto: "",
    nombreNavbar: "",
    firma: "",
    img_perfil: "",
    avatar: "",
  });

  const [dataAfiliado, setDataAfiliado] = useState({
    id: "",
    nombre: "",
    apellido_p: "",
    apellido_m: "",
    // password: "",
    fecha_nac: "",
    nacionalidad: "",
    tel_casa: "",
    celular: "",
    rfc: "",
    curp: "",
    calle: "",
    cp: "",
    no_exterior: "",
    no_interior: "",
    colonia: "",
    alcaldia: "",
    nombre_empresa: "",
    img_perfil: ""
  });
    
  useEffect(()=>{
      getAfiliadoById(cookies.get('afiliadoID')).then((data) => {
        setData((prevState) => {
          return {
            ...prevState,
            nombreNavbar : data.nombre + " " + data.apellido_p,
            nombreCompleto: data.nombre + " " + data.apellido_p + " " + data.apellido_m,
            img_perfil: data.img_perfil,
            avatar : data.img_perfil,
            firma : data.img_firma,
          }
        });
        setDataAfiliado((prevState) => {
          return {
            ...prevState,
            id : data.id,
            nombre : data.nombre ,
            apellido_p: data.apellido_p,
            apellido_m: data.apellido_m,
            // password: "",
            fecha_nac: data.fecha_nac,
            nacionalidad: data.nacionalidad,
            tel_casa: data.tel_casa,
            celular: data.celular,
            rfc: data.rfc,
            curp: data.curp,
            calle: data.calle,
            cp: data.cp,
            no_exterior: data.no_exterior,
            no_interior: data.no_interior,
            colonia: data.colonia,
            alcaldia: data.alcaldia,
            nombre_empresa: data.nombre_empresa,
            img_perfil: data.img_perfil,
          }
        });
        console.log('firma: ' + data.img_firma);
        console.log('nombre: ' + data.nombre + data.apellido_p + " " + data.apellido_m);
        console.log('nombre: ' + dataAfiliado.nombre);
        console.log('nombre: ' + cookies.get('cookieNombreCompleto'));
      });
  }, []);

  const nombreChangeHandler =(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, nombre: event.target.value};
    });
  };
  const  apellidoPChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, apellido_p: event.target.value};
    });
  };
  const  apellidoMChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, apellido_m: event.target.value};
    });
  };
  const  fechaNChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, fecha_nac: event.target.value};
    });
  };
  const  nacionalidadChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, nacionalidad: event.target.value};
    });
  };
  const  telCasaChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, tel_casa: event.target.value};
    });
  };
  const  telCelularChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, celular: event.target.value};
    });
  };
  const  curpChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, curp: event.target.value};
    });
  };
  const  rfcChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, rfc: event.target.value};
    });
  };
  const  nombreEmpresaChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, nombre_empresa: event.target.value};
    });
  };
  const  calleChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, calle: event.target.value};
    });
  };
  const  cpChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, cp: event.target.value};
    });
  };
  const  noExtChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, no_exterior: event.target.value};
    });
  };
  const  noIntChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, no_interior: event.target.value};
    });
  };
  const  coloniaChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, colonia: event.target.value};
    });
  };
  const  alcaldiaChangeHandler=(event) => {
    setDataAfiliado((prevState) => {
      return {...prevState, alcaldia: event.target.value};
    });
  };
  
  // CHANGE HANDLERS AVATAR
  const inputFileRef = useRef(null);
  const editorRef = useRef(null);

  const handleActualizarImagen = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      
      img.onload = () => {
        // Redimensiona la imagen a 250*250 píxeles
        const canvas = document.createElement('canvas');
        const maxDimension = 250;
        
        let width = img.width;
        let height = img.height;
  
        // Calcula las dimensiones de la imagen redimensionada
        if (width > height) {
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, width, height);
  
        // Obtén la imagen redimensionada como una cadena base64
        const resizedImageBase64 = canvas.toDataURL('image/png', .7); // Ajusta la calidad si es necesario
  
        // Guarda la imagen redimensionada en el estado
        setDataAfiliado((prevDataAfiliado) => ({
          ...prevDataAfiliado,
          img_perfil: resizedImageBase64,
        }));
  
        // setBase64Image(resizedImageBase64);
      };
    };
    
    if (file) {
      reader.readAsDataURL(file);
    }
  };


    
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar sx={{marginTop: '4vh'}} />
      {/* <List>
        {['Beneficios', 'Servicios'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <List>
        <ListItem >
          <ListItemButton onClick={() => setSelectedSection('inicio')}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary='Inicio' />
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton onClick={() => setSelectedSection('informacion')}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary='Información' />
          </ListItemButton>
        </ListItem>
        <PDFDownloadLink 
          document={
              <CredencialPDF
              name={data.nombreCompleto}
              signature={data.firma}
              id={dataAfiliado.id}
              avatar={data.img_perfil}
            />
        }
          // fileName={'Credencial_' + cookies.get('cookieNombreCompleto') + '.pdf'}
          fileName={'Credencial_' + dataAfiliado.nombre + '.pdf'}
          style={{ textDecoration: 'none', color: 'inherit' }} 
        > 
          <ListItem key="Credencial" >
            <ListItemButton>
              <ListItemIcon>
                <DownloadIcon/>
              </ListItemIcon>
              <ListItemText primary='Credencial'/>
            </ListItemButton>
          </ListItem>
        </PDFDownloadLink>
      </List>
      <Divider/>
      <List>
        {['Cerrar sesión'].map((text, index) => (
          <ListItem key={text} >
            <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

//   Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%', paddingTop: 1, paddingBottom: 1,background: '#ffff'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
          <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <img 
              src={src_url + 'logo_spm.png'}
              style={{width: '7ch'}}
            />
            <Typography>
              {data.nombreNavbar}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        
        {/* BTN INICIO */}
        {selectedSection === 'inicio' && (
          <div>
            <Typography variant="h6">Información Personal</Typography>
            <Typography>Nombre: {data.nombreCompleto}</Typography>
            <Typography>ID: {dataAfiliado.id}</Typography>
            {/* Otros datos que quieres mostrar en "Inicio" */}
          </div>
        )}

        {/* BTN EDITAR DATOS */}
        {selectedSection === 'informacion' && (
          <Box sx={{ paddingBlock: '10px' }}>
            <Container sx={{ marginTop: '1%' }}>
              <Typography variant="h5">Información personal</Typography>
              
              <Grid container spacing={2} sx={{ marginTop: '-2%' }}>
                <Grid item xs={12} md={12}>
                  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <figure>
                      {dataAfiliado.img_perfil !== data.img_perfil ? (
                        // <AvatarEditor
                        //   ref={editorRef}
                        //   image={dataAfiliado.img_perfil}
                        //   width={170}
                        //   height={170}
                        //   border={50}
                        //   // borderRadius='50%'
                        //   color={[255, 255, 255, 0.7]}
                        //   scale={1}
                        //   rotate={0}
                        // />
                        <img
                          src={dataAfiliado.img_perfil}
                          alt="Base64"
                          style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                        />
                      ) : (
                        <img
                          src={data.img_perfil}
                          alt="Base64"
                          style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                        />
                      )}
                    </figure>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '-3%'}}>
                    <IconButton color='primary' aria-label="upload picture" component="label">
                      <input
                        ref={inputFileRef}
                        hidden accept="image/*"
                        type="file"
                        onChange={handleActualizarImagen}
                      />
                      <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                    </IconButton>
                  </Box>
                </Grid>

                {/* Fila 1 */}
                <Grid item xs={4} md={4}>
                  <TextField 
                    type='text'
                    label='Nombre(s)'
                    value={dataAfiliado.nombre}
                    color='primary' 
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={nombreChangeHandler}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextField 
                    type='text'
                    label='Apellido paterno' 
                    value={dataAfiliado.apellido_p}
                    color='primary' 
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={apellidoPChangeHandler}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextField 
                    type='text'
                    label='Apellido materno' 
                    value={dataAfiliado.apellido_m}
                    color='primary' 
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={apellidoMChangeHandler}
                  />
                </Grid>

                {/* Fila 2 */}
                <Grid item xs={6} md={3}>
                  <TextField 
                    type='date'
                    label='Fecha de nacimiento' 
                    value={dataAfiliado.fecha_nac}
                    color='primary' 
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    onChange={fechaNChangeHandler}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField 
                    type='text'
                    label='Nacionalidad' 
                    value={dataAfiliado.nacionalidad}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChange={nacionalidadChangeHandler}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    type='text'
                    label='Tel. casa' 
                    value={dataAfiliado.tel_casa}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={telCasaChangeHandler}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField 
                    type='text'
                    label='Tel. celular' 
                    value={dataAfiliado.celular}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChange={telCelularChangeHandler}
                  />
                </Grid>

                {/* Fila 3 */}
                <Grid item xs={6} md={4}>
                  <TextField 
                    type='text'
                    label='CURP' 
                    value={dataAfiliado.curp}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChangecurpChangeHandler
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    type='text'
                    label='RFC' 
                    value={dataAfiliado.rfc}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={rfcChangeHandler}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    type='text'
                    label='Nombre de la empresa' 
                    value={dataAfiliado.nombre_empresa}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={nombreEmpresaChangeHandler}
                  />
                </Grid>

                {/* Fila 4 */}
                <Grid item xs={6} md={3}>
                  <TextField
                    type='text'
                    label='Calle' 
                    value={dataAfiliado.calle}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={calleChangeHandler}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextField 
                    type='text'
                    label='C.P' 
                    value={dataAfiliado.cp}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChange={cpChangeHandler}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextField
                    type='text'
                    label='N. exterior' 
                    value={dataAfiliado.no_exterior}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={noExtChangeHandler}
                  />
                </Grid>
                <Grid item xs={4} md={3}>
                  <TextField 
                    type='text'
                    label='N. interior' 
                    value={dataAfiliado.no_interior}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChange={noIntChangeHandler}
                  />
                </Grid>

                {/* Fila 5 */}
                <Grid item xs={4} md={6}>
                  <TextField
                    type='text'
                    label='Colonia' 
                    value={dataAfiliado.colonia}
                    color='primary'
                    variant='filled'
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    onChange={coloniaChangeHandler}
                  />
                </Grid>
                <Grid item xs={4} md={6}>
                  <TextField 
                    type='text'
                    label='Alcaldia' 
                    value={dataAfiliado.alcaldia}
                    color='primary' 
                    variant='filled' 
                    fullWidth
                    inputProps={{ maxLength: 18 }}
                    onChange={alcaldiaChangeHandler}
                  />
                </Grid>
              </Grid>
              <Btn_UpDateData
                updateAfiliadoData='yes'
                update={actualizaAfiliado}
                json={dataAfiliado}
                label='GUARDAR'
                disabled={false}
              />
            </Container>
          </Box>
        )}










      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
