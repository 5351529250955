import React, { useState } from 'react'
import './AdministrarUsuarios_DashAnalista.css';

export const Tarjeta_usuario = (props) => {
    console.log(props);
    const [ contSeleccionados, setContSeleccionados ] = useState();
    const selectHandler=() => {
      var cont=contSeleccionados;
      cont++;
      console.log(contSeleccionados)
      setContSeleccionados(cont)
    }

     /* var cont = 0;
      var check = document.getElementById("1");      
      if (check.ariaChecked == true) {
        
        cont++;
        
        } else {
          cont--;
        }*/

return (


<div className='table_datos'>
  <form id='1'>
<table>
  <tbody>
    <tr>
    <td>
      <input 
        onClick
        id='1' 
        type="checkbox"
        checked={props.check}
      />
      </td>      
      <td>
      <p className='Nombre_usuario'>{props.usuario.nombreUsuario}</p>
      </td>
      <td>
      <p className='Correo_usuario'>{props.usuario.correoUsuario}</p>
      </td>
      <td>
        1 hora
      </td>
      <td className='activo'>
        ACTIVO
      </td>
      <td>
        <button className='btn_Editar_table_top_Analista_Tarjeta'>
           <img
              src='../img/admin/1.png'  
              className='img_admin_us'
           />
        </button>
      </td>
    </tr>
  </tbody>
</table>
</form>
</div>

)

}