import React, { useState } from 'react'
import { getUrlParameter } from '../../../utils/jstools'
import { Header } from "../header/Header";
import { Cuestionario_Condicional21 } from './Cuestionario_Condicional21'
import { MenuCuestionario } from './MenuCuestionario'

export const Template_Cuestionario_Condicional21 = () => {

    //Copiar a todos los templates
  const [perfil, setPerfil] = useState({
    perfId: getUrlParameter("perfId"), //Dato de parametro perfId
    userId: getUrlParameter("userId"), //Dato de parametro userId
    nombre: getUrlParameter("nombre") //Dato estático
  });

  window.location.hash="no-back-button";
  window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
  window.onhashchange=function(){
    window.location.hash="no-back-button";
  }

    return (
        <div
        style=
                {
                    {
                        // backgroundImage: "url(../img/Background_Login2.svg)", 
                        backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                        width: "100%", 
                        height: "112vh", 
                        backgroundRepeat: "no-repeat", 
                        backgroundSize: "cover",
                        marginTop: "-100px",
                        paddingTop: "100px",
                        // marginBottom: "-150px"
                    }
                }>
            <Header nombre={perfil.nombre}/>
            <Cuestionario_Condicional21 perfil={perfil.perfId} usuario={perfil.userId} nombre={perfil.nombre}/>
        </div>
    )
}