import React from 'react'
import { Config } from '../../components/xmex/admin/Config'

export const DashConfig = () => {
  return (
    <div>
      <Config/>
    </div>
  )
}
