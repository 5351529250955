import React, { useContext, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import IdContext from '../../../context/xmex/IdContext';
import { emailAutomatico, getToken, getUserByEmail, emailAutomaticoPreafiliacionExitosa, 
  emailAutomaticoContinuarPreafiliacion, emailAutomaticoCorreoRegistrado } from '../../../utils/xmex/services';
import Cookies from 'universal-cookie';
import { getUrlParameter } from '../../../utils/jstools';

export const Btn_afiliarse = (props) => {

  const cookies = new Cookies();

  // mensaje de error
  const [msg, setMsg] = useState('');
  // color de mensaje 
  const [colorMsg, setColorMsg] = useState('');
  //
  const [idParameter, setIdParameter] = useState(getUrlParameter('id'));

  
  const ctx = useContext(IdContext);
  const clickHandlerAfiliarse = async () => {

    if(props.btnLogInIniciar === 'si'){
      await getToken();
    }else{
      await getToken();
      if (idParameter){
        console.log(ctx.idAfiliado);
        console.log(idParameter);
        props.json.id = idParameter;
        ctx.idAfiliado = idParameter;
      } else {
        console.log(ctx.idAfiliado);
        console.log(idParameter);
        props.json.id = ctx.idAfiliado;
      }
    }
    await getUserByEmail(props.json.email).then((response) => {
      if (response.status === 200 && response.data && props.signatureEmailName == 'val') {
        // El login fue exitoso, redirigir
        setMsg('Este correo electrónico ya ha sido registrado');
        setColorMsg('red');
        emailAutomaticoCorreoRegistrado({
          usuario: {
            id: response.data.id,
            nombre: response.data.nombre,
            email: response.data.email
          }
        });
      } else {
        if(props.test != 'si'){

          //console.log('id: ' + props.json.id);
          // if(ctx.idAfiliado > 0 || ctx.idAfiliado != null){
          //   props.json.id = ctx.idAfiliado;
          //   console.log('id: ' + props.json.id);
          // }else{
          //   props.json.id = getUrlParameter('id');
          //   console.log('id: ' + props.json.id);
          // }
          props.update(props.json).then((data) => {
            console.log(data);
            if(ctx.idAfiliado === 0){ 
              //ctx.updateAfiliado(data.id);
              ctx.updateAfiliado(props.json.id);
              console.log('id: ' + data.id);
              console.log(ctx.idAfiliado);
            }
            if (props.signatureEmailName === 'si'){
              props.setFirmaBase64(data.img_firma);
              props.setIdAfiliado(data.id);
              props.btnCredencialDisabled(false);
              
              //Actializo el estado
              // setEmailAuto((prevEmailAuto) => ({
              //   ...prevEmailAuto,
              //   usuario: {
              //     ...prevEmailAuto.usuario,
              //     email: data.email
              //   }
              // }));
              emailAutomatico({
                usuario: {
                  // nombre: props.nameAfiliado,
                  nombre: cookies.get('cookieNombreCompleto'),
                  email: cookies.get('cookieEmailAfiliacion')
                }
              });
              console.log("name: ", + props.nameAfiliado);
              console.log("email: ", + cookies.get('cookieEmailAfiliacion'));
              emailAutomatico({
                usuario: {
                  nombre: cookies.get('cookieNombreCompleto'),
                  // nombre: "Jarrett Ademir Vizuet JSON",
                  email: "jarrett_vizuet@outlook.com"
                }
              });
      
              // POST al metodo para el correo automatico
              // emailAutomatico(emailAuto)
            } else if (props.signatureEmailName == 'val'){
              emailAutomaticoPreafiliacionExitosa({
                usuario: {
                  id: data.id,
                  nombre: data.nombre,
                  email: data.email
                }
              });
              setMsg('Te hemos enviado un correo electrónico para continuar tu afiliación');
              setColorMsg('green');
            }
          });
          }
      }
    })
  };

  return (
    <>
      {msg && (
        <Typography sx={{ color: colorMsg, fontSize: '14px', marginBottom: '10px' }}>
          {msg}
        </Typography>
      )}
      
      {/* Renderizamos el botón solo si msg no tiene contenido */}
      {!msg && (
        <Button 
          color='primary' 
          variant='contained'
          sx={{borderRadius: 8}}
          onClick={clickHandlerAfiliarse}
          disabled={props.disabled}
        >
          <Link to={props.link} style={{textDecoration: 'none'}}>
            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
              {props.label}
            </Typography>
          </Link>     
        </Button>
      )}
    </>    
  );
  
};
