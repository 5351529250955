import React, { useState } from 'react';
import { getUrlParameter } from '../../../../utils/jstools';
import { getPerfilEmpleadoByUsuarioId } from "../../../../utils/services";
import { deleteEmpleado } from "../../../../utils/services";
import { putPerfilEmpleado } from "../../../../utils/services";
import { actualizaUsuario } from "../../../../utils/services";
import './TarjetaRegEmpleados.css';
import Popup from "reactjs-popup";

export const TarjetaRegEmpleados = (props) => {

    const [ perfilUsuario, setPerfilUsuario ] = useState({
      Id: parseInt(props.empleado.id),
      Email: props.empleado.email,
      NumEmp: props.empleado.numTrabajador,
      // empresaId: parseInt(getUrlParameter("empresaId")),
      // rolId: 2,
      // pass: "nom35"
  });

  const [perfilJson, setPerfilJson] = useState({
    Id: null,
    usuarioId: null,
    qId: ""
  });

    // ClickHandler para actualizar datos del empleado
    const clickHandlerActualizaDatosEmp = () => {
      actualizaUsuario(perfilUsuario).then((data) => {
      });
      console.log('id empleado: ' + perfilUsuario.Id);
      window.alert("Los cambios han sido guardados");
      // window.location.replace("");
      window.location.reload(true);
      getPerfilEmpleadoByUsuarioId(perfilUsuario.Id).then((data) => {
        const perfil = data[0];
            if (perfil.qId=="val"){
            const putPerfil = perfilJson;
            putPerfil.Id = perfil.Id;
            putPerfil.usuarioId = perfilUsuario.Id;
            putPerfil.qId = "101";
            putPerfilEmpleado(putPerfil);
          }
      });
    };

    // ClickHandler para eliminar empleado
    const clickHandlerDeleteEmp = () => {
      deleteEmpleado((perfilUsuario.Id), (props.empleado.idPE)).then((data) => {
        window.location.reload(true);
      });
    };

  //Handlers editar
  const numEmpChangeHandler = (event) => {
    setPerfilUsuario((prevState) => {
      return { 
        ...prevState, 
        NumEmp: event.target.value 
      };
    });
  };
  const emailChangeHandler = (event) => {
    setPerfilUsuario((prevState) => {
      return { 
        ...prevState, 
        Email: event.target.value 
      };
    });
  };

  // Condicion para el boton editar popUp   
  var dis = true;
  if (
    (perfilUsuario.NumEmp == "" || perfilUsuario.Email == "")
    ){
    dis = true;
  }
  else {
    dis = false;
  }

  // Datos del estatus
  var estatus;
  var className;
  var titleEstatus;
  var disBTNEditarEliminar = true;
  switch(props.empleado.estatus) 
  {
    case "":
      estatus= "Pendiente";
      className= "hr_regEmpleados_DashAdmin"
      titleEstatus= "Pendiente"
      disBTNEditarEliminar=false
      break;
    case "val":
      estatus= "Validado";
      className= "hr_regEmpleados_DashAdmin2"
      titleEstatus= "Validado"
      break;
    case "101":
      estatus= "Registrado";
      className= "hr_regEmpleados_DashAdmin3"
      titleEstatus= "Registrado"
      break;
    case "fin":
      estatus= "Completado";
      className= "hr_regEmpleados_DashAdmin4"
      titleEstatus= "Registrado"
      break;
    default:
      estatus= "Registrado";
      className= "hr_regEmpleados_DashAdmin3"
      titleEstatus= "Registrado"
      break;
  }

  const contentStyle = {
    maxWidth: "600px",
    width: "90%"
  };

  return (
    <div>      
      <table className='table_TarjetaEmpleados'>
            <tbody>
              <tr>
                {/* <td className='td1_table_top_Admin_RegEmpleados_Tarjeta'>
                  <input 
                    onClick
                    className='inp_emp'
                    type="checkbox" 
                    checked={props.check}
                  />
                </td> */}
                <td className='td2_table_top_Admin_RegEmpleados_Tarjeta'>
                  <p
                  className='txt_targ'
                  > 
                    {props.empleado.numTrabajador} 
                  </p>
                </td>
                <td className='td3_table_top_Admin_RegEmpleados_Tarjeta'>
                  <p
                  className='txt_targ'
                  > 
                    {props.empleado.email} 
                  </p>
                </td>
                <td className='td4_table_top_Admin_RegEmpleados_Tarjeta'>
                  
                  {/* Estatus del empleado */}
                  <div className='div_EstatusColores'>
                  <p
                  className='txt_targ'
                  > 
                    <span>
                      <hr
                        title={titleEstatus}
                        className={className}
                      />
                    </span>
                    {estatus} 

                  </p>
                  </div>
                </td>
                
                {/* btn editar empleado */}
                <td className='td5_table_top_Admin_RegEmpleados_Tarjeta'>
                  <Popup
                    modal
                    contentStyle={contentStyle}
                    trigger=
                      {
                        <button 
                          className='btn_Editar_table_top_Admin_RegEmpleados_Tarjeta'
                          id='btn_PopUp_AnadirAnalista'
                          disabled={disBTNEditarEliminar}
                        >
                          <img
                            src='../img/admin/1.png' 
                            className='img_admin_us_editar'
                          /> 
                        </button>
                      }
                  >
                    {
                      close => 
                      (            
                        <div className="popUp_editarEmpleados">
                          <p className='popUp_txt_editarEmpleado'>
                            Editar empleado
                          </p>
                          <div className='popUp_div1_editarEmpleado'>
                            <p className='popUp_txt_numEmpleado'>
                              Número de trabajador
                            </p>
                            <input
                              className='popUp_input__numEmpleado_editarEmpleado'
                              type="text"
                              value={perfilUsuario.NumEmp}
                              pattern='[0-9,a-z,A-Z]+'
                              maxLength={100}
                              onChange={numEmpChangeHandler}
                            />
                          </div>            
                          <div className='popUp_div2_editarEmpleado'>
                            <p className='popUp_txt_emailEmpleado'>
                              Email
                            </p>
                            <input
                              className='popUp_input__emailEmpleado_editarEmpleado'
                              type="email"
                              value={perfilUsuario.Email}
                              onChange={emailChangeHandler}
                            />
                          </div>
                          <div>
                            <button
                              className='popUp_btn_editarEmpleado_guardarCambios'
                              onClick={clickHandlerActualizaDatosEmp}
                              disabled={dis}
                              id={'btn_PopUp_Cerrar_Editar' + props.empleado.id}
                              update={actualizaUsuario}
                            >
                              Guardar cambios
                            </button>
                          </div>
                          <button
                            className='popUp_btn_cerrarPopUp_editarEmpleados'
                            id={'btn_PopUp_Cerrar_Cerrar' + props.empleado.id}
                            onClick={close}
                          >
                            Cerrar
                          </button>
                        </div>
                      )
                    }
                  </Popup>
                </td>
                
                {/* btn eliminar empleado */}
                <td className='td6_table_top_Admin_RegEmpleados_Tarjeta'>
                  <button 
                    className='btn_Eliminar'
                    disabled={disBTNEditarEliminar}
                    // id={'btn_EliminarEmpleado' + props.empleado.id}
                    onClick={clickHandlerDeleteEmp}
                  >
                    <img
                      src='../img/eliminarx.png' 
                      className='img_admin_us_eliminar'
                    />
                  </button>
                </td>
              </tr>
            </tbody>  
        </table>
    </div>
  )
}
