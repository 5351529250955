import React, { useEffect } from "react";
import './Button_CuestionarioSiguiente.css';
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { validateUserIdCookieEmpleado } from "../../../../cookie/cookies";
import { guardaRespuestas, guardaRespuestas101 } from "../../../../utils/services";

export const Button_CuestionarioSiguente = (props) => {

   // Tiempo de vida para cookie idUser
  //  const expirationDate = new Date();
  //  expirationDate.setMinutes(expirationDate.getMinutes() + 10);
  //  console.log("BTN expirationDate: " + expirationDate);
   
   // Cookie id
  //  const cookie = new Cookies();
  //  const valueCookie = cookie.get('idUser');
  //  console.log('cookie header: ' + valueCookie); 
  //  useEffect(() => {
  //   cookie.set('idUser', valueCookie, {path: '/',  expires:  expirationDate});
  //  }, []);

  const clickHandler = () => {
    // validateUserIdCookieEmpleado(valueCookie, props.sesion, props.perfil, props.respuestas, props.upDate);
    switch (props.condicionPreg){
      case '101Cond':
        console.log("PARA IMRPIMIR EL JSON DE RESPUESTAS:");
        console.log(props.respuestas);
        guardaRespuestas101(props.sesion, props.perfil, props.respuestas);
      break;
      case '100Restantes':
        console.log("PARA IMRPIMIR EL JSON DE RESPUESTAS:");
        console.log(props.respuestas);
        guardaRespuestas(props.sesion, props.perfil, props.respuestas);
      break;
      case '200Restantes':
        console.log("PARA IMRPIMIR EL JSON DE RESPUESTAS:");
        console.log(props.respuestas);
        guardaRespuestas(props.sesion, props.perfil, props.respuestas);
        break;
      }
    console.log('Si entra al boton');
  };

  return (
          <Link to={props.sesion.link}>
            <button
              className="btn_siguiente_cuestionario_componente"
              onClick={clickHandler}
              disabled={props.disabled}
            >
              Siguiente
            </button>
          </Link>
  );
};