import React, { useState } from 'react';

import { Link } from "react-router-dom";

export const Aviso_legal = () => {
    const [chkValue, setChkValue] = useState(true);
  return ( 
    <div
        style=
            {
                {
                    
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    
                    height: "100vh", 
                     
                    backgroundSize: "cover",
                    marginTop: "-4%",
                    
                    paddingTop: "4%",
                    
                }
            } 
    >
    <div className="App">
   
    </div>

    <Link to="/">
        <img
                    className='img_logoPB'
                    src='../img/pagina_publica/LOGO-SMI-CALADO 1.png'
                />
                </Link>

        <div className='box_Generales'>
            
            <p className='txt_Principal_Generales'>AVISO LEGAL</p>
            <div className='txt_Instrucciones_Generales'>
                
            </div>
       


       


       
        </div>
        
        
    </div>
    );
};
