import React from 'react'
import { Link } from 'react-router-dom';
import whatsAppLogo from '../../../../src/assets/logos/whatsappLogo.png'

export const WhatsappChatButton = () => {
  return (
    <div>
        <Link to={"https://wa.me/+525622170053"}>
            <img 
                src={whatsAppLogo}
                style={{
                    position: 'fixed',
                    width: '70px',
                    height: '70px',
                    bottom: '14px',
                    left: '15px',
                    zIndex: 1000
                }}
            />
        </Link>
    </div>
  )
}
