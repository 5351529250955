// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header{
    /* background: rgb(182, 70, 55); */
    /* background-color: rgb(243, 240, 240);  */
    background-color: white; 
    width: 100%;
    height: 65px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, .5);
    /* border-top: 5px solid rgb(117, 23, 11); */
    border-bottom: rgb(243, 240, 240);
}
.img_SMI_Header{
    margin-top: -15px;
    margin-left: 100px;
    width: 6%;
    height: 100px;
}
.txt_NOM035{
    font-size: 40px;
    font-family: 'Montserrat';
    text-align: left;
    margin-top: 0px;
    padding-top: 5px;
    margin-left: 3%;
    color: #585858;
    font-weight: 300;
}
.text_Header{
    font-size: 40px;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(117, 23, 11);
    margin-top: -85px;
}
.text_hola_Header{
    text-align: right;
    margin-right: 80px;
    margin-top: -70px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #777777;
    font-size: 1.07vw;
}
.img_user_Header{
    float: right;
    margin-right: 10px;
    margin-top: -3vw;
    width: 3%;
   
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/header/Header.css"],"names":[],"mappings":"AAEA;IACI,kCAAkC;IAClC,2CAA2C;IAC3C,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,4CAA4C;IAC5C,iCAAiC;AACrC;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;IACT,aAAa;AACjB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,6EAA6E;IAC7E,uBAAuB;IACvB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,6EAA6E;IAC7E,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;;AAEb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Roboto:wght@300&display=swap');\n\n.Header{\n    /* background: rgb(182, 70, 55); */\n    /* background-color: rgb(243, 240, 240);  */\n    background-color: white; \n    width: 100%;\n    height: 65px;\n    box-shadow: 0px 0px 40px rgba(0, 0, 0, .5);\n    /* border-top: 5px solid rgb(117, 23, 11); */\n    border-bottom: rgb(243, 240, 240);\n}\n.img_SMI_Header{\n    margin-top: -15px;\n    margin-left: 100px;\n    width: 6%;\n    height: 100px;\n}\n.txt_NOM035{\n    font-size: 40px;\n    font-family: 'Montserrat';\n    text-align: left;\n    margin-top: 0px;\n    padding-top: 5px;\n    margin-left: 3%;\n    color: #585858;\n    font-weight: 300;\n}\n.text_Header{\n    font-size: 40px;\n    text-align: center;\n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n    color: rgb(117, 23, 11);\n    margin-top: -85px;\n}\n.text_hola_Header{\n    text-align: right;\n    margin-right: 80px;\n    margin-top: -70px;\n    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n    color: #777777;\n    font-size: 1.07vw;\n}\n.img_user_Header{\n    float: right;\n    margin-right: 10px;\n    margin-top: -3vw;\n    width: 3%;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
