import React from 'react'
import { ScreenAfiliacionExitosa } from '../../components/xmex/afiliacion/afiliacion/ScreenAfiliacionExitosa'

export const AfiliacionExitosa = () => {
  return (
    <div>
        <ScreenAfiliacionExitosa/>
    </div>
  )
}
