import React from 'react';
import { Header } from '../../header/Header';
import { Menu_lateral_analista } from '../menu_lateral_analista/Menu_lateral_analista';
import './VistaGeneral_DashAnalista.css';
// import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Cookies from 'universal-cookie';

// RECHARTS
// import { LineChart, Line } from 'recharts';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


// const data = [{name: 'Page A', uv: 400, pv: 2400, amt:2400}, ...];
// const data = [{name: 'Page A', uv: 400, pv: 2400, amt:2400}];

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

const data = [
  { name: "Group A", value: 500 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 200 },
];
const data2 = [
  { name: "Group A", value: 200 },
  { name: "Group B", value: 350 },
  { name: "Group C", value: 450 },
];
const COLORS = ["#E5E5E5", "#00C49F", '#0088FE'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text 
      x={x} 
      y={y} 
      fill="#585858" 
      textAnchor={x > cx ? 'start' : 'end'} 
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};


export const VistaGeneral_DashAnalista = () => {  

  // instacia de cookies
  const cookies = new Cookies();

  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  return (
    <div>
        <Header
          nombre={cookies.get('nameUser')}
          expirationDate={expirationDate}
        />
        <Menu_lateral_analista
          idUsuario={cookies.get('idUser')}
        />
        <div className='box_DashAnalist_VistaGeneral'>
          <div
              style=
              {
                  {
                    backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                    width: "100%", 
                    height: "15%", 
                    backgroundSize: "cover",
                    marginTop: "1%",
                    display: "block",
                  }
              }
          >
            <div className='div_txt_TittleVistaGeneral_Analista'>
              <p>ANALISTA</p>
              <p>VISTA GENERAL</p>
            </div>
          </div>

          {/* Vista general */}

          {/* <LineChart width={400} height={400} data={data}>
            <Line type="monotone" dataKey="uv" stroke="#5fd417" />
          </LineChart> */}

        {/* <ResponsiveContainer  width="100%" height="60%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }}/>
            <Line type="monotone" dataKey="uv" stroke="#82ca9d"/>
          </LineChart>
        </ResponsiveContainer> */}

      <ResponsiveContainer width="100%" height="100%">
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height="100%">
      </ResponsiveContainer>
        </div>
    </div>
  )
}
