import React, { useState } from "react";
import "./Button_CuestionarioSiguiente.css";
import { Link } from "react-router-dom";
import { getPerfilEmpleadoByUsuarioId, putPerfilEmpleado } from "../../../../utils/services";

export const Button_siguente_empleado = (props) => {
  
  const [perfilJson, setPerfilJson] = useState({
    Id: null,
    usuarioId: null,
    qId: ""
  });

  const clickHandler = () => {
    props.update(props.json).then((data) => {
      console.log("data admin: " + data);
    });
    window.alert("Los cambios han sido guardados.");
    getPerfilEmpleadoByUsuarioId(props.json.Id).then((data) => {
      const perfil = data[0];
          if (perfil.qId=="val"){
          const putPerfil = perfilJson;
          putPerfil.Id = perfil.Id;
          putPerfil.usuarioId = props.json.Id;
          putPerfil.qId = "101";
          putPerfilEmpleado(putPerfil);
        }
  });
  };
  
  return (
    <Link to={props.link}>
      <button
        className={props.css}
        onClick={clickHandler}
        disabled={props.disabled}
      >
        {props.name_btn}
      </button>
    </Link>
  );
};