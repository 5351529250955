import Cookies from 'universal-cookie';
// ENDPOINTS
// const API_URL = 'http://192.168.100.144:8000/';
//const API_URL = 'https://xmex-api-xvcz4.ondigitalocean.app/';
//const API_URL = 'http://192.168.100.144:8000/';
const API_URL = 'https://xmex-erp-api-5fsjg.ondigitalocean.app/';
const ENDPOINT_AVATAR = 'https://xmex-media.nyc3.digitaloceanspaces.com/avatar';
const ENDPOINT_POSTAFILIADO = 'api/xmex/Afiliado/';
const ENDPOINT_EMAILAUTO = 'api/xmex/enviar-correo/bienvenida/';
const ENDPOINT_EMAILAUTO_RECOVER_PASS = 'api/xmex/enviar-correo/olvido-password/';
const ENDPOINT_EMAILAUTO_PREAFILIACION_EXITOSA = 'api/xmex/enviar-correo/preafiliacion-exitosa/';
const ENDPOINT_EMAILAUTO_CONTINUAR_PREAFILIACION = 'api/xmex/enviar-correo/continuar-preafiliacion/';
const ENDPOINT_EMAILAUTO_CORREO_REGISTRADO = 'api/xmex/enviar-correo/correo-registrado/';
const ENDPOINT_ADMIN = 'api/xmex/Admin/';
const ENDPOINT_EMPLEADOS = 'api/xmex/Empleado/';
const ENDPOINT_EMPRESA = 'api/xmex/Empresas/';
const GET_TOKEN = 'api/token/';
const REFRESH_TOKEN = 'api/token/refresh/';
const VERIFY_TOKEN = 'api/token/verify/';
const CALCULO_NOMINA = 'api/xmex/CalculoEmpleado/';
const ENDPOINT_Chat_GPT = 'api/xmex/xmex_ia/chat-bot';

export const getUserByEmailPass = async (email, pass) => {
    await getToken(); // Obtener el token
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
        
    // Determinar el endpoint adecuado en base al email
    const url = (email === 'pruebas') 
    ? `${API_URL}${ENDPOINT_ADMIN}?email=${email}&pass=${pass}`
    : `${API_URL}${ENDPOINT_POSTAFILIADO}?email=${email}&password=${pass}`;    
    console.log('url: ' + url);    
    
    // Determinar el que vista abrir adecuado en al email
    const destino = ( email == 'pruebas' ) ? 'nomina' : 'dashboardAfiliado'; 
    

    // Lógica común para ambas rutas
        try {
            const response = await fetch(url, {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : 'Bearer ' + cookieToken
                }
            });
    
            // Verificamos si la respuesta es exitosa por el estatus
            if (response.ok) {  // status 200-299
                const data = await response.json();
                const afiliadoData = data[0];
                // Acceso correcto a las propiedades
                const afiliadoID = afiliadoData.id;
                // Guardando cookies
                cookies.set('afiliadoID', afiliadoID, {path: '/'});   
                
                // Aquí validamos si realmente hay un usuario en la respuesta
                if (data.length > 0) {
                    return {
                        url: destino,
                        status: response.status, // status 200
                        data: data[0] // Retorna el primer usuario si existe
                    };
                } else {
                    return {
                        status: 401, // Status 401 para indicar credenciales incorrectas
                        message: 'Email o password incorrecto.'
                    };
                }
            } else {
                return {
                    status: response.status, // Si no es exitoso, devolvemos el estatus
                    message: 'Error en el servidor o credenciales incorrectas.'
                };
            }
        } catch (error) {
            console.error('Error en getUserByEmailPass:', error);
            return {
                status: 500, // Error interno del servidor
                message: 'Error de conexión, por favor intenta más tarde.'
            };
        }
};

export const getUserByEmail = async (email) => {
    await getToken(); // Obtener el token
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
        
    // Determinar el endpoint adecuado en base al email
    const url = (email === 'pruebas') 
    ? `${API_URL}${ENDPOINT_ADMIN}?email=${email}`
    : `${API_URL}${ENDPOINT_POSTAFILIADO}?email=${email}`;    
    console.log('url: ' + url);    
    
    // Determinar el que vista abrir adecuado en al email
    const destino = ( email == 'pruebas' ) ? 'nomina' : 'loginXmex'; 
    

    // Lógica común para ambas rutas
        try {
            const response = await fetch(url, {
                method: "GET",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : 'Bearer ' + cookieToken
                }
            });
    
            // Verificamos si la respuesta es exitosa por el estatus
            if (response.ok) {  // status 200-299
                const data = await response.json();
                const afiliadoData = data[0];
                // Acceso correcto a las propiedades
                const afiliadoID = afiliadoData.id;
                const afiliadoNombre = afiliadoData.nombre;
                const afiliadoEmail = afiliadoData.email;
                // Guardando cookies
                //cookies.set('afiliadoID', afiliadoID, {path: '/'});   
                
                // Aquí validamos si realmente hay un usuario en la respuesta
                if (data.length > 0) {
                    return {
                        url: destino,
                        status: response.status, // status 200
                        data: data[0] // Retorna el primer usuario si existe
                    };
                } else {
                    return {
                        status: 401, // Status 401 para indicar credenciales incorrectas
                        message: 'Email incorrecto.'
                    };
                }
            } else {
                return {
                    status: response.status, // Si no es exitoso, devolvemos el estatus
                    message: 'Error en el servidor o credenciales incorrectas.'
                };
            }
        } catch (error) {
            console.error('Error en getUserByEmailPass:', error);
            return {
                status: 500, // Error interno del servidor
                message: 'Error de conexión, por favor intenta más tarde.'
            };
        }
};

export const guardaJsonEmpleadosYCalculoNomina = async (jsonDatos) => {
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + CALCULO_NOMINA;

    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonDatos),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + cookieToken
            }
        });

        if (response.status === 401) {
            alert('La sesión ha caducado. Por favor, vuelve a intentarlo.');
            window.location.href = '/'; // Redirige al usuario a la página de inicio de sesión
        } else if (response.ok) {
            const responseData = await response.json();
            // Imprime el response de cada empleado
            console.log('Response de empleado:', responseData);

            // ****************************************************************
            return {
                disabledBtn: false,
                responseData
            };
            // ****************************************************************
        } else {
            console.log('Error en la respuesta:', await response.text());
            alert('Error en la respuesta. Por favor, revisa la consola para más detalles.');
        }
    } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        alert('Error al realizar la solicitud. Por favor, revisa la consola para más detalles.');
    }
};

// const ifSueldoMinimo = (sueldo, pEmpresa, pSindicato, ley, sindicato, totalLey, totalSindicato) => {
const ifSueldoMinimo = (sueldo, pEmpresa, pSindicato, ley, sindicato, sueldo_forEachParaCalculo) => {
    // console.log('ley if: ' + totalLey);
    // console.log('sindi if: ' + totalSindicato);


    console.log('sueldo: ' + sueldo);
    console.log('pEmpresa: ' + pEmpresa);
    console.log('pSindicato: ' + pSindicato);
    console.log('sueldo * pEmpresa: ' + (sueldo * pEmpresa));
    // if((sueldo * pEmpresa) <= 7467.90){
    if((sueldo * pEmpresa) <= sueldo_forEachParaCalculo){
        // sindicato += sueldo - 7467.9;
        sindicato += sueldo - sueldo_forEachParaCalculo;
        // totalSindicato = totalSindicato + sindicato;
        console.log('sindicato: ' + sindicato);
        // ley += 7467.9;
        ley += sueldo_forEachParaCalculo;
        // totalLey += ley;
        // console.log('ifSueldoMinimo SUELDO: ' + sueldo + ' LEY: 7467.9' + ' SINDI: ' + (sueldo - 7467.9));
        console.log('ifSueldoMinimo SUELDO: ' + sueldo + ' LEY: ' + sueldo_forEachParaCalculo + ' SINDI: ' + (sueldo - sueldo_forEachParaCalculo));
        // console.log('ifSueldoMinimo SUELDO: ' + sueldo + ' LEY: ' + ley + ' SINDI: ' + sindicato + ' TOTALley: ' + totalLey + ' TOTALsindi: ' + totalSindicato);
    }else{
        sindicato += sueldo * pSindicato;
        // totalSindicato = totalSindicato + sindicato;
        console.log('sindicato: ' + sindicato);
        ley += sueldo * pEmpresa;
        // totalLey += ley;
        // console.log('ifSueldoMinimo SUELDO: ' + sueldo + ' LEY: ' + ley + ' SINDI: ' + sindicato + ' TOTALley: ' + totalLey + ' TOTALsindi: ' + totalSindicato);
        console.log('ifSueldoMinimo SUELDO: ' + sueldo + ' LEY: ' + (sueldo * pEmpresa) + ' SINDI: ' + (sueldo * pSindicato));
        console.log('ley: ' + ley);
    };
    return{ley, sindicato}
};

export const forEachEmpleadosParaCalculo = async (listaEmpleados, jsonEmpresaData) => {

    let ley = 0;
    let sindicato = 0;
    let totalLey = 0;
    let totalSindicato = 0;

    // Columna negra
    let totalSat = 0;
    let totalImss = 0;
    let totalImpNomina =0;
    let totalImssEmpresa = 0;
    let totalRcv = 0;
    let totalInfonavit = 0;

    let totalIsr = 0;
    let totalSueldos = 0;
    let totalSueldosBase = 0;

    let psIsrEmpleado = 0;
    let psImssEmpleado = 0;
    let psImpuestoNomina = 0;
    let psImssEmpresa = 0;
    let psRcv = 0;
    let psInfonavit = 0;

    let resta1 = 0;

    let disabledBtnResponse = true;

    const datosParaExcel = []; // Aquí almacenaremos los datos para el Excel

    for (const empleado of listaEmpleados) {

        if (!empleado.sueldo_bruto || isNaN(empleado.sueldo_bruto) || empleado.sueldo_bruto <= 0) {
            console.warn(`Empleado con sueldo inválido: ${empleado.sueldo_bruto}. Se omite este empleado.`);
            continue; // Saltar al siguiente empleado
        }

        console.log("sueldo_bruto: " + empleado.sueldo_bruto);
        // if (empleado.sueldo_bruto == 7467.9){
        if (empleado.sueldo_bruto == jsonEmpresaData.sueldo_forEachParaCalculo){
            console.log("sueldo_bruto es igual a salario minimo ");
            console.log("sueldo_forEachParaCalculo: " + jsonEmpresaData.sueldo_forEachParaCalculo);
            ley += empleado.sueldo_bruto;
            // totalLey += ley;
            console.log("ley: " + ley);
            // console.log("totalLey: " + totalLey);
            sindicato += 0;
            // totalSindicato += sindicato;
            console.log("sindicato: " + sindicato);
            // console.log("totalSindicato: " + totalSindicato);

            console.log('ifSueldoMinimo SUELDO: ' + empleado.sueldo_bruto + ' LEY: ' + (empleado.sueldo_bruto) + ' SINDI: 0');
        }else{
            console.log("sueldo_bruto es mayor a salario minimo ");
            console.log("tipoPeriodo: " + jsonEmpresaData.planSindical);

            let leyActualizado, sindicatoActualizado;

            switch(jsonEmpresaData.planSindical){
                case 'Empresa 60% - Sindicato 40%':
                    console.log('empleado.sueldo_bruto switch: ' + empleado.sueldo_bruto);
                    console.log('ley switch: ' + ley);
                    console.log('sindicato switch: ' + sindicato);

                    ({ley: leyActualizado, sindicato: sindicatoActualizado} = ifSueldoMinimo(empleado.sueldo_bruto, .60, .40, ley, sindicato, jsonEmpresaData.sueldo_forEachParaCalculo));
                    ley = leyActualizado;
                    sindicato = sindicatoActualizado;
                    // ifSueldoMinimo(empleado.sueldo_bruto, .60, .40, ley, sindicato, totalLey, totalSindicato);
                break;
                case 'Empresa 70% - Sindicato 30%':
                    ({ley: leyActualizado, sindicato: sindicatoActualizado} = ifSueldoMinimo(empleado.sueldo_bruto, .70, .30, ley, sindicato, jsonEmpresaData.sueldo_forEachParaCalculo));
                    ley = leyActualizado;
                    sindicato = sindicatoActualizado;
                break;
                case 'Empresa 80% - Sindicato 20%':
                    ({ley: leyActualizado, sindicato: sindicatoActualizado} = ifSueldoMinimo(empleado.sueldo_bruto, .80, .20, ley, sindicato, jsonEmpresaData.sueldo_forEachParaCalculo));
                    ley = leyActualizado;
                    sindicato = sindicatoActualizado;
                break;
                case 'Empresa 90% - Sindicato 10%':
                    ({ley: leyActualizado, sindicato: sindicatoActualizado} = ifSueldoMinimo(empleado.sueldo_bruto, .90, .10, ley, sindicato, jsonEmpresaData.sueldo_forEachParaCalculo));
                    ley = leyActualizado;
                    sindicato = sindicatoActualizado;
                break;
            }
        }

        totalLey += ley;
        totalSindicato += sindicato;
        console.log('totalLey: ' + totalLey + 'totalSindicato: ' + totalSindicato);

        var tipoNomina;
        switch(jsonEmpresaData.tipoPeriodo){
            case 'Mensual':
                tipoNomina = '30';
                break;
            case 'Quincenal':
                tipoNomina = '15';
                break;
            case 'Semanal':
                tipoNomina = '7';
                break;
            case 'Diario':
                tipoNomina = '1';
                break;
            case '10 días':
                tipoNomina = '10';
                break;
        }

        const jsonEmpleado = {
            sueldo_bruto: empleado.sueldo_bruto,
            dias_del_mes: tipoNomina,
            uma: jsonEmpresaData.uma,
            f_riesgo: jsonEmpresaData.primaRiesgo,
            salario_minimo: jsonEmpresaData.salarioMinimo
        };
        console.log('f_riesgo:' + jsonEmpresaData.primaRiesgo);
        console.log('dias_del_mes:' + jsonEmpleado.dias_del_mes);
        console.log('jsonEmpleado:', jsonEmpleado);
        // console.log('jsonEmpleado tipoNomina:', tipoNomina);
        const response = await guardaJsonEmpleadosYCalculoNomina(jsonEmpleado);
        disabledBtnResponse= response.disabledBtn;
        console.log('responsedisabledBtnResponse: ' + response.disabledBtn);
        // SUMATORIAS
        console.log('response: ' + response);
        console.log('*******: ', {response});
        // console.log('retencion_imss: ' + response.retencion_imss);

        totalSat += response.responseData.isr_sueldo || 0;
        totalImss += response.responseData.retencion_imss || 0;
        totalImpNomina += response.responseData.impuesto_sobre_nomina || 0;
        totalImssEmpresa += response.responseData.retencion_imss_empresa || 0;
        totalRcv += response.responseData.rcv_patronal || 0;
        totalInfonavit += response.responseData.rcv_infonavyt || 0;

        totalIsr += response.responseData.isr_subsido || 0;
        totalSueldos += response.responseData.sueldo_neto || 0;
        totalSueldosBase += empleado.sueldo_bruto;

         // Calcula los valores necesarios para el Excel
         const valoresParaExcel = calcularValoresParaEmpleado(empleado, response.responseData);
         // Agrega los valores al array de datos para el Excel
         datosParaExcel.push(valoresParaExcel);
    }

    // Después de completar todas las solicitudes, imprime el total final
    console.log('Datos para el Excel:', datosParaExcel);
    // Después de completar todas las solicitudes, imprime el total final
    console.log('**********************************************');
    console.log('TOTAL totalImss:', totalImss);
    console.log('TOTAL totalImssEmpresa:', totalImssEmpresa);
    console.log('TOTAL totalSat:', totalSat);
    console.log('TOTAL totalIsr:', totalIsr);
    console.log('TOTAL totalSueldos:', totalSueldos);
    console.log('TOTAL totalSueldosBase:', totalSueldosBase);
    console.log('TOTAL totalRcv:', totalRcv);
    console.log('**********************************************');
    console.log('ifSueldoMinimo SUELDO Ley sumatoria: ', ley);
    console.log('ifSueldoMinimo SUELDO Sindicato sumatoria: ', sindicato);
    const jsonPropuestaSindical ={
        sueldo_bruto: ley,
        dias_del_mes: tipoNomina,
        uma: jsonEmpresaData.uma,
        f_riesgo: jsonEmpresaData.primaRiesgo,
        salario_minimo: jsonEmpresaData.salarioMinimo
    }
    const responsePropuestaSindical = await guardaJsonEmpleadosYCalculoNomina(jsonPropuestaSindical);
    console.log("isr empleado ley: " + ley);
    psIsrEmpleado = responsePropuestaSindical.responseData.isr_sueldo;
    psImssEmpleado = responsePropuestaSindical.responseData.retencion_imss;
    psImpuestoNomina = responsePropuestaSindical.responseData.impuesto_sobre_nomina    ;
    psImssEmpresa = responsePropuestaSindical.responseData.retencion_imss_empresa;
    psRcv = responsePropuestaSindical.responseData.rcv_infonavyt;
    psInfonavit = responsePropuestaSindical.responseData.rcv_patronal;
    console.log("ps isr empleado: " + psIsrEmpleado);
    console.log("ps imss empleado: " + psImssEmpleado);
    console.log("ps impuesto sobre nomina: " + psImpuestoNomina);
    console.log("ps imss empresa: " + psImssEmpresa);
    console.log("ps rcv: " + psRcv);
    console.log("ps infonavit: " + psInfonavit);

    return {
        ley,
        sindicato,

        totalSat,
        totalImss,
        totalImpNomina,
        totalImssEmpresa,
        totalRcv,
        totalInfonavit,
        ctatotal: (totalSat + totalImss + totalImpNomina + totalImssEmpresa + totalRcv + totalInfonavit),

        totalIsr,
        totalSueldos,
        totalSueldosBase,

        psIsrEmpleado,
        psImssEmpleado,
        psImpuestoNomina,
        psImssEmpresa,
        psRcv,
        psInfonavit,
        psTotal: (psIsrEmpleado + psImssEmpleado + psImpuestoNomina + psImssEmpresa + psRcv + psInfonavit),

        restaIsrEmpleado: (totalSat - psIsrEmpleado),
        restaImssEmpleado: (totalImss - psImssEmpleado),
        restaImpuestoNomina: (totalImpNomina - psImpuestoNomina),
        restaImssEmpresa: (totalImssEmpresa - psImssEmpresa),
        restaRcv: (totalRcv - psRcv),
        restaInfonavit: (totalInfonavit - psInfonavit),

        disabledBtnResponse,

        datosParaExcel
    };
};

// Toma los datos de un solo empleado y calcula los valores necesarios para el Excel
const calcularValoresParaEmpleado = (empleado, response) => {
    console.log('EXCEL sueldo_bruto: ' + empleado.sueldo_bruto);
    console.log('EXCEL isr_subsido: ' + response.isr_subsido);
    console.log('EXCEL retencion_imss: ' + response.retencion_imss);
    console.log('EXCEL retencion_imss_empresa: ' + response.retencion_imss_empresa);
    // console.log('imss_total: ' + empleado.);
    // console.log('neto_Pagar: ' + empleado.);
    const imssTotal = response.retencion_imss + response.retencion_imss_empresa;
    const netoPagar = empleado.sueldo_bruto - response.isr_subsido - response.retencion_imss - response.retencion_imss_empresa - imssTotal;

    return {
        id: empleado.id,
        dias_periodo: response.constructor_data.dias_periodo,
        uma: response.constructor_data.valor_uma,
        salario_diario: response.constructor_data.salario_diario,
        salario_diario_integrado: response.constructor_data.sueldo_diario_integrado,
        veces_uma: response.constructor_data.veces_uma,
        sueldo: empleado.sueldo_bruto,
        isr_empleado: response.isr_sueldo,
        imss_empleado: response.retencion_imss,
        impuesto_sobre_nomina: response.impuesto_sobre_nomina,
        imss_empresa: response.retencion_imss_empresa,
        rcv: response.rcv_patronal,
        infonavit: response.rcv_infonavyt,

        // Nuevos campos a excel
        // FALTA: sueldo base de cotizacion
        limite_inferior: response.limite_inferior,
        excedente_limite_inferior: response.excedente,
        tasa_isr: response.tasa_isr,
        impuesto_marginal: response.imp_marginal,
        cuota_fija: response.cuota_fija,
        isr_sueldo: response.isr_sueldo,
        subsido_isr: response.isr_subsido,
        isr_a_retener: response.retencion_sat

        // sueldo_bruto: empleado.sueldo_bruto,
        // isr_subsido: response.isr_subsido,
        // retencion_imss: response.retencion_imss,
        // retencion_imss_empresa: response.retencion_imss_empresa,
        // imss_total: imssTotal,
        // neto_Pagar: netoPagar
    };
};


// ------------------------------ TOKEN ------------------------------
// Obtener access token y refresh token
export const getToken = async () => {
    const cookies = new Cookies();
    console.log("Si entra a la funcion");
    const url = API_URL + GET_TOKEN;

    const AccessJSON = {
        username: "SindicatoXMEX",
        password: "xmexAPI",
      };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(AccessJSON),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const data = await response.json();
    const accessToken = data.access;
    const refreshToken = data.refresh;
    // setTokens.AccessToken = accessToken;
    // setTokens.RefreshToken = refreshToken;

    cookies.set('accessToken', accessToken, {path: '/'});
    var cookieToken = cookies.get('accessToken');
    console.log('ESTE es el token: '+ cookieToken);
    console.log('Token de Acceso por COOKIE' + data.access);

    console.log("ESTOS SON LOS TOKENS:");
    console.log("Token de Acceso:", accessToken);
    console.log("Token de Refresco:", refreshToken);
};

// ------------------------------ EMPRESA ------------------------------
export const guardaEmpresa = async (jsonDatos) => {
    const cookies = new Cookies();
    const url = API_URL + ENDPOINT_EMPRESA;
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authotization': 'Bearer' + cookies.get('accessToken')
        }
    });
    if (response.status === 401) {
      // El código de estado 401 indica que el token es inválido o ha caducado
      // Toma medidas para cerrar la sesión del usuario o realizar acciones apropiadas
      alert('La sesión ha caducado. Por favor, vuelve a intentarlo.');
      window.location.href = '/'; // Redirige al usuario a la página de inicio de sesión
    } else if (response !== undefined) {
      // El token sigue siendo válido, puedes continuar con la lógica normal
      alert('Preafiliación exitosa');
      console.log('Preafiliación exitosa');
      return response.json();
    } else {
      // Ocurrió algún otro error en la solicitud (opcional: mostrar un mensaje de error)
      alert('Error');
      console.log('Error al preafiliarse');
      return null;
    }
};

// ------------------------------ EMPLEADOS ------------------------------
export const guardaJsonEmpleados = async (jsonDatos) => {
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMPLEADOS;
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        // body: jsonDatos,
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    if (response.status === 401) {
      alert('La sesión ha caducado. Por favor, vuelve a intentarlo.');
      window.location.href = '/'; // Redirige al usuario a la página de inicio de sesión
    } else if (response !== undefined) {
      console.log('Error en la respuesta:', await response.text());
    //   return response.json();
    } else {
      alert('Error');
      console.log('Error al preafiliarse');
      return null;
    }
};
export const forEachEmpleados = (listaEmpleados) => {
    listaEmpleados.forEach(async (empleado) => {
        // var tipoNomina;
        // switch(empleado.tipoNomina){
        //     case 'Semanal':
        //         tipoNomina = '7';
        //     break;
        //     case 'Quincenal':
        //         tipoNomina = '15';
        //     break;
        //     case 'Mensual':
        //         tipoNomina = '30';
        //     break;
        // }
        // Crea un nuevo objeto para cada empleado
        const jsonEmpleado = {
            noEmpleado: empleado.noEmpleado,
            nombre: empleado.nombre,
            apellido_paterno: empleado.apellido_paterno,
            apellido_materno: empleado.apellido_materno,
            sueldo_bruto: empleado.sueldo_bruto,
            sueldo_neto: empleado.sueldo_neto,
            empresa: empleado.empresa,
            // tipoNomina: tipoNomina
        };

        // Llama a la función para guardar el empleado
        await guardaJsonEmpleados(jsonEmpleado);
        console.log('jsonEmpleados: ' + jsonEmpleado);
        // console.log('jsonEmpleados tipoNomina: ' + jsonEmpleado.tipoNomina);
    });
};



// ------------------------------ AFILIADO ------------------------------
// Crea afiliado
export const guardaAfiliado = async (jsonDatos) => {
    const url = API_URL + ENDPOINT_POSTAFILIADO;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    })
    if (response.status === 401) {
        // El código de estado 401 indica que el token es inválido o ha caducado
        // Toma medidas para cerrar la sesión del usuario o realizar acciones apropiadas
        alert('La sesión ha caducado. Por favor, vuelve a intentarlo.');
        window.location.href = '/'; // Redirige al usuario a la página de inicio de sesión
      } else if (response !== undefined) {
          // El token sigue siendo válido, puedes continuar con la lógica normal
        //   alert('Preafiliación exitosa');
          console.log('Preafiliación exitosa');
        return response.json();
      } else {
        // Ocurrió algún otro error en la solicitud (opcional: mostrar un mensaje de error)
        alert('Error');
        console.log('Error al preafiliarse');
        return null;
      }
};

// Actualiza afiliado
export const actualizaAfiliado = async (jsonDatos) => {
    await getToken(); // Obtener el token
    const url = API_URL + ENDPOINT_POSTAFILIADO + jsonDatos.id + '/';
    console.log('id: ' + jsonDatos.id);
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    })
    if (response !== undefined) {
        // alert('Afiliación exitoso');
        console.log('Afiliación exitoso');
        return response.json();
    } else {
        alert('Error');
        console.log('Error al preafiliarse');
        return null;
    }

    // .then(function (response) {
    //     alert('Registro exitoso');
    //     console.log('Registro exitoso');
    // })
    // .catch(function (error) {
    //     alert('Error: ' + error + ' al registrarse');
    //     console.log('Error: ' + error + ' al registrarse');
    // });
    // return response.json();
};

export const actualizaAvatarAfiliado = async (imagenFile) => {
    const url = ENDPOINT_AVATAR;
    const formData = new FormData();
    formData.append('img_perfil', imagenFile);
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: formData
    });
};

//Obtiene datos del afiliado por ID
export const getAfiliadoById = async (id) => {
    const url = API_URL + ENDPOINT_POSTAFILIADO + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache", 
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });

    return response.json();
};

// ------------------------------ EMAIL ------------------------------
// Email automatico
export const emailAutomatico = async (jsonDatos) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMAILAUTO;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
};

export const emailAutomaticoRecoverPass = async (jsonDatos) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMAILAUTO_RECOVER_PASS;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
};

export const emailAutomaticoPreafiliacionExitosa = async (jsonDatos) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMAILAUTO_PREAFILIACION_EXITOSA;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
};

export const emailAutomaticoContinuarPreafiliacion = async (jsonDatos) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMAILAUTO_CONTINUAR_PREAFILIACION;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
};

export const emailAutomaticoCorreoRegistrado = async (jsonDatos) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const url = API_URL + ENDPOINT_EMAILAUTO_CORREO_REGISTRADO;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
};

// *************************************************************************************
// *************************************************************************************
export const chat_GPT = async (question) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    console.log('token: ' + cookies.get('accessToken'));
    console.log('token: ' + question);
    const url = API_URL + ENDPOINT_Chat_GPT;
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(question),
        // body: JSON.stringify({ "Tema o pregunta": question }),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookieToken
        }
    });
    return response.json();
};
