// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signatureCanvas_FirmaAfiliacion_SmallScreen{
    border-bottom: 3px solid #808080;
    width: 100%;
    height: 25vh;
    margin-bottom: 3%;
}
.signatureCanvas_FirmaAfiliacion_MediumScreen{
    border-bottom: 3px solid #808080;
    width: 80%;
    height: 25vh;
    margin-bottom: 3%;
}
.signatureCanvas_FirmaAfiliacion_LargeScreen{
    border-bottom: 3px solid #808080;
    width: 60%;
    height: 25vh;
    margin-bottom: 3%;
}
.signatureCanvas_FirmaAfiliacion_ExtraLargeScreen{
    border-bottom: 3px solid #808080;
    width: 40%;
    height: 25vh;
    margin-bottom: 3%;
}`, "",{"version":3,"sources":["webpack://./src/components/xmex/afiliacion/firmaAfiliacion/ScreenFirmaAfiliacion.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,UAAU;IACV,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,UAAU;IACV,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,UAAU;IACV,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".signatureCanvas_FirmaAfiliacion_SmallScreen{\n    border-bottom: 3px solid #808080;\n    width: 100%;\n    height: 25vh;\n    margin-bottom: 3%;\n}\n.signatureCanvas_FirmaAfiliacion_MediumScreen{\n    border-bottom: 3px solid #808080;\n    width: 80%;\n    height: 25vh;\n    margin-bottom: 3%;\n}\n.signatureCanvas_FirmaAfiliacion_LargeScreen{\n    border-bottom: 3px solid #808080;\n    width: 60%;\n    height: 25vh;\n    margin-bottom: 3%;\n}\n.signatureCanvas_FirmaAfiliacion_ExtraLargeScreen{\n    border-bottom: 3px solid #808080;\n    width: 40%;\n    height: 25vh;\n    margin-bottom: 3%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
