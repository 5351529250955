import React, { useState } from "react";
import "./Estilo_Cuestionario_Binario.css";;
import { PreguntaSiNo } from "./elementos_cuestionarios/PreguntaSiNo";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario12 = (props) => {

  //Copias a los cuestionarios
  const sigPag = "103";
  const pNombre = props.nombre;
  const pUserId = props.usuario;
  const pPerfId = props.perfil;
  const pIdEmpresa = getUrlParameter('empresaId');
  

  const cantidadTotalPreguntas= 2;
  var numPreguntas= 0;

  const [deseableState, setDeseableState] = useState(true)

  const [sessionJson, setSessionJson] = useState({
    referenciaId: 1,    //Dato estático
    seccionId: 2,       //Dato estático
    tipoPreguntaId: 1,  //Dato estático
    preguntaInicial: 7, //Dato estático
    nombre: props.nombre,
    link: ""
  });

  const [perfilJson, setPerfilJson] = useState({
    Id: props.perfil,
    usuarioId: props.usuario,
    qId: sigPag,
    EmpresaId: pIdEmpresa
  });

  const [respuestasJson, setRespuestasJson] = useState({
    respuestas: [
      { pregunta: 7, respuesta: 0 },
      { pregunta: 8, respuesta: 0 }
    ],
  });

  const updatePreg = (preg, resp, condPreguntaActiva) => {
    console.log(preg, resp);
    if(condPreguntaActiva==1){
      numPreguntas++;
    }
    if(numPreguntas==cantidadTotalPreguntas){
      setDeseableState(false);
    }
    const respuestas = { respuestasJson };
    respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
      pregunta: preg,
      respuesta: parseInt(resp)
      // Respuesta: resp
      // pregunta: preg,
      // respuesta: resp,
    };
    setRespuestasJson(respuestas.respuestasJson);
    console.log({ respuestasJson });
    
  };

  React.useEffect(() => {
    setSessionJson((prevState) => {
      return {
        ...prevState,
        link:
          "/cuestionario_" +
          sigPag +
          "?userId=" +
          pUserId +
          "&perfId=" +
          pPerfId +
          "&empresaId=" +
          pIdEmpresa +
          "&nombre=" +
          pNombre
      };
    });

    setPerfilJson(() => {
      return {
        Id: pPerfId,
        usuarioId: parseInt(pUserId),
        // usuarioId: pUserId,
        qId: sigPag,
        EmpresaId: parseInt(pIdEmpresa)
        // EmpresaId: pIdEmpresa
      };
    });
  }, []);

  return (
    <div className="box_menu_y_cuestionario_binario">
      <div className="cuestionario_div_binario">
        <div className="div_svg_menu_binario">
          <img
            src="./img/menu_cuestionarios/menu_c12.svg"
            className="img_svg_binario"
          />
        </div>
        <div className="text_cuestionario_binario">
          <img
            src="./img/barra_progreso/acontecimientos_traumaticos_severos/barra_de_progreso_12.svg"
            className="img_progreso_binario"
          />
          <p className="titulo_cuestionario_binario">
            <img src="./img/barra_progreso/iconos/traumas_icono_cuestionario.svg" />{" "}
            RECUERDOS PERSISTENTES SOBRE EL ACONTECIMIENTO
          </p>
          <div className="preguntas_binario_sn">
          <PreguntaSiNo
            numPreg="7"
            idSi="Si7"
            idNo="No7"
            rSi="1"
            rNo="0"
            texto="2-¿Ha tenido recuerdos recurrentes sobre el acontecimiento que le provocan malestares?"
            update={updatePreg}
          />

          <PreguntaSiNo
            numPreg="8"
            idSi="Si8"
            idNo="No8"
            rSi="1"
            rNo="0"
            texto="3-¿Ha tenido sueños de carácter recurrente sobre el acontecimiento, que le producen malestar?"
            update={updatePreg}
          />
          </div>

          <Button_CuestionarioSiguente
            className="btn_siguiente_cuestionario_binario"
            sesion={sessionJson}
            perfil={perfilJson}
            respuestas={respuestasJson}
            disabled={deseableState}
            upDate={"guardaRespuestas"}
            condicionPreg='100Restantes'
          />
        </div>
      </div>
    </div>
  );
};
