// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preguntas_Cuestionario12{
    font-size: 20px;
    text-align: justify;
    margin-bottom: -20px;
}
.rbtn_No_Cuestionario12{
    margin-top: -500px;
    margin-left: -50px;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/cuestionario/Cuestionario12.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".Preguntas_Cuestionario12{\n    font-size: 20px;\n    text-align: justify;\n    margin-bottom: -20px;\n}\n.rbtn_No_Cuestionario12{\n    margin-top: -500px;\n    margin-left: -50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
