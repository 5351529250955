// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box_DashAnalist_VistaGeneral{
    background: #FFFFFF;
    width: 85%;
    margin-top: -31.5vh;
    height: 89vh;
    margin-left: 14%;
    border-radius: 8px;
    box-shadow: 1px 1px 37px rgba(0, 0, 0, .15);
    background-color: rgba(41, 85, 153, 0.15);
}
.div_txt_TittleVistaGeneral_Analista{
    width: 50%;
    height: 5vh;
    display: inline-block;
    margin-left: 3%;
    margin-bottom: 5%;
    color: #FFFFFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    font-size: 1.7vw;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/analista/vista_general/VistaGeneral_DashAnalista.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,UAAU;IACV,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,2CAA2C;IAC3C,yCAAyC;AAC7C;AACA;IACI,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".box_DashAnalist_VistaGeneral{\n    background: #FFFFFF;\n    width: 85%;\n    margin-top: -31.5vh;\n    height: 89vh;\n    margin-left: 14%;\n    border-radius: 8px;\n    box-shadow: 1px 1px 37px rgba(0, 0, 0, .15);\n    background-color: rgba(41, 85, 153, 0.15);\n}\n.div_txt_TittleVistaGeneral_Analista{\n    width: 50%;\n    height: 5vh;\n    display: inline-block;\n    margin-left: 3%;\n    margin-bottom: 5%;\n    color: #FFFFFF;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 21px;\n    font-size: 1.7vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
