import React, { useState } from "react";
import './Estilo_Cuestionario_Binario.css';
import { PreguntaSiNo } from "./elementos_cuestionarios/PreguntaSiNo";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario13 = (props) => {

    const sigPag = "104";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 7;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true)
    
      const [sessionJson, setSessionJson] = useState({
        referenciaId: 1,    //Dato estático
        seccionId: 3,       //Dato estático
        tipoPreguntaId: 1,  //Dato estático
        preguntaInicial: 9, //Datao estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: props.perfil,
        usuarioId: props.usuario,
        qId: sigPag,
        EmpresaId: pIdEmpresa
      });

      const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 9, respuesta: 0 },
          { pregunta: 10, respuesta: 0 },
          { pregunta: 11, respuesta: 0 },
          { pregunta: 12, respuesta: 0 },
          { pregunta: 13, respuesta: 0 },
          { pregunta: 14, respuesta: 0 },
          { pregunta: 15, respuesta: 0 }
        ],
      });
    
      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: parseInt(pUserId),
            qId: sigPag,
            EmpresaId: parseInt(pIdEmpresa)
          };
        });
      }, []);
    
      const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg, resp);

        if(condPreguntaActiva==1){
          numPreguntas++;
        }

        const respuestas = { respuestasJson };
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: parseInt(resp)
          // respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson);
        console.log({ respuestasJson });
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
      };

    return (
        <div className="box_menu_y_cuestionario_binario">
            <div className="cuestionario_div_binario">
                <div className="div_svg_menu_binario">
                    <img src="./img/menu_cuestionarios/menu_c13.svg" className="img_svg_binario"/>
                </div>
                <div className="text_cuestionario_binario">
                    <img src="./img/barra_progreso/acontecimientos_traumaticos_severos/barra_de_progreso_13.svg" className="img_progreso_binario"/>
                    <p className="titulo_cuestionario_binario"><img src="./img/barra_progreso/iconos/traumas_icono_cuestionario.svg"/> ESFUERZO POR EVITAR CIRCUNSTANCIAS PARECIDAS O ASOCIADAS AL ACONTECIMIENTO</p>
                    <div className="preguntas_binario_sn">
                        <PreguntaSiNo
                            numPreg="9"
                            idSi="Si9"
                            idNo="No9"
                            rSi="1"
                            rNo="0"
                            texto="4-¿Se ha esforzado por evitar todo tipo de sentimientos, conversaciones o situaciones que le puedan recordar el acontecimiento?"
                            update={updatePreg}
                        />
                    
                        <PreguntaSiNo
                            numPreg="10"
                            idSi="Si10"
                            idNo="No10"
                            rSi="1"
                            rNo="0"
                            texto="5-¿Se ha esforzado por evitar todo tipo de actividades, lugares o personas que motivan recuerdos del acontecimiento?"
                            update={updatePreg}
                        />
                   
                        <PreguntaSiNo
                            numPreg="11"
                            idSi="Si11"
                            idNo="No11"
                            rSi="1"
                            rNo="0"
                            texto="6-¿Ha tenido dificultad para recordar alguna parte importante del evento?"
                            update={updatePreg}
                        />
                   
                        <PreguntaSiNo
                            numPreg="12"
                            idSi="Si12"
                            idNo="No12"
                            rSi="1"
                            rNo="0"
                            texto="7-¿Ha disminuido su interés en sus actividades cotidianas?"
                            update={updatePreg}
                        />
                    
                        <PreguntaSiNo
                            numPreg="13"
                            idSi="Si13"
                            idNo="No13"
                            rSi="1"
                            rNo="0"
                            texto="8-¿Se ha sentido usted alejado o distante de los demás?"
                            update={updatePreg}
                        />
                    
                        <PreguntaSiNo
                            numPreg="14"
                            idSi="Si14"
                            idNo="No14"
                            rSi="1"
                            rNo="0"
                            texto="9-¿Ha notado que tiene dificultad para expresar sus sentimientos?"
                            update={updatePreg}
                        />
                    
                        <PreguntaSiNo
                            numPreg="15"
                            idSi="Si15"
                            idNo="No15"
                            rSi="1"
                            rNo="0"
                            texto="10-¿Ha tenido la impresión de que su vida se va a acortar, que va a morir antes que otras personas o que tiene un futuro limitado?"
                            update={updatePreg}
                        />
                        </div>
                 
                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas"}
                      condicionPreg='100Restantes'
                    />

                </div>
            </div>
        </div>
    )
}
