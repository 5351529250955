import React from 'react';
import { TextField, FormControl, Box, InputLabel, Select, MenuItem, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BaseCalculoSelector } from './BaseCalculoSelector';
import { SubsidioEmpleoEditor } from './SubsidioEmpleoEditor';


export const NominaForm = ({
  jsonEmpresaData,
  setJsonEmpresaData,
  handleFileChange,
  handleUploadClick,
  jsonSubsidioData,
  setJsonSubsidioData,
  isLoading,
  file,
}) => {


  const nameEmpresaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      nameEmpresa: event.target.value,
    }));
  };

  const tipoPeriodoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      tipoPeriodo: event.target.value,
    }));
  };

  const periodoFiscalChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      periodoFiscal: event.target.value,
    }));
  };

  const esquemaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      esquema: event.target.value,
    }));
  };

  const planSindicalChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      planSindical: event.target.value,
    }));
  };

  const baseCalculoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      baseCalculo: event.target.value,
    }));
  };

  const primaRiesgoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      primaRiesgo: event.target.value,
    }));
  };

  const costoOperacionChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      costoOperacion: event.target.value,
    }));
  };

  const umaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      uma: event.target.value,
    }));
  };

  const salarioMinimoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => ({
      ...prevState,
      salarioMinimo: event.target.value,
    }));
  };

  const disabledCargarExcel =
    !jsonEmpresaData.nameEmpresa ||
    !jsonEmpresaData.baseCalculo ||
    !jsonEmpresaData.primaRiesgo ||
    !jsonEmpresaData.costoOperacion ||
    !jsonEmpresaData.periodoFiscal ||
    !jsonEmpresaData.tipoPeriodo ||
    !jsonEmpresaData.planSindical ||
    !jsonEmpresaData.esquema;

  return (
    <>
      <TextField
        type="text"
        label="Nombre de la empresa"
        color="primary"
        variant="filled"
        sx={{ width: '50%', marginTop: '2%', marginRight: '1.333%' }}
        onChange={nameEmpresaChangeHandler}
        value={jsonEmpresaData.nameEmpresa}
      />
      <TextField
        type="text"
        label="Prima de riesgo"
        color="primary"
        variant="filled"
        onChange={primaRiesgoChangeHandler}
        value={jsonEmpresaData.primaRiesgo}
        sx={{ width: '12%', marginTop: '2%', marginRight: '1.333%' }}
      />
      <TextField
        type="text"
        label="Costo de operación"
        color="primary"
        variant="filled"
        onChange={costoOperacionChangeHandler}
        value={jsonEmpresaData.costoOperacion}
        sx={{ width: '15%', marginTop: '2%', marginRight: '1.333%' }}
      />
      <FormControl fullWidth sx={{ width: '10%', marginTop: '2%', marginRight: '1.333%' }}>
        <InputLabel id="uma-select-label">UMA</InputLabel>
        <Select
          labelId="uma-select-label"
          id="uma-select"
          label="UMA"
          onChange={umaChangeHandler}
          value={jsonEmpresaData.uma}
        >
          <MenuItem value="113.14">113.14</MenuItem>
          <MenuItem value="108.57">108.57</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ width: '17.3%', marginTop: '2%' }}>
        <InputLabel id="salario-minimo-select-label">Salario mínimo</InputLabel>
        <Select
          labelId="salario-minimo-select-label"
          id="salario-minimo-select"
          label="Salario mínimo"
          onChange={salarioMinimoChangeHandler}
          value={jsonEmpresaData.salarioMinimo}
        >
          <MenuItem value="278.80">278.80</MenuItem>
          <MenuItem value="248.93">248.93</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ width: '24%', marginRight: '1.333%', marginTop: '2%' }}>
        <InputLabel id="periodo-fiscal-select-label">Periodo fiscal</InputLabel>
        <Select
          labelId="periodo-fiscal-select-label"
          id="periodo-fiscal-select"
          label="Periodo fiscal"
          onChange={periodoFiscalChangeHandler}
          value={jsonEmpresaData.periodoFiscal}
        >
          <MenuItem value="2025">2025</MenuItem>
          <MenuItem value="2024">2024</MenuItem>
          <MenuItem value="2023">2023</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ width: '24%', marginRight: '1.333%', marginTop: '2%' }}>
        <InputLabel id="tipo-periodo-select-label">Tipo de periodo</InputLabel>
        <Select
          labelId="tipo-periodo-select-label"
          id="tipo-periodo-select"
          label="Tipo de periodo"
          onChange={tipoPeriodoChangeHandler}
          value={jsonEmpresaData.tipoPeriodo}
        >
          <MenuItem value="Mensual">Mensual</MenuItem>
          <MenuItem value="Quincenal">Quincenal</MenuItem>
          <MenuItem value="Semanal">Semanal</MenuItem>
          <MenuItem value="Diario">Diario</MenuItem>
          <MenuItem value="10 días">10 días</MenuItem>
        </Select>
      </FormControl>
      <BaseCalculoSelector
        baseCalculoChangeHandler={baseCalculoChangeHandler}
        jsonEmpresaData={jsonEmpresaData}
      />
      <FormControl fullWidth sx={{ width: '24%', marginRight: '1.333%', marginTop: '2%' }}>
        <InputLabel id="plan-sindical-select-label">Distribución del cálculo</InputLabel>
        <Select
          labelId="plan-sindical-select-label"
          id="plan-sindical-select"
          label="Distribución del cálculo"
          onChange={planSindicalChangeHandler}
          value={jsonEmpresaData.planSindical}
        >
          <MenuItem value="Empresa 60% - Sindicato 40%">Empresa 60% - Sindicato 40%</MenuItem>
          <MenuItem value="Empresa 70% - Sindicato 30%">Empresa 70% - Sindicato 30%</MenuItem>
          <MenuItem value="Empresa 80% - Sindicato 20%">Empresa 80% - Sindicato 20%</MenuItem>
          <MenuItem value="Empresa 90% - Sindicato 10%">Empresa 90% - Sindicato 10%</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ width: '24%', marginTop: '2%' }}>
        <InputLabel id="esquema-select-label">Esquema de distribución</InputLabel>
        <Select
          labelId="esquema-select-label"
          id="esquema-select"
          label="Esquema de distribución"
          onChange={esquemaChangeHandler}
          value={jsonEmpresaData.esquema}
        >
          <MenuItem value="Indemnización">Indemnización</MenuItem>
        </Select>
      </FormControl>

      <SubsidioEmpleoEditor subsidioData={jsonSubsidioData} setSubsidioData={setJsonSubsidioData} />

      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '2%' }}>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            window.location.href =
              'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/documentos/templateNomina.xlsx';
          }}
          startIcon={<DownloadIcon />}
          style={{ color: '#FFFFFF' }}
          sx={{ borderRadius: 3 }}
        >
          <Typography sx={{ color: '#ffff' }}>Template</Typography>
        </Button>
        <Button
          component="label"
          variant="contained"
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          disabled={disabledCargarExcel}
          startIcon={<CloudUploadIcon sx={{ color: '#ffff' }} />}
          sx={{ borderRadius: 3, marginLeft: '2%' }}
        >
          <Typography sx={{ color: '#ffff' }}>Cargar excel</Typography>
          <input type="file" hidden />
        </Button>
        <Button
          variant="contained"
          onClick={handleUploadClick}
          disabled={!file}
          sx={{ marginLeft: '2%', borderRadius: 3, marginRight: '2%' }}
        >
          {isLoading ? 'Procesando...' : ''}
          <Typography sx={{ color: '#ffff' }}>Calcular</Typography>
        </Button>
      </Box>
    </>
  );
};