import React, { useState } from "react";
import './Cuestionario12.css';
import './Estilo_Cuestionario_Binario.css';
import { PreguntaSiNo } from "./elementos_cuestionarios/PreguntaSiNo";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario14 = (props) => {

    const sigPag = "201";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 5;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true)
    
      const [sessionJson, setSessionJson] = useState({
        referenciaId: 1,     //Dato estático
        seccionId: 4,        //Dato estático
        tipoPreguntaId: 1,   //Dato estático
        preguntaInicial: 16, //Datao estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: props.perfil,
        usuarioId: props.usuario,
        qId: sigPag,
        EmpresaId: pIdEmpresa
      });

      const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 16, respuesta: 0 },
          { pregunta: 17, respuesta: 0 },
          { pregunta: 18, respuesta: 0 },
          { pregunta: 19, respuesta: 0 },
          { pregunta: 20, respuesta: 0 }
        ],
      });
    
      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: parseInt(pUserId),
            qId: sigPag,
            EmpresaId: parseInt(pIdEmpresa)
          };
        });
      }, []);

      const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg, resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        const respuestas = { respuestasJson };
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: parseInt(resp)
        };
        setRespuestasJson(respuestas.respuestasJson);
        console.log({ respuestasJson });
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
      };

    return (
        <div className="box_menu_y_cuestionario_binario">
            <div className="cuestionario_div_binario">
                <div className="div_svg_menu_binario">
                    <img src="./img/menu_cuestionarios/menu_c14.svg" className="img_svg_binario"/>
                </div>
                <div className="text_cuestionario_binario">
                    <img src="./img/barra_progreso/acontecimientos_traumaticos_severos/barra_de_progreso_14.svg" className="img_progreso_binario"/>
                    <p className="titulo_cuestionario_binario"><img src="./img/barra_progreso/iconos/traumas_icono_cuestionario.svg"/> AFECTACIÓN</p>
                    <div className="preguntas_binario_sn">
                    <PreguntaSiNo
                            numPreg="16"
                            idSi="Si16"
                            idNo="No16"
                            rSi="1"
                            rNo="0"
                            texto="11-¿Ha tenido usted dificultades para dormir?"
                            update={updatePreg}
                    />
                    <PreguntaSiNo
                            numPreg="17"
                            idSi="Si17"
                            idNo="No17"
                            rSi="1"
                            rNo="0"
                            texto="12-¿Ha estado particularmente irritable o le han dado arranques de coraje?"
                            update={updatePreg}
                    />
                    <PreguntaSiNo
                            numPreg="18"
                            idSi="Si18"
                            idNo="No18"
                            rSi="1"
                            rNo="0"
                            texto="13-¿Ha tenido dificultad para concentrarse?"
                            update={updatePreg}
                    />
                    <PreguntaSiNo
                            numPreg="19"
                            idSi="Si19"
                            idNo="No19"
                            rSi="1"
                            rNo="0"
                            texto="14-¿Ha estado nervioso o constantemente en alerta?"
                            update={updatePreg}
                    />
                    <PreguntaSiNo
                            numPreg="20"
                            idSi="Si20"
                            idNo="No20"
                            rSi="1"
                            rNo="0"
                            texto="15-¿Se ha sobresaltado fácilmente por cualquier cosa?"
                            update={updatePreg}
                    />
                    </div>
                    
                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas"}
                      condicionPreg='100Restantes'
                    />
                     
                </div>
            </div>
        </div>
    )
}
