import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';

export const SubsidioEmpleoEditor = ({ subsidioData, setSubsidioData }) => {
  const handleChange = (index, field, value) => {
    const newData = [...subsidioData];
    newData[index][field] = value;
    setSubsidioData(newData);
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: '2%', marginBottom: '2%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Límite Inferior</TableCell>
            <TableCell>Límite Superior</TableCell>
            <TableCell>% Subsidio al Empleo enero 2025</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subsidioData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  type="number"
                  value={row.limiteInferior}
                  onChange={(e) => handleChange(index, 'limiteInferior', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={row.limiteSuperior}
                  onChange={(e) => handleChange(index, 'limiteSuperior', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={row.subsidio}
                  onChange={(e) => handleChange(index, 'subsidio', e.target.value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};