// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cuestionario_div{
    /* background: rgb(182, 70, 55); */
    background-color: rgb(243, 240, 240); 
    width: 78%;
    height: 750px;
    margin-left: 18%;
    display: block;
    border-radius: 15px;
    box-shadow: 7px 3px 37px rgba(0, 0, 0, .5);
}

.rbnt_div{
    text-align: left;
    margin-left: 12%;
}

.desc_cuestionario{
    font-size: 20px;
    margin-left: 5%;
}

.tittle_cuestionario{
    font-size: 28px;
    margin-left: 5%;
}

.Preguntas_Cuestionario{
    margin-left: 5%;
}

.img_Progreso{
    margin-left: 2%;
    margin-bottom: 2%;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/cuestionario/Cuestionario21.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,oCAAoC;IACpC,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,0CAA0C;AAC9C;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".cuestionario_div{\n    /* background: rgb(182, 70, 55); */\n    background-color: rgb(243, 240, 240); \n    width: 78%;\n    height: 750px;\n    margin-left: 18%;\n    display: block;\n    border-radius: 15px;\n    box-shadow: 7px 3px 37px rgba(0, 0, 0, .5);\n}\n\n.rbnt_div{\n    text-align: left;\n    margin-left: 12%;\n}\n\n.desc_cuestionario{\n    font-size: 20px;\n    margin-left: 5%;\n}\n\n.tittle_cuestionario{\n    font-size: 28px;\n    margin-left: 5%;\n}\n\n.Preguntas_Cuestionario{\n    margin-left: 5%;\n}\n\n.img_Progreso{\n    margin-left: 2%;\n    margin-bottom: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
