import React, { useState } from 'react';
import './Generales.css';
import { Link } from "react-router-dom";

export const Generales = () => {
    const [chkValue, setChkValue] = useState(true);
  return ( 
    <div
        style=
            {
                {
                    
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    
                    height: "100vh", 
                     
                    backgroundSize: "cover",
                    marginTop: "-4%",
                    
                    paddingTop: "4%",
                    
                }
            } 
    >
    <div className="App">
   
    </div>

    <Link to="/">
        <img
                    className='img_logoPB'
                    src='../img/pagina_publica/LOGO-SMI-CALADO 1.png'
                />
                </Link>

        <div className='box_Generales'>
            
            <p className='txt_Principal_Generales'>GENERALES / RESPONSABILIDADES</p>
            <div className='txt_Instrucciones_Generales'>
                <p>Bienvenid@</p>
                <p>Felicidades, estás por comenzar tu proceso de certificación en la NOM-035 para tu organización, a partir de este punto te empezaremos a explicar los generales que necesitamos del Perfil del  Administrador,  quien se convertirá en la persona encargada del control en este proceso de Certificación para tu empresa.</p>
                <p>Si tu serás la persona encargada de gestionar esta certificación en tu empresa, requerimos que nos proporciones cierta información sobre tu empresa, por lo que al aceptar estas condiciones, damos entendido que tu empresa está en total acuerdo en que te conviertas en el líder de este proyecto y que entiendes las responsabilidades que esto conlleva.</p>
                <p>Entre algunas de tus principales responsabilidades serán:</p>
                <p>-Completar el registro de datos personales de contacto<br></br>
                   -Registrar el perfil de la empresa<br></br>
                   -Realizarás la carga de información que requerimos de tus centros de trabajo<br></br>
                   -Notificar a los empleados de tu empresa sobre el proceso<br></br>
                   -Revisar el avance de encuestas entre los empleados seleccionados.<br></br>
                </p>
                <p>Recuerda que como Administrador, serás el encargado de capacitar a los usuarios internos sobre la herramienta, atender solicitudes de los cambios en los campos que sean requeridos para que tus colaboradores puedan ser sorteados correctamente.</p>
                <label className='lbl_Acepto_Generales'>
                <input
                    style=
                        {
                            {
                                // width: "80px", 
                                // height: "100vh", 
                                marginRight: "10px",
                                // paddingTop: "100px",
                            }
                        }  
                    type="checkbox" 
                    className='cbox_Acepto_Generales'
                    onClick={()=>setChkValue(!chkValue)}
                /> 
                ACEPTO
            </label>
            </div>
       


       


        <Link to="/">
            <button
                className='btn_ConocerMas_Generales'
                type='submit'
            >
                CONOCER MAS
            </button>
        </Link>
        <Link to="/registro_empresa">
            <button
                className='btn_Continuar_GeneralesAdministrador'
                type='submit'
                disabled={chkValue}
            >
                CONTINUAR
            </button>
        </Link>

        </div>
        
        
    </div>
    );
};
