import React, { useState, useRef } from 'react';
import { Box, Button, IconButton, TextField, Typography, Container, useTheme, useMediaQuery } from '@mui/material';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import DownloadIcon from '@mui/icons-material/Download';
import { NavBar_FirmaYAfiliacion } from '../NavBar_FirmaYAfiliacion';
import { Btn_afiliarse } from '../Btn_afiliarse';
import { actualizaAfiliado, actualizaAvatarAfiliado, guardaAfiliado } from '../../../../utils/xmex/services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CredencialPDF } from './CredencialPDF';
import AvatarEditor from 'react-avatar-editor';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

export const ScreenAfiliacion = () => {

  // Instancia para cookies
  const cookies = new Cookies();
    
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [datosAfiliado, setDatosAfiliado] = useState({
    val_id: 2,
    fecha_nac: '',
    nacionalidad: '',
    tel_casa: '',
    rfc: '',
    nombre_empresa: '',
    calle: '',
    no_exterior: '',
    no_interior: '0',
    cp: '',
    colonia: '',
    alcaldia: '',
    img_perfil: '0'
  });

  const [btnCredencialDisabled, setBtnCredencialDisabled] = useState(true);
  
  // useState para mandar la firma en base64 a la credencial
  const [firmaBase64, setFirmaBase64] = useState('');
  // useState para mandar el ID a la credencial
  const [idAfiliado, setIdAfiliado] = useState('');

  // useState para mandar el correo automatico de validacion de afiliacion
  // const [emailAuto, setEmailAuto] = useState({
  //   usuario: {
  //     nombre: cookies.get('cookieNombreCompleto'),
  //     email: ''
  //   }
  // });

  // disabled btn Guardar
  var disabledBtn = true;
  if(
      (datosAfiliado.fecha_nac != '') & (datosAfiliado.nacionalidad != '') 
      & (datosAfiliado.tel_casa != '') & (datosAfiliado.rfc != '') 
      & (datosAfiliado.calle != '') & (datosAfiliado.no_exterior != '') 
      & (datosAfiliado.cp != '') & (datosAfiliado.colonia != '') 
      & (datosAfiliado.alcaldia != '')
    ){
      disabledBtn = false;
    }
 
  // CHANGE HANDLERS
  const [base64Image, setBase64Image] = useState('');
  const inputFileRef = useRef(null);
  const editorRef = useRef(null);

  // const handleActualizarImagen = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const base64String = reader.result;
  //     const img = new Image();
  //     img.src = base64String;
  //     img.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       const context = canvas.getContext('2d');
  //       canvas.width = 170;
  //       canvas.height = 170;
  //       context.drawImage(img, 0, 0, 170, 170);
  //       const croppedImage = canvas.toDataURL();

  //       setDatosAfiliado((prevDatosAfiliado) => ({
  //         ...prevDatosAfiliado,
  //         img_perfil: croppedImage,
  //       }));  
  //     setBase64Image(base64String);
  //     };
  //   };
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleActualizarImagen = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      
      img.onload = () => {
        // Redimensiona la imagen a 250*250 píxeles
        const canvas = document.createElement('canvas');
        const maxDimension = 250;
        
        let width = img.width;
        let height = img.height;
  
        // Calcula las dimensiones de la imagen redimensionada
        if (width > height) {
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, width, height);
  
        // Obtén la imagen redimensionada como una cadena base64
        const resizedImageBase64 = canvas.toDataURL('image/png', .7); // Ajusta la calidad si es necesario
  
        // Guarda la imagen redimensionada en el estado
        setDatosAfiliado((prevDatosAfiliado) => ({
          ...prevDatosAfiliado,
          img_perfil: resizedImageBase64,
        }));
  
        setBase64Image(resizedImageBase64);
      };
    };
    
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const fecha_nacimientoAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, fecha_nac: event.target.value};
    });
  };
  const nacionalidadAfiliadoChangeHandler =(event) => {    
    setDatosAfiliado((prevState) => {
      return { ...prevState, nacionalidad: event.target.value };
    });
  };
  const tel_casaAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, tel_casa: event.target.value};
    });
  };
  const rfcAfiliadoChangeHandler = (event) => {

    const uppercaseValue = event.target.value.toUpperCase();

    setDatosAfiliado((prevState) => {
      return {...prevState, rfc: uppercaseValue};
    });
  };
  const empresaAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, nombre_empresa: event.target.value};
    });
  };
  const calleAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, calle: event.target.value};
    });
  };
  const no_exterioreriorlAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, no_exterior: event.target.value};
    });
  };
  const no_interioreriorlAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, no_interior: event.target.value};
    });
  };
  const cpAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, cp: event.target.value};
    });
  };
  const coloniaAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, colonia: event.target.value};
    });
  };
  const alcaldiaAfiliadoChangeHandler =(event) => {
    setDatosAfiliado((prevState) => {
      return {...prevState, alcaldia: event.target.value};
    });
  };

  //KeyDowns para restringir:
  const lettersKeyDownHandler = (event) => {
    const tecla = event.key;
    const teclasPermitidas = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
  
    // Permitir letras, espacios y teclas de control
    if (!/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]$/.test(tecla) && !teclasPermitidas.includes(tecla)) {
      event.preventDefault();
    }
  };

  const rfcKeyDownHandler = (event) => {
    const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
    const key = event.key;
  
    // Allow only uppercase letters, numbers, and control keys
    if (!/^[A-Za-z0-9]$/.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault();
    }
  };

  const numbersKeyDownHandler = (event) => {
    const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
    const key = event.key;
  
    // Allow only digits and control keys
    if (!/^[0-9]$/.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault();
    }
  };
  
  // Navega a template de afiliacion exitosa
  const navigate = useNavigate();
  const handleDownloadPDF = async () => {
    navigate('afiliacionExitosa');
  };

  return (
    <Box>
      <NavBar_FirmaYAfiliacion/>
      {isSmallScreen &&
        <Container sx={{marginBlock: '2vh'}}>
          <Container sx={{borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', paddingLeft: '2vw'}}>
            <Typography color='primary' sx={{fontSize: 22, fontWeight: 800, width: '100%'}}> 
              AFÍLIATE
            </Typography>
            <Typography sx={{fontSize: 16, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
              Estimado afiliado por favor llena cuidadosamente 
              cada campo, si hay algún error podrás modificarla.
            </Typography>
          </Container>

          {/* Base64: {base64Image} */}

          {/* BOX QUE CONTIENE EL AVARAR E INPUTS */}
          {/* <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '1%', marginBottom: '2%', marginLeft: '5%', width: '90%'}}> */}
          {/* <Box sx={{display: 'flex', justifyContent: 'center'}}> */}
          <Box >
          
          
          
          {/* BOX DE AVATAR CON EL UPDATE DEL MISMO */}
        
        
        
        
        
            <Box sx={{width: '100%'}}>
              <Box sx={{display: 'flex', justifyContent: 'center', marginBlock: '1vh'}}>
                {datosAfiliado.img_perfil !== '0' ? (
                  <AvatarEditor
                    ref={editorRef}
                    image={datosAfiliado.img_perfil}
                    width={170}
                    height={170}
                    border={50}
                    // borderRadius='50%'
                    color={[255, 255, 255, 0.7]}
                    scale={1}
                    rotate={0}
                  />
                ) : (
                  <img
                    src={src_url + 'afiliacion/sin_avatar.png'}
                    alt="Base64"
                    style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                  />
                )}
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton color='primary' aria-label="upload picture" component="label">
                  <input
                    ref={inputFileRef}
                    hidden accept="image/*"
                    type="file"
                    onChange={handleActualizarImagen}
                  />
                  <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                </IconButton>
              </Box>
            </Box>
        
        
        
        
        
        
        
        
        
            {/* <Box sx={{width: '15%', marginRight: '3%'}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  {
                    base64Image ? 
                    // datosAfiliado.img_perfil != '0' ? 
                    <img 
                      src={base64Image} 
                      // src={datosAfiliado.img_perfil} 
                      alt='Base64' 
                      style={{
                        height: '170px',
                        width: '170px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                    /> 
                    : 
                    <img 
                      src={src_url + 'afiliacion/sin_avatar.png'} 
                      alt='Base64' 
                      style={{
                        // width: '20ch'
                        height: '170px',
                        width: '170px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                    /> 
                  }
                </figure>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton color='primary' aria-label="upload picture" component="label">
                  <input 
                    hidden accept='image/*' 
                    type='file'
                    onChange={handleActualizarImagen}
                  />
                  <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                </IconButton>
              </Box>
            </Box> */}





            {/* BOX DE INPUTS DE REGISTRO */}
            <Container>
              <Box sx={{marginBlock: '2vh'}}>
                
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Datos generales
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Nacionalidad' 
                  variant='filled' 
                  inputProps={{maxLength: 18}}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={nacionalidadAfiliadoChangeHandler}
                  onKeyDown={lettersKeyDownHandler}
                  />
                  <TextField type= 'date' color='primary' label='Nacimiento' variant='filled' sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} InputLabelProps={{shrink: true}}onChange={fecha_nacimientoAfiliadoChangeHandler}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Tel. Casa'
                  inputProps={{ maxLength: 12 }} 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={tel_casaAfiliadoChangeHandler}
                  />
                  <TextField 
                  type='text'
                  color='primary' 
                  label='RFC' 
                  variant='filled' 
                  inputProps={{ maxLength: 18 }}
                  onKeyDown={rfcKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} 
                  value={datosAfiliado.rfc}
                  onChange={rfcAfiliadoChangeHandler}
                  />
                </Box>
               
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Dato laboral
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Empresa donde labora' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 32}} 
                  onChange={empresaAfiliadoChangeHandler}
                  />
                </Box>

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Domicilio
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Calle' 
                  variant='filled' 
                  inputProps={{maxLength: 32}} 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={calleAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='No ext.' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_exterioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='No int.' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%',marginRight: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_interioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='CP' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 8 }}
                  onChange={cpAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Colonia' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginRight: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={coloniaAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Alcaldía' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={alcaldiaAfiliadoChangeHandler}
                  />
                </Box>

              </Box>
              {/* BOTONES */}
              <Box sx={{textAlign: 'right', marginBottom: '3vh'}}>
                <Btn_afiliarse
                  btnCredencialDisabled={setBtnCredencialDisabled}
                  json={datosAfiliado}
                  // nameAfiliado={nombreCompleto}
                  // link='afiliacionExitosa'
                  // jsonBase64Img={datosAfiliado}
                  update={actualizaAfiliado}
                  method='PUT'
                  disabled={disabledBtn}
                  label='GUARDAR'
                  setFirmaBase64={setFirmaBase64}
                  setIdAfiliado={setIdAfiliado}
                  signatureEmailName='si'
                />
                {/* Btn PDF */}
                <PDFDownloadLink 
                  document={
                    <CredencialPDF
                      name={cookies.get('cookieNombreCompleto')}
                      signature={firmaBase64}
                      id={idAfiliado}
                      avatar={base64Image}
                    />
                  }
                  fileName={'Credencial_' + cookies.get('cookieNombreCompleto') + '.pdf'}
                  onClick={handleDownloadPDF}
                >
                  <Button
                    color='primary' 
                    variant='contained'
                    startIcon={<DownloadIcon/>}
                    sx={{borderRadius: 3, marginLeft: '2%'}} 
                    style={{color: '#FFFFFF'}}
                    disabled={btnCredencialDisabled}
                  >
                    <Typography>
                      CREDENCIAL
                    </Typography>
                  </Button>
                </PDFDownloadLink>    
              </Box>
            </Container>
          </Box>
        </Container>
      }
      {isMediumScreen &&
        <Container sx={{marginBlock: '2vh'}}>
          <Container sx={{borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', paddingLeft: '2vw'}}>
            <Typography color='primary' sx={{fontSize: 22, fontWeight: 800, width: '100%'}}> 
              AFÍLIATE
            </Typography>
            <Typography sx={{fontSize: 16, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
              Estimado afiliado, por favor llena cuidadosamente 
              cada campo, si hay algún error podrás modificarla.
            </Typography>
          </Container>
          {/* BOX QUE CONTIENE EL AVARAR E INPUTS */}
          <Box>
            {/* BOX DE AVATAR CON EL UPDATE DEL MISMO */}
            <Box sx={{width: '100%'}}>
              <Box sx={{display: 'flex', justifyContent: 'center', marginBlock: '1vh'}}>
                {datosAfiliado.img_perfil !== '0' ? (
                  <AvatarEditor
                    ref={editorRef}
                    image={datosAfiliado.img_perfil}
                    width={170}
                    height={170}
                    border={50}
                    // borderRadius='50%'
                    color={[255, 255, 255, 0.7]}
                    scale={1}
                    rotate={0}
                  />
                ) : (
                  <img
                    src={src_url + 'afiliacion/sin_avatar.png'}
                    alt="Base64"
                    style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                  />
                )}
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton color='primary' aria-label="upload picture" component="label">
                  <input
                    ref={inputFileRef}
                    hidden accept="image/*"
                    type="file"
                    onChange={handleActualizarImagen}
                  />
                  <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                </IconButton>
              </Box>
            </Box>
            {/* BOX DE INPUTS DE REGISTRO */}
            <Container>
              <Box sx={{marginBlock: '2vh'}}>
                
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Datos generales
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Nacionalidad' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 18}}
                  onChange={nacionalidadAfiliadoChangeHandler}
                  onKeyDown={lettersKeyDownHandler}
                  />
                  <TextField type= 'date' color='primary' label='Fecha de nacimiento' variant='filled' sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} InputLabelProps={{shrink: true}}onChange={fecha_nacimientoAfiliadoChangeHandler}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Teléfono de casa' 
                  inputProps={{ maxLength: 12 }}
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={tel_casaAfiliadoChangeHandler}
                  />
                  <TextField 
                  type='text'
                  color='primary' 
                  label='RFC' 
                  variant='filled' 
                  inputProps={{ maxLength: 18 }}
                  onKeyDown={rfcKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} 
                  value={datosAfiliado.rfc}
                  onChange={rfcAfiliadoChangeHandler}
                  />
                </Box>

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Dato laboral
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Empresa donde labora' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 32}} 
                  onChange={empresaAfiliadoChangeHandler}
                  />
                </Box>

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Domicilio
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Calle' 
                  variant='filled' 
                  inputProps={{maxLength: 32}} 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={calleAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='No ext.' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_exterioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='No int.' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%',marginRight: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_interioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='CP' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}}
                  inputProps={{ maxLength: 8 }} 
                  onChange={cpAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Colonia' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginRight: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={coloniaAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Alcaldía' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={alcaldiaAfiliadoChangeHandler}
                  />
                </Box>

              </Box>
              {/* BOTONES */}
              <Box sx={{textAlign: 'right', marginBottom: '3vh'}}>
                <Btn_afiliarse
                  btnCredencialDisabled={setBtnCredencialDisabled}
                  json={datosAfiliado}
                  // nameAfiliado={nombreCompleto}
                  // link='afiliacionExitosa'
                  // jsonBase64Img={datosAfiliado}
                  update={actualizaAfiliado}
                  method='PUT'
                  disabled={disabledBtn}
                  label='GUARDAR'
                  setFirmaBase64={setFirmaBase64}
                  setIdAfiliado={setIdAfiliado}
                  signatureEmailName='si'
                />
                {/* Btn PDF */}
                <PDFDownloadLink 
                  document={
                    <CredencialPDF
                      name={cookies.get('cookieNombreCompleto')}
                      signature={firmaBase64}
                      id={idAfiliado}
                      avatar={base64Image}
                    />
                  }
                  fileName={'Credencial_' + cookies.get('cookieNombreCompleto') + '.pdf'}
                  onClick={handleDownloadPDF}
                >
                  <Button
                    color='primary' 
                    variant='contained'
                    startIcon={<DownloadIcon/>}
                    sx={{borderRadius: 3, marginLeft: '2%'}} 
                    style={{color: '#FFFFFF'}}
                    disabled={btnCredencialDisabled}
                  >
                    <Typography>
                      CREDENCIAL
                    </Typography>
                  </Button>
                </PDFDownloadLink>    
              </Box>
            </Container>
          </Box>
        </Container>
      }
      {isLargeScreen &&
        <Box>
          <Box sx={{marginLeft: '5%', marginTop: '2%', width: '90%', }}>
            <Box sx={{borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303',width: '100%'}}>
              <Typography color='primary' sx={{fontSize: 40, fontWeight: 800, width: '60%', marginLeft: '1%'}}> 
                AFÍLIATE
              </Typography>
              <Typography sx={{fontSize: 20, fontWeight: 400, color: '#303030', width: '60%', textAlign: 'justify', marginLeft: '1%'}}>
                Estimado afiliado, por favor llena cuidadosamente 
                cada campo, si hay algún error podrás modificarla.
              </Typography>
            </Box>
          </Box>
          {/* BOX QUE CONTIENE EL AVARAR E INPUTS */}
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '2%', marginLeft: '5%', width: '90%'}}>
            {/* BOX DE AVATAR CON EL UPDATE DEL MISMO */}
            <Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                {datosAfiliado.img_perfil !== '0' ? (
                  <AvatarEditor
                    ref={editorRef}
                    image={datosAfiliado.img_perfil}
                    width={170}
                    height={170}
                    border={50}
                    // borderRadius='50%'
                    color={[255, 255, 255, 0.7]}
                    scale={1}
                    rotate={0}
                  />
                ) : (
                  <img
                    src={src_url + 'afiliacion/sin_avatar.png'}
                    alt="Base64"
                    style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                  />
                )}
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton color='primary' aria-label="upload picture" component="label">
                  <input
                    ref={inputFileRef}
                    hidden accept="image/*"
                    type="file"
                    onChange={handleActualizarImagen}
                  />
                  <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                </IconButton>
              </Box>
            </Box>
            {/* BOX DE INPUTS DE REGISTRO */}
            <Box sx={{width: '70%'}}>
              <Box sx={{width: '95%', marginLeft: '5%'}}>
                
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Datos generales
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField type= 'date' color='primary' label='Fecha de nacimiento' variant='filled' sx={{width: '100%', marginBottom: '2%'}} InputLabelProps={{shrink: true}}onChange={fecha_nacimientoAfiliadoChangeHandler}/>
                  <TextField 
                  color='primary' 
                  label='Teléfono de casa' 
                  inputProps={{ maxLength: 12 }}
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} 
                  onChange={tel_casaAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Nacionalidad' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 18}}
                  onChange={nacionalidadAfiliadoChangeHandler}
                  onKeyDown={lettersKeyDownHandler}
                  />
                  <TextField 
                  type='text'
                  color='primary' 
                  label='RFC' 
                  variant='filled' 
                  inputProps={{ maxLength: 18 }}
                  onKeyDown={rfcKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}}
                  value={datosAfiliado.rfc} 
                  onChange={rfcAfiliadoChangeHandler}
                  />
                </Box>

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Dato laboral
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Empresa donde labora' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 32}} 
                  onChange={empresaAfiliadoChangeHandler}
                  />
                </Box>  

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Domicilio
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Calle' 
                  variant='filled' 
                  inputProps={{maxLength: 32}} 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={calleAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Número exterior' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_exterioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Número interior' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%',marginRight: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_interioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='CP' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 8 }}
                  onChange={cpAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Colonia' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginRight: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={coloniaAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Alcaldía' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={alcaldiaAfiliadoChangeHandler}
                  />
                </Box>

              </Box>
              {/* BOTONES */}
              <Box sx={{width: '95%', textAlign: 'right'}}>
                <Btn_afiliarse
                  btnCredencialDisabled={setBtnCredencialDisabled}
                  json={datosAfiliado}
                  // nameAfiliado={nombreCompleto}
                  // link='afiliacionExitosa'
                  // jsonBase64Img={datosAfiliado}
                  update={actualizaAfiliado}
                  method='PUT'
                  disabled={disabledBtn}
                  label='GUARDAR'
                  setFirmaBase64={setFirmaBase64}
                  setIdAfiliado={setIdAfiliado}
                  signatureEmailName='si'
                />
                {/* Btn PDF */}
                <PDFDownloadLink 
                  document={
                    <CredencialPDF
                      name={cookies.get('cookieNombreCompleto')}
                      signature={firmaBase64}
                      id={idAfiliado}
                      avatar={base64Image}
                    />
                  }
                  fileName={'Credencial_' + cookies.get('cookieNombreCompleto') + '.pdf'}
                  onClick={handleDownloadPDF}
                >
                  <Button
                    color='primary' 
                    variant='contained'
                    startIcon={<DownloadIcon/>}
                    sx={{borderRadius: 3, marginLeft: '2%'}} 
                    style={{color: '#FFFFFF'}}
                    disabled={btnCredencialDisabled}
                  >
                    <Typography>
                      CREDENCIAL
                    </Typography>
                  </Button>
                </PDFDownloadLink>    
              </Box>
            </Box>
          </Box>
        </Box>
      }
      {isExtraLargeScreen &&
        <Box>
          <Box sx={{marginLeft: '5%', marginTop: '2%', width: '90%', }}>
            <Box sx={{borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303',width: '100%'}}>
              <Typography color='primary' sx={{fontSize: 40, fontWeight: 800, width: '60%', marginLeft: '1%'}}> 
                AFÍLIATE
              </Typography>
              <Typography sx={{fontSize: 20, fontWeight: 400, color: '#303030', width: '60%', textAlign: 'justify', marginLeft: '1%'}}>
                Estimado afiliado, por favor llena cuidadosamente 
                cada campo, si hay algún error podrás modificarla.
              </Typography>
            </Box>
          </Box>
          {/* BOX QUE CONTIENE EL AVARAR E INPUTS */}
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '2%', marginLeft: '5%', width: '90%'}}>
            {/* BOX DE AVATAR CON EL UPDATE DEL MISMO */}
            <Box sx={{width: '15%', marginRight: '3%'}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <figure>
                {datosAfiliado.img_perfil !== '0' ? (
                  <AvatarEditor
                    ref={editorRef}
                    image={datosAfiliado.img_perfil}
                    width={170}
                    height={170}
                    border={50}
                    // borderRadius='50%'
                    color={[255, 255, 255, 0.7]}
                    scale={1}
                    rotate={0}
                  />
                ) : (
                  <img
                    src={src_url + 'afiliacion/sin_avatar.png'}
                    alt="Base64"
                    style={{height: '170px', width: '170px', borderRadius: '50%', objectFit: 'cover'}}
                  />
                )}
              </figure>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <IconButton color='primary' aria-label="upload picture" component="label">
                  <input
                    ref={inputFileRef}
                    hidden accept="image/*"
                    type="file"
                    onChange={handleActualizarImagen}
                  />
                  <AddAPhotoRoundedIcon sx={{fontSize: 40}}/>
                </IconButton>
              </Box>
            </Box>
            {/* BOX DE INPUTS DE REGISTRO */}
            <Box sx={{marginLeft: '3%', width: '60%', marginTop: '1%'}}>
              <Box sx={{width: '90%', marginLeft: '5% '}}>
                
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Datos generales
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField type= 'date' color='primary' label='Fecha de nacimiento' variant='filled' sx={{width: '100%', marginBottom: '2%'}} InputLabelProps={{shrink: true}}onChange={fecha_nacimientoAfiliadoChangeHandler}/>
                  <TextField 
                  color='primary' 
                  label='Teléfono de casa' 
                  inputProps={{ maxLength: 12 }}
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}} 
                  onChange={tel_casaAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Nacionalidad' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{maxLength: 18}}
                  onChange={nacionalidadAfiliadoChangeHandler}
                  onKeyDown={lettersKeyDownHandler}
                  />
                  <TextField 
                  type='text'
                  color='primary' 
                  label='RFC' 
                  variant='filled' 
                  inputProps={{ maxLength: 18 }}
                  onKeyDown={rfcKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%'}}
                  value={datosAfiliado.rfc} 
                  onChange={rfcAfiliadoChangeHandler}
                  />
                </Box>

                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Dato laboral
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Empresa donde labora' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%'}}
                  inputProps={{maxLength: 32}} 
                  onChange={empresaAfiliadoChangeHandler}
                  />
                </Box>
                
                <Box sx={{borderWidth: '2px', borderBottomStyle: 'solid', borderBottomColor: '#A35709', marginBottom: '2%', width: '100%'}}>
                  <Typography color='primary' sx={{fontSize: 24, fontWeight: 500, width: '60%', marginLeft: '1%'}}>
                    Domicilio
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Calle' 
                  variant='filled' 
                  inputProps={{maxLength: 32}} 
                  sx={{width: '100%', marginBottom: '2%'}} 
                  onChange={calleAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Número exterior' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_exterioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Número interior' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '2%',marginRight: '2%'}} 
                  inputProps={{ maxLength: 4 }}
                  onChange={no_interioreriorlAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='CP' 
                  variant='filled' 
                  onKeyDown={numbersKeyDownHandler}
                  sx={{width: '100%', marginBottom: '2%'}} 
                  inputProps={{ maxLength: 8 }}
                  onChange={cpAfiliadoChangeHandler}
                  />
                </Box>
                <Box sx={{display: 'flex',flexDirection: 'row'}}>
                  <TextField 
                  color='primary' 
                  label='Colonia' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginRight: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={coloniaAfiliadoChangeHandler}
                  />
                  <TextField 
                  color='primary' 
                  label='Alcaldía' 
                  variant='filled' 
                  sx={{width: '100%', marginBottom: '2%', marginLeft: '1%'}} 
                  inputProps={{maxLength: 28}} 
                  onChange={alcaldiaAfiliadoChangeHandler}
                  />
                </Box>

              </Box>
              {/* BOTONES */}
              <Box sx={{width: '95%', textAlign: 'right'}}>
                <Btn_afiliarse
                  btnCredencialDisabled={setBtnCredencialDisabled}
                  json={datosAfiliado}
                  // nameAfiliado={nombreCompleto}
                  // link='afiliacionExitosa'
                  // jsonBase64Img={datosAfiliado}
                  update={actualizaAfiliado}
                  method='PUT'
                  disabled={disabledBtn}
                  label='GUARDAR'
                  setFirmaBase64={setFirmaBase64}
                  setIdAfiliado={setIdAfiliado}
                  signatureEmailName='si'
                />
                {/* Btn PDF */}
                  <PDFDownloadLink 
                    document={
                      <CredencialPDF
                        name={cookies.get('cookieNombreCompleto')}
                        signature={firmaBase64}
                        id={idAfiliado}
                        avatar={base64Image}
                      />
                    }
                    fileName={'Credencial_' + cookies.get('cookieNombreCompleto') + '.pdf'}
                    onClick={handleDownloadPDF}
                  >
                  {/* <Link to='afiliacionExitosa'> */}
                    <Button
                      color='primary' 
                      variant='contained'
                      startIcon={<DownloadIcon/>}
                      sx={{borderRadius: 3, marginLeft: '2%'}} 
                      style={{color: '#FFFFFF'}}
                      disabled={btnCredencialDisabled}
                    >
                      <Typography>
                        CREDENCIAL
                      </Typography>
                    </Button>
                  </PDFDownloadLink>    
                {/* </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
};
