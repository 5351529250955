import React, { useState } from "react"; 
import './Estilo_Cuestionario_Escala.css';
import { PreguntaEscalaSN } from "./elementos_cuestionarios/PreguntaEscalaSN";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario21 = (props) => {
  
  const sigPag = "202";
  const pNombre = props.nombre;
  const pUserId = props.usuario;
  const pPerfId = props.perfil;
  const pIdEmpresa = getUrlParameter('empresaId');
  
    const cantidadTotalPreguntas= 9;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);

      const [sessionJson, setSessionJson] = useState({
        referenciaId: 2,    //Dato estático
        seccionId: 5,       //Dato estático
        tipoPreguntaId: 2,  //Dato estático
        preguntaInicial: 1, //Datao estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        usuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });
      console.log('json: ' + perfilJson.EmpresaId);

    const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 1, respuesta: 0 },
          { pregunta: 2, respuesta: 0 },
          { pregunta: 3, respuesta: 0 },
          { pregunta: 4, respuesta: 0 },
          { pregunta: 5, respuesta: 0 },
          { pregunta: 6, respuesta: 0 },
          { pregunta: 7, respuesta: 0 },
          { pregunta: 8, respuesta: 0 },
          { pregunta: 9, respuesta: 0 }
        ]
      });

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);
      
    const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg,resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
        console.log("clics en la misma pregunta: "+condPreguntaActiva);
        console.log("numero de preguntas contestadas: "+numPreguntas)
        const respuestas = {respuestasJson};
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson );
        console.log({ respuestas });
      };
      
    return (
        <div className="box_menu_y_cuestionario_escala">
            <div className="cuestionario_div_escala">
                <div className="div_svg_menu_escala">
                    <img 
                      src="./img/menu_cuestionarios/menu_c21.svg" 
                      className="img_svg_escala"/>
                </div>
                <div className="text_cuestionario_escala">
                    <img 
                      src="./img/barra_progreso/riesgo_psicosocial/barra_de_progreso_21.svg" 
                      className="img_progreso_escala"
                    />
                    <p className="titulo_cuestionario_escala">
                      <img src="./img/barra_progreso/iconos/psicosocial_icono_cuestionario.svg"
                      />  
                      RIESGO PSICOSOCIAL
                    </p>

                    <p className="instrucciones_cuestionario_escala">Para responder las preguntas siguientes considere las condiciones de su centro de trabajo, así como la cantidad y ritmo de
                    trabajo.</p>
                    <div className="body_escalas">
                    <div className="titulos_escalas">
                    <table>
                    <tbody>
                    <tr>
      
                        <td><p className="box_titulos_escalas">Siempre</p></td>
                        <td><p className="box_titulos_escalas">Casi<br></br>siempre</p></td>
                        <td><p className="box_titulos_escalas">Algunas<br></br>veces</p></td>
                        <td><p className="box_titulos_escalas_1">Casi<br></br>nunca</p></td>
                        <td><p className="box_titulos_escalas_2">Nunca</p></td>   
                    </tr>
                    </tbody>
                    </table>
                    </div>

                    
                   <div className="preguntas_escala_sn">
                      
                      <PreguntaEscalaSN
                        numPreg="1"
                        texto="Mi trabajo me exige hacer mucho esfuerzo físico"
                        update={updatePreg}
                        rSiempre= {4}
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                                    
                      <PreguntaEscalaSN
                        numPreg="2"
                        texto="Me preocupa sufrir un accidente en mi trabajo"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="3"
                        texto="Considero que las actividades que realizo son peligrosas"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="4"
                        texto="Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="5"
                        texto="Por la cantidad de trabajo que tengo debo trabajar sin parar"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="6"
                        texto="Considero que es necesario mantener un ritmo de trabajo acelerado"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="7"
                        texto="Mi trabajo exige que esté muy concentrado"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="8"
                        texto="Mi trabajo requiere que memorice mucha información"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="9"
                        texto="Mi trabajo exige que atienda varios asuntos al mismo tiempo"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />

                    </div>
                    </div>
            
                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas"}
                      condicionPreg='200Restantes'
                    />

                </div>
            </div>
        </div>
    );
};
