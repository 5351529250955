import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/convenios/';

export const Convenios = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen &&
            <Box sx={{
                    backgroundColor: '#F5F5F5', 
                    // backgroundColor: 'lightblue', 
                    width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flex-root'}>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{marginBlock: '2vh'}}>
                            {/* TEXTOS */}
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                                <Typography color='primary' sx={{fontSize: 20, fontWeight: 700, textAlign: 'center'}}>
                                    NUESTROS CONVENIOS
                                </Typography>
                                <Typography color='primary' sx={{fontSize: 27, fontWeight: 700, textAlign: 'center'}}>
                                    PARA NUESTROS AFILIADOS
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 14, fontWeight: 400, color: '#757575',width: '90%',textAlign: 'center'}}>
                                        Al unirse a Sindicato por México, usted se 
                                        convierte en parte de una comunidad unida 
                                        de trabajadores que comparten sus objetivos 
                                        y preocupaciones. Como miembro, podrá disfrutar 
                                        de beneficios como:
                                    </Typography>
                                </Box>
                            </Box>
                            {/* GRID */}
                            <Grid container spacing={0} >
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'descuentos.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575', textAlign: 'center'}}>
                                                Descuentos para afiliados
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'consulta_medica.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575', textAlign: 'center'}}>
                                                Consulta médica gratuita
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'indemnizacion.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'65%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575', textAlign: 'center'}}>
                                                Indemnización por riesgo laboral
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'asistencia_psicologica.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575', textAlign: 'center'}}>
                                                Asistencia psicológica
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'seguro_muerte.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'75%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575', textAlign: 'center'}}>
                                                Seguro por muerte accidental
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'pension.png'}
                                                style={{width: '100px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'80%'}}>
                                            <Typography sx={{fontSize: 12, color: '#757575',textAlign: 'center'}}>
                                                Plan de pensiones sindicales
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isMediumScreen &&
            <Box sx={{
                    backgroundColor: '#F5F5F5', 
                    // backgroundColor: 'lightblue', 
                    width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flex-root'}>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{marginBlock: '2vh'}}>
                            {/* TEXTOS */}
                            <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center',width: '100%',}}>
                                <Typography color='primary' sx={{fontSize: 25, fontWeight: 700, textAlign: 'center'}}>
                                    NUESTROS CONVENIOS
                                </Typography>
                                <Typography color='primary' sx={{fontSize: 35, fontWeight: 700, textAlign: 'center'}}>
                                    PARA NUESTROS AFILIADOS
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 17, fontWeight: 400, color: '#757575',width: '91%',textAlign: 'center'}}>
                                        Al unirse a Sindicato por México, usted se 
                                        convierte en parte de una comunidad unida 
                                        de trabajadores que comparten sus objetivos 
                                        y preocupaciones. Como miembro, podrá disfrutar 
                                        de beneficios como:
                                    </Typography>
                                </Box>
                            </Box>
                            {/* GRID */}
                            <Grid container spacing={0} >
                                {/* <Grid item xs={12} sm={4} md={4}> */}
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'descuentos.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'70%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575', textAlign: 'center'}}>
                                                Descuentos para afiliados
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'consulta_medica.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'70%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575', textAlign: 'center'}}>
                                                Consulta médica gratuita
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'indemnizacion.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'75%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575', textAlign: 'center'}}>
                                                Indemnización por riesgo laboral
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'asistencia_psicologica.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575', textAlign: 'center'}}>
                                                Asistencia psicológica
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'seguro_muerte.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'75%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575', textAlign: 'center'}}>
                                                Seguro por muerte accidental
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'pension.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'80%'}}>
                                            <Typography sx={{fontSize: 17, color: '#757575',textAlign: 'center'}}>
                                                Plan de pensiones sindicales
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isLargeScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'lightblue', 
                width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flex-root'}>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{marginBlock: '2vh'}}>
                            {/* TEXTOS */}
                            <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center',width: '100%',}}>
                                <Typography color='primary' sx={{fontSize: 28, fontWeight: 700, textAlign: 'center'}}>
                                    NUESTROS CONVENIOS
                                </Typography>
                                <Typography color='primary' sx={{fontSize: 35, fontWeight: 700, textAlign: 'center'}}>
                                    PARA NUESTROS AFILIADOS
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 22, fontWeight: 400, color: '#757575',width: '91%',textAlign: 'center'}}>
                                        Al unirse a Sindicato por México, usted se 
                                        convierte en parte de una comunidad unida 
                                        de trabajadores que comparten sus objetivos 
                                        y preocupaciones. Como miembro, podrá disfrutar 
                                        de beneficios como:
                                    </Typography>
                                </Box>
                            </Box>
                            {/* GRID */}
                            <Grid container spacing={0} >
                                {/* <Grid item xs={12} sm={4} md={4}> */}
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'descuentos.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575', textAlign: 'center'}}>
                                                Descuentos exclusivos para afiliados
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'consulta_medica.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575', textAlign: 'center'}}>
                                                Consulta médica gratuita
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'indemnizacion.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575', textAlign: 'center'}}>
                                                Indemnización por riesgo laboral
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'asistencia_psicologica.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575', textAlign: 'center'}}>
                                                Asistencia psicológica
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'seguro_muerte.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575', textAlign: 'center'}}>
                                                Seguro por muerte accidental
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'pension.png'}
                                                style={{width: '130px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 20, color: '#757575',textAlign: 'center'}}>
                                                Plan de pensiones sindicales
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isExtraLargeScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'lightblue', 
                width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flex-root'}>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{marginBlock: '2vh'}}>
                            {/* TEXTOS */}
                            <Box sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center',width: '100%',}}>
                                <Typography color='primary' sx={{fontSize: 32, fontWeight: 700, textAlign: 'center'}}>
                                    NUESTROS CONVENIOS
                                </Typography>
                                <Typography color='primary' sx={{fontSize: 40, fontWeight: 700, textAlign: 'center'}}>
                                    PARA NUESTROS AFILIADOS
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 25, fontWeight: 400, color: '#757575', width: '80%',textAlign: 'center'}}>
                                        Al unirse a Sindicato por México, usted se 
                                        convierte en parte de una comunidad unida 
                                        de trabajadores que comparten sus objetivos 
                                        y preocupaciones. Como miembro, podrá disfrutar 
                                        de beneficios como:
                                    </Typography>
                                </Box>
                            </Box>
                            {/* GRID */}
                            <Grid container spacing={0} >
                                {/* <Grid item xs={12} sm={4} md={4}> */}
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'descuentos.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'60%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575', textAlign: 'center'}}>
                                                Descuentos para nuestros afiliados
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'consulta_medica.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'40%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575', textAlign: 'center'}}>
                                                Consulta médica gratuita
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'indemnizacion.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'45%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575', textAlign: 'center'}}>
                                                Indemnización por riesgo laboral
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'asistencia_psicologica.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'40%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575', textAlign: 'center'}}>
                                                Asistencia psicológica
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'seguro_muerte.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575', textAlign: 'center'}}>
                                                Seguro por muerte accidental
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <figure>
                                            <img
                                                src={src_url + 'pension.png'}
                                                style={{width: '150px'}}
                                            />
                                        </figure>
                                        <Box sx={{width:'50%'}}>
                                            <Typography sx={{fontSize: 23, color: '#757575',textAlign: 'center'}}>
                                                Plan de pensiones sindicales
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </Box>
  );
};
