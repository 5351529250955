import { Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react'
import { utils, writeFile } from 'xlsx'

export const JsonToExcel = ({ jsonData, fileName, disabled }) => {

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(jsonData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    writeFile(wb, fileName + '.xlsx');
  };

  return (
    <div style={{marginLeft: "2%"}}>
      <Button
        color='success'
        variant='contained'
        disabled={disabled}
        startIcon={<DownloadIcon/>}
        sx={{borderRadius: 3}}
        style={{color: '#FFFFFF'}}
        onClick={exportToExcel}
      >
        <Typography>
          EXCEL
        </Typography>
      </Button>
    </div>
    //<button onClick={exportToExcel}>Exportar a Excel</button>
  )
}
