import React, { useState, useEffect } from 'react'
import { Header } from "../../nom35/header/Header";
import "./TerminosCondiciones.css";
import { Link } from "react-router-dom";
import { getUrlParameter } from "../../../utils/jstools";
import { getUserById, getPerfilEmpleadoByUsuarioId, getEmpresaById } from "../../../utils/services";


export const TerminosCondiciones = () => {
  
const [chkValue, setChkValue] = useState(true);

  const [user, setUser] = useState({
    name: "",
    id: "",
    qid: "",
    link: "",
    //empresaId:parseInt(getUrlParameter("empresaId"))
  });
  const [ perfilUsuario, setPerfilUsuario ] = useState({
    id: parseInt(getUrlParameter("userId")),
   //empresaId: getUrlParameter("empresaId"),
    //empresaId: "",
});
const idEmpresa = parseInt(getUrlParameter("empresaId"));
  const [nombreEmpresa, setNombreEmpresa] = useState("NAMECOMPANY");
                

  React.useEffect(() => {
    const userId = getUrlParameter("userId");

    var nombre = "";
    getUserById(userId).then((data) => {
      console.log(data);
      nombre = data.Nombre + " " + data.Paterno + " " + data.Materno;
      
      getEmpresaById(data.EmpresaId).then((empresa)=>{
       setNombreEmpresa(empresa.NickName)
            });
      

      setUser((prevState) => {
        return {
          ...prevState,
          name: nombre,
          id: data.Id,
          empresaId: data.EmpresaId
        };
      });
    });


    getPerfilEmpleadoByUsuarioId(userId).then((data) => {
      console.log("getPerfilEmpleadoByUsuarioId",data[0]);
      const perfil = data[0];
      setUser((prevState) => {
        return {
          ...prevState,
          qid: perfil.qId,
          link: "/cuestionario_" + perfil.qId+
          "?userId="+perfil.usuarioId+
          "&perfId="+perfil.Id+
          "&nombre="+nombre,
        };
      });
      const perfilId = perfil.id;
    });

  }, []);
  
  return (
    <div>
      <Header nombre={user.name} />
      <div className="box">
        <img
          src="../img/trabajdor/Background_HomeHageTrabajador.png"
          className="img_HPTrabajador"
        />
        
        <p className="txt_HMTrabajador_HolaUsuario">Hola  {user.name}</p>
        <hr className="hr_HMTrabajador" />

        <div className='div_TerminosCondiciones'>
            
          <p
          className='p1'
          > CONSENTIMIENTO INFORMADO 


<p>El usuario que suscribe declara que ha sido informado/a sobre su participación en la aplicación que la empresa Sistemas y Procesos SMI, S.A. de C.V. lleva a cabo sobre la NOM-035-STPS-2018 ,  asimismo, acepta dar cabal cumplimiento a todos y cada uno de los requisitos que establece la NOM-035-STPS-2018 y otorga su consentimiento para que se le realicen los estudios psicológicos realizados por los especialistas y en las fechas que apruebe la Empresa Sistema y procesos SMI, S.A. de C.V., reconociendo de manera expresa que entiende LA OBLIGATORIEDAD de realizarse dichos estudios.</p>



<p>En este sentido, autorizo a Sistemas y Procesos SMI, S.A. de C.V. que los resultados de mis estudios, así como todas las comunicaciones que requiera hacer de mi conocimiento, se realicen vía correo electrónico, al correo electrónico que proporcione a través de mi Expediente Único Digital, en la sección de “Datos Personales”. </p> 


<p>Puntualizando que, en relación a los estudios psicológico, al ser un documento protegido por la Ley de Protección de Datos Personales en Posesión de Particulares y la NOM-004-SSA3-2012, solicita que las valoraciones que integran el expediente psicológico, al estar protegidos por la normatividad en cita, sean enviados solamente al correo señalado en el párrafo que antecede, garantizando el uso exclusivo entre el  psicólogo o sociólogo  y paciente, aclarando que únicamente se autoriza a Sistemas y Procesos SMI, S.A. de C.V. a integrar a mi expediente.</p>

<p>Asimismo, el usuario manifiesta bajo protesta de decir verdad, que los datos que proporciona a través de la Plataforma Digital realizada por Sistemas y Procesos SMI, S.A. de C.V. , donde se aloja el llenado de mis cuestionarios psicológicos son fidedignos y se  compromete a actualizarlos cada vez que éstos cambien.</p>

<p>De la misma forma, autorizo a Sistemas y Procesos SMI, S.A. de C.V. para tratar y proteger mis datos personales en términos de lo establecido en la Ley de Protección de Datos Personales en Posesión de Particulares.</p>

<p>Por lo anterior y para los efectos a que haya lugar, el usuario designa y autoriza, desde este momento y por el tiempo que dure mi permanencia dentro de la empresa {nombreEmpresa}, como representante del (de la) suscrito (a) y de mis intereses, a la empresa Sistemas y Procesos SMI, S.A. de C.V.</p>

<p>Leído el presente, y en pleno conocimiento de los alcances del CONSENTIMIENTO INFORMADO, lo acepto en todo su contenido.</p>


<div>
          <label className="lbl_Acepto_HPTrabajador">
            <input
              style={{
                // width: "80px",
                // height: "100vh",
                marginRight: "10px",
                // paddingTop: "100px",
              }}
              type="checkbox"
              className="cbox_Acepto_Generales"
              onClick={() => setChkValue(!chkValue)}
            />
            ACEPTO
          </label>
        </div>

            </p>
        </div>


        {/* <Link to={user.link}> */}/cuestionario_
        <Link to={"/cuestionario_" + getUrlParameter('qId') + "?userId=" + getUrlParameter('userId') + "&perfId=" + getUrlParameter('perfId') + "&empresaId=" + getUrlParameter('empresaId') + "&qId=" + getUrlParameter('qId') + "&userName=" + getUrlParameter('userName')}>
          <button className="btn_HMTrabajador_Siguiente" disabled={chkValue}>
            INICIAR
          </button>
        </Link>
      </div>
    </div>
  );
};
