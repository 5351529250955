import React from 'react';
import "./Estilo_Cuestionario_MBI.css";

export const PreguntaMBI = (props) => {

    var condPreguntaActiva = 0;
    
    const updateNunca = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),0,condPreguntaActiva);
    }
    const updatePocasVecesAlAno = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),1,condPreguntaActiva);
    }
    const updateUnaVezAlMesOMenos = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),2,condPreguntaActiva);
    }
    const updateUnasPocasVecesAlMes = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),3,condPreguntaActiva);
    }
    const updateUnaVezALaSemana = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),4,condPreguntaActiva);
    }
    const updateUnasPocasVecesALaSemana = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),5,condPreguntaActiva);
    }
    const updateTodosLosDias = () => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),6,condPreguntaActiva);
    }

  return (
    <div>
        <table className='table_MBI'>
            <tbody>
            <tr>
                <td className="td_num_Preg_MBI">
                    <p className="num_Preg_MBI">
                        {props.numPreg}
                    </p>
                </td>
                <td>
                    <p className="txt_Preg_MBI">
                        {props.texto}
                    </p>
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateNunca}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updatePocasVecesAlAno}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateUnaVezAlMesOMenos}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateUnasPocasVecesAlMes}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateUnaVezALaSemana}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateUnasPocasVecesALaSemana}

                    />
                </td>
                <td className="td_RB_MBI">
                    <input 
                        type="radio" 
                        name={props.numPreg}
                        // id={props.id}
                        onClick={updateTodosLosDias}

                    />
                </td>
            </tr>
            </tbody>
        </table>
    </div>
  )
}
