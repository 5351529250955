import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { getUserByEmailPass, getPerfilEmpleadoByUsuarioId } from "../../../utils/services";
import Cookies from 'universal-cookie';

const HOME_URL = "https://my-app-57crz.ondigitalocean.app";

export const Login = () => {
  const [usuario, setUsuario] = useState([]);
  const [ user, setUser ] = useState({
    qId: ''
  });
  const [empresa, setEmpresa] = useState([]);
  const [link, setLink] = useState("/login");
  var errorPassEmail= 0;
  const [userInput, setUserInput] = useState({
    enteredUser: "",
    enteredPass: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  const userChangeHandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, enteredUser: event.target.value };
    });
  };

  const passChangeHandler = (event) => {
    setUserInput((prevState) => {
      return { ...prevState, enteredPass: event.target.value };
    });
  };

  const validateForm = () => {
    if ((userInput.enteredUser != "") & (userInput.enteredPass != "")) {

      // TODO Revisar requests
      getUserByEmailPass(userInput.enteredUser, userInput.enteredPass).then((data) => {
          const respUser = data[0];
          setUsuario(respUser);
          if (data.length > 0) {
            console.log(data);

            console.log(respUser);

            console.log(respUser.RolId);

            // COOKIES
            const cookie = new Cookies();
            // var nameUser = respUser.Nombre + " " + respUser.Paterno + " " + respUser.Materno;
            cookie.set('idUser', respUser.Id, {path: '/'});
            // cookie.set('nameUser', nameUser, {path: '/'});
            cookie.set('idEmpresa', respUser.EmpresaId, {path: '/'});
            // console.log("qId: " + cookie.get('EmpresaId'));

            // Nombre usuario
            const userName = respUser.Nombre + " " + respUser.Paterno + " " + respUser.Materno;
            
            switch (respUser.RolId) {
              case 1:
                getPerfilEmpleadoByUsuarioId(respUser.Id).then((data) => {
                  const perfilqId = data[0];
                  console.log('asdasd: ' + perfilqId.qId);
                  console.log('qqqq: ' + perfilqId);
                  window.open(
                    // HOME_URL + "/dashboard_admin?userId=" +
                    "/dashboard_admin?userId=" + respUser.Id + "&empresaId=" + respUser.EmpresaId
                     + "&qId=" + perfilqId.qId + "&userName=" + userName, "_self"
                    );
                });
                console.log("Es un administrador");
                setLink("/dashboard_admin"); 
                errorPassEmail= 1;
                break;
              case 2:
                getPerfilEmpleadoByUsuarioId(respUser.Id).then((data) => {
                  // var linkEmpleado = HOME_URL + "/bienvenida?userId=" + respUser.Id;
                  // var linkEmpleado = + "/bienvenida?userId=" + respUser.Id + "&empresaId=" + respUser.EmpresaId
                  // + "&qId=" + perfilqId.qId + "&userName=" + userName;
                  var linkEmpleado;
                  const perfil = data[0];

                  // obtenemos el qId
                  setUser((prevState) => {
                    return { 
                      ...prevState,
                      qId: perfil.qId
                    };
                  });

                  switch ( perfil.qId )
                    {
                      case "":
                        window.alert("Debes ser pre-registrado.");
                        linkEmpleado = HOME_URL + "/preregistro_empleado";
                      break;
                      case "val":
                        window.alert("Debes registrar tus datos.");
                        linkEmpleado = HOME_URL + "/registro_empleado?userId=" + respUser.id;
                      break;
                      case "fin":
                        // linkEmpleado = HOME_URL + "/cuestionario_fin?userId=" + perfil.usuarioId + "&perfId=" + perfil.id + "&nombre=" + userName;
                        linkEmpleado = "/cuestionario_fin?userId=" + perfil.usuarioId + "&perfId=" + perfil.id + "&nombre=" + userName;
                        break;
                      case "101":
                        // linkEmpleado = HOME_URL + "/bienvenida?userId=" + respUser.Id + "&empresaId=" + respUser.EmpresaId + "&qId=" + perfil.qId + "&userName=" + userName;
                        linkEmpleado = "/bienvenida?userId=" + respUser.Id + "&empresaId=" + respUser.EmpresaId + "&qId=" + perfil.qId + "&userName=" + userName;
                      break;
                      default:
                        // linkEmpleado = HOME_URL + "/cuestionario_" + perfil.qId + "?userId=" + respUser.id + "&perfId=" + perfil.id + "&nombre=" + respUser.nombre;
                        linkEmpleado = "/cuestionario_" + perfil.qId + "?userId=" + respUser.Id + "&perfId=" + perfil.Id + "&empresaId=" + respUser.EmpresaId + "&nombre=" + userName;
                      break;
                    }

                  window.open(linkEmpleado,"_self");
                });
                console.log("Es un empleado");
                setLink("/bienvenida");
                errorPassEmail= 1;
                break;
              case 3:
                console.log("Es un analista");
                setLink("/dashboard_analista");
                window.open( HOME_URL + "/dashboard_analista?userId=" + respUser.id + "&nombre=" + respUser.nombre,"_self");
                errorPassEmail= 1;
                break;
            }
          }
          if (errorPassEmail!=1){
            setErrorMsg("Contraseña o usuario inválido.");
          }
        }
      );
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(../img/Background_Login_Blue.svg)",
        width: "100%",
        height: "86.5vh",
        backgroundSize: "cover",
        paddingTop: "7%",
        
      }}
    >
      <div className="Box_Login">
        <img 
          src="../img/LogoSMI_Login.svg" 
          className="img_LogoSMI_Login" 
        />
        <p 
          className="txt2_Login2"
        >
          Nombre de usuario:
        </p>
        <form action="email">
        <input
          className="tbo_Login"
          type="email"
          placeholder="Correo electrónico"
          value={userInput.enteredUser}
          onChange={userChangeHandler}
        />
        </form>
        <p className="txt2_Login2">Password:</p>
        <input
          className="tbo_Login"
          type="password"
          placeholder="Contraseña"
          value={userInput.enteredPass}
          onChange={passChangeHandler}
        />
        <div className="mensaje_error_div">
        <p className="mensaje_error">{errorMsg}</p>
        </div>
        <Link to={link}>
          <button className="btn_Ingresar" type="button" onClick={validateForm}>
            Ingresar
          </button>
        </Link>
        <div className="text_cont">
        
        <Link to="/registro_empresa">
            <p
                className="txt3_Login2"
                type='submit'
                
                // disabled={true}
                // disabled={!validateForm()}
            >
                ¿No estás registrado?
            </p>
        </Link>
        
      </div>
      </div>
    </div>
  );
};
