import React, { useState } from "react";
import './Estilo_Cuestionario_Escala.css';
import { PreguntaEscalaSN } from "./elementos_cuestionarios/PreguntaEscalaSN";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario24 = (props) => {

    const sigPag = "205";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 5;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);

      const [sessionJson, setSessionJson] = useState({
        referenciaId: 2,     //Dato estático
        seccionId: 8,        //Dato estático
        tipoPreguntaId: 2,   //Dato estático
        preguntaInicial: 18, //Dato estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        usuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });

    const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 18, respuesta: 0 },
          { pregunta: 19, respuesta: 0 },
          { pregunta: 20, respuesta: 0 },
          { pregunta: 21, respuesta: 0 },
          { pregunta: 22, respuesta: 0 }
        ]
      });

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);
      
    const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg,resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
        const respuestas = {respuestasJson};
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson );
        console.log({ respuestasJson });
      };

    return (
        <div className="box_menu_y_cuestionario_escala">
            <div className="cuestionario_div_escala">
                <div className="div_svg_menu_escala">
                    <img src="./img/menu_cuestionarios/menu_c24.svg" className="img_svg_escala"/>
                </div>
                <div className="text_cuestionario_escala">
                    <img src="./img/barra_progreso/riesgo_psicosocial/barra_de_progreso_24.svg" className="img_progreso_escala"/>
                    <p className="titulo_cuestionario_escala"><img src="./img/barra_progreso/iconos/psicosocial_icono_cuestionario.svg"/>  RIESGO PSICOSOCIAL</p>

                    <p className="instrucciones_cuestionario_escala">Las preguntas siguientes están relacionadas con las decisiones que puede 
                    tomar en su trabajo.</p>
                    <div className="body_escalas">
                    <div className="titulos_escalas">
                    <table>
                    <tbody>
                    <tr>
                        <td><p className="box_titulos_escalas">Siempre</p></td>
                        <td><p className="box_titulos_escalas">Casi<br></br>siempre</p></td>
                        <td><p className="box_titulos_escalas">Algunas<br></br>veces</p></td>
                        <td><p className="box_titulos_escalas_1">Casi<br></br>nunca</p></td>
                        <td><p className="box_titulos_escalas_2">Nunca</p></td>   
                    </tr>
                    </tbody>
                    </table>
                    </div>

                    <div className="preguntas_escala_sn">
                      
                      <PreguntaEscalaSN
                        numPreg="18"
                        texto="Mi trabajo permite que desarrolle nuevas habilidades"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                                    
                      <PreguntaEscalaSN
                        numPreg="19"
                        texto="En mi trabajo puedo aspirar a un mejor puesto"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="20"
                        texto="Durante mi jornada de trabajo puedo tomar pausas cuando las necesito"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="21"
                        texto="Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="22"
                        texto="Puedo cambiar el orden de las actividades que realizo en mi trabajo"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                    </div>
                    </div>
                    
                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas"}
                      condicionPreg='200Restantes'
                    />

                </div>
            </div>
        </div>
    )
}
