import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getUrlParameter } from '../../../../utils/jstools';
import { getPerfilEmpleadoByUsuarioId } from '../../../../utils/services';
import { Header } from '../../header/Header';
import { Menu_lateral_admin } from '../menu_lateral_admin/Menu_lateral_admin';
import './Dashboard1_admin.css';

export const Dashboard1_admin = () => {

  // instancia de cookies
  const cookies = new Cookies();

  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  useEffect(() => {
    // Actualizar el estado del componente cuando cambie el valor de la cookie
    setperfilUsuario((prevState) => ({
      ...prevState,
      Id: cookies.get("idUser") || 7, // Si la cookie no está presente, usa 0 como valor predeterminado
    }));
    console.log('cookie IdEmpresa: ' + cookies.get('idUser'));
  }, [cookies.get("idUser")]);


  const { userId } = useParams();
  console.log("UserId:", userId);
  const [perfilUsuario, setperfilUsuario] = useState({
    // Id: cookies.get('idUser'),
    Id: "",
    // qId: cookies.get('qIdUser')
    qId: ""
  });
  useEffect(() => {
    // Obtenemos el valor de userId usando getUrlParameter
    const userId = getUrlParameter('userId');
    // Actualizamos el estado de perfilUsuario con el valor obtenido
    setperfilUsuario({
      ...perfilUsuario,
      Id: userId
    });
    console.log('userId: ++++' + getUrlParameter('userId'));
    console.log('userId: ++++' + userId);
  }, []);


  console.log("qID cookie: " + perfilUsuario.qId);
  
  useEffect(()=>{
      // getPerfilEmpleadoByUsuarioId(perfilUsuario.Id).then((data) => {
      getPerfilEmpleadoByUsuarioId(getUrlParameter('userId')).then((data) => {
        const perfilAdminQId = data[0];
        setperfilUsuario((prevState) => {
          return {
            ...prevState,
            qId : perfilAdminQId.qId
          }
        });
      });
  }, []);

  var imgAdmin;
  var imgEmp;
  switch(perfilUsuario.qId)
    {
      case "" :
        imgAdmin= "../img/admin/DashAdmin_Admin_img.svg";
        imgEmp= "../img/admin/DashAdmin_Empresa_img.svg";
      break;
      case "100" :
        imgAdmin= "../img/admin/DashAdmin_AdminCompletado_img.svg";
        imgEmp= "../img/admin/DashAdmin_Empresa_img.svg";
      break;
      case "001" :
        console.log('++++++qid 001++++++')
        imgAdmin= "../img/admin/DashAdmin_Admin_img.svg";
        imgEmp= "../img/admin/DashAdmin_EmpresaCompletado_img.svg";
      break;
      case "111" :
        imgAdmin= "../img/admin/DashAdmin_AdminCompletado_img.svg";
        imgEmp= "../img/admin/DashAdmin_EmpresaCompletado_img.svg";
      break;
    };

  return (
    <div>
      <Header
        // nombre={cookies.get('nameUser')}
        nombre={getUrlParameter('userName')}
        expirationDate={expirationDate}
      />
      <Menu_lateral_admin 
        // idUsuario={cookies.get('idUser')}
        idUsuario={getUrlParameter('userId')}
        // idEmpresa={cookies.get('idEmpresa')}
        idEmpresa={getUrlParameter('empresaId')}
        qId={getUrlParameter('qId')}
        nombre={getUrlParameter('userName')}
      />
      <div className='box_DashAdmin1'>
        <div
          style=
          {
            {
              backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
              width: "100%", 
              height: "15%", 
              backgroundSize: "cover",
              marginTop: "1%",
              display: "block",
            }
          }
        >
          <div className='div_txt_DashAdmin1_InfoEmpresa'>
            <p>ADMINISTRADOR</p>
            <p>INICIO</p>
          </div>
        </div>
        <div className='div_Dash1Admin_SVGs'>
          {/* <img
            src="../img/admin/DashAdmin_Admin_img.svg"
            className="img_SVGs_DashAdmin1"
          /> */}    
          {/* img Admin */}
          <img
            src={imgAdmin}
            className="img_SVGs_DashAdmin1"
          />
          {/* img Empresa */}
          <img
            src={imgEmp}
            className="img_SVGs_DashAdmin1"
          />
          {/* <img
            src="../img/admin/DashAdmin_PreRegistro_img.svg"
            className="img_SVGs_DashAdmin1"
          />
          <img
            src="../img/admin/DashAdmin_Licencia_img.png"
            className="img_SVGs_DashAdmin1"
          /> */}
        </div>
        <div className='input_date_DashAdmin1'>    
          {/* <p>
            Fecha de inicio: <br/>
            <input 
              type="date"
              className='input_date_1'
            />
          </p> */}

          {/* <p>
            Fecha de fin: <br/>
            <input 
              type="date"
              className='input_date_2'
            />
          </p> */}
        </div>
        {/* <button className='btn_DashAdmin1_IniciarEncuestas'>
          INICIAR ENCUESTAS
        </button> */}
        
      </div>
    </div>
  );
};