import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'

export const Footer = () => {

  const src_urlLogin = 'https://ums.sindicatopormexico.com/accounts/login/';

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
      <Box>
          {isSmallScreen &&
            <Box sx={{backgroundColor: '#FF8030', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Container sx={{display: 'flex', justifyContent: 'end'}}>
                    {/* <a href={src_urlLogin} style={{textDecoration: 'none'}}> */}
                        <Typography sx={{textDecoration: 'none', color: '#F5F5F5', fontSize: '2ch'}}>
                            Email: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="mailto:contacto@sindical.com.mx">contacto@sindical.com.mx</a>
                            <br/>
                            Número: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="https://wa.me/+525622170053">56 22170053</a>
                        </Typography>
                    {/* </a> */}
                </Container>
            </Box>
          }
          {isMediumScreen &&
            <Box sx={{backgroundColor: '#FF8030', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Container sx={{display: 'flex', justifyContent: 'end'}}>
                    {/* <a href={src_urlLogin} style={{textDecoration: 'none'}}> */}
                        <Typography sx={{textDecoration: 'none', color: '#F5F5F5', fontSize: '2.6ch'}}>
                            Email: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="mailto:contacto@sindical.com.mx">contacto@sindical.com.mx</a>
                            <br/>
                            Número: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="https://wa.me/+525622170053">56 22170053</a>
                        </Typography>
                    {/* </a> */}
                </Container>
            </Box>
          }
          {isLargeScreen &&
            <Box sx={{backgroundColor: '#FF8030', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Container sx={{display: 'flex', justifyContent: 'end'}}>
                    {/* <a href={src_urlLogin} style={{textDecoration: 'none'}}> */}
                        <Typography sx={{textDecoration: 'none', color: '#F5F5F5', fontSize: '2.5ch'}}>
                            Email: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="mailto:contacto@sindical.com.mx">contacto@sindical.com.mx</a>
                            <br/>
                            Número: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="https://wa.me/+525622170053">56 22170053</a>
                        </Typography>
                    {/* </a> */}
                </Container>
            </Box>
          }
          {isExtraLargeScreen &&
            <Box sx={{backgroundColor: '#FF8030', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Container sx={{display: 'flex', justifyContent: 'end'}}>
                    {/* <a href={src_urlLogin} style={{textDecoration: 'none'}}> */}
                        <Typography sx={{textDecoration: 'none', color: '#F5F5F5', fontSize: '2.2ch'}}>
                            Email: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="mailto:contacto@sindical.com.mx">contacto@sindical.com.mx</a>
                            <br/>
                            Número: <a style={{textDecoration: 'none', color: '#F5F5F5'}} href="https://wa.me/+525622170053">56 22170053</a>
                        </Typography>
                    {/* </a> */}
                </Container>
            </Box>
          }
      </Box>
  )
}
