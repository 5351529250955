import React from 'react'
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/promociones/';

export const Promociones = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'green', 
            width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '2.5vh'}}>
                        <Box sx={{width: '90%', marginBlock: '0%'}}>
                            <Typography color='primary' sx={{fontSize: 25, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                CONOCE LAS PROMOCIONES QUE TENEMOS 
                                PARA TÍ A TRAVÉS DEL MERCADO SINDICAL
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBlock: '2.5vh'}}>
                        {/* TARJETA */}
                        <Box sx={{width: '42.5%', backgroundColor: '#FAFAFA', margin: '2.5%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_funeraria.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia Funeraria Familiar
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography  sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $190.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                        $170.58 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        {/* TARJETA */}
                        <Box sx={{width: '42.5%', backgroundColor: '#FAFAFA', margin: '2.5%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'paquete_celular.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Paquete Alma.tel de 60 G
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography  sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $490.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                            $475.30 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        {/* TARJETA */}
                        <Box sx={{width: '42.5%', backgroundColor: '#FAFAFA', margin: '2.5%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_viajes.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia de viajes
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $821.56 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                            $789.11 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    {/* BOTON */}
                    <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2.5vh'}}>
                        <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>            
            </Box>       
        }
        {isMediumScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'green', 
                width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '2.5vh'}}>
                        <Box sx={{width: '90%', marginBlock: '0%'}}>
                            <Typography color='primary' sx={{fontSize: 25, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                CONOCE LAS PROMOCIONES QUE TENEMOS 
                                PARA TÍ A TRAVÉS DEL MERCADO SINDICAL
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBlock: '2.5vh'}}>
                        {/* TARJETA */}
                        <Box sx={{width: '28%', backgroundColor: '#FAFAFA', margin: '1%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_funeraria.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia Funeraria Familiar
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography  sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $190.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                        $170.58 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        {/* TARJETA */}
                        <Box sx={{width: '28%', backgroundColor: '#FAFAFA', margin: '1%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'paquete_celular.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Paquete Alma.tel de 60G
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography  sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $490.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                            $475.30 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        {/* TARJETA */}
                        <Box sx={{width: '28%', backgroundColor: '#FAFAFA', margin: '1%', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_viajes.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 17, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia de viajes
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público:
                                </Typography>
                                <Typography sx={{fontSize: 18, fontWeight: '700', color: '#A35709'}}>
                                    $821.56 MXN
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado:
                                </Typography>
                            </Container>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 18, fontWeight: 700}} style={{color: 'white'}}>
                                            $789.11 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    {/* BOTON */}
                    <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2.5vh'}}>
                        <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>            
            </Box>       
        }
        {isLargeScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'green', 
                width: '100%', height: '100%', backgroundSize: "cover"}}
            >
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '2.5vh'}}>
                        <Box sx={{width: '70%', marginTop: '0'}}>
                            <Typography color='primary' sx={{fontSize: 35, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                CONOCE LAS PROMOCIONES QUE TENEMOS 
                                PARA TÍ A TRAVÉS DEL MERCADO SINDICAL
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
                        <Box sx={{width: '250px', backgroundColor: '#FAFAFA', margin: '20px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_funeraria.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia Funeraria Familiar
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $190.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                        $170.58 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{width: '250px', backgroundColor: '#FAFAFA', margin: '20px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'paquete_celular.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Paquete Alma.tel de 60 G
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $490.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $475.30 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{width: '250px', backgroundColor: '#FAFAFA', margin: '20px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_viajes.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia de viajes
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $821.56 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                    
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $789.11 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2.5vh'}}>
                        <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>            
            </Box>       
        }
        {isExtraLargeScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                // backgroundColor: 'green', 
                width: '100%', height: '100%', backgroundSize: "cover"}}
            >
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '2.5vh'}}>
                        <Box sx={{width: '70%', marginTop: '0'}}>
                            <Typography color='primary' sx={{fontSize: 45, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                CONOCE LAS PROMOCIONES QUE TENEMOS 
                                PARA TÍ A TRAVÉS DEL MERCADO SINDICAL
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
                        
                    <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '30px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_funeraria.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia Funeraria Familiar
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $190.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                        $170.58 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        
                        <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '30px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'paquete_celular.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Paquete Alma.tel de 60 G
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $490.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $475.30 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>

                        <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '30px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)', borderRadius: '8px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={src_url + 'asistencia_viajes.png'}
                                    style={{width: '100%', borderTopRightRadius: '8px', borderTopLeftRadius: '8px'}}
                                />
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia de viajes
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $821.56 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                    
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $789.11 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2.5vh'}}>
                        <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>            
            </Box>       
        }
        {/* {isSmallScreen &&
            <Box sx={{
                backgroundColor: '#F5F5F5', 
                backgroundColor: 'green', 
            width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '0vh'}}>
                        <Box sx={{width: '70%', marginTop: '0'}}>
                            <Typography color='primary' sx={{fontSize: 45, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                CONOCE LAS PROMOCIONES QUE TENEMOS 
                                PARA TÍ A TRAVÉS DEL MERCADO SINDICAL
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
                        
                    <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '40px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'asistencia_funeraria.png'}
                                        style={{backgroundColor: 'pink'}}
                                    />
                                </figure>
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia Funeraria Familiar
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $190.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                        $170.58 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        
                        <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '40px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'paquete_celular.png'}
                                        style={{backgroundColor: 'pink'}}
                                    />
                                </figure>
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Paquete Alma.tel de 60 G
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $490.00 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                        
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $475.30 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>

                        <Box sx={{width: '370px', backgroundColor: '#FAFAFA', margin: '40px', boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'asistencia_viajes.png'}
                                        style={{backgroundColor: 'pink'}}
                                    />
                                </figure>
                            </Box>
                            <Container>
                                <Typography sx={{fontSize: 22, fontWeight: '600', color: '#585858', marginBottom: '20px'}}>
                                    Asistencia de viajes
                                </Typography>
                                <Typography sx={{fontSize: 15, color: '#585858'}}>
                                    Precio al público
                                </Typography>
                                <Typography  sx={{fontSize: 22, fontWeight: '700', color: '#A35709'}}>
                                    $821.56 MXN
                                </Typography>
                                <Typography sx={{fontSize: 18, color: '#585858', marginTop: '20px'}}>
                                    Precio de afiliado
                                </Typography>
                            </Container>
                    
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            $789.11 MXN
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>            
            </Box>       
        }*/}
    </Box> 
    
  )
}
