import React, { useState } from "react";
import "../Estilo_Cuestionario_Binario.css";
import "../Estilo_Cuestionario_Escala.css";

export const PreguntaEscalaSN = (props) => {

    var condPreguntaActiva = 0;

    const updateSiempre = (self) => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),(props.rSiempre),condPreguntaActiva);
    }
    
    const updateCasiSiempre = (self) => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),(props.rCasiSiempre),condPreguntaActiva);
    }
    
    const updateAlgunasVeces = (self) => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),(props.rAlgunasVeces),condPreguntaActiva);
    }
    
    const updateCasiNunca = (self) => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),(props.rCasiNunca),condPreguntaActiva);
    }
    
    const updateNunca = (self) => {
        condPreguntaActiva++;
        props.update(parseInt(props.numPreg),(props.rNunca),condPreguntaActiva);
    }

  return (
    <div>
                <table>
                    <tbody>
                    <tr>
                        <td><p className="preguntas_cuestionario_escala">{props.numPreg}</p></td>
                        <td><p className="preguntas_cuestionario_escala">{props.texto}</p></td>
                        <td className="radio_td"><input type="radio" name={props.numPreg} onClick={updateSiempre}/></td>
                        <td className="radio_td"><input type="radio" name={props.numPreg} onClick={updateCasiSiempre}/></td>
                        <td className="radio_td"><input type="radio" name={props.numPreg} onClick={updateAlgunasVeces}/></td>
                        <td className="radio_td"><input type="radio" name={props.numPreg} onClick={updateCasiNunca}/></td>
                        <td className="radio_td"><input type="radio" name={props.numPreg} onClick={updateNunca}/></td>
                    </tr>
                    </tbody>
                </table>
    </div>
  )
}