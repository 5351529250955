import React, { useState, useContext, useEffect } from 'react';
import { getEmpleadosByEmpresaId, getPerfilEmpleadoByUsuarioId, guardaUsuario, getEmpresaById } from '../../../../utils/services';
import { Header } from '../../header/Header';
import { Menu_lateral_analista } from '../menu_lateral_analista/Menu_lateral_analista';
import { getUrlParameter } from '../../../../utils/jstools';
import AdminContext from '../../../../context/AdminContext';
import { Tarjeta_usuario } from '../administrar_usuarios/Tarjeta_usuario';
import Popup from "reactjs-popup";
import Cookies from 'universal-cookie';

const listaEmpleados=[
    {nombreUsuario :"Ruben",
    correoUsuario:"Ruben035@smi.com"},
    {nombreUsuario :"Jaime",
    correoUsuario:"Jaime035@smi.com"},
    
  ]

export const Registro_analistas = () => {

  // instancia de cookies
  const cookies = new Cookies();
  
  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

    const [ listaEmpleadosRegistrados, setListaEmpleadosRegistrados ] = useState ([]);
    const [ anadirEmpleado, setAnadirEmpleado ] = useState({
      nomAnl: "",
      email: "",
      rolId: "",
      pass: "nom35",
      empresaId: parseInt(getUrlParameter("empresaId")),
    });
  
    const [ perfilEmpresa, setPerfilEmpresa ] = useState ({
      id: getUrlParameter("empresaId"),
      nombre: "",
      rasonSocial: "",
      rfc: "",
      calle: "",
      numExt: "",
      colonia: "",
      cp: "",
      municipio: "",
      localidad: "",
      estado: "",
      referencia: "",
    });
  
    useEffect(() => {

      getEmpresaById(perfilEmpresa.id).then((data)=>{
          setPerfilEmpresa((prevState) => {
              return { ...prevState,
                  nombre: data.name,
                  rasonSocial: data.rasonSocial,
                  rfc: data.rfc,
                  calle: data.calle,
                  numExt: data.numExt,
                  colonia: data.colonia,
                  cp: data.cp,
                  municipio: data.municipio,
                  localidad: data.localidad,
                  estado: data.estado,
                  referencia: data.referencia
              };
          });
      });
  
    // Obtener los analistas registrados
    var empleadosArray = [];
    setListaEmpleadosRegistrados(empleadosArray); 
    getEmpleadosByEmpresaId(perfilEmpresa.id).then((data) => {
      const arreglo = data;
      console.log("Arreglo : " + arreglo);
      arreglo.forEach((empleado) => {
        
        if ( empleado.rolId == 2 ) {
          getPerfilEmpleadoByUsuarioId(empleado.id).then((dataPerf) =>{
                const datoQid = dataPerf;
                
                const perfilEstatus = {
                  id: empleado.id, 
                  numTrabajador: empleado.numEmp,
                  email: empleado.email,
                  estatus: datoQid[0].qId
                };
    
              empleadosArray.push(perfilEstatus);
              setListaEmpleadosRegistrados(oldArray => [...oldArray, perfilEstatus]);
  
            });
        }
      });
  });
},[]);

  
    const [ checkBoxAnalista, setCheckBoxAnalista ] = useState(false);
    var dis = true;
      if (
        (anadirEmpleado.nomAnl != "") & (anadirEmpleado.email != "")
      ){
        dis = false;
      }
      else {
        dis = true;
      }
    
      const nomAnlAnadirAnalistaChangeHandler = (event) => {
        setAnadirEmpleado((prevState) => {
          return { ...prevState, nomAnl: event.target.value,  };
        });
      };
      const emailAnadirAnalistaChangeHandler = (event) => {
        setAnadirEmpleado((prevState) => {
          return { ...prevState, email: event.target.value,  };
        });
      };
      const rolIdAnadirAnalistaChangeHandler = (event) => {
        setAnadirEmpleado((prevState) => {
          return { ...prevState, rolId: parseInt(event.target.value)};
        });
      };
      const agregarAnalista = (nuevoEmpleado) => {
        let nuevosEmpleados = new Array();
        listaEmpleadosAPI.map((empleado) =>
          {
            nuevosEmpleados.push(empleado);
          }
        )
        nuevosEmpleados.push(nuevoEmpleado);
        setListaEmpleadosAPI(nuevosEmpleados);
      };
      const ctx = useContext(AdminContext);
      const clickHandler = () => {
        guardaUsuario(anadirEmpleado).then((data) => {
          console.log(data);
          if (ctx.empresaId == 0) {
            ctx.updateAdmin(data.id);
          }
        });
      
        agregarAnalista(
          {
            nomAnalista: anadirEmpleado.nomAnl,
            email: anadirEmpleado.email
          }
        );
      // };
    
        window.alert("Registro de Analista exitoso");  
      };
    const contentStyle = {
      maxWidth: "600px",
      width: "90%"
    };  
    
  return (
    <div>
        <Header
          nombre={cookies.get('nameUser')}
          expirationDate={expirationDate}
        />
        <Menu_lateral_analista
          idUsuario={cookies.get('idUser')}
        />

        <div className='box_DashAnalist'>
        <div
            style=
            {
                {
                  backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                  width: "100%", 
                  height: "15%", 
                  backgroundSize: "cover",
                  marginTop: "1%",
                  display: "block",
                }
            }
          >
            
            <div className='div_txt_TittleRegistroAnalistas_Analista'>
              <p>ANALISTA</p>
              <p>ADMINISTRACIÓN DE USUARIOS</p>
            </div>
            <div className='table_top_Analista_AdminU'>
              <table>
                <tbody>
                  <tr>
                    <td>
                    <img
                       src='../img/admin/Administrar_usuarios.png' 
                       className='img_admin_us'
                      />
                    </td>
                    <td>
                      USUARIOS ACTIVOS
                    </td>
                    <td>
                      USUARIOS SELECCIONADOS  
                    </td>
                    <td 
                    >
                      
                    </td>
                    <td>
                    <button 
                    className='btn_usuariosSeleccionados_regEmp'
                  >
                    <img
                       src='../img/admin/Vectoreliminar.png' 
                       className='img_eliminar'
                      />
                      
                  </button>
                </td>
                <td className='td14_table_top_Admin_RegEmpleados'>
                 


                    {/* <Modal /> */}
   {/* const CustomModal  = () => ( */}
    <Popup className="pupUp_A"
      trigger={<button 
        className='btn_anaadirUsuarios_regEmp'
        id='btn_PopUp_AnadirAnalista'
      >
        <img
                       src='../img/admin/Vectorañadir.png' 
                       className='img_añadir'
                      /> 
       </button>}
      modal
      contentStyle={contentStyle}
    >
      {close => (
        <div className="modal">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="header"> Añadir analista </div>
          <div className="content">
          <p className='txt_NumTrabajador_PopUp_anadirAnalista'>
                        Nombre del analista 
                      </p>
                      <input
                        className='input__NumTrabajador_PopUp_anadirAnalista'
                        type="text"
                        placeholder='Número de trabajador'
                        pattern='[a-z,A-Z]+'
                        maxLength={30}
                        value={anadirEmpleado.nomAnl}
                        onChange={nomAnlAnadirAnalistaChangeHandler}
                      />
          </div>
          <div className='div2_popUp_RegistroEmpleados'>
                      <p className='txt_email_PopUp_anadirAnalista'>
                        Email
                      </p>
                      <input
                        className='input_email_PopUp_anadirAnalista'
                        type="email"
                        placeholder='Email'
                        value={anadirEmpleado.email}
                        onChange={emailAnadirAnalistaChangeHandler}
                      />
                      <p className='txt_InputTitle_DIV2_DashAdmin_PerfilAdmin'>
                        Rol del analista
                      </p>
                <select 
                    id="id_AnalistaRol" 
                    name="name_Analista_Rol" 
                    className='cn_Analista_Rol' 
                    value={anadirEmpleado.rolId}
                    onChange={rolIdAnadirAnalistaChangeHandler}
                >
                    <option 
                      value={4}
                    >
                      Analista 1
                    </option>
                    <option 
                      value={5}
                    >
                      Analista 2
                    </option>
                    <option 
                      value={6}
                    >
                      Analista 3
                    </option>
                </select>
                      <button
                          className='btn_anadirAnalista_popUp'
                          onClick={clickHandler}
                          id='btn_PopUp_Cerrar_Agregar'
                          disabled={dis}
                      >
                          Agregar
                      </button>
                      
                    </div>
          
        </div>
      )}
    </Popup>

                </td>
               
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table_titulos'>
              <table>
                <tbody>
                  <tr>
                    
                    <td>
                      <input  type="checkbox" 
                      onClick={() => setCheckBoxAnalista(!checkBoxAnalista)}
                      />
                    </td>
                    <td>
                      NOMBRE
                    </td>
                    <td>
                      CORREO ELECTRONICO
                    </td>
                    <td>
                      ULTIMA VEZ ACTIVO
                    </td>
                    <td>
                      ESTATUS
                    </td>
                    <td>
                      EDITAR DATOS
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            {
              listaEmpleadosRegistrados.map((empleado) => 
              <Tarjeta_usuario 
                empleado={empleado}
                check={checkBoxAnalista} 
              />
            )}
          </div>
          </div>

    </div>
  )
}
