import React, { useEffect } from 'react';
import './Menu_lateral_analista.css';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

export const Menu_lateral_analista = (props) => {

    const navVistaGeneral = () => {
        window.open("/analista_vista-general?userId=" + props.idUsuario,"_self");
    };
    const navListadoClientes = () => {
        window.open("/dashboard_analista?userId=" + props.idUsuario,"_self");
    };
    const navDatosEncuestas = () => {
        window.open("/analista_datos-encuestas?userId=" + props.idUsuario,"_self");
    };
    const navRegistrarAnalistas = () => {
        window.open("/analista_registrar-analistas?userId=" + props.idUsuario,"_self");
    };

    // Cookie id
    const cookie = new Cookies();
    const valueCookie = cookie.get('idUser');
    // borrar cookie por tiempo
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
    useEffect(() => {
        cookie.set('idUser', valueCookie, {path: '/',  expires:  expirationDate});
    }, []);
    // borrar cookie por btn 
    const cerrarSesion = () => {
        cookie.set('idUser', null,  {path: '/'});
    };

  return (
    <div>
       <div className='box_menu_lateral_analista'>
                <button 
                    className='btn_MenuLateralAnalista'
                    onClick={navVistaGeneral}
                >
                    <img
                        src="../img/analista/DashAnalista_MenuLateral_VistaGeneral_ico.svg"
                        className='ico_Menu_DashAnalista'
                    />
                    Vista general
                </button>
                <button 
                    className='btn_MenuLateralAnalista2'
                    onClick={navListadoClientes}
                >
                    <img
                        src="../img/analista/DashAnalista_MenuLateral_VistaClientes_ico.svg"
                        className='ico2_Menu_DashAnalista'
                    />
                    Listado de clientes
                </button>
                <button 
                    className='btn_MenuLateralAnalista2'
                    onClick={navDatosEncuestas}
                >
                    <img
                        src="../img/analista/DashAnalista_MenuLateral_DatosEncuestas_ico.svg"
                        className='ico2_Menu_DashAnalista'
                    />
                    Datos de encuestas
                </button>
                <button 
                    className='btn_MenuLateralAnalista2'
                    onClick={navRegistrarAnalistas}
                >
                    <img
                        src="../img/analista/DashAnalista_MenuLateral_AdministrarUsuarios_ico.svg"
                        className='ico2_Menu_DashAnalista'
                    />
                    Registrar analistas
                </button>
            <Link to="/">
                    <button 
                        className='btn_MenuLateralAnalist_CerrarSesion'
                        onClick={cerrarSesion}
                    >
                        <p className='p_MenuLateralAdmin_CerrarSesion'>
                            CERRAR SESIÓN
                        </p>
                    </button>
                </Link>
        </div>
    </div>
  );
};
