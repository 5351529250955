import React, { useState, useEffect } from 'react';
import { getEmpresaById, getUserById } from '../../../../utils/services';
import { Header } from '../../header/Header';
import { Menu_lateral_admin } from '../menu_lateral_admin/Menu_lateral_admin';
import "./Reportes_dashboard1_admin.css";
import Cookies from 'universal-cookie';

export const Reportes_dashboard1_admin = () => {

  // instancia de cookies
  const cookies = new Cookies();

  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  const [ perfilUsuario, setPerfilUsuario ] = useState({
    id: cookies.get('idUser'),
  });

  const [ perfilEmpresa, setPerfilEmpresa ] = useState ({
    id: cookies.get('idEmpresa'),
    nombre: "",
    rasonSocial: "",
    rfc: "",
    calle: "",
    numExt: "",
    colonia: "",
    cp: "",
    municipio: "",
    localidad: "",
    estado: "",
    referencia: ""
  });

  useEffect(() => {
    getEmpresaById(perfilEmpresa.id).then((data)=>{
        setPerfilEmpresa((prevState) => {
            return { ...prevState,
                nombre: data.name,
                rasonSocial: data.rasonSocial,
                rfc: data.rfc,
                calle: data.calle,
                numExt: data.numExt,
                colonia: data.colonia,
                cp: data.cp,
                municipio: data.municipio,
                localidad: data.localidad,
                estado: data.estado,
                referencia: data.referencia
            };
        });
    });

    getUserById(perfilUsuario.id).then((data) => {
      setPerfilUsuario((prevState) => {
        return { ...prevState,
          nombre: data.nombre
        };
      });
    });

  },[]);
  
  return (
    <div>
      <Header
        nombre={cookies.get('nameUser')}
        expirationDate={expirationDate}
      />
      <Menu_lateral_admin
        idUsuario={perfilUsuario.id}
        idEmpresa={perfilEmpresa.id}
      />
      <div className='box_DashAdmin1'>
        <div
          style=
          {
            {
              backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
              width: "100%", 
              height: "15%", 
              backgroundSize: "cover",
              marginTop: "1%",
              display: "block",
            }
          }
        >
          <div className='div_txt_DashAdmin1_InfoEmpresa'>
            <p>ADMINISTRADOR</p>
            <p>REPORTES</p>
          </div>
        </div> 
        <p className='t1'>Estimado Adiministrador, el reporte estara listo a los 10 dias habiles de terminadas todas las encuestas de los empleados.</p>
        <td className='td15_table_top_Admin_RegEmpleados'>
          <button
            className='btn_cargar_regEmp'
          >
            <img
              src='../img/admin/download.png' 
              className='img_download'
            />
          </button>
        </td>
      </div>
    </div>
  );
};