import React from 'react'
import { AltaEmpresas } from '../../components/xmex/admin/AltaEmpresas'

export const DashAltaEmpresas = () => {
  return (
    <div>
        <AltaEmpresas/>
    </div>
  )
}
