import { Link } from "react-router-dom";
import './Preregistro_empleado_aviso.css';
import React, { useState } from "react";


export const Preregistro_empleado_aviso = () => {

    const [status, setStatus] = useState(true);

    var btn_style_continuar = "btn_Continuar_RegistroAdmin"

    const fun_continuar = function fun_continuar_1() {
        if (status) {
            window.alert("No puede continuar sino acepta");
            btn_style_continuar = "btn_Continuar_RegistroAdmin"
          } else {
            window.alert("Pasar a la siguiente pagina");
            btn_style_continuar = "btn_conocer_preregistro_aviso"
          }
    }

    const fun_acepto = function fun_acepto() {
        
    }

    return (
        <div
            style=
            {
                {
                    // backgroundImage: "url(../img/Background_Login2.svg)", 
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    height: "100vh", 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover",
                    marginTop: "-100px",
                    paddingTop: "100px",
                    // marginBottom: "-150px"
                }
            } 
        >
            <div className='box_preregistro_empleado'>
                <img src="../img/LogoSMI_Login.svg" className='img_LogoSMI_RegistroAdmin'/>
                <p className='txt_title_preregistro_empleado'>AVISO DE PRIVACIDAD Y PROTECCIÓN DE DATOS</p><br></br>
                <p className='txt_preregistro_empleado'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                    dolor in reprehenderit in voluptate velit esse cillum dolore eu 
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum. 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                    dolor in reprehenderit in voluptate velit esse cillum dolore eu 
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                    dolor in reprehenderit in voluptate velit esse cillum dolore eu 
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
                    sunt in culpa qui officia deserunt mollit anim id est laborum. 
                    
                </p><br></br>

                        <div className='div_confirmacion_datos_empleado'>
                            <br></br>
                            <div>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={()=>setStatus(!status)}></input>
                                <label class="form-check-label" for="flexCheckDefault">
                                    <p className="txt_confirmacion_preregistro_datos">ACEPTO</p>
                                </label>
                            </div>
                        </div>

                        <div className='div_reportar_datos_empleado'>
                            <br></br>
                            {/* <Link to="/registro_empresa"> */}
                                        <button
                                            className='btn_conocer_preregistro_aviso'
                                            type='submit'
                                            // onClick={fun_test}
                                        >
                                            CONOCER MÁS
                                        </button>
                            {/* </Link> */}
                        </div>

                        <div className='div_finalizar_datos_empleado'>
                            <br></br>
                                {/* <Link to="/registro_empresa"> */}
                                        <button
                                            // className='btn_Continuar_RegistroAdmin'
                                            className={btn_style_continuar}
                                            type='submit'
                                            onClick={fun_continuar}
                                        >
                                            CONTINUAR
                                        </button>
                                {/* </Link> */}
                        </div>
            </div>
        </div>
        );
};