import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export const BaseCalculoSelector = ({ baseCalculoChangeHandler, jsonEmpresaData }) => {
  return (
  <FormControl component="fieldset" sx={{ width: '300px', marginTop: '2%', marginRight: '1.333%' }}>
    <FormLabel component="legend" sx={{ marginBottom: '8px' }}>
      Base de cálculo
    </FormLabel>
    <RadioGroup
      aria-label="base-de-calculo"
      name="base-de-calculo"
      value={jsonEmpresaData.baseCalculo}
      onChange={baseCalculoChangeHandler}
      row
    >
      <FormControlLabel value="Sueldo bruto" control={<Radio />} label="Sueldo bruto" />
      <FormControlLabel value="Sueldo neto" control={<Radio />} label="Sueldo neto" />
    </RadioGroup>
  </FormControl>
  );
};