import React, { useState } from 'react'

const IdContext = React.createContext({
    idAfiliado: 0,
    updateAfiliado: (id) => {}
});

export const IdContextProvider = (props) => {
  const [idAfiliado, setIdAfiliado] = useState(0);

  const updateAfiliadoHandler = (id) => {
    setIdAfiliado(id);
  };
  
  return (
      <IdContext.Provider value = {{
            idAfiliado: idAfiliado,
            updateAfiliado: updateAfiliadoHandler
        }}
      >
        {props.children}
      </IdContext.Provider>
  );
};

export default IdContext;