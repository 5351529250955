import React from 'react';
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom';

export const MenuAdmin = ({isXsScreen}) => {
  return (
    <Box>
        <Box sx={{display:'flex', justifyContent:'center'}}>
            <Box sx={{borderRadius:'14px', width: isXsScreen ? '100%' : '87%', display:'flex', flexDirection:'column', marginTop: isXsScreen ? '0%' : '10%', alignItems:'center', background: '#FF8030'}}>
                <Typography variant="h5" sx={{marginBlock: 2, color:'#FFFFFF', fontWeight: '700', fontSize:{xs: '.7rem', sm: '1.2rem', md: '1.5rem' }}}>
                    POR MEXICO
                </Typography>
                <Link to='/altaEmpresas' style={{width:'100%'}}>
                    <Button variant='contained' sx={{width:'100%', backgroundColor: '#f5f5f5', '&:hover':{backgroundColor: '#dcdcdc'}}} disableRipple>
                        <Typography sx={{color:'#353535', fontSize:{xs: '.6rem', sm: '.8rem', md: '1rem'}}}>
                            Alta de empresas
                        </Typography>
                    </Button>
                </Link>
                <Link to='/nomina' style={{width:'100%'}}>
                    <Button variant='contained' sx={{width:'100%', backgroundColor: '#f5f5f5', '&:hover':{backgroundColor: '#dcdcdc'}}} disableRipple>
                        <Typography sx={{color:'#353535', fontSize:{xs: '.6rem', sm: '.8rem', md: '1rem'}}}>
                            Cotización de nómina
                        </Typography>
                    </Button>
                </Link>
                <Link to='/listado' style={{width:'100%'}}>
                    <Button variant='contained' sx={{width:'100%', backgroundColor: '#f5f5f5', '&:hover':{backgroundColor: '#dcdcdc'}}} disableRipple>
                        <Typography sx={{color:'#353535', fontSize:{xs: '.6rem', sm: '.8rem', md: '1rem'}}}>
                            Listado
                        </Typography>
                    </Button>
                </Link>
                <Link to='/config' style={{width:'100%'}}>
                    <Button variant='contained' sx={{width:'100%', backgroundColor: '#f5f5f5', '&:hover':{backgroundColor: '#dcdcdc'}}} disableRipple>
                        <Typography sx={{color:'#353535', fontSize:{xs: '.6rem', sm: '.8rem', md: '1rem'}}}>
                            Configuración
                        </Typography>
                    </Button>
                </Link>
            </Box>
        </Box>
    </Box>
  )
}
