// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img_logo_paginaPublica{
    width: 7ch;
}

@media (max-width: 950px){
    /* navBar */
    .appBar_paginaPublica{
        width: 100%;
        left: 0%;
    }
    .img_logo_paginaPublica{
        width: 5ch;
    }
    .typography_button_paginaPublica{
        font-size: 15px;
    }

    /* 1 principal */
    .box_1Principal_paginaPublica{
        height: 100vh;
    }
    .typography_text1_1Principal_paginaPublica{
        font-size: 30px;
    }
    .typography_text1Pildora_1Principal_paginaPublica{
        font-size: 35px;
    }
    .typography_text2Pildora_1Principal_paginaPublica{
        font-size: 25px;
    }
}

@media (max-width: 750px){
    /* navBar */
    .appBar_paginaPublica{
        padding-top: 0;
        padding-bottom: 0;
    }
    .img_logo_paginaPublica{
        width: 4ch;
    }
    .typography_text_navBar_paginaPublica{
        font-size: 15px;
    }

    /* 1 principal */
    .typography_text1_1Principal_paginaPublica{
        font-size: 20px;
    }
    .typography_text1Pildora_1Principal_paginaPublica{
        font-size: 25px;
    }
    .typography_text2Pildora_1Principal_paginaPublica{
        font-size: 15px;
    }
}

@media (max-width: 500px){
    /* navBar */
    .appBar_paginaPublica{
        padding-top: 0;
        padding-bottom: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/xmex/paginaPublica/PaginaPublica.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX;QACI,WAAW;QACX,QAAQ;IACZ;IACA;QACI,UAAU;IACd;IACA;QACI,eAAe;IACnB;;IAEA,gBAAgB;IAChB;QACI,aAAa;IACjB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,WAAW;IACX;QACI,cAAc;QACd,iBAAiB;IACrB;IACA;QACI,UAAU;IACd;IACA;QACI,eAAe;IACnB;;IAEA,gBAAgB;IAChB;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,WAAW;IACX;QACI,cAAc;QACd,iBAAiB;IACrB;AACJ","sourcesContent":[".img_logo_paginaPublica{\n    width: 7ch;\n}\n\n@media (max-width: 950px){\n    /* navBar */\n    .appBar_paginaPublica{\n        width: 100%;\n        left: 0%;\n    }\n    .img_logo_paginaPublica{\n        width: 5ch;\n    }\n    .typography_button_paginaPublica{\n        font-size: 15px;\n    }\n\n    /* 1 principal */\n    .box_1Principal_paginaPublica{\n        height: 100vh;\n    }\n    .typography_text1_1Principal_paginaPublica{\n        font-size: 30px;\n    }\n    .typography_text1Pildora_1Principal_paginaPublica{\n        font-size: 35px;\n    }\n    .typography_text2Pildora_1Principal_paginaPublica{\n        font-size: 25px;\n    }\n}\n\n@media (max-width: 750px){\n    /* navBar */\n    .appBar_paginaPublica{\n        padding-top: 0;\n        padding-bottom: 0;\n    }\n    .img_logo_paginaPublica{\n        width: 4ch;\n    }\n    .typography_text_navBar_paginaPublica{\n        font-size: 15px;\n    }\n\n    /* 1 principal */\n    .typography_text1_1Principal_paginaPublica{\n        font-size: 20px;\n    }\n    .typography_text1Pildora_1Principal_paginaPublica{\n        font-size: 25px;\n    }\n    .typography_text2Pildora_1Principal_paginaPublica{\n        font-size: 15px;\n    }\n}\n\n@media (max-width: 500px){\n    /* navBar */\n    .appBar_paginaPublica{\n        padding-top: 0;\n        padding-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
