import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const ReporteNominaPDF = ({
  nameEmpresa,
  tipoPeriodo,
  periodoFiscal,
  esquema,
  planSindical,
  baseCalculo,
  sueldoBaseNaranjaPorcentajeEmpresaLey,
  indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato,
  sumatoriaSat,
  sumatoriaImss,
  sumatoriaImpNomina,
  sumatoriaImssEmpresa,
  sumatoriaRcv,
  sumatoriaInfonavit,
  sumatoriaIsr,
  sumatoriaSueldos,
  sumatoriaSueldosBase,
  sumatoriaCTA,
  sumatoriaCTApoyoSindicalImss,
  sumatoriaCTApoyoSindicalImssEmpresa,
  sumatoriaCTApoyoSindicalSat,
  sumatoriaCTApoyoSindicalIsr,
  sumatoriaCTApoyoSindicalRcv,
  sumatoriaCTAS,
  psIsrEmpleado,
  psImssEmpleado,
  psImpuestoNomina,
  psImssEmpresa,
  psRcv,
  psInfonavit,
  psTotal,
  restaIsr,
  restaImss,
  restaImpuestoNomina,
  restaImssEmpresa,
  restaRcv,
  restaInfonavit,
  restaTotal,
  indemnizacionRiesgoLaboral,
  costoOperacion,
  resultadoPorcentajeEmpresa,
  resultadoPorcentajeSindicato,
  costoOperacionMensual,
  costoOperacionAnual,
  ahorroReal,
  ahorroRealAnual,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Nombre de la empresa: {nameEmpresa}</Text>
          <Text>Tipo de periodo: {tipoPeriodo}</Text>
          <Text>Periodo fiscal: {periodoFiscal}</Text>
          <Text>Esquema: {esquema}</Text>
          <Text>Plan sindical: {planSindical}</Text>
          <Text>Base de cálculo: {baseCalculo}</Text>
          <Text>Sueldo base naranja (Empresa): {sueldoBaseNaranjaPorcentajeEmpresaLey}</Text>
          <Text>Indemnización riesgo laboral (Sindicato): {indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato}</Text>
          <Text>Sumatoria SAT: {sumatoriaSat}</Text>
          <Text>Sumatoria IMSS: {sumatoriaImss}</Text>
          <Text>Sumatoria Impuesto sobre nómina: {sumatoriaImpNomina}</Text>
          <Text>Sumatoria IMSS Empresa: {sumatoriaImssEmpresa}</Text>
          <Text>Sumatoria RCV: {sumatoriaRcv}</Text>
          <Text>Sumatoria Infonavit: {sumatoriaInfonavit}</Text>
          <Text>Sumatoria ISR: {sumatoriaIsr}</Text>
          <Text>Sumatoria Sueldos: {sumatoriaSueldos}</Text>
          <Text>Sumatoria Sueldos base: {sumatoriaSueldosBase}</Text>
          <Text>Sumatoria CTA: {sumatoriaCTA}</Text>
          <Text>Sumatoria CTA Apoyo Sindical IMSS: {sumatoriaCTApoyoSindicalImss}</Text>
          <Text>Sumatoria CTA Apoyo Sindical IMSS Empresa: {sumatoriaCTApoyoSindicalImssEmpresa}</Text>
          <Text>Sumatoria CTA Apoyo Sindical SAT: {sumatoriaCTApoyoSindicalSat}</Text>
          <Text>Sumatoria CTA Apoyo Sindical ISR: {sumatoriaCTApoyoSindicalIsr}</Text>
          <Text>Sumatoria CTA Apoyo Sindical RCV: {sumatoriaCTApoyoSindicalRcv}</Text>
          <Text>Sumatoria CTAS: {sumatoriaCTAS}</Text>
          <Text>ISR Empleado: {psIsrEmpleado}</Text>
          <Text>IMSS Empleado: {psImssEmpleado}</Text>
          <Text>Impuesto sobre nómina: {psImpuestoNomina}</Text>
          <Text>IMSS Empresa: {psImssEmpresa}</Text>
          <Text>RCV: {psRcv}</Text>
          <Text>Infonavit: {psInfonavit}</Text>
          <Text>Total: {psTotal}</Text>
          <Text>Resta ISR: {restaIsr}</Text>
          <Text>Resta IMSS: {restaImss}</Text>
          <Text>Resta Impuesto sobre nómina: {restaImpuestoNomina}</Text>
          <Text>Resta IMSS Empresa: {restaImssEmpresa}</Text>
          <Text>Resta RCV: {restaRcv}</Text>
          <Text>Resta Infonavit: {restaInfonavit}</Text>
          <Text>Resta Total: {restaTotal}</Text>
          <Text>Indemnización riesgo laboral: {indemnizacionRiesgoLaboral}</Text>
          <Text>Costo de operación: {costoOperacion}</Text>
          <Text>Resultado porcentaje empresa: {resultadoPorcentajeEmpresa}</Text>
          <Text>Resultado porcentaje sindicato: {resultadoPorcentajeSindicato}</Text>
          <Text>Costo de operación mensual: {costoOperacionMensual}</Text>
          <Text>Costo de operación anual: {costoOperacionAnual}</Text>
          <Text>Ahorro real: {ahorroReal}</Text>
          <Text>Ahorro real anual: {ahorroRealAnual}</Text>
        </View>
      </Page>
    </Document>
  );
};