import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import { NavBar } from './NavBar';
import { Footer } from './Footer';

export const Beneficios2 = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
        <Box>
            <NavBar/>
              {isSmallScreen &&
                <Box marginTop={10} marginBottom={4}>
                    <Container>
                        <Typography variant="h5" gutterBottom>
                            Beneficios sindicales: Ejemplos y ventajas para los trabajadores
                        </Typography>
                        <Typography fontWeight={400} variant="h7" gutterBottom>
                            Cuando se habla de sindicatos, es común pensar en la lucha por mejores condiciones laborales y salarios justos. Sin embargo, los beneficios que los sindicatos pueden ofrecer a los trabajadores van más allá de eso. Aquí te presentamos algunos ejemplos de los beneficios sindicales que pueden marcar una diferencia en la vida de los empleados:
                        </Typography>           
                        <Typography fontWeight={400} marginLeft={10} marginRight={10} variant="h7" gutterBottom>
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                1. Protección y representación en el lugar de trabajo
                            </Typography>
                            Uno de los principales beneficios de pertenecer a un sindicato es contar con protección y representación en el lugar de trabajo. Esto significa que ante cualquier conflicto laboral o situación injusta, el sindicato estará allí para defender tus derechos. Si te enfrentas a despidos injustificados, acoso laboral, discriminación o cualquier otra situación negativa, el sindicato puede brindarte asesoramiento legal y apoyo para garantizar que se respeten tus derechos laborales.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                                2. Negociación colectiva de salarios y condiciones laborales
                            </Typography>
                            Uno de los aspectos más importantes de los sindicatos es su capacidad para negociar colectivamente salarios y condiciones laborales con los empleadores. Esto significa que, al unirse a un sindicato, tienes la oportunidad de influir en la negociación de salarios justos, beneficios adicionales como  seguro de salud, días de vacaciones y horarios de trabajo flexibles. Con la fuerza y la solidaridad de un sindicato, los trabajadores pueden lograr mejoras significativas en sus condiciones de trabajo y garantizar una remuneración justa por su labor.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                                3. Acceso a programas de capacitación y desarrollo profesional
                            </Typography>
                            Muchos sindicatos ofrecen programas de capacitación y desarrollo profesional para sus miembros. Estos programas pueden incluir cursos, talleres y certificaciones que te ayudarán a mejorar tus habilidades y competencias laborales. Al tener acceso a estas oportunidades de formación, podrás ampliar tu conocimiento y experiencia, lo que te abrirá puertas a mejores oportunidades laborales a largo plazo. Además, el sindicato puede brindarte asesoramiento sobre planes de jubilación,  seguros de vida y otros beneficios importantes para asegurar tu bienestar y seguridad futura.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                Conclusion
                            </Typography>
                            <br/>
                            En resumen, los beneficios sindicales para los trabajadores van más allá de la negociación de salarios y condiciones laborales. Los sindicatos ofrecen protección y representación, así como acceso a programas de capacitación y desarrollo profesional. Si bien hay muchas más ventajas de ser parte de un sindicato, estos ejemplos muestran cómo la unión de los trabajadores puede marcar una diferencia tangible en sus vidas y en la defensa de sus derechos.

                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                Ventajas de los beneficios sindicales
                            <br/>
                            </Typography>
                            Los beneficios sindicales pueden ser una gran ventaja para los trabajadores, ya que les ofrecen una serie de beneficios y protecciones adicionales en su lugar de trabajo. Una de las ventajas más importantes es la negociación colectiva, en la que el sindicato puede negociar en nombre de todos los trabajadores para establecer mejores salarios, condiciones laborales más justas y beneficios adicionales, como  seguro de salud y planes de jubilación.
                            Además de la negociación colectiva, los beneficios sindicales también pueden proporcionar a los trabajadores protección en caso de despidos injustos. Los sindicatos pueden brindar asesoramiento legal y apoyo en caso de discriminación laboral o despidos basados en motivos injustos. Esto puede proporcionar a los trabajadores una mayor seguridad laboral y tranquilidad en su empleo.
                            Otra ventaja de los beneficios sindicales es la formación y educación continua. Muchos sindicatos ofrecen programas de formación y desarrollo profesional para sus miembros, lo que les permite mejorar sus habilidades y conocimientos en su campo laboral. Esto no solo beneficia a los trabajadores individualmente, sino que también puede tener un impacto positivo en la productividad y competitividad de las empresas.
                            En resumen, los beneficios sindicales ofrecen a los trabajadores una voz colectiva y la capacidad de influir en su entorno laboral. Estos beneficios pueden incluir mejores salarios, condiciones laborales más justas, protección contra despidos injustos y oportunidades de formación y desarrollo profesional. Al unirse a un sindicato, los trabajadores pueden obtener una serie de ventajas adicionales que pueden mejorar su calidad de vida y su satisfacción laboral.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                Impacto de los beneficios sindicales en los salarios y horarios
                            </Typography>
                            <br/>
                            Los sindicatos han desempeñado un papel crucial en la mejora de las condiciones laborales en todo el mundo. Han sido fundamentales en la lucha por salarios justos, horarios razonables y beneficios para los trabajadores. Sin embargo, aún existe cierto debate sobre el impacto real de los beneficios sindicales en los salarios y horarios.
                            <br/>
                            Cuando un sindicato negocia un contrato laboral, es común que se acuerden aumentos salariales y mejoras en los horarios de trabajo. Esto se debe a que los sindicatos tienen la capacidad de presionar a los empleadores para que ofrezcan condiciones más favorables a los trabajadores. En muchos casos, los empleadores prefieren llegar a un acuerdo con los sindicatos para evitar huelgas o disputas prolongadas.
                            <br/>
                            Además de los salarios y horarios, los beneficios sindicales también pueden incluir atención médica, jubilación, vacaciones pagadas y otros privilegios. Estos beneficios pueden tener un impacto significativo en la calidad de vida de los trabajadores y su capacidad para satisfacer sus necesidades básicas.
                            <br/>
                            Ahora bien, es importante considerar que los beneficios sindicales también tienen sus detractores. Algunos argumentan que los acuerdos sindicales pueden causar un aumento en los costos laborales, lo que podría resultar en la pérdida de empleos o en la incapacidad de las empresas para competir en el mercado. Sin embargo, estudios han demostrado que la presencia de sindicatos puede conducir a una mayor productividad y estabilidad en el lugar de trabajo, lo que a su vez puede beneficiar a las empresas.
                            <br/>
                            En resumen, los beneficios sindicales tienen un impacto indudable en los salarios y horarios de los trabajadores. Aunque existe cierto debate sobre su impacto económico, no se puede negar que los sindicatos han desempeñado un papel crucial en la mejora de las condiciones laborales. Los trabajadores sindicalizados tienen más probabilidades de obtener salarios más altos y disfrutar de horarios más flexibles, así como de otros beneficios adicionales. Es importante reconocer y valorar el legado de los sindicatos en la lucha por la igualdad y la justicia en el ámbito laboral.

                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                Beneficios sindicales más comunes
                            </Typography>
                            <Container>
                                <Typography fontWeight={400} variant="h7" gutterBottom marginLeft={15}>
                                    <br/>
                                    – Aumentos salariales: los sindicatos tienen la capacidad de negociar salarios más altos para los trabajadores, asegurando que tengan un salario justo por su trabajo.
                                    <br/>
                                    – Mejoras en los horarios de trabajo: los sindicatos pueden luchar por horarios más flexibles y garantizar que los trabajadores no sean explotados.
                                    <br/>
                                    – Atención médica: muchos sindicatos ofrecen planes de  seguro médico para los trabajadores y sus familias, lo que les brinda acceso a atención médica asequible.
                                    <br/>
                                    – Jubilación: las pensiones y los planes de jubilación son beneficios importantes negociados por los sindicatos para garantizar que los trabajadores puedan disfrutar de una jubilación segura y cómoda.
                                    <br/>
                                    – Vacaciones pagadas: los sindicatos pueden garantizar que los trabajadores tengan derecho a un tiempo adecuado de descanso y recreación mediante el establecimiento de días de vacaciones pagadas.
                                </Typography>
                            </Container>
                            Los beneficios sindicales pueden variar de un país a otro y de una industria a otra. Sin embargo, es evidente que los sindicatos siguen siendo una fuerza poderosa en la defensa de los derechos de los trabajadores y en la mejora de las condiciones laborales en general. Es importante que los trabajadores continúen apoyando a los sindicatos y se unan para luchar por salarios justos, horarios razonables y beneficios adecuados.

                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                Beneficios sindicales en la protección laboral
                            <br/>
                            </Typography>
                            Cuando se trata de proteger los derechos de los trabajadores, los sindicatos juegan un papel crucial. Muchas personas pueden pensar en los sindicatos como organizaciones que solo se ocupan de los salarios y las horas de trabajo, pero hay mucho más en juego. Los sindicatos ofrecen una serie de beneficios que van más allá de las negociaciones salariales, y que pueden tener un impacto significativo en la protección laboral.
                            <br/>
                            Uno de los principales beneficios de pertenecer a un sindicato es el acceso a una mejor cobertura de  seguro de salud. Los sindicatos a menudo negocian con los empleadores para obtener planes de salud más competitivos y asequibles para los trabajadores. Esto no solo garantiza una mayor atención médica, sino que también proporciona una red de seguridad financiera en caso de enfermedad o accidente.
                            <br/>
                            Además de la cobertura médica, los sindicatos pueden brindar protección adicional en el lugar de trabajo. A través de la negociación colectiva, los sindicatos pueden establecer condiciones laborales más seguras y garantizar la implementación de regulaciones y políticas de seguridad. Esto no solo beneficia a los miembros del sindicato, sino que también mejora las condiciones generales para todos los trabajadores en un determinado sector o industria.
                            <br/>
                            Otro beneficio clave de pertenecer a un sindicato es la representación legal. Los sindicatos tienen expertos legales que están capacitados para brindar asistencia y representación en casos de discriminación laboral, despidos injustificados o cualquier violación de los derechos laborales. Un trabajador sindicalizado puede tener la tranquilidad de saber que tiene a su disposición un equipo de profesionales dispuestos a luchar por sus derechos.
                            <br/>
                            En resumen, los beneficios sindicales en la protección laboral son fundamentales para garantizar condiciones de trabajo justas y seguras. Desde una mejor cobertura de  seguro de salud hasta una representación legal, los sindicatos desempeñan un papel esencial en la defensa de los derechos de los trabajadores. Es importante reconocer la importancia de estos beneficios y apoyar a los sindicatos en su lucha por una protección laboral justa y equitativa.

                            <Typography fontWeight={600} variant="h7" gutterBottom>
                            <br/>
                            <br/>
                                El papel de los beneficios sindicales en la negociación colectiva
                            <br/>
                            </Typography>
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                                ¿Qué son los beneficios sindicales?
                            </Typography>
                            <br/>
                            Cuando hablamos de negociación colectiva, es fundamental entender el papel que desempeñan los beneficios sindicales. 
                            <br/>
                            Estos beneficios son acuerdos que los sindicatos negocian con los empleadores en nombre de los trabajadores. 
                            <br/>
                            Pueden incluir desde aumentos salariales y bonificaciones hasta días de vacaciones adicionales, planes de salud y seguridad laboral.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                                ¿Por qué son importantes en la negociación colectiva?
                            <br/>
                            </Typography>
                            Los beneficios sindicales son una parte fundamental de la negociación colectiva, ya que tienen un impacto directo en la calidad de vida de los trabajadores. Al asegurar mejores salarios y condiciones laborales, los sindicatos pueden ayudar a garantizar la estabilidad económica y personal de sus miembros.
                            <br/>
                            Además, los beneficios sindicales también fomentan la lealtad y la satisfacción de los empleados. Cuando los trabajadores sienten que sus necesidades y derechos están siendo atendidos, es más probable que se sientan comprometidos con su trabajo y con la empresa en general. Esto crea un ambiente laboral más positivo y productivo.
                            <Typography fontWeight={500} variant="h7" gutterBottom>
                            <br/>
                                Ejemplos de beneficios sindicales
                            <br/>
                            </Typography>
                            Los beneficios sindicales pueden variar dependiendo del sector y del país, pero algunos ejemplos comunes incluyen:
                            <Typography fontWeight={400} variant="h7" gutterBottom marginLeft={5}>
                                <br/>
                                - Aumentos salariales: Los sindicatos pueden negociar incrementos salariales regulares para garantizar que los trabajadores reciban un salario justo y acorde a sus responsabilidades.
                                <br/>
                                - Planes de salud: Muchos sindicatos luchan por conseguir beneficios de salud más amplios y asequibles para los empleados y sus familias.
                                <br/>
                                - Horas de trabajo: Los sindicatos también pueden negociar la reducción de la jornada laboral o la implementación de horarios flexibles.
                            </Typography>
                            En resumen, los beneficios sindicales desempeñan un papel crucial en la negociación colectiva, ya que buscan mejorar las condiciones laborales y el bienestar de los trabajadores. Con mejores salarios, seguridad laboral y otros beneficios, los empleados se sienten valorados y motivados, lo que a su vez contribuye a un entorno laboral más productivo y armonioso.

                            <br/><br/>
                            ¿Quiéres conocer todos los beneficios?, da click aquí:
                            <Button
                                onClick={() => { window.location.href = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/Beneficios_sindicales.pdf' }}
                            >
                                <Typography
                                    sx={{fontSize: 16, fontWeight: 700}}>
                                    Beneficios
                                </Typography>
                            </Button>

                        </Typography>           
                    </Container>
                </Box>
              }
              {isMediumScreen &&
                <Box>
                    <Container>
                        
                    </Container>
                </Box>
              }
              {isLargeScreen &&
                <Box>
                    <Container>
                                            
                    </Container>
                </Box>
              }
              {isExtraLargeScreen &&
                <Box marginTop={20} marginBottom={7}>
                    <Container>
                        <Typography variant="h4" gutterBottom>
                            Beneficios sindicales: Ejemplos y ventajas para los trabajadores
                        </Typography>
                        <Typography fontWeight={400} variant="h5" gutterBottom>
                            Cuando se habla de sindicatos, es común pensar en la lucha por mejores condiciones laborales y salarios justos. Sin embargo, los beneficios que los sindicatos pueden ofrecer a los trabajadores van más allá de eso. Aquí te presentamos algunos ejemplos de los beneficios sindicales que pueden marcar una diferencia en la vida de los empleados:
                        </Typography>           
                        <Typography fontWeight={400} marginLeft={10} marginRight={10} variant="h6" gutterBottom>
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                1. Protección y representación en el lugar de trabajo
                            </Typography>
                            Uno de los principales beneficios de pertenecer a un sindicato es contar con protección y representación en el lugar de trabajo. Esto significa que ante cualquier conflicto laboral o situación injusta, el sindicato estará allí para defender tus derechos. Si te enfrentas a despidos injustificados, acoso laboral, discriminación o cualquier otra situación negativa, el sindicato puede brindarte asesoramiento legal y apoyo para garantizar que se respeten tus derechos laborales.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                2. Negociación colectiva de salarios y condiciones laborales
                            </Typography>
                            Uno de los aspectos más importantes de los sindicatos es su capacidad para negociar colectivamente salarios y condiciones laborales con los empleadores. Esto significa que, al unirse a un sindicato, tienes la oportunidad de influir en la negociación de salarios justos, beneficios adicionales como  seguro de salud, días de vacaciones y horarios de trabajo flexibles. Con la fuerza y la solidaridad de un sindicato, los trabajadores pueden lograr mejoras significativas en sus condiciones de trabajo y garantizar una remuneración justa por su labor.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                3. Acceso a programas de capacitación y desarrollo profesional
                            </Typography>
                            Muchos sindicatos ofrecen programas de capacitación y desarrollo profesional para sus miembros. Estos programas pueden incluir cursos, talleres y certificaciones que te ayudarán a mejorar tus habilidades y competencias laborales. Al tener acceso a estas oportunidades de formación, podrás ampliar tu conocimiento y experiencia, lo que te abrirá puertas a mejores oportunidades laborales a largo plazo. Además, el sindicato puede brindarte asesoramiento sobre planes de jubilación,  seguros de vida y otros beneficios importantes para asegurar tu bienestar y seguridad futura.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Conclusion
                            </Typography>
                            En resumen, los beneficios sindicales para los trabajadores van más allá de la negociación de salarios y condiciones laborales. Los sindicatos ofrecen protección y representación, así como acceso a programas de capacitación y desarrollo profesional. Si bien hay muchas más ventajas de ser parte de un sindicato, estos ejemplos muestran cómo la unión de los trabajadores puede marcar una diferencia tangible en sus vidas y en la defensa de sus derechos.

                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Ventajas de los beneficios sindicales
                            </Typography>
                            Los beneficios sindicales pueden ser una gran ventaja para los trabajadores, ya que les ofrecen una serie de beneficios y protecciones adicionales en su lugar de trabajo. Una de las ventajas más importantes es la negociación colectiva, en la que el sindicato puede negociar en nombre de todos los trabajadores para establecer mejores salarios, condiciones laborales más justas y beneficios adicionales, como  seguro de salud y planes de jubilación.
                            Además de la negociación colectiva, los beneficios sindicales también pueden proporcionar a los trabajadores protección en caso de despidos injustos. Los sindicatos pueden brindar asesoramiento legal y apoyo en caso de discriminación laboral o despidos basados en motivos injustos. Esto puede proporcionar a los trabajadores una mayor seguridad laboral y tranquilidad en su empleo.
                            Otra ventaja de los beneficios sindicales es la formación y educación continua. Muchos sindicatos ofrecen programas de formación y desarrollo profesional para sus miembros, lo que les permite mejorar sus habilidades y conocimientos en su campo laboral. Esto no solo beneficia a los trabajadores individualmente, sino que también puede tener un impacto positivo en la productividad y competitividad de las empresas.
                            En resumen, los beneficios sindicales ofrecen a los trabajadores una voz colectiva y la capacidad de influir en su entorno laboral. Estos beneficios pueden incluir mejores salarios, condiciones laborales más justas, protección contra despidos injustos y oportunidades de formación y desarrollo profesional. Al unirse a un sindicato, los trabajadores pueden obtener una serie de ventajas adicionales que pueden mejorar su calidad de vida y su satisfacción laboral.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Impacto de los beneficios sindicales en los salarios y horarios
                            </Typography>
                            Los sindicatos han desempeñado un papel crucial en la mejora de las condiciones laborales en todo el mundo. Han sido fundamentales en la lucha por salarios justos, horarios razonables y beneficios para los trabajadores. Sin embargo, aún existe cierto debate sobre el impacto real de los beneficios sindicales en los salarios y horarios.
                            <br/>
                            Cuando un sindicato negocia un contrato laboral, es común que se acuerden aumentos salariales y mejoras en los horarios de trabajo. Esto se debe a que los sindicatos tienen la capacidad de presionar a los empleadores para que ofrezcan condiciones más favorables a los trabajadores. En muchos casos, los empleadores prefieren llegar a un acuerdo con los sindicatos para evitar huelgas o disputas prolongadas.
                            <br/>
                            Además de los salarios y horarios, los beneficios sindicales también pueden incluir atención médica, jubilación, vacaciones pagadas y otros privilegios. Estos beneficios pueden tener un impacto significativo en la calidad de vida de los trabajadores y su capacidad para satisfacer sus necesidades básicas.
                            <br/>
                            Ahora bien, es importante considerar que los beneficios sindicales también tienen sus detractores. Algunos argumentan que los acuerdos sindicales pueden causar un aumento en los costos laborales, lo que podría resultar en la pérdida de empleos o en la incapacidad de las empresas para competir en el mercado. Sin embargo, estudios han demostrado que la presencia de sindicatos puede conducir a una mayor productividad y estabilidad en el lugar de trabajo, lo que a su vez puede beneficiar a las empresas.
                            <br/>
                            En resumen, los beneficios sindicales tienen un impacto indudable en los salarios y horarios de los trabajadores. Aunque existe cierto debate sobre su impacto económico, no se puede negar que los sindicatos han desempeñado un papel crucial en la mejora de las condiciones laborales. Los trabajadores sindicalizados tienen más probabilidades de obtener salarios más altos y disfrutar de horarios más flexibles, así como de otros beneficios adicionales. Es importante reconocer y valorar el legado de los sindicatos en la lucha por la igualdad y la justicia en el ámbito laboral.

                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Beneficios sindicales más comunes
                            </Typography>
                            <Typography fontWeight={400} variant="h6" gutterBottom marginLeft={5}>
                                – Aumentos salariales: los sindicatos tienen la capacidad de negociar salarios más altos para los trabajadores, asegurando que tengan un salario justo por su trabajo.
                                <br/>
                                – Mejoras en los horarios de trabajo: los sindicatos pueden luchar por horarios más flexibles y garantizar que los trabajadores no sean explotados.
                                <br/>
                                – Atención médica: muchos sindicatos ofrecen planes de  seguro médico para los trabajadores y sus familias, lo que les brinda acceso a atención médica asequible.
                                <br/>
                                – Jubilación: las pensiones y los planes de jubilación son beneficios importantes negociados por los sindicatos para garantizar que los trabajadores puedan disfrutar de una jubilación segura y cómoda.
                                <br/>
                                – Vacaciones pagadas: los sindicatos pueden garantizar que los trabajadores tengan derecho a un tiempo adecuado de descanso y recreación mediante el establecimiento de días de vacaciones pagadas.
                            </Typography>
                            Los beneficios sindicales pueden variar de un país a otro y de una industria a otra. Sin embargo, es evidente que los sindicatos siguen siendo una fuerza poderosa en la defensa de los derechos de los trabajadores y en la mejora de las condiciones laborales en general. Es importante que los trabajadores continúen apoyando a los sindicatos y se unan para luchar por salarios justos, horarios razonables y beneficios adecuados.

                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Beneficios sindicales en la protección laboral
                            </Typography>
                            Cuando se trata de proteger los derechos de los trabajadores, los sindicatos juegan un papel crucial. Muchas personas pueden pensar en los sindicatos como organizaciones que solo se ocupan de los salarios y las horas de trabajo, pero hay mucho más en juego. Los sindicatos ofrecen una serie de beneficios que van más allá de las negociaciones salariales, y que pueden tener un impacto significativo en la protección laboral.
                            <br/>
                            Uno de los principales beneficios de pertenecer a un sindicato es el acceso a una mejor cobertura de  seguro de salud. Los sindicatos a menudo negocian con los empleadores para obtener planes de salud más competitivos y asequibles para los trabajadores. Esto no solo garantiza una mayor atención médica, sino que también proporciona una red de seguridad financiera en caso de enfermedad o accidente.
                            <br/>
                            Además de la cobertura médica, los sindicatos pueden brindar protección adicional en el lugar de trabajo. A través de la negociación colectiva, los sindicatos pueden establecer condiciones laborales más seguras y garantizar la implementación de regulaciones y políticas de seguridad. Esto no solo beneficia a los miembros del sindicato, sino que también mejora las condiciones generales para todos los trabajadores en un determinado sector o industria.
                            <br/>
                            Otro beneficio clave de pertenecer a un sindicato es la representación legal. Los sindicatos tienen expertos legales que están capacitados para brindar asistencia y representación en casos de discriminación laboral, despidos injustificados o cualquier violación de los derechos laborales. Un trabajador sindicalizado puede tener la tranquilidad de saber que tiene a su disposición un equipo de profesionales dispuestos a luchar por sus derechos.
                            <br/>
                            En resumen, los beneficios sindicales en la protección laboral son fundamentales para garantizar condiciones de trabajo justas y seguras. Desde una mejor cobertura de  seguro de salud hasta una representación legal, los sindicatos desempeñan un papel esencial en la defensa de los derechos de los trabajadores. Es importante reconocer la importancia de estos beneficios y apoyar a los sindicatos en su lucha por una protección laboral justa y equitativa.

                            <Typography fontWeight={600} variant="h6" gutterBottom>
                            <br/>
                                El papel de los beneficios sindicales en la negociación colectiva
                            </Typography>
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                                ¿Qué son los beneficios sindicales?
                            </Typography>
                            Cuando hablamos de negociación colectiva, es fundamental entender el papel que desempeñan los beneficios sindicales. 
                            <br/>
                            Estos beneficios son acuerdos que los sindicatos negocian con los empleadores en nombre de los trabajadores. 
                            <br/>
                            Pueden incluir desde aumentos salariales y bonificaciones hasta días de vacaciones adicionales, planes de salud y seguridad laboral.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                ¿Por qué son importantes en la negociación colectiva?
                            </Typography>
                            Los beneficios sindicales son una parte fundamental de la negociación colectiva, ya que tienen un impacto directo en la calidad de vida de los trabajadores. Al asegurar mejores salarios y condiciones laborales, los sindicatos pueden ayudar a garantizar la estabilidad económica y personal de sus miembros.
                            <br/>
                            Además, los beneficios sindicales también fomentan la lealtad y la satisfacción de los empleados. Cuando los trabajadores sienten que sus necesidades y derechos están siendo atendidos, es más probable que se sientan comprometidos con su trabajo y con la empresa en general. Esto crea un ambiente laboral más positivo y productivo.
                            <Typography fontWeight={500} variant="h6" gutterBottom>
                            <br/>
                                Ejemplos de beneficios sindicales
                            </Typography>
                            Los beneficios sindicales pueden variar dependiendo del sector y del país, pero algunos ejemplos comunes incluyen:
                            <br/>
                            <Typography fontWeight={400} variant="h6" gutterBottom marginLeft={5}>
                                - Aumentos salariales: Los sindicatos pueden negociar incrementos salariales regulares para garantizar que los trabajadores reciban un salario justo y acorde a sus responsabilidades.
                                <br/>
                                - Planes de salud: Muchos sindicatos luchan por conseguir beneficios de salud más amplios y asequibles para los empleados y sus familias.
                                <br/>
                                - Horas de trabajo: Los sindicatos también pueden negociar la reducción de la jornada laboral o la implementación de horarios flexibles.
                            </Typography>
                            En resumen, los beneficios sindicales desempeñan un papel crucial en la negociación colectiva, ya que buscan mejorar las condiciones laborales y el bienestar de los trabajadores. Con mejores salarios, seguridad laboral y otros beneficios, los empleados se sienten valorados y motivados, lo que a su vez contribuye a un entorno laboral más productivo y armonioso.
                            <br/><br/>
                            ¿Quiéres conocer todos los beneficios?, da click aquí:
                            <Button
                                onClick={() => { window.location.href = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/Beneficios_sindicales.pdf' }}
                            >
                                <Typography
                                    sx={{fontSize: 17, fontWeight: 700}}>
                                    Beneficios
                                </Typography>
                            </Button>

                        </Typography>           
                    </Container>
                </Box>
              }
            <Footer/>
        </Box>
    )
}

