import { Grid, Box } from '@mui/material'
import React from 'react'
import { MenuAdmin } from './MenuAdmin';

export const Config = () => {
  const isXsScreen = window.innerWidth < 600; // Cambia el valor según el tamaño de pantalla que consideres como xs

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}>
        <MenuAdmin isXsScreen={isXsScreen}/>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Box sx={{display:'flex', justifyContent:'center', marginTop:'2%'}}>
            <Box sx={{borderRadius:'10px', width:'90%', display:'flex', justifyContent:'center'}}>
                <img
                    src='..\imgs\dash\config.png'
                    style={{width:'90%'}}
                />
            </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
