import React, { useState } from "react";
import './Estilo_Cuestionario_Binario.css';
import { Preguntas101SiNoCond } from "./elementos_cuestionarios/Preguntas101SiNoCond";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario_Condicional21 = (props) => {
    const sigPag = "208";
    const pNombre = props.nombre;
    // const pUserId = props.usuario;
    const pUserId = getUrlParameter('userId');
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 1;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);
    
      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        UsuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });

      const [sessionJson, setSessionJson] = useState({
        referenciaId: 2,      //Dato estático
        seccionId: 11,        //Dato estático
        tipoPreguntaId: 1,    //Dato estático
        preguntaInicial: 41,  //Dato estático
        nombre: props.nombre,
        link: ""
      });

    const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [ ]
    });

    const updatePerfil = (preg, resp, qIdCond, condPreguntaActiva) => {
        console.log(preg,resp,qIdCond);
        if(condPreguntaActiva>=1){
          numPreguntas++;
        };
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        };

        var arrayRespuestas;
        if ( resp == 0 ){
          arrayRespuestas = { respuestas:
            [
              { ReferenciaId: 2, SeccionId: 11,  Pregunta: 41, Respuesta: 0 },
              { ReferenciaId: 2, SeccionId: 11,  Pregunta: 42, Respuesta: 0 },
              { ReferenciaId: 2, SeccionId: 11,  Pregunta: 43, Respuesta: 0 },
            ]
          };
        }else{
          arrayRespuestas = { respuestas: []};
        }
        setRespuestasJson(arrayRespuestas);

        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              qIdCond +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
      };

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            UsuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);

    return (
        <div className="box_menu_y_cuestionario_escala">
            <div className="cuestionario_div_escala">
                <div className="div_svg_menu_escala">
                    <img src="./img/menu_cuestionarios/menu_c27.svg" className="img_svg_escala"/>
                </div>
                <div className="text_cuestionario_escala">
                    <img src="./img/barra_progreso/riesgo_psicosocial/barra_de_progreso_27.svg" className="img_progreso_escala"/>
                    <p className="titulo_cuestionario_escala">
                      <img src="./img/barra_progreso/iconos/psicosocial_icono_cuestionario.svg"/>  
                        RIESGO PSICOSOCIAL
                    </p>

                    <Preguntas101SiNoCond
                        numPreg="41"
                        texto="En mi trabajo debo brindar servicio a clientes o usuarios:"
                        rSi="1"
                        rNo="0"
                        condSi="208"
                        condNo="209"
                        idSi="Si21"
                        idNo="No21"
                        update={updatePerfil}
                    />

                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas101"}
                      condicionPreg='101Cond'
                    />
                    
                </div>
            </div>
        </div>
    );
};