// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_txt_TittleDatosEncuestas_Analista{
    width: 50%;
    height: 5vh;
    display: inline-block;
    margin-left: 3%;
    margin-bottom: 5%;
    color: #FFFFFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    font-size: 1.7vw;
}
.txt_NombreEmpresa_DashAdmin1{
    font-size: 1vw;
}
.txt_Nombre_DashAdmin1{
    font-size: 1vw;
}
.txt_RazonSocial_DashAdmin1{
    font-size: 1vw;
}
.txt_RFC_DashAdmin1{
    font-size: 1vw;
}
.txt_Direccion_DashAdmin1{
    font-size: 1vw;
}
.span_txt_NombreEmpresa_DashAdmin1{
    font-size: 1vw;
}
.span_txt_RazonSocial_DashAdmin1{
    font-size: 1vw;
}
.span_txt_RFC_DashAdmin1{
    font-size: 1vw;
}
.span_txt_Direccion_DashAdmin1{
    font-size: 1vw;
}
.canvasA{
    color: turquoise;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/analista/datos_encuestas/DatosEncuestas_DashAnalista.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".div_txt_TittleDatosEncuestas_Analista{\n    width: 50%;\n    height: 5vh;\n    display: inline-block;\n    margin-left: 3%;\n    margin-bottom: 5%;\n    color: #FFFFFF;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 21px;\n    font-size: 1.7vw;\n}\n.txt_NombreEmpresa_DashAdmin1{\n    font-size: 1vw;\n}\n.txt_Nombre_DashAdmin1{\n    font-size: 1vw;\n}\n.txt_RazonSocial_DashAdmin1{\n    font-size: 1vw;\n}\n.txt_RFC_DashAdmin1{\n    font-size: 1vw;\n}\n.txt_Direccion_DashAdmin1{\n    font-size: 1vw;\n}\n.span_txt_NombreEmpresa_DashAdmin1{\n    font-size: 1vw;\n}\n.span_txt_RazonSocial_DashAdmin1{\n    font-size: 1vw;\n}\n.span_txt_RFC_DashAdmin1{\n    font-size: 1vw;\n}\n.span_txt_Direccion_DashAdmin1{\n    font-size: 1vw;\n}\n.canvasA{\n    color: turquoise;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
