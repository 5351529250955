import { Button, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

export const Btn_UpDateData = (props) => {

  const clickHandlerUpDateData = () => {
    
    // UPDATE AFILIADO DATA
    if (props.updateAfiliadoData == 'yes') {
      props.update(props.json)
    }

  }

  return (
    <>
      <Button
        color='primary' 
        variant='contained'
        sx={{borderRadius: 8, marginTop: '1%'}}
        onClick={clickHandlerUpDateData}
        disabled={props.disabled}
      >
        <Link to={props.link} style={{textDecoration: 'none'}}>
          <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
            {props.label}
          </Typography>
        </Link>     
      </Button>
    </>
  );
};
