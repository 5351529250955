import React from 'react';
import './Tarjeta_empresa.css';

export const Tarjeta_empresa = (props) => {
    console.log(props);
const propsEail = "mailto:" + props.admin.email;

return (
    <div>
      <table className='div_t_EmpresasRegistradas_analista'>
        <tbody>
          <tr>

            {/* <td className='td1_table_EmpresasRegistradas_Analista'>
              <img
                src='../img/admin/user.png' 
                className='img_analista'
              />
            </td> */}

            <td className='td2_table_EmpresasRegistradas_Analista'>
              <p className='Razon_social'>{props.admin.rasonSocial}</p>
            </td>
            
            <td className='td3_table_EmpresasRegistradas_Analista'>
              <p className='N_admin'>{props.admin.nombre}</p>
            </td>
            
            {/* <td className='td4_table_EmpresasRegistradas_Analista'>
              <img
                src='../img/admin/var5.png'
                className='estatus'
              />
            </td> */}

            {/* <td className='td5_table_EmpresasRegistradas_Analista'>
              <button className='btn_registro_pago'>
                Registro Pago 
              </button>
            </td> */}

            <td className='td6_table_EmpresasRegistradas_Analista'>
              <a href="https://189.216.10.93/descargas/xmexico/digitalizamx_nom35.csv">
                  <button className='btn_DescargarDatos_Analista'>
                    DESCARGAR<br/> DATOS
                      <img
                        src='../img/admin/download.png'
                        className='download_analista'
                      />
                  </button>           
              </a>
            </td>
            
            <td className='td7_table_EmpresasRegistradas_Analista'>
              <button className='btn_cargar_reporte'>
                CARGAR <br/> REPORTE
                <img
                  src='../img/admin/upload.png'
                  className='upload_analista'
                />
              </button>
            </td>
            
            <td className='td8_table_EmpresasRegistradas_Analista'>
              {/* <img
                src='../img/admin/mail.png'
                className='mail_png'
              />*/}    
              <a href= {propsEail} >
                <img src='../img/admin/mail.png' className='mail_png'/>
              </a> 
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  )
}