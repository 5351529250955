import React from 'react'
import { ScreenAfiliacion } from '../../components/xmex/afiliacion/afiliacion/ScreenAfiliacion'

export const Afiliacion = () => {
  return (
      <div>
          <ScreenAfiliacion/>
      </div>
  );
};
