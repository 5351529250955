import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { getUrlParameter } from '../../../../utils/jstools';
import { getPerfilEmpleadoByUsuarioId, getUserById, putPerfilEmpleado } from '../../../../utils/services';
import { actualizaUsuario } from '../../../../utils/services';
import { Button_siguente_empleado } from '../../cuestionario/elementos_cuestionarios/Button_siguiente_empleado';
import { Header } from '../../header/Header';
import { Menu_lateral_admin } from '../menu_lateral_admin/Menu_lateral_admin';
import './Perfil_admin_dashboard1_admin.css';

export const Perfil_admin_dashboard1_admin = () => {

    // instancia de cookies
    const cookies = new Cookies();
  
    // Tiempo de vida para cookie idUser
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
  
    const [ perfilUsuario, setPerfilUsuario ] = useState({
        Id: cookies.get('idUser'),
        Nombre: "",
        Paterno: "",
        Materno: "",
        Email: "",
        TelOficina: "",
        Celular: "",
        NumEmp: "",
        Pass: "",
        Rfc: "",
        Escolaridad: "",
        FecNac:"",
        RolId:1,
        GeneroId: 1,
        EmpresaId: cookies.get('idEmpresa'),
        qId: ""
    });

    const [perfilJson, setPerfilJson] = useState({
      qId: ""
    });

    const [confPass, setConfPass] = useState({
      confPassword: ""
    });

    // state para ocultar botones depende del qId
    const [ validateVisibility, setValidateVisibility ] = useState( "visible" );
  
    useEffect(() => {
      console.log('idempresa: ' + perfilUsuario.Id);
      // getUserById(perfilUsuario.Id).then((data)=>{
        console.log('userId admin:' + getUrlParameter('userId'));
        getUserById(getUrlParameter('userId')).then((data)=>{
            setPerfilUsuario((prevState) => {
                return { ...prevState,
                    Nombre: data.Nombre,
                    Paterno: data.Paterno,
                    Materno: data.Materno,
                    Email: data.Email,
                    Celular: data.Celular,
                    Pass: data.Pass,
                    Rfc: data.Rfc,
                    TelOficina: data.TelOficina,
                    NumEmp: data.NumEmp,
                    GeneroId: data.GeneroId,
                    FecNac: data.FecNac
                };
            });
            // setConfPass(data.pass);
        });

        // switch ( cookies.get('qIdUser') )
        console.log('idempresa: ' + getUrlParameter('qId'));
          switch (getUrlParameter('qId'))
            {
              case "100":
                setValidateVisibility("hidden");
                console.log('100')
                break;
                case "111":
                  setValidateVisibility("hidden");
                  console.log('111')
              break;
            }
    },[]);

    //Handlers editar
    const nomAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Nombre: event.target.value };
        });
      };

    const apAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Paterno: event.target.value };
        });
      };

    const amAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Materno: event.target.value };
        });
      };

    const emailAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Email: event.target.value };
        });
      };

    const numCelAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Celular: event.target.value };
        });
      };

    const passAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Pass: event.target.value };
        });
      };

    const rfcAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, Rfc: event.target.value };
        });
      };

    const fecNacAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, FecNac: event.target.value };
        });
      };

    const numOfAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, TelOficina: event.target.value };
        });
      };

    const numEmpAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, NumEmp: event.target.value };
        });
      };

    const generoAdminChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, GeneroId: parseInt(event.target.value) };
        });
      };

      const confirmarRegAdminChangeHandler = (event) => {
        setConfPass((prevState) => {
          return { ...prevState, confPassword: event.target.value,  };
        });
      };


    // Llenar el genero del administrador
    var genero="";
    switch ( perfilUsuario.GeneroId )
        {
            case 1:
                genero="Masculino"
            break;    
            case 2:
                genero="Femenino"
            break;    
            case 3:
                genero="Personalizado"
            break;    
        }

    

    // Link para el boton de guardar cambios
    const linkGuardarCambios = (props) => {
      linkGuardarCambios = "perfil-admin_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa
  };

  // state para los btn: editar y guardar cambios
  const [ disBtns, setDisBtns ] = useState(false);

  // state para btn editar
  const [ dis, setDis ] = useState(true);

  // Condicion para el boton guardar cambios
  var diss = true;
  if ( 
    ((perfilUsuario.Nombre != "") & (perfilUsuario.Paterno != "") &
    (perfilUsuario.Materno != "") & ( dis==false ) &
    (perfilUsuario.Celular != "") & (perfilUsuario.Rfc != "") & 
    (perfilUsuario.TelOficina != "") & (perfilUsuario.NumEmp != ""))
    // &((perfilUsuario.pass == confPass.confPassword) || (perfilUsuario.pass & confPass.confPassword == "") )
    // & (perfilUsuario.email != "") 
  ){
    diss = false;
  }
  else {
    diss = true;
  }

// clickHander de btn validar
const clickHandlerValidar = () => {
  actualizaUsuario(perfilUsuario).then((data) =>
    {
      console.log("data admin: "+ perfilUsuario);
      console.log("data admin: "+ data);
    }
  );
  
  getPerfilEmpleadoByUsuarioId(perfilUsuario.Id).then((data) =>
  // getPerfilEmpleadoByUsuarioId(getUrlParameter('userId')).then((data) =>
    {
      const perfilAdmin = data[0];
      const putPerfil = perfilJson;
      switch (getUrlParameter('qId'))
        {
          case "":
            putPerfil.Id = perfilAdmin.Id;
            putPerfil.UsuarioId = perfilUsuario.Id;
            putPerfil.qId = "100";
            putPerfilEmpleado(putPerfil);
            setValidateVisibility("hidden");
          break;
          case "001":
            putPerfil.Id = perfilAdmin.Id;
            putPerfil.UsuarioId = perfilUsuario.Id;
            putPerfil.qId = "111";
            putPerfilEmpleado(putPerfil);
            setValidateVisibility("hidden");
          break;
        }
            
      window.alert("Validación de administrador correcta.");

    }
  );
};

  return (
    <div>
      <Header
        // nombre={cookies.get('nameUser')}
        nombre={getUrlParameter('userName')}
        expirationDate={expirationDate}
      />
      <Menu_lateral_admin
        // idUsuario={cookies.get('idUser')}
        idUsuario={getUrlParameter('userId')}
        // idEmpresa={cookies.get('idEmpresa')}
        idEmpresa={getUrlParameter('empresaId')}
        qId={getUrlParameter('qId')}
        nombre={getUrlParameter('userName')}
      />
      <div className='box_DashAdmin1'>
        <div
          style=
          {
            {
              backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
              width: "100%", 
              height: "15%", 
              backgroundSize: "cover",
              marginTop: "1%",
              display: "block",
            }
          }
        >
          <div className='div_txt_DashAdmin1_InfoEmpresa'>
            <p>ADMINISTRADOR</p>
            <p>PERFIL ADMINISTRADOR</p>
          </div>
        </div>    

        {/* INPUTS */}
        <div className='div_scroll_DashAdmin_PerfilAdmin'>
        <div className='div_Solito_DashAdmin_PerfilAdmin'>    
          <div className=''>
            <div className='input_DashAdmin_PerfilAdmin'>
              <div className='input_DashAdmin_PerfilAdmin_Nombre'>
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Nombre (s)</p>
                <input 
                  className='input_DashAdmin_PerfilAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.Nombre} 
                  disabled={dis} 
                  value={perfilUsuario.Nombre}
                  onChange={nomAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_DashAdmin_PerfilAdmin'>
              <div className='input_RegistroAdmin_APaterno'>
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Apellido paterno</p>
                <input 
                  className='input_DashAdmin_PerfilAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.Paterno} 
                  disabled={dis}
                  value={perfilUsuario.Paterno}
                  onChange={apAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_DashAdmin_PerfilAdmin'>
              <div className='input_RegistroAdmin_AMaterno'>
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Apellido materno</p>
                <input 
                  className='input_DashAdmin_PerfilAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.Materno} 
                  disabled={dis}
                  value={perfilUsuario.Materno}
                  onChange={amAdminChangeHandler}
                />
              </div>
            </div>
          </div>
        </div> 
        <div className='div_campos_3'>
          <div className="">
            <div className="input_RegistroEmpleado">
              <div className="input_RegistroEmpleado_nombre">
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Correo electrónico</p>
                <input 
                  className='input_DIV1_DashAdmin_PerfilAdmin_CSS' 
                  type="email" 
                  titlle="" 
                  placeholder= {perfilUsuario.Email} 
                  disabled={true}
                  //  disabled={dis}
                  value={perfilUsuario.Email}
                  onChange={emailAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_RegistroEmpleado'>
              <div className='input_RegistroEmpleado_N_O'>
              <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Número telefonico de oficina</p>
                <input 
                  className='input_DIV2_RegistroAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.TelOficina} 
                  disabled={dis}
                  value={perfilUsuario.TelOficina}
                  onChange={numOfAdminChangeHandler}
                />
              </div>
            </div>     
          </div>
        </div>   
        <div className='div_campos_3'>
          <div className="">
            <div className="input_RegistroEmpleado">
              <div className="input_RegistroEmpleado_nombre">
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Celular</p>
                <input 
                    className='input_DIV1_DashAdmin_PerfilAdmin_CSS' 
                    type="text" 
                    titlle="" 
                    placeholder= {perfilUsuario.Celular} 
                    disabled={dis}
                    value={perfilUsuario.Celular}
                    onChange={numCelAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_RegistroEmpleado'>
              <div className='input_RegistroEmpleado_N_O'>
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Número de empleado</p>
                <input 
                  className='input_DIV2_RegistroAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.NumEmp} 
                  disabled={dis}
                  value={perfilUsuario.NumEmp}
                  onChange={numEmpAdminChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>   
        <div className='div_campos_3'>
          <div className="">
            <div className="input_RegistroEmpleado">
              <div className="input_RegistroEmpleado_nombre">
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Password</p>
                <input 
                  className='input_DIV1_DashAdmin_PerfilAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.Pass} 
                  disabled={true}
                  value={perfilUsuario.Pass}
                  onChange={passAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_RegistroEmpleado'>
              <div className='input_RegistroEmpleado_N_O'>
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Confirmar password</p>
                <input 
                  className='input_DIV2_RegistroAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  disabled={true}
                  value={confPass.confPassword}
                  onChange={confirmarRegAdminChangeHandler}
                />
              </div>
            </div>   
          </div>
        </div>   
        <div className='div_campos_3'>
          <div className="">
            <div className="input_RegistroEmpleado">
              <div className="input_RegistroEmpleado_nombre">
                <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>RFC</p>
                <input 
                  className='input_DIV1_DashAdmin_PerfilAdmin_CSS' 
                  type="text" 
                  titlle="" 
                  placeholder= {perfilUsuario.Rfc} 
                  disabled={dis}
                  value={perfilUsuario.Rfc}
                  onChange={rfcAdminChangeHandler}
                />
              </div>
            </div>
            <div className='input_RegistroEmpleado'>
              <div className='input_RegistroEmpleado_N_O'>
               <p className='txt_InputTitle_DashAdmin_PerfilAdmin'>Género</p>
                <select 
                  id="id_sexo_RegistroAdmin" 
                  name="name_sexo_RegistroAdmin" 
                  className='input_DIV2_RegistroAdmin_CSS' 
                  disabled={dis}
                  value={perfilUsuario.GeneroId}
                  onChange={generoAdminChangeHandler}
                >
                  <option value={1}>{genero}</option>
                  <option value={1}>Masculino</option>
                  <option value={2}>Femenino</option>
                  <option value={3}>Personalizado</option>
                </select>
              </div>
            </div>   
          </div>
        </div>   
        <p className='txt_InputTitle_RegistroAdmin'>Fecha de nacimiento</p>
        {/* <p className='txt_InputTitle_RegistroAdmin'>Tu fecha es:{perfilUsuario.fecNac}</p> */}
          <input 
            type="date"
            className='input_DIV2_RegistroAdmin_CSS'
            disabled={dis}
            value={perfilUsuario.FecNac}
            onChange={fecNacAdminChangeHandler}
          />
        </div>
        <div className='div_btns_DashAdmin1_PerfilAdmin'>
          <div 
            style=
            {
              {
                visibility: validateVisibility
              }
            }
          >
            <Button_siguente_empleado
              link={linkGuardarCambios}
              json={perfilUsuario}
              update={actualizaUsuario}
              disabled={diss}
              name_btn="Guardar cambios"
              css="btn_componente_Empleado"
            />
          </div>  
          <button 
            className="btn_DashAdmin1_PerfilAdmin_Editar"
            onClick={() => setDis(!dis)}
            disabled={disBtns}
            style={{visibility: validateVisibility}}
          >
            Editar
          </button>
          <button 
            className="btn_DashAdmin1_PerfilAdmin_Validar"
            onClick={clickHandlerValidar}
            style={{visibility: validateVisibility}}
          >
            Validar
          </button>
        </div>
      </div>
    </div>
  );
};