import React, { Fragment } from 'react';
import { AppBar, Box, Button, Toolbar, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';
const src_urlLogin = 'https://ums.sindicatopormexico.com/accounts/login/';

export const NavBar_afiliacion = () => {
    
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Fragment>
        {isSmallScreen &&
            <AppBar position='sticky' sx={{paddingTop: 1, paddingBottom: 1, background: '#ffff'}}>
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            <img 
                                src={src_url + 'logo_spm.png'} 
                                style={{width: '9vw'}}
                            />
                        </Link>
                    </Box>
                    <Box>
                        <Link to='/loginXmex'  style={{textDecoration: 'none'}}>
                        {/* <a href={src_urlLogin}> */}
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 15, fontWeight: 500}}> 
                                    INICIAR SESION
                                </Typography>
                            </Button>
                        {/* </a> */}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isMediumScreen &&
            <AppBar position='sticky' sx={{paddingTop: 1, paddingBottom: 1, background: '#ffff'}}>
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            <img 
                                src={src_url + 'logo_spm.png'} 
                                style={{width: '8vw'}}
                            />
                        </Link>
                    </Box>
                    <Box>
                        <Link to='/loginXmex'  style={{textDecoration: 'none'}}>
                        {/* <a href={src_urlLogin}> */}
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 17, fontWeight: 500}}> 
                                    INICIAR SESION
                                </Typography>
                            </Button>
                        {/* </a>     */}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isLargeScreen &&
            <AppBar position='sticky' sx={{paddingTop: 1, paddingBottom: 1, background: '#ffff'}}>
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <figure>
                            <Link to='/' style={{textDecoration: 'none'}}>
                                <img 
                                    src={src_url + 'logo_spm.png'} 
                                    style={{width: '7ch'}}
                                />
                            </Link>
                        </figure>
                    </Box>
                    <Box>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 18, fontWeight: 500}}> 
                                    INICIO
                                </Typography>
                            </Button>
                        </Link>
                        {/* <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500}}> 
                                NOSOTROS
                            </Typography>
                        </Button>
                        <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500}}> 
                                BENEFICIOS
                            </Typography>
                        </Button>
                        <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500}}> 
                                CONTACTO
                            </Typography>
                        </Button> */}
                        <Link to='/loginXmex'  style={{textDecoration: 'none'}}>
                        {/* <a href={src_urlLogin}> */}
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 18, fontWeight: 500}}> 
                                    INICIAR SESION
                                </Typography>
                            </Button>
                        {/* </a> */}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isExtraLargeScreen &&
            <AppBar position='sticky' sx={{paddingTop: 1, paddingBottom: 1, background: '#ffff'}}>
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <figure>
                            <Link to='/' style={{textDecoration: 'none'}}>
                                <img 
                                    src={src_url + 'logo_spm.png'} 
                                    style={{width: '7ch'}}
                                />
                            </Link>
                        </figure>
                    </Box>
                    <Box>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 20, fontWeight: 500}}> 
                                    INICIO
                                </Typography>
                            </Button>
                        </Link>
                        {/* <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 20, fontWeight: 500}}> 
                                NOSOTROS
                            </Typography>
                        </Button>
                        <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 20, fontWeight: 500}}> 
                                BENEFICIOS
                            </Typography>
                        </Button>
                        <Button variant="text" sx={{color: '#303030'}}>
                            <Typography sx={{fontSize: 20, fontWeight: 500}}> 
                                CONTACTO
                            </Typography>
                        </Button> */}
                        <Link to='/loginXmex'  style={{textDecoration: 'none'}}>
                        {/* <a href={src_urlLogin}> */}
                            <Button variant="text" sx={{color: '#303030'}}>
                                <Typography sx={{fontSize: 20, fontWeight: 500}}> 
                                    INICIAR SESION
                                </Typography>
                            </Button>
                        {/* </a> */}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
    </Fragment>
  );
};
