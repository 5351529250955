import React, { useState } from "react";
import './Preregistro_empleado.css';
import { getUserByEmailPass, getEmpresaById, getPerfilEmpleadoByUsuarioId, putPerfilEmpleado } from "../../../../utils/services";

export const Preregistro_empleado = () => {

    const [perfilUsuario, setPerfilUsuario] = useState({
        id: null,
        qId: "",
        enteredUserEmail: "",
        enteredUserPass: "nom35",
        numEmp: ""
    });

    const [perfilJson, setPerfilJson] = useState({
        Id: null,
        UsuarioId: null,
        qId: ""
    });

    const btbFinalizar = () => {
        getPerfilEmpleadoByUsuarioId(perfilUsuario.id).then((data) => {
            const perfil = data[0];
            if(perfil.qId==""){
                console.log("Este empleado se ha preregistrado.");       
                const putPerfil = perfilJson;
                putPerfil.Id = perfil.Id;
                putPerfil.UsuarioId = perfilUsuario.id;
                putPerfil.qId = "val";
                putPerfilEmpleado(putPerfil);
                window.alert("Gracias por tu confirmación. Por favor mantente atento a tu correo para futura información.");
                window.open("http://smisistemas.com.mx/","_self");
            }else if (perfil.qId=="val"){
                window.alert("Ya fuiste preregistrado.");
                window.open(
                    "http://smisistemas.com.mx/","_self"
                  );
            }else{
                window.alert("Ya realizaste tu preregistro y registro.");
                window.open(
                    "http://smisistemas.com.mx/","_self"
                  );
            }
            console.log("Qid: "+perfil.qId);
        });
    };

    const userEmailChangeHandler = (event) => {
        setPerfilUsuario((prevState) => {
          return { ...prevState, enteredUserEmail: event.target.value };
        });
    };

    const [perfilEmpresa, setPerfilEmpresa] = useState({
        id: idEmpresa,
        nombre: "",
        rasonSocial: "" 
      });

    var idEmpresa = null;

    const [condicionesPreregistro, setCondicionesPreregistro] = useState({
        checkDisabled: true,
        btnDisabled: true,
        conditionDisabled: 0
      });

    const validateEmail = () => {
        if ((perfilUsuario.enteredUserEmail != "")){
            // TODO Revisar requests
            getUserByEmailPass(perfilUsuario.enteredUserEmail, perfilUsuario.enteredUserPass).then((data) => {
                if (data.length > 0) {
                        const respUserEmail = data[0];
                        console.log(respUserEmail);
                        setPerfilUsuario((prevState) => {
                            return { ...prevState, 
                                numEmp: respUserEmail.NumEmp
                            }
                        });
                        idEmpresa = respUserEmail.EmpresaId;
                        perfilUsuario.numEmp = respUserEmail.NumEmp;
                        // TODO Revisar estos requests
                        getEmpresaById(idEmpresa).then((dataEmpresa)=> {
                            setPerfilEmpresa((prevState) => {
                                return { ...prevState, 
                                    nombre: dataEmpresa.Name, 
                                    rasonSocial: dataEmpresa.RasonSocial
                                }
                            })
                        });
                        setPerfilUsuario({ ...perfilUsuario, id:respUserEmail.Id })
                        setCondicionesPreregistro({ ...condicionesPreregistro, checkDisabled:false });
                        console.log(perfilEmpresa);
                    }else{
                    window.alert("Este correo electrónico no está registrado");
                    }
                }
            )
        }else{
            window.alert("Coloca tu correo electrónico para validar tus datos");
        }
    };

    const condBotonSiguiente = () => {
        if(condicionesPreregistro.checkDisabled!=true){
            setCondicionesPreregistro({ ...condicionesPreregistro, btnDisabled:false });
        }
    }

    return (
        <div
            style=
                {
                    {
                        // backgroundImage: "url(../img/Background_Login2.svg)", 
                        backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                        width: "100%", 
                        height: "100vh", 
                        backgroundRepeat: "no-repeat", 
                        backgroundSize: "cover",
                        marginTop: "-100px",
                        paddingTop: "100px",
                        // marginBottom: "-150px"
                    }
                } 
        >
            <div className='box_preregistro_empleado'>
                <img src="../img/LogoSMI_Login.svg" className='img_LogoSMI_RegistroAdmin'/>
                <p className='title_preregistro_empleado'>PRE-REGISTRO DE EMPLEADO</p><br></br>
                <p className='txt_preregistro_empleado'>
                Estimado empleado, es importante validar la información de tus datos de registro. Porfavor contempla que más adelante podrás ser seleccionado en el sorteo de empleado que realizarán las encuestas para NOM-035
                </p><br></br>

                        <div className='div_correo_preregistro_empleado_correo'>
                            <table>
                                <tr>
                                    <td className="td_tabla_correo_consulta">
                                        <p className='txt_preregistro_datos_correo'>Ingrese el correo con el que fue pre-registrado
                                        </p>
                                    </td>
                                    <td className="td_tabla_correo_consulta_1">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="td_tabla_correo_consulta_1">
                                        <input 
                                        className='correo_preregistro_empleado' 
                                        type="email" 
                                        titlle="" 
                                        placeholder='Correo electrónico' 
                                        value={perfilUsuario.enteredUserEmail} onChange={userEmailChangeHandler}/>
                                    </td>
                                    <td className="td_tabla_correo_consulta">
                                        <button
                                            className='btn_consulta'
                                            type='submit'
                                            onClick={validateEmail}
                                        >
                                            CONSULTAR
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className='div_preregistro_datos_empleado'>
                            <br></br>
                            <table>
                                <tr>
                                    <td><p className='title_preregistro_datos'>EMPRESA</p></td>
                                    <td><p className='preregistro_datos'>{perfilEmpresa.nombre}</p></td>
                                </tr>
                                <tr>
                                    <td><p className='title_preregistro_datos'>CENTRO DE TRABAJO</p></td>
                                    <td><p className='preregistro_datos'>{perfilEmpresa.rasonSocial}</p></td>
                                </tr>
                                <tr>
                                    <td><p className='title_preregistro_datos'># EMPLEADO</p></td>
                                    <td><p className='preregistro_datos'>{perfilUsuario.numEmp}</p></td>
                                </tr>
                            </table>
                        </div>

                        <div className='div_confirmacion_datos_empleado'>
                            <br></br>
                            <div>
                                <input 
                                className="Preregistro_emp"
                                class="chkConfirmar" 
                                type="checkbox" 
                                value="" 
                                id="flexCheckDefault" 
                                disabled={condicionesPreregistro.checkDisabled} 
                                onClick={condBotonSiguiente}>

                                </input>
                                <label class="form-check-label" for="flexCheckDefault">
                                    <p className="txt_confirmacion_preregistro_datos">CONFIRMO QUE LA INFORMACIÓN ES CORRECTA</p>
                                </label>
                            </div>
                        </div>

                        {/* <div className='div_reportar_datos_empleado'>
                            <br></br>
                                <p className='txt_preregistro_reporte'> <img src="./img/reporte_preregistro.svg" className="img_reporte"/> REPORTAR UN PROBLEMA</p>
                        </div> */}
                        
                        <div className='div_finalizar_datos_empleado'>
                            <br></br>
                                {/* <Link to="/registro_empresa"> */}
                                        <button
                                            className='btn_Continuar_RegistroAdmin'
                                            type='submit'
                                            onClick={btbFinalizar}
                                            disabled={condicionesPreregistro.btnDisabled}
                                        >
                                            FINALIZAR
                                        </button>
                                {/* </Link> */}
                        </div>
            </div>
        </div>
        );
};