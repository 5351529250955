import React from 'react';
import { Link } from "react-router-dom";
import './Button_ActualizaEmpresa.css'

export const Button_actualizaEmpresa = (props) => {

const clickHandler = () => {
    props.update(props.json).then((data) => {
    });
    window.alert("Los cambios han sido guardados.");
    console.log('json empresa::: ' + props.json);
    console.log('json empresa id: ' + props.json.Id);
};

  return (
    <Link to={props.link}>
        <button
            className={props.css}
            onClick={clickHandler}
            disabled={props.disabled}
        >
            {props.name_btn}
        </button>
    </Link>
  )
}
