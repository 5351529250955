import React, { useEffect } from 'react';
import './Menu_lateral_admin.css';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

export const Menu_lateral_admin = (props) => {

    const navDashAdmin = () => {
        window.open("/dashboard_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };
    const navPerfilAdmin = () => {
        window.open("/perfil-admin_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };
    const navPerfiEmpresa = () => {
        window.open("/perfil-empresa_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };
    const registroEmpleados = () => {
        window.open("/registro-empleados_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };
    const navLicencia = () => {
        window.open("/licencia_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };
    const navReportes = () => {
        window.open("/reportes_admin?userId="+props.idUsuario+"&empresaId="+props.idEmpresa+"&qId="+props.qId+"&userName="+props.nombre,"_self");
    };

    // Cookie isUser
    const cookie = new Cookies();
    const valueCookie = cookie.get('idUser');
    // borrar cookie por tiempo
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);
    useEffect(() => {
        cookie.set('idUser', valueCookie, {path: '/',  expires:  expirationDate});
    }, []);
    // borrar cookie por btn 
    const cerrarSesion = () => {
        cookie.set('idUser', null,  {path: '/'});
    };

    return (
    <div>
        <div className='box_menu_lateral_admin'>
                <button 
                    className='btn_MenuLateralAdmin_Inicio'
                    onClick={navDashAdmin}
                >
                    <img
                        src="../img/admin/DashAdmin_Menu_Home_img.svg"
                        className='ico_Home_Menu_DashAdmin2'
                    />
                    INICIO
                </button>
                <button 
                    className='btn_MenuLateralAdmin_PerfAdmin'
                    onClick={navPerfilAdmin}
                >
                    <img
                        src="../img/admin/DashAdmin_Menu_Admin_img.svg"
                        className='ico_Home_Menu_DashAdmin'
                    />
                    Perfil Administrador
                </button>
                <button 
                    className='btn_MenuLateralAdmin_PerfEmpre'
                    onClick={navPerfiEmpresa}
                >
                    <img
                        src="../img/admin/DashAdmin_Menu_Empresa_img.svg"
                        className='ico_Home_Menu_DashAdmin'
                    />
                    Perfil Empresa
                </button>

                <button 
                    className='btn_MenuLateralAdmin_PerfPreRegis'
                    onClick={registroEmpleados}
                >
                    <img
                        src="../img/admin/DashAdmin_Menu_Admin_img.svg"
                        className='ico_Home_Menu_DashAdmin'
                    />
                    Registro empleados
                </button>

                <button 
                    className='btn_MenuLateralAdmin_Licencia'
                    onClick={navLicencia}
                    disabled={true}
                    >
                    <img
                        src='../img/admin/DashAdmin_Menu_Licencia_img.svg'
                        className='ico_Home_Menu_DashAdmin'
                        />
                    Licencia
                </button>
                <button 
                    className='btn_MenuLateralAdmin_Reportes'
                    onClick={navReportes}
                    disabled={true}
                >
                    <img
                    src='../img/admin/DashAdmin_Menu_Reportes_img.svg'
                    className='ico_Home_Menu_DashAdmin'
                    />
                    Reportes
                </button>

                <Link to="/">
                    <button 
                        className='btn_MenuLateralAdmin_CerrarSesion'
                        onClick={cerrarSesion}
                    >
                        <p className='p_MenuLateralAdmin_CerrarSesion'>
                            CERRAR SESIÓN
                        </p>
                    </button>
                </Link>

        </div>
    </div>
  );
};
