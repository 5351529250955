// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn_siguiente_cuestionario_componente1{
  
    float: right;
    margin-top: 0%;
    margin-right: 20px; 
    background: #38AC4D;
    border-width: 0px;
    box-shadow: 6px 6px 5px -2px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    color: #FFFFFF;
    font-size: 1vw;
    height: 40px;
    padding-left: 2%;
    padding-right: 2%;
    transition: .5s;
    cursor: pointer;
    font-family:'Montserrat';
    margin-bottom: 35px;
    margin-bottom: 15%;
    width: 8vw;
    height: 2.5vw;
    font-size: .87vw;
    
    
}

.btn_siguiente_cuestionario_componente1:hover{
    background: #257734;
    box-shadow: 6px 6px 5px -2px rgba(0, 0, 0, 0.5);
}

.btn_siguiente_cuestionario_componente1_001{
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/nom35/admin/btns/Button_ActualizaEmpresa.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,gDAAgD;IAChD,mBAAmB;IACnB,cAAc;IACd,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,gBAAgB;;;AAGpB;;AAEA;IACI,mBAAmB;IACnB,+CAA+C;AACnD;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".btn_siguiente_cuestionario_componente1{\n  \n    float: right;\n    margin-top: 0%;\n    margin-right: 20px; \n    background: #38AC4D;\n    border-width: 0px;\n    box-shadow: 6px 6px 5px -2px rgba(0, 0, 0, 0.25);\n    border-radius: 16px;\n    color: #FFFFFF;\n    font-size: 1vw;\n    height: 40px;\n    padding-left: 2%;\n    padding-right: 2%;\n    transition: .5s;\n    cursor: pointer;\n    font-family:'Montserrat';\n    margin-bottom: 35px;\n    margin-bottom: 15%;\n    width: 8vw;\n    height: 2.5vw;\n    font-size: .87vw;\n    \n    \n}\n\n.btn_siguiente_cuestionario_componente1:hover{\n    background: #257734;\n    box-shadow: 6px 6px 5px -2px rgba(0, 0, 0, 0.5);\n}\n\n.btn_siguiente_cuestionario_componente1_001{\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
