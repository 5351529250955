import './Registro_empleado.css';
import React, { useEffect, useState } from "react";
import { actualizaUsuario, getPerfilEmpleadoByUsuarioId, getUserById, guardaEmpleado, putPerfilEmpleado } from '../../../../utils/services';
import { getUrlParameter } from '../../../../utils/jstools';
import { Link } from "react-router-dom";


export const Registro_empleado = () => {

    const [regEmpleadoInput, setRegEmpleadoInput] = useState({
        Id: parseInt(getUrlParameter("userId")),
        Nombre: "",
        Paterno: "",
        Materno: "",
        Email: "",
        TelOficina: "",
        Celular: "",
        NumEmp: "",
        Pass: "",
        Rfc: "",
        Escolaridad: "Primaria",
        FecNac:"",
        RolId:2,
        GeneroId: 1,
        EmpresaId: 2
    });

    const [perfilJson, setPerfilJson] = useState({
        Id: null,
        usuarioId: null,
        qId: "101"
    });

    const [confPass, setConfPass] = useState({
      confirmarPass:""
    });

    useEffect(() => {
      const userId = getUrlParameter("userId");
      console.log(userId);
      getUserById(userId).then((data) => {
        const empleado = data;
        console.log(empleado);
        setRegEmpleadoInput({...regEmpleadoInput, 
        Email: empleado.Email,
        NumEmp: empleado.NumEmp,
        EmpresaId: empleado.EmpresaId
         });
      });
    }, []);
  
    const confirmarPassRegEmpleadoChangeHandler = (event) => {
      setConfPass((prevState) => {
        return { ...prevState, confirmarPass: event.target.value,  };
      });
    };

    const nomRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Nombre: event.target.value };
        });
      };
    
    const apRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Paterno: event.target.value };
        });
      };
    const amRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Materno: event.target.value };
        });
      };
    
    const emailRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Email: event.target.value };
        });
      };

    const numOfnaRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, TelOficina: event.target.value };
        });
      };

    const numcelRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Celular: event.target.value };
        });
      };

      const numempleRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, NumEmp: event.target.value };
        });
      };
    
    const passRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Pass: event.target.value };
        });
      };

    const rfcRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, Rfc: event.target.value };
        });
      };

    const escolaridadRegEmpleadoChangeHandler = (event) => {
         setRegEmpleadoInput((prevState) => {
           return { ...prevState, Escolaridad: event.target.value };
         });
      };

    const generoRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, GeneroId: parseInt(event.target.value)};
        });
      };

    const fecNacRegEmpleadoChangeHandler = (event) => {
        setRegEmpleadoInput((prevState) => {
          return { ...prevState, FecNac: event.target.value };
        });
      };

    var cond=0;
    var dis = true;
    if (
      (regEmpleadoInput.Nombre != "") & (regEmpleadoInput.Paterno != "") &
      (regEmpleadoInput.Paterno != "") & (regEmpleadoInput.Email != "") &
      (regEmpleadoInput.TelOficina != "") & (regEmpleadoInput.Celular != "") &
      (regEmpleadoInput.NumEmp != "") & (regEmpleadoInput.Rfc != "") & 
      (regEmpleadoInput.Escolaridad != "") & (regEmpleadoInput.FecNac != "") 
      & [(regEmpleadoInput.Pass == confPass.confirmarPass) & regEmpleadoInput.Pass != "" & confPass.confirmarPass != ""]
    ){
      dis = false;
      // cond=2;
    }
    else if(regEmpleadoInput.Pass !== confPass.confirmarPass){
      dis = true;
      // cond=1;
    }
    else{
      dis = true;
      // cond=0;
    }

    // onClick btn continuar    
    const clickHandler = () => {
      actualizaUsuario(regEmpleadoInput).then((data) => 
        {
          console.log(data);
        }
      );
      window.alert("Registro completo. Serás redirigido al inicio de sesión.");
      getPerfilEmpleadoByUsuarioId(regEmpleadoInput.Id).then((data) => 
          {
            const perfil = data[0];
              if (perfil.qId=="val")
                {
                  const putPerfil = perfilJson;
                  putPerfil.Id = perfil.Id;
                  putPerfil.usuarioId = regEmpleadoInput.Id;
                  putPerfil.qId = "101";
                  putPerfilEmpleado(putPerfil);
                }
          }
        );
      };


    return (
        <div
            style=
            {
                {
                    // backgroundImage: "url(../img/Background_Login2.svg)", 
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    height: "100vh", 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover",
                    marginTop: "-100px",
                    paddingTop: "100px",
                    // marginBottom: "-150px"
                }
            } 
        >
            <div className='box_preregistro_empleado'>
                <img src="../img/LogoSMI_Login.svg" className='img_LogoSMI_RegistroAdmin'/>
                <p className='txt_title_preregistro_empleado'>REGISTRO DE  EMPLEADO</p><br></br>
                <p className='txt_preregistro_empleado'>
                Estimado Empleado, muchas gracias por tu valiosa participación en el proceso de certificación NOM-035 de tu empresa.

Por favor completa la siguiente información para genera tu expediente, te recordamos que esta información será confidencial y con fines médicos en caso de detectar áreas de riesgo Psico social.
 
                    
                </p>

                <div className="body_campos">

                        <div className='div_campos_1'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">Nombre (s)</p>
                                  <input
                                     className="input_RegistroEmpleado_CSS"
                                     type="text"
                                     title=""
                                     placeholder='Nombre (s)'
                                     pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                                     maxLength="20"
                                     value={regEmpleadoInput.Nombre}
                                     onChange={nomRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_APaterno'>
                        <p className='txt_preregistro_datos_correo'>Apellido paterno</p>
                        <input 
                            className='input_RegistroEmpleado_CSS' 
                            type="text" 
                            titlle="" 
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            placeholder='Apellido paterno'
                            maxLength="20"
                            value={regEmpleadoInput.Paterno}
                            onChange={apRegEmpleadoChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_AMaterno'>
                        <p className='txt_preregistro_datos_correo'>Apellido materno</p>
                        <input 
                            className='input_RegistroEmpleado_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Apellido materno'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            maxLength="20"
                            value={regEmpleadoInput.Materno}
                            onChange={amRegEmpleadoChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">Correo electrónico</p>
                                  <input
                                   className='inp_c' 
                                   type="email" 
                                   titlle="" 
                                   placeholder='Correo electrónico'
                                   maxLength="45"
                                   value={regEmpleadoInput.Email}
                                   onChange={emailRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_N_O'>
                        <p className='txt_preregistro_datos_correo'>Número telefonico de oficina</p>
                        <input 
                            className='inp_c' 
                            type="text" 
                            titlle="" 
                            pattern="[0-9]+"
                            placeholder='Número de oficina'
                            maxLength="15"
                            value={regEmpleadoInput.TelOficina}
                            onChange={numOfnaRegEmpleadoChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">Celular</p>
                                  <input
                                   className='inp_c' 
                                   type="text"
                                   pattern="[0-9]+"
                                   maxLength="10"
                                   titlle="" 
                                   placeholder='Celular'
                                   value={regEmpleadoInput.Celular}
                                   onChange={numcelRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_N_O'>
                        <p className='txt_preregistro_datos_correo'>Número de empleado</p>
                        <input 
                            className='inp_c' 
                            type="text" 
                            titlle="" 
                            placeholder='Número de empleado'
                            maxLength="15"
                            value={regEmpleadoInput.NumEmp}
                            onChange={numempleRegEmpleadoChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">Password</p>
                                  <input
                                   className='inp_c' 
                                   type="password" 
                                   titlle="" 
                                   placeholder='Password'
                                   value={regEmpleadoInput.Pass}
                                   onChange={passRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_N_O'>
                        <p className='txt_preregistro_datos_correo'>Confirmar password</p>
                        <input 
                            className='inp_c' 
                            type="password" 
                            titlle="" 
                            placeholder='Confirmar password'
                            id="pass2"
                            value={regEmpleadoInput.confPass}
                            onChange={confirmarPassRegEmpleadoChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">RFC</p>
                                  <input
                                   className='inp_c' 
                                   type="text" 
                                   titlle="" 
                                   placeholder='RFC'
                                   maxLength="13"
                                   value={regEmpleadoInput.Rfc}
                                   onChange={rfcRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_N_O'>
                        <p className='txt_preregistro_datos_correo'>Escolaridad</p>
                        <select 
                     id="id_RegistroEmpleado" 
                     name="name_RegistroEmpleado" 
                     className='inp_c'
                    value={regEmpleadoInput.Escolaridad}
                    onChange={escolaridadRegEmpleadoChangeHandler}
                >
                    <option 
                        value="1"
                    >
                        PRIMARIA
                    </option>
                    <option 
                        value="2"
                    >
                        SECUNDARIA
                    </option>
                    <option 
                        value="3"
                    >
                        PREPARATORIA
                    </option>
                    <option 
                        value="4"
                    >
                        LICENCIATURA
                    </option>
                    <option 
                        value="5"
                    >
                        POSGRADO
                    </option>
                </select>
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroEmpleado">
                              <div className="input_RegistroEmpleado_nombre">
                                <p className="txt_preregistro_datos_correo">Fecha de nacimiento</p>
                                  <input
                                   className='inp_c' 
                                   type="date" 
                                   titlle="" 
                                   placeholder='Fecha de nacimiento'
                                   maxLength="13"
                                   value={regEmpleadoInput.FecNac}
                                   onChange={fecNacRegEmpleadoChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroEmpleado'>
                    <div className='input_RegistroEmpleado_N_O'>
                        <p className='txt_preregistro_datos_correo'>Género</p>
                        <select
                    id="id_sexo_RegistroEmpleado" 
                    name="name_sexo_RegistroEmpleado" 
                    className='inp_c'
                    value={regEmpleadoInput.GeneroId}
                    onChange={generoRegEmpleadoChangeHandler}
                   >
                       <option 
                        value="1"
                    >
                        Masculino
                    </option>
                    <option 
                        value="2"
                    >
                        Femenino
                    </option>
                    <option 
                        value="3"
                    >
                        Personalizado
                    </option>
                </select> 
                    </div>
                </div>
               
            </div>
        </div>  
                </div>                      
                        <div className='div_finalizar_datos_empleado'>
                            <br></br>  
                            
                            <Link to="/login">
                              <button
                                className='btn_continuar_registroEmpleado'
                                onClick={clickHandler}
                                disabled={dis}
                              >
                                Continuar
                              </button>
                            </Link>
                        </div>
            </div>
        </div>
        );
};