import React, { useState } from "react";
import './Cuestionario21.css';
import './Estilo_Cuestionario_Escala.css';
import { PreguntaEscalaSN } from "./elementos_cuestionarios/PreguntaEscalaSN"; 
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario32 = (props) => {

    const sigPag = "303";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 3;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);

    const [sessionJson, setSessionJson] = useState({
        referenciaId: 3,     //Dato estático
        seccionId: 14,       //Dato estático
        tipoPreguntaId: 2,   //Dato estático
        preguntaInicial: 6,  //Dato estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        usuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });

      const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 6, respuesta: 0 },
          { pregunta: 7, respuesta: 0 },
          { pregunta: 8, respuesta: 0 },
         ]
      });

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);

      const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg,resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
        const respuestas = {respuestasJson};
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson );
        console.log({ respuestasJson });
      };

    return (
      <div className="box_menu_y_cuestionario_escala">
        <div className="cuestionario_div_escala">
          <div className="div_svg_menu_escala">
            <img src="./img/menu_cuestionarios/menu_c302.svg" className="img_svg_escala_entorno_org"/>
          </div>
          <div className="text_cuestionario_escala">
            <img src="./img/barra_progreso/entorno_organizacional/barra_de_progreso_302.svg" className="img_progreso_escala"/>
            <p className="titulo_cuestionario_escala"><img src="./img/barra_progreso/iconos/organizacional-icono_cuestionario.svg"/>  ENTORNO ORGANIZACIONAL</p>

            <p className="instrucciones_cuestionario_escala">Para responder a las preguntas siguientes piense en la cantidad y ritmo de trabajo que tiene.</p>
            <div className="body_escalas">
              <div className="titulos_escalas">
                <table>
                  <tbody>
                    <tr>
                      <td><p className="box_titulos_escalas">Siempre</p></td>
                      <td><p className="box_titulos_escalas">Casi<br></br>siempre</p></td>
                      <td><p className="box_titulos_escalas">Algunas<br></br>veces</p></td>
                      <td><p className="box_titulos_escalas_1">Casi<br></br>nunca</p></td>
                      <td><p className="box_titulos_escalas_2">Nunca</p></td>
                    </tr>
                  </tbody>
                </table>
              </div>
                  
              <div className="preguntas_escala_sn">
                
                <PreguntaEscalaSN
                  numPreg="6"
                  texto="Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno"
                  update={updatePreg}
                  rSiempre="4"
                  rCasiSiempre="3"
                  rAlgunasVeces="2"
                  rCasiNunca="1"
                  rNunca="0"
                />
                              
                <PreguntaEscalaSN
                  numPreg="7"
                  texto="Por la cantidad de trabajo que tengo debo trabajar sin parar"
                  update={updatePreg}
                  rSiempre="4"
                  rCasiSiempre="3"
                  rAlgunasVeces="2"
                  rCasiNunca="1"
                  rNunca="0"
                />
              
                <PreguntaEscalaSN
                  numPreg="8"
                  texto="Considero que es necesario mantener un ritmo de trabajo acelerado"
                  update={updatePreg}
                  rSiempre="4"
                  rCasiSiempre="3"
                  rAlgunasVeces="2"
                  rCasiNunca="1"
                  rNunca="0"
                />

              </div>
            </div>
  
            <Button_CuestionarioSiguente
              className="btn_siguiente_cuestionario_binario"
              sesion={sessionJson}
              perfil={perfilJson}
              respuestas={respuestasJson}
              disabled={deseableState}
              upDate={"guardaRespuestas"}
              condicionPreg='200Restantes'
            />
                    
          </div>
        </div>
      </div>
    )
}