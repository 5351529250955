import { Document, Image, Page, View, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    page: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    imageReport: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    textNameEmpresa: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      color: '#312E2F', 
      marginTop: '10%',
      marginLeft: '31%'
    },
    textTipoPeriodo: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      color: '#312E2F', 
      marginTop: '12.5%',
      marginLeft: '31%'
    },
    textPeriodoFiscal: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      color: '#312E2F', 
      marginTop: '15%',
      marginLeft: '31%'
    },
    textEsquema: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      // marginTop: '18%',
      marginTop: '25%',
      marginLeft: '11%'
    },
    textPlanSindical: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '25%',
      // marginTop: '18%',
      marginLeft: '35%'
    },
    textBaseCalculo: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '25%',
      // marginTop: '18%',
      marginLeft: '69%'
    },

    // ******************** Columna NEGRA ********************
    textSumatoriaSat: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '62%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaImss: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '66%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textImpuestoSobreNomina: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '70.2%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaImssEmpresa: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '74.4%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaRcv: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '78.5%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaRcv2: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '83%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaCTA: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '89.5%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    // CREO QUE SOBRAN ************
    textSumatoriaIsr: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '62%',
      // marginTop: '18%',
      marginLeft: '24%'
    },
    textSumatoriaSueldos: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '41.5%',
      // marginTop: '18%',
      marginLeft: '20%'
    },
    // ****************************
    // ******************** Columna NARANJA ********************
    textSumatoriaCTApoyoSindicalIsr: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '62%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textSumatoriaCTApoyoSindicalImss: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '66%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textSumatoriaCTApoyoSindicalSat: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '70.2%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textSumatoriaCTApoyoSindicalImssEmpresa: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '74.4%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textSumatoriaCTApoyoSindicalRcv: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '78.5%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textSumatoriaCTAS: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '83%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    textTotalNaranja: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '89.5%',
      // marginTop: '18%',
      marginLeft: '50.8%'
    },
    // ******************** Columna VERDE ********************
    textRestaIsr: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '62%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textRestaImss: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '66%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textRestaSat: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '70.2%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textRestaImssEmpresa: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '74.4%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textRestaRcv: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '78.5%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textRestaTotal: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '83%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    textTotalVerde: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '89.5%',
      // marginTop: '18%',
      marginLeft: '77.8%'
    },
    // *******************************************************

    textSumatoriaSueldos2: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '51%',
      // marginTop: '18%',
      marginLeft: '25%'
    },
    textSumatoriaSueldos3: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '51%',
      // marginTop: '18%',
      marginLeft: '66%'
    },
    textPorcentajeEmpresa: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '39%',
      // marginTop: '18%',
      marginLeft: '69%'
    },
    textPorcentajeSindicato: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '44%',
      // marginTop: '18%',
      marginLeft: '69%'
    },
    // ************* PLAN SINDICAL *************
    textPagoSinFideicomiso: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '101.4%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    textCostoOperacion: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '105.5%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    // ************* BENEFICIOS *************
    textAhorroTributario: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '117.7%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    textCostoOperacionMensual: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '121.8%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    textCostoOperacionAnual: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '125.9%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    // ************* AHORROS *************
    textAhorroReal: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '131.4%',
      // marginTop: '18%',
      marginLeft: '51%'
    },
    textAhorroRealAnual: {
      position: 'absolute',
      fontWeight: '700', 
      fontSize: 12, 
      // color: '#991f1f', 
      color: '#312E2F', 
      marginTop: '135.5%',
      // marginTop: '18%',
      marginLeft: '51%'
    }
})

export const ReporteNominaPDF = (props) => {
  return (
    <Document>
        <Page size='A4' orientation='portrait'>
            <View style={styles.page}>
                <Image
                    src='..\..\img\spm\reporteNomina.png'
                    style={styles.imageReport}
                />
                <Text style={styles.textNameEmpresa}>
                  {props.nameEmpresa}
                </Text>
                <Text style={styles.textTipoPeriodo}>
                  {props.tipoPeriodo}
                </Text>
                <Text style={styles.textPeriodoFiscal}>
                  {props.periodoFiscal}
                </Text>
                <Text style={styles.textEsquema}>
                  {props.esquema}
                </Text>
                <Text style={styles.textPlanSindical}>
                  {props.planSindical}
                </Text>
                <Text style={styles.textBaseCalculo}>
                  {props.baseCalculo}
                </Text>
                <Text style={styles.textSumatoriaSueldos}>
                  {props.sumatoriaSueldosBase}
                </Text>
                <Text style={styles.textSumatoriaSueldos2}>
                  {props.sumatoriaSueldosBase}
                </Text>
                {/* Sueldo base naranja */}
                <Text style={styles.textPorcentajeEmpresa}>
                  {props.sueldoBaseNaranjaPorcentajeEmpresaLey} 
                  {/* {props.resultadoPorcentajeEmpresa}  */}
                </Text>
                {/* Indemnizacion por riesgo laboral naranja */}
                <Text style={styles.textPorcentajeSindicato}>
                  {props.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato} 
                </Text>
                {/* TOTAL propuesta sindical NARANJA */}
                <Text style={styles.textSumatoriaSueldos3}>
                  {props.sumatoriaSueldos} 
                </Text> 
                
                {/* COLUMNA NEGRA */}
                {/* 1 */}
                <Text style={styles.textSumatoriaSat}>
                  {props.sumatoriaSat}
                </Text>
                {/* 2 */}
                <Text style={styles.textSumatoriaImss}>
                  {props.sumatoriaImss}
                </Text>
                {/* 3 */}
                <Text style={styles.textImpuestoSobreNomina}>
                  {props.sumatoriaImpNomina}
                </Text>
                {/* 4 */}
                <Text style={styles.textSumatoriaImssEmpresa}>
                  {props.sumatoriaImssEmpresa}
                </Text>
                {/* 5 */}
                <Text style={styles.textSumatoriaRcv}>
                  {props.sumatoriaRcv}
                </Text>
                {/* 6 */}
                <Text style={styles.textSumatoriaRcv2}>
                  {props.sumatoriaInfonavit}
                </Text>
                {/* <Text style={styles.textSumatoriaIsr}>
                  {props.sumatoriaIsr}
                </Text> */}
                {/* total */}
                <Text style={styles.textSumatoriaCTA}>
                  {props.sumatoriaCTA}
                </Text>

                {/* COLUMNA NARANJA */}
                {/* 1 */}
                <Text style={styles.textSumatoriaCTApoyoSindicalIsr}>
                  {props.psIsrEmpleado}
                </Text>
                {/* 2 */}
                <Text style={styles.textSumatoriaCTApoyoSindicalImss}>
                  {props.psImssEmpleado}
                </Text>
                {/* 3 */}
                <Text style={styles.textSumatoriaCTApoyoSindicalSat}>
                  {props.psImpuestoNomina}
                </Text>
                {/* 4 */}
                <Text style={styles.textSumatoriaCTApoyoSindicalImssEmpresa}>
                  {props.psImssEmpresa}
                </Text>
                {/* 5 */}
                <Text style={styles.textSumatoriaCTApoyoSindicalRcv}>
                  {props.psRcv}
                </Text>
                {/* 6 */}
                <Text style={styles.textSumatoriaCTAS}>
                  {props.psInfonavit}
                </Text>
                {/* total */}
                <Text style={styles.textTotalNaranja}>
                  {props.psTotal}
                </Text>

                {/* COLUMNA VERDE */}
                {/* 1 */}
                <Text style={styles.textRestaIsr}>
                  {props.restaIsr}
                </Text>
                {/* 2 */}
                <Text style={styles.textRestaImss}>
                  {props.restaImss}
                </Text>
                {/* 3 */}
                <Text style={styles.textRestaSat}>
                  {props.restaImpuestoNomina}
                </Text>
                {/* 4 */}
                <Text style={styles.textRestaImssEmpresa}>
                  {props.restaImssEmpresa}
                </Text>
                {/* 5 */}
                <Text style={styles.textRestaRcv}>
                  {props.restaRcv}
                </Text>
                {/* 6 */}
                <Text style={styles.textRestaTotal}>
                  {props.restaInfonavit}
                </Text>
                {/* total */}
                <Text style={styles.textTotalVerde}>
                  {props.restaTotal}
                </Text>

                {/* Indemnizacion por riesgo laboral */}
                <Text style={styles.textPagoSinFideicomiso}>
                  {props.indemnizacionRiesgoLaboral}
                </Text>
                {/* % operación */}
                <Text style={styles.textCostoOperacion}>
                  {props.costoOperacion}%
                </Text>

                {/* Ahorro tributario */}
                <Text style={styles.textAhorroTributario}>
                  {props.restaTotal} 
                </Text>
                {/* Costo de operacion mensual */}
                <Text style={styles.textCostoOperacionMensual}>
                  {props.costoOperacionMensual} 
                </Text>
                {/* Costo de operacion anual */}
                <Text style={styles.textCostoOperacionAnual}>
                  {props.costoOperacionAnual} 
                </Text>

                {/* Ahorro real */}
                <Text style={styles.textAhorroReal}>
                  {props.ahorroReal} 
                </Text>
                {/* Ahorro real anual */}
                <Text style={styles.textAhorroRealAnual}>
                  {props.ahorroRealAnual} 
                </Text>
            </View>
        </Page>
    </Document>
  )
}

//api/xmex/CalculoEmpleado