import React, { useState } from "react";
import "./Estilo_Cuestionario_Binario.css";
import { Preguntas101SiNoCond } from "./elementos_cuestionarios/Preguntas101SiNoCond";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario11 = (props) => {
  
  const sigPag = "102";
  const pNombre = getUrlParameter('userName');
  // console.log('props nombre: ' + pNombre);
  const pUserId = props.usuario;
  // console.log('props usuario: ' + pUserId);
  const pPerfId = props.perfil;
  // console.log('props perfil: ' + pPerfId);
  const pIdEmpresa = getUrlParameter('empresaId');
  console.log("Este es el ID de la empresa: "+pIdEmpresa);

  const cantidadTotalPreguntas= 1;
  var numPreguntas= 0;

  const [deseableState, setDeseableState] = useState(true);

  const [perfilJson, setPerfilJson] = useState({
    Id: props.perfil,
    UsuarioId: props.usuario,
    qId: sigPag,
    EmpresaId: pIdEmpresa
  });

  const [sessionJson, setSessionJson] = useState({
    referenciaId: 1,     //Dato estático
    seccionId: 1,        //Dato estático
    tipoPreguntaId: 1,   //Dato estático
    preguntaInicial: 1,  //Dato estático
    nombre: pNombre,
    link: ""
  });

  const [respuestasJson, setRespuestasJson] = useState({
    respuestas: [
      { referenciaId: 1, seccionId: 1, pregunta: 1, respuesta: 0 }
    ]
  });

  const updatePreg = (preg, resp, qIdCond, condPreguntaActiva) => {
    console.log(preg, resp);
    if(condPreguntaActiva==1){
      numPreguntas++;
    }
    if(numPreguntas==cantidadTotalPreguntas){
      setDeseableState(false);
    }

    console.log("clics en la misma pregunta: "+condPreguntaActiva);
    console.log("numero de preguntas contestadas: "+numPreguntas);
    
    var arrayRespuestas;
    if ( resp == 1){
      arrayRespuestas = {respuestas:
        [
          { ReferenciaId: 1, SeccionId: 1, Pregunta: 1, Respuesta: 1 }
        ]
      }
    }else{
      arrayRespuestas = {respuestas: 
        [ 
          { ReferenciaId: 1, SeccionId: 1, Pregunta: 1, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 2, Pregunta: 7, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 2, Pregunta: 8, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 9, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 10, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 11, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 12, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 13, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 14, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 3, Pregunta: 15, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 4, Pregunta: 16, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 4, Pregunta: 17, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 4, Pregunta: 18, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 4, Pregunta: 19, Respuesta: 0 },
          { ReferenciaId: 1, SeccionId: 4, Pregunta: 20, Respuesta: 0 }
        ]
      }
    }

    setRespuestasJson(arrayRespuestas);
    console.log("resp: " + resp);
    console.log("array resp: " + respuestasJson);

    setSessionJson((prevState) => {
      return {
        ...prevState,
        link:
        "/cuestionario_" +
        qIdCond +
        "?userId=" +
        pUserId +
        "&perfId=" +
        pPerfId +
        "&empresaId=" +
        getUrlParameter('empresaId') +
        "&nombre=" +
        pNombre
      };
    });
  };

  React.useEffect(() => {
    setSessionJson((prevState) => {
      return {
        ...prevState,
        link:
          "/cuestionario_" +
          sigPag +
          "?userId=" +
          pUserId +
          "&perfId=" +
          pPerfId +
          "&empresaId=" +
          getUrlParameter('empresaId') +
          "&nombre=" +
          pNombre
      };
    });

    setPerfilJson(() => {
      return {
        Id: pPerfId,
        UsuarioId: pUserId,
        qId: sigPag,
        EmpresaId: pIdEmpresa
      };
    });
  }, []);

  // const [userInput, setUserInput] = useState({
  //   button_conditional_si_style: "btn_si_cuestionario_binario_activo",
  //   button_conditional_no_style: "btn_no_cuestionario_binario_activo",
  // });

  return (
    <div className="box_menu_y_cuestionario_binario">
      <div className="cuestionario_div_binario">
        <div className="div_svg_menu_binario">
          <img
            src="./img/menu_cuestionarios/menu_c11.svg"
            className="img_svg_binario"
          />
        </div>
        <div className="text_cuestionario_binario">
          <img
            src="./img/barra_progreso/acontecimientos_traumaticos_severos/barra_de_progreso_11.svg"
            className="img_progreso_binario"
          />
          <p className="titulo_cuestionario_binario">
            <img 
              src="./img/barra_progreso/iconos/traumas_icono_cuestionario.svg"
              className="img_logo"
              />{" "}
            ACONTECIMIENTOS TRAUMÁTICOS SEVEROS
          </p>

          <div className="preguntas_binario_sn">
            
            <Preguntas101SiNoCond
              className="psn"
              numPreg="1"
              texto="1-¿Ha presenciado o sufrido alguna vez, durante o con motivo del trabajo un acontecimiento como los siguientes? 
              Accidente que tenga como consecuencia la muerte, la pérdida de un miembro o una lesión grave.
              Asaltos, actos violentos que derivaron en lesiones graves.
              Secuetro, amezana o cualquier otro que ponga en riesgo su vida o salud, y/o la de otras personas."
              update={updatePreg}
              rSi="1"
              rNo="0"
              condSi="102"
              condNo="201"
              idSi="Si1"
              idNo="No1"
            />
          </div>

          <Button_CuestionarioSiguente
            className="btn_siguiente_cuestionario_binario"
            sesion={sessionJson}
            perfil={perfilJson}
            respuestas={respuestasJson}
            disabled={deseableState}
            upDate={"guardaRespuestas101"}
            condicionPreg='101Cond'
          />

        </div>
      </div>
    </div>
  );
};