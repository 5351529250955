import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper, Typography, Container } from '@mui/material';
import { MenuAdmin } from './MenuAdmin';
import { NominaForm } from './nomina_componentes/NominaForm';
import { NominaActions } from './nomina_componentes/NominaActions';
import * as XLSX from 'xlsx'
import { forEachEmpleados, guardaJsonEmpleados, forEachEmpleadosParaCalculo } from '../../../utils/xmex/services';

export const Nomina = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [jsonDataExcel, setJsonDataExcel] = useState([]);
  const [jsonSubsidioData, setJsonSubsidioData] = useState([
    { limiteInferior: 0.01, limiteSuperior: 10170.99, subsidio: 13.80 },
    { limiteInferior: 10171.00, limiteSuperior: 9999999.99, subsidio: 0 },
    ]);
  const [jsonApoyoSindical, setJsonApoyoSindical] = useState({
    isrEmpleado: 0,
    imssEmpleado: 0,
    impuestoSobreNomina: 0,
    imssEmpresa: 0,
    rcv: 0,
    infonavit: 0,
    total: 0,
  });
  const [jsonAhorro, setJsonAhorro] = useState({
    isrEmpleado: 0,
    imssEmpleado: 0,
    impuestoSobreNomina: 0,
    imssEmpresa: 0,
    rcv: 0,
    infonavit: 0,
    total: 0,
    totalFloat: 0,
  });
  const [jsonEmpresaData, setJsonEmpresaData] = useState({
    nameEmpresa: '',
    tipoPeriodo: 'Mensual',
    periodoFiscal: '2025',
    ano: '',
    esquema: 'Indemnización',
    planSindical: 'Empresa 60% - Sindicato 40%',
    baseCalculo: 'Sueldo bruto',
    primaRiesgo: 0.0054355,
    uma: 113.14,
    salarioMinimo: '278.80',
    disBtnReporte: true,
    sueldo_forEachParaCalculo: 0,
    sumatoriaSat: 0,
    sumatoriaImss: 0,
    sumatoriaImpNomina: 0,
    sumatoriaImssEmpresa: 0,
    sumatoriaIsr: 0,
    sumatoriaSueldos: 0,
    sumatoriaSueldosBase: 0,
    sumatoriaRcv: 0,
    sumatoriaInfonavit: 0,
    sumatoriaCTA: 0,
    sumatoriaCTApoyoSindicalImss: 0,
    sumatoriaCTApoyoSindicalImssEmpresa: 0,
    sumatoriaCTApoyoSindicalSat: 0,
    sumatoriaCTApoyoSindicalIsr: 0,
    sumatoriaCTApoyoSindicalRcv: 0,
    sumatoriaCTAS: 0,
    restaIsr: 0,
    restaImss: 0,
    restaImpuestoNomina: 0,
    restaImssEmpresa: 0,
    restaRcv: 0,
    restaInfonavit: 0,
    restaTotal: 0,
    sueldoBaseNaranjaPorcentajeEmpresaLey: 0,
    indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato: 0,
    resultadoPorcentajeEmpresa: 0,
    resultadoPorcentajeSindicato: 0,
    costoOperacion: '0',
    costoOperacionMensual: '',
    costoOperacionAnual: '',
    ahorroReal: '',
    ahorroRealAnual: '',
  });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUploadClick = async () => {
    if (!file) {
      alert('Por favor, selecciona un archivo antes de continuar.');
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const userData = XLSX.utils.sheet_to_json(sheet);

        const {
          ley,
          sindicato,
          totalSat,
          totalImss,
          totalImpNomina,
          totalImssEmpresa,
          totalRcv,
          totalInfonavit,
          ctatotal,
          totalIsr,
          totalSueldos,
          totalSueldosBase,
          psIsrEmpleado,
          psImssEmpleado,
          psImpuestoNomina,
          psImssEmpresa,
          psRcv,
          psInfonavit,
          psTotal,
          restaIsrEmpleado,
          restaImssEmpleado,
          restaImpuestoNomina,
          restaImssEmpresa,
          restaRcv,
          restaInfonavit,
          disabledBtnResponse,
          datosParaExcel,
          subsLimiteInferior1,
          subsLimiteSuperior1,
          subsLSubsidio1,
          subsLimiteInferior2,
          subsLimiteSuperior2,
          subsLSubsidio2
        } = await forEachEmpleadosParaCalculo(userData, jsonEmpresaData);

        const totalAhorro = ctatotal - psTotal;
        const totalCTA = ctatotal;

        setJsonDataExcel(datosParaExcel);

        setJsonEmpresaData((prevData) => ({
          ...prevData,
          sueldoBaseNaranjaPorcentajeEmpresaLey: ley.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato: sindicato.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaSat: totalSat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImss: totalImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImpNomina: totalImpNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImssEmpresa: totalImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaRcv: totalRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaInfonavit: totalInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaIsr: totalIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaSueldos: (ley + sindicato).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaSueldosBase: totalSueldosBase.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaCTA: ctatotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          disBtnReporte: disabledBtnResponse,
        }));

        setJsonApoyoSindical((prevData) => ({
          ...prevData,
          isrEmpleado: psIsrEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpleado: psImssEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          impuestoSobreNomina: psImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpresa: psImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          rcv: psRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          infonavit: psInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          total: psTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        }));

        setJsonSubsidioData([
          { limiteInferior: subsLimiteInferior1, limiteSuperior: subsLimiteSuperior1, subsidio: subsLSubsidio1 },
          { limiteInferior: subsLimiteInferior2, limiteSuperior: subsLimiteSuperior2, subsidio: subsLSubsidio2 },
        ]);

        setJsonAhorro((prevState) => ({
          ...prevState,
          isrEmpleado: restaIsrEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpleado: restaImssEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          impuestoSobreNomina: restaImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpresa: restaImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          rcv: restaRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          infonavit: restaInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          total: totalAhorro.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          totalFloat: totalAhorro,
        }));

        switch (jsonEmpresaData.planSindical) {
          case 'Empresa 60% - Sindicato 40%': {
            const { costoOperacionMensual } = await calculoEspecifico(totalSueldos, 0.6, 0.4, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
            const ahorroReal = totalAhorro - costoOperacionMensual;
            const ahorroRealAnual = ahorroReal * 12;

            setJsonEmpresaData((prevState) => ({
              ...prevState,
              ahorroReal: ahorroReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              ahorroRealAnual: ahorroRealAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }));
            break;
          }
          case 'Empresa 70% - Sindicato 30%':
            await calculoEspecifico(totalSueldos, 0.7, 0.3, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
            break;
          case 'Empresa 80% - Sindicato 20%':
            await calculoEspecifico(totalSueldos, 0.8, 0.2, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
            break;
          case 'Empresa 90% - Sindicato 10%':
            await calculoEspecifico(totalSueldos, 0.9, 0.1, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('Error al procesar el archivo:', error);
        alert('Ocurrió un error al procesar el archivo. Por favor, asegurate de usar el template.');
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  
  const calculoEspecifico = async (sumatoriaSueldos, porcentajeEmpresa, porcentajeSindicato, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal) => {
    // Calculos en base a la distribucion de calculo
    const resultadoPorcentajeEmpresa = sumatoriaSueldos * porcentajeEmpresa;
    console.log(`Resultado del porcentajeEmpresa: ${resultadoPorcentajeEmpresa}`);
    const resultadoPorcentajeSindicato = sumatoriaSueldos * porcentajeSindicato;
    console.log(`Resultado del porcentajeSindicato: ${resultadoPorcentajeSindicato}`);
    const resultadoPorcentajeImss = totalImss * porcentajeEmpresa;
    console.log(`resultadoPorcentajeImss: ${resultadoPorcentajeImss}`);
    const resultadoPorcentajeImssEmpresa = totalImssEmpresa * porcentajeEmpresa;
    console.log(`resultadoPorcentajeImssEmpresa: ${resultadoPorcentajeImssEmpresa}`);
    const resultadoPorcentajeSat = totalSat * porcentajeEmpresa;
    console.log(`resultadoPorcentajeSat: ${resultadoPorcentajeSat}`);
    const resultadoPorcentajeIsr = totalIsr * porcentajeEmpresa;
    console.log(`resultadoPorcentajeIsr: ${resultadoPorcentajeIsr}`);
    const resultadoPorcentajeRcv = totalRcv * porcentajeEmpresa;
    console.log(`resultadoPorcentajeRcv: ${resultadoPorcentajeRcv}`);
    // TOTAL negro
    // var sumatoriaCTAS = resultadoPorcentajeImss + resultadoPorcentajeImssEmpresa + resultadoPorcentajeSat + resultadoPorcentajeIsr + resultadoPorcentajeRcv + resultadoPorcentajeRcv;
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalImss: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalImss);
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa);
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalSat: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalSat);
    console.log('jsonEmpresaData.sumatoriaIsr: ' + jsonEmpresaData.sumatoriaIsr);
    console.log('jsonEmpresaData.sumatoriaRcv: ' + jsonEmpresaData.sumatoriaRcv);
    var sumatoriaCTAS = (jsonEmpresaData.sumatoriaCTApoyoSindicalImss + jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa + jsonEmpresaData.sumatoriaCTApoyoSindicalSat + jsonEmpresaData.sumatoriaIsr + jsonEmpresaData.sumatoriaRcv + jsonEmpresaData.sumatoriaInfonavit);
    // CALCULO DE AHORRO (RESTAS)
    console.log('jsonEmpresaData.sumatoriaImss: ' + jsonEmpresaData.sumatoriaImss);
    var restaIsr = (jsonEmpresaData.sumatoriaSat - jsonApoyoSindical.isrEmpleado);
    var restaImss = jsonEmpresaData.sumatoriaImss - jsonApoyoSindical.imssEmpleado;
    var restaImpuestoNomina = jsonEmpresaData.sumatoriaImpNomina - jsonApoyoSindical.impuestoSobreNomina;
    var restaImssEmpresa = jsonEmpresaData.sumatoriaImssEmpresa - jsonApoyoSindical.imssEmpresa;
    var restaRcv = jsonEmpresaData.sumatoriaRcv - jsonApoyoSindical.rcv;
    var restaInfonavit = jsonEmpresaData.sumatoriaInfonavit - jsonApoyoSindical.infonavit;
    var restaTotal = jsonEmpresaData.sumatoriaCTAS - jsonApoyoSindical.total;
    console.log('RrestaIsr: ' + restaIsr + '  =  ' + jsonEmpresaData.sumatoriaSat + '  -  ' + jsonApoyoSindical.isrEmpleado);
    console.log('RrestaImss: ' + restaImss + '  =  ' + jsonEmpresaData.sumatoriaImss + '  -  ' + jsonApoyoSindical.imssEmpleado);
    console.log('RrestaImpuestoNomina: ' + restaImpuestoNomina + '  =  ' + jsonEmpresaData.sumatoriaImpNomina + '  -  ' + jsonApoyoSindical.impuestoSobreNomina);
    console.log('RrestaImssEmpresa: ' + restaImssEmpresa + '  =  ' + jsonEmpresaData.sumatoriaImssEmpresa + '  -  ' + jsonApoyoSindical.imssEmpresa);
    console.log('RrestaRcv: ' + restaRcv + '  =  ' + jsonEmpresaData.sumatoriaRcv + '  -  ' + jsonApoyoSindical.rcv);
    console.log('RrestaInfonavit: ' + restaInfonavit + '  =  ' + jsonEmpresaData.sumatoriaRcv + '  -  ' + jsonApoyoSindical.infonavit);
    console.log('RrestaTotal: ' + restaTotal + '  =  ' + jsonEmpresaData.sumatoriaCTAS + '  -  ' + jsonApoyoSindical.total);
    
    // Calculo de costo de operacion MENSUAL
    const costoOperacionMensual = (sindicato * jsonEmpresaData.costoOperacion)/100;
      console.log('costoOperacionMensual: ' + costoOperacionMensual);
      console.log('costoOperacionMensual: ' + jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato);
      console.log('costoOperacionMensual: ' + jsonEmpresaData.costoOperacion);
    // Calculo de costo de operacion ANUAL
    const costoOperacionAnual = (costoOperacionMensual * 12);

    setJsonEmpresaData(prevData => ({
      ...prevData,
        resultadoPorcentajeEmpresa : resultadoPorcentajeEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        resultadoPorcentajeSindicato: resultadoPorcentajeSindicato.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        sumatoriaCTApoyoSindicalImss: resultadoPorcentajeImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalImssEmpresa:resultadoPorcentajeImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalSat: resultadoPorcentajeSat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalIsr: resultadoPorcentajeIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalRcv: resultadoPorcentajeRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTAS: sumatoriaCTAS.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        restaIsr: restaIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImss: restaImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImpuestoNomina: restaImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImssEmpresa: restaImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaRcv: restaRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaInfonavit: restaInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaTotal: restaTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        costoOperacionMensual: costoOperacionMensual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        costoOperacionAnual: costoOperacionAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        
        // ahorroReal: ahorroReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        // ahorroRealAnual: ahorroRealAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }));
      
    return{
      costoOperacionMensual,
      costoOperacionAnual
    }
  };

  const isXsScreen = window.innerWidth < 600;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}>
        <MenuAdmin isXsScreen={isXsScreen} />
      </Grid>
      <Grid item xs={12} sm={10} sx={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
        <Box sx={{ width: '95%', height: '100vh' }}>
          <Paper elevation={3} sx={{ width: '100%', height: '100%', borderRadius: '14px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '7%', backgroundColor: '#FF8030', borderRadius: '14px' }}>
              <Typography variant="h5" sx={{ color: '#FFFFFF', fontWeight: '700', marginLeft: '2%', fontSize: { xs: '.7rem', sm: '1.2rem', md: '1.5rem' } }}>
                CALCULO DE NOMINA
              </Typography>
            </Box>
            <Container sx={{ display: 'flex', marginTop: '1%' }}>
              <Box sx={{ width: '100%', marginBlock: '1%', alignItems: 'center' }}>
                <NominaForm
                  jsonEmpresaData={jsonEmpresaData}
                  setJsonEmpresaData={setJsonEmpresaData}
                  handleFileChange={handleFileChange}
                  handleUploadClick={handleUploadClick}
                  jsonSubsidioData={jsonSubsidioData} // Pass the state, not the setter
                  setJsonSubsidioData={setJsonSubsidioData} 
                  isLoading={isLoading}
                  file={file}
                />
                <NominaActions
                  jsonEmpresaData={jsonEmpresaData}
                  jsonApoyoSindical={jsonApoyoSindical}
                  jsonAhorro={jsonAhorro}
                  jsonDataExcel={jsonDataExcel}
                />
              </Box>
            </Container>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};