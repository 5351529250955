// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_MBI{
    margin-left: 0%;
    margin-right: 0%;   
}
.td_num_Preg_MBI{
    width: 2%;
    text-align: center;
}
.num_Preg_MBI{
    font-size: 1vw;
    font-family: 'Montserrat', sans-serif;
}
.txt_Preg_MBI{
    font-size: 1vw;
    font-family: 'Montserrat', sans-serif;
    margin-left: 3%;
}
.td_RB_MBI{
    width: 5%;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/cuestionario/elementos_cuestionarios/Estilo_Cuestionario_MBI.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,qCAAqC;AACzC;AACA;IACI,cAAc;IACd,qCAAqC;IACrC,eAAe;AACnB;AACA;IACI,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".table_MBI{\n    margin-left: 0%;\n    margin-right: 0%;   \n}\n.td_num_Preg_MBI{\n    width: 2%;\n    text-align: center;\n}\n.num_Preg_MBI{\n    font-size: 1vw;\n    font-family: 'Montserrat', sans-serif;\n}\n.txt_Preg_MBI{\n    font-size: 1vw;\n    font-family: 'Montserrat', sans-serif;\n    margin-left: 3%;\n}\n.td_RB_MBI{\n    width: 5%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
