import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

export const NavBar = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    return (
    <Box display='flex' justifyContent='center'>
        {isSmallScreen && 
            <AppBar 
                sx={{
                    width: '95%', 
                    left: '2.5%', 
                    borderRadius: 8, 
                    marginTop: '3%', 
                    background: 'rgba(217, 217, 217, 0.85)',
                    // background: 'lightblue',
                    paddingTop: '1vw'
                }}
            >
               <Toolbar>
                    {/* <Box> */}
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <a href='#inicio'>
                                <img 
                                    src={src_url + 'logo_spm.png'}
                                    style={{width: '7vw'}}
                                />
                            </a>
                        </Link>
                    </Box>
                    <Box sx={{flexGrow: 1, marginTop: '-1vw'}}>
                    {/* <Box> */}
                        {/* <a href='#beneficios'> */}
                        <Link to='/beneficios' style={{textDecoration: 'none'}}>
                            <Button color='white' variant="text" sx={{marginRight: '-1%'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: '3.55vw', fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    BENEFICIOS
                                </Typography>
                            </Button>
                        </Link>
                        {/* </a> */}
                    </Box>
                    <Box sx={{marginTop: '-1vw'}}>
                        <Link to='/preAfiliacion' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: '3vw', fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                    <Box sx={{marginTop: '-1vw'}}>
                        <Link to='/loginXmex' style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: '3vw', fontWeight: 700}} style={{color: 'white'}}>
                                    LOGIN
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isMediumScreen &&
            <AppBar 
                sx={{
                    width: '95%', 
                    left: '2.5%', 
                    // display: 'flex',
                    // justifyContent: 'center',
                    borderRadius: 8, 
                    marginTop: '2%', 
                    background: 'rgba(217, 217, 217, 0.85)'
                    // background: 'violet'
                }}
            >
            <Toolbar>
                    {/* <Box> */}
                    <Box sx={{flexGrow: 0.5}}>
                        <Link to='/' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <figure>
                                <a href='#inicio'>
                                    <img 
                                        src={src_url + 'logo_spm.png'}
                                        style={{width: '7vw'}}
                                        />
                                </a>
                            </figure>
                        </Link>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        {/* <a href='#beneficios'> */}
                        <Link to='/beneficios' style={{textDecoration: 'none'}}>
                            <Button color='white' variant="text" sx={{marginLeft: '0vw'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: 19, fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    BENEFICIOS
                                </Typography>
                            </Button>
                        </Link>
                        {/* </a> */}
                    </Box>
                    <Box>
                        <Link to='/preAfiliacion' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: 17, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to='/loginXmex' style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: 17, fontWeight: 700}} style={{color: 'white'}}>
                                    LOGIN
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isLargeScreen &&
            <AppBar 
                sx={{
                    width: '90%',
                    // width: '1199px', 
                    left: '5%', 
                    // display: 'flex',
                    // justifyContent: 'center',
                    borderRadius: 8, 
                    marginTop: '1%', 
                    paddingTop: 1, 
                    background: 'rgba(217, 217, 217, 0.85)'
                    // background: 'pink'
                }}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <a href='#inicio'>
                                <img 
                                    src={src_url + 'logo_spm.png'}
                                    style={{width: '5vw'}}
                                    />
                            </a>
                        </Link>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                        <a href='#inicio'>
                            <Link to='/nom35' style={{textDecoration: 'none'}}>
                                <Button color='white' variant="text" sx={{marginLeft: '-2%'}}>
                                    <Typography 
                                        // className='typography_text_navBar_paginaPublica'
                                        sx={{fontSize: '1.7vw', fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                        NOM 035
                                    </Typography>
                                </Button>
                            </Link>
                        </a>
                        <a href='#nosotros'>
                            <Button color='white' variant="text" sx={{marginLeft: '2%'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: '1.7vw', fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    NOSOTROS
                                </Typography>
                            </Button>
                        </a>
                        {/* <a href='#beneficios'> */}
                        <Link to='/beneficios' style={{textDecoration: 'none'}}>
                            <Button color='white' variant="text" sx={{marginLeft: '2%'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: '1.7vw', fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    BENEFICIOS
                                </Typography>
                            </Button>
                        </Link>
                        {/* </a> */}
                    </Box>
                    <Box sx={{marginTop: '-0.9vw'}}>
                        <Link to='/preAfiliacion' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: '1.7vw', fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                    <Box sx={{marginTop: '-0.9vw'}}>
                        <Link to='/loginXmex' style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: '1.7vw', fontWeight: 700}} style={{color: 'white'}}>
                                    LOGIN
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isExtraLargeScreen &&
            <AppBar 
                // className='appBar_paginaPublica'
                // className={classes.myComponent}
                sx={{
                    width: '85%', 
                    left: '7.5%', 
                    justifyContent: 'center',
                    borderRadius: 8, 
                    marginTop: '1%', 
                    paddingTop: 1, 
                    paddingBottom: 1,
                    background: 'rgba(217, 217, 217, 0.85)'
                }}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            <figure>
                                <a href='#inicio'>
                                    <img 
                                        src={src_url + 'logo_spm.png'}
                                        style={{width: '4vw'}}
                                        />
                                </a>
                            </figure>
                        </Link>
                    </Box>
                    {/* <Box sx={{flexGrow: 1}}> */}
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/nom35' style={{textDecoration: 'none'}}>
                            <Button color='white' variant="text" sx={{marginLeft: '-2%'}}>
                                <Typography 
                                    sx={{fontSize: 25, fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    NOM 035
                                </Typography>
                            </Button>
                        </Link>
                        <a href='#nosotros'>
                            <Button color='white' variant="text" sx={{marginLeft: '2%'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: 25, fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    NOSOTROS
                                </Typography>
                            </Button>
                        </a>
                        {/* <a href='#beneficios'> */}
                        <Link to='/beneficios' style={{textDecoration: 'none'}}>
                            <Button color='white' variant="text" sx={{marginLeft: '2%'}}>
                                <Typography 
                                    // className='typography_text_navBar_paginaPublica'
                                    sx={{fontSize: 25, fontWeight: 700, color: 'white', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                    BENEFICIOS
                                </Typography>
                            </Button>
                        </Link>
                        {/* </a> */}
                    </Box>
                    <Box>
                        <Link to='/preAfiliacion' style={{marginLeft: '-15%', textDecoration: 'none'}}>
                            <Button color='success' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    AFILIARSE
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to='/loginXmex' style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                <Typography 
                                    // className='typography_button_paginaPublica'
                                    sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                    INICIAR SESION
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        }
    </Box>
  );
};
