import { Box, Container, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/';

export const Bienvenidos = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen &&
            <Box id={'nosotros'} sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                {/* TEXTO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{marginTop: '-20vh', width: '95%', textAlign: 'center'}}>
                    <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 18, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                      Luchando por los derechos y beneficios 
                      de los trabajadores mexicanos
                    </Typography>
                  </Box>
                </Box>
                {/* BOTON */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '-22vh'}}>
                      <Typography sx={{fontSize: 16, fontWeight: 700}} style={{color: 'white'}}>
                        AFILIARSE
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                {/* PILDORA CON DATOS DEL SINDITACO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', width: '90%', marginTop: '-7vh', borderRadius: 8, left: '15%', background: 'rgba(217,217,217,100)', paddingTop: '2%', paddingBottom: '2%'}}>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: '700'}}>
                          +60,000
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 12, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          AFILIADOS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, borderWidth: '2px', borderLeftStyle: 'solid', borderLeftColor: '#FFFFFF',  borderRightStyle: 'solid', borderRightColor: '#FFFFFF'}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: '700'}}>
                          +80
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 12, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          EMPRESAS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: '700'}}>
                          +100
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 12, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          BENEFICIOS
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', marginTop: '1vh', marginBottom: '2vh', width: '95%'}}>
                            {/* CAJA CON TEXTOS */}
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography color='primary' sx={{fontSize: 22, fontWeight: 700, width: '65%',textAlign: 'center'}}>
                                        Bienvenidos a 
                                        Sindicato por México
                                    </Typography>
                                </Box>
                                <Box sx={{marginTop: '1vh'}}>
                                    <Typography sx={{fontSize: 15, fontWeight: 700, color: '#757575', width: '100%',textAlign: 'justify'}}>
                                        Una organización sindical dedicada a defender
                                        los derechos y mejorar las condiciones laborales 
                                        de los trabajadores mexicanos.
                                    </Typography>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)', marginBlock: '2vh'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 15, fontWeight: 700, width: '100%'}}>
                                            NUESTRA MISIÓN
                                        </Typography>
                                        <Typography sx={{fontSize: 13, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            Representar a nuestros miembros ante los empleadores 
                                            y las autoridades gubernamentales, promoviendo el 
                                            desarrollo económico y social del país.
                                        </Typography>
                                    </Container>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 15, fontWeight: 700, width: '100%'}}>
                                            NUESTRO COMPROMISO
                                        </Typography>
                                        <Typography sx={{fontSize: 13, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            En Sindicato por México, nos comprometemos a construir 
                                            un futuro más justo y equitativo para todos los 
                                            trabajadores mexicanos
                                        </Typography>
                                    </Container>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isMediumScreen &&
            <Box id={'nosotros'} sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                {/* TEXTO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{marginTop: '-21.5vh', width: '75%', textAlign: 'center'}}>
                    <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 22, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                      Luchando por los derechos y beneficios 
                      de los trabajadores mexicanos
                    </Typography>
                  </Box>
                </Box>
                {/* BOTON */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '-23vh'}}>
                      <Typography sx={{fontSize: 16, fontWeight: 700}} style={{color: 'white'}}>
                        AFILIARSE
                      </Typography>
                    </Button>
                  </Link>
                </Box>  
                {/* PILDORA CON DATOS DEL SINDITACO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', width: '90%', marginTop: '-8vh', borderRadius: 8, left: '15%', background: 'rgba(217,217,217,100)', paddingTop: '2%', paddingBottom: '2%'}}>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 26, fontWeight: '700'}}>
                          +60,000
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 18, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          AFILIADOS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, borderWidth: '2px', borderLeftStyle: 'solid', borderLeftColor: '#FFFFFF',  borderRightStyle: 'solid', borderRightColor: '#FFFFFF'}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 26, fontWeight: '700'}}>
                          +80
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 18, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          EMPRESAS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 26, fontWeight: '700'}}>
                          +100
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 18, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          BENEFICIOS
                      </Typography>
                    </Box>
                  </Box>
                </Box> 
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', marginBlock: '2vh', width: '95%'}}>
                            {/* CAJA CON TEXTOS */}
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography color='primary' sx={{fontSize: 25, fontWeight: 700, width: '50%',textAlign: 'center'}}>
                                        Bienvenidos a 
                                        Sindicato por México
                                    </Typography>
                                </Box>
                                <Box sx={{marginTop: '1vh'}}>
                                    <Typography sx={{fontSize: 18, fontWeight: 700, color: '#757575', width: '100%',textAlign: 'justify'}}>
                                        Una organización sindical dedicada a defender
                                        los derechos y mejorar las condiciones laborales 
                                        de los trabajadores mexicanos.
                                    </Typography>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)', marginBlock: '2vh'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 18, fontWeight: 700, width: '100%'}}>
                                            NUESTRA MISIÓN
                                        </Typography>
                                        <Typography sx={{fontSize: 15, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            Representar a nuestros miembros ante los empleadores 
                                            y las autoridades gubernamentales, promoviendo el 
                                            desarrollo económico y social del país.
                                        </Typography>
                                    </Container>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 18, fontWeight: 700, width: '100%'}}>
                                            NUESTRO COMPROMISO
                                        </Typography>
                                        <Typography sx={{fontSize: 15, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            En Sindicato por México, nos comprometemos a construir 
                                            un futuro más justo y equitativo para todos los 
                                            trabajadores mexicanos
                                        </Typography>
                                    </Container>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isLargeScreen &&
            <Box 
                id={'nosotros'}
                sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}
            >
                {/* TEXTO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{marginTop: '-25vh', width: '65%', textAlign: 'center'}}>
                    <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 28, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                      Luchando por los derechos y beneficios 
                      de los trabajadores mexicanos
                    </Typography>
                  </Box>
                </Box>
                {/* BOTON */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '-26vh'}}>
                      <Typography sx={{fontSize: 20, fontWeight: 700}} style={{color: 'white'}}>
                        AFILIARSE
                      </Typography>
                    </Button>
                  </Link>
                </Box>  
                {/* PILDORA CON DATOS DEL SINDITACO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', width: '90%', marginTop: '-9vh', borderRadius: 8, left: '15%', background: 'rgba(217,217,217,100)', paddingTop: '2%', paddingBottom: '2%'}}>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 30, fontWeight: '700'}}>
                          +60,000
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 22, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          AFILIADOS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, borderWidth: '2px', borderLeftStyle: 'solid', borderLeftColor: '#FFFFFF',  borderRightStyle: 'solid', borderRightColor: '#FFFFFF'}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 30, fontWeight: '700'}}>
                          +80
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 22, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          EMPRESAS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 30, fontWeight: '700'}}>
                          +100
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 22, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          BENEFICIOS
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', marginBlock: '2vh', width: '90%'}}>
                            {/* CAJA CON TEXTOS */}
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography color='primary' sx={{fontSize: 27, fontWeight: 700, width: '80%',textAlign: 'center'}}>
                                        Bienvenidos a 
                                        Sindicato por México
                                    </Typography>
                                </Box>
                                <Box sx={{marginTop: '1vh'}}>
                                    <Typography sx={{fontSize: 18, fontWeight: 700, color: '#757575', width: '100%',textAlign: 'justify'}}>
                                        Una organización sindical dedicada a defender
                                        los derechos y mejorar las condiciones laborales 
                                        de los trabajadores mexicanos.
                                    </Typography>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)', marginBlock: '2vh'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 18, fontWeight: 700, width: '100%'}}>
                                            NUESTRA MISIÓN
                                        </Typography>
                                        <Typography sx={{fontSize: 15, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            Representar a nuestros miembros ante los empleadores 
                                            y las autoridades gubernamentales, promoviendo el 
                                            desarrollo económico y social del país.
                                        </Typography>
                                    </Container>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 18, fontWeight: 700, width: '100%'}}>
                                            NUESTRO COMPROMISO
                                        </Typography>
                                        <Typography sx={{fontSize: 15, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            En Sindicato por México, nos comprometemos a construir 
                                            un futuro más justo y equitativo para todos los 
                                            trabajadores mexicanos
                                        </Typography>
                                    </Container>
                                </Box>
                            </Box>
                            {/* CAJA CON IMAGEN */}
                            <Box sx={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end'}}>
                                <img 
                                    src={src_url + 'familia.png'}
                                    style={{width: '95%'}}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isExtraLargeScreen &&
            <Box 
                id={'nosotros'}
                sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}
            >
                {/* TEXTO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{marginTop: '-31vh', width: '55%', textAlign: 'center'}}>
                    <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 32, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                      Luchando por los derechos y beneficios 
                      de los trabajadores mexicanos
                    </Typography>
                  </Box>
                </Box>
                {/* BOTON */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '-34vh'}}>
                      <Typography sx={{fontSize: 22, fontWeight: 700}} style={{color: 'white'}}>
                        AFILIARSE
                      </Typography>
                    </Button>
                  </Link>
                </Box>  
                {/* PILDORA CON DATOS DEL SINDITACO */}
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'row', width: '90%', marginTop: '-12vh', borderRadius: 8, left: '15%', background: 'rgba(217,217,217,100)', paddingTop: '2%', paddingBottom: '2%'}}>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 36, fontWeight: '700'}}>
                          +60,000
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 30, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          AFILIADOS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1, borderWidth: '2px', borderLeftStyle: 'solid', borderLeftColor: '#FFFFFF',  borderRightStyle: 'solid', borderRightColor: '#FFFFFF'}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 36, fontWeight: '700'}}>
                          +80
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 30, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          EMPRESAS
                      </Typography>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 36, fontWeight: '700'}}>
                          +100
                      </Typography>
                      <Typography sx={{display: 'flex', justifyContent: 'center', color: '#FFFFFF', fontSize: 30, fontWeight: '700', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                          BENEFICIOS
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', marginBlock: '2vh', width: '90%'}}>
                            {/* CAJA CON TEXTOS */}
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%'}}>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography color='primary' sx={{fontSize: 36, fontWeight: 700, width: '70%',textAlign: 'center'}}>
                                        Bienvenidos a 
                                        Sindicato por México
                                    </Typography>
                                </Box>
                                <Box sx={{marginTop: '1vh'}}>
                                    <Typography sx={{fontSize: 23, fontWeight: 700, color: '#757575', width: '100%',textAlign: 'justify'}}>
                                        Una organización sindical dedicada a defender
                                        los derechos y mejorar las condiciones laborales 
                                        de los trabajadores mexicanos.
                                    </Typography>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)', marginBlock: '3vh'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 22, fontWeight: 700, width: '100%'}}>
                                            NUESTRA MISIÓN
                                        </Typography>
                                        <Typography sx={{fontSize: 18, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            Representar a nuestros miembros ante los empleadores 
                                            y las autoridades gubernamentales, promoviendo el 
                                            desarrollo económico y social del país.
                                        </Typography>
                                    </Container>
                                </Box>
                                <Box sx={{borderRadius: 8, background: 'rgba(217,217,217,100)'}}>
                                    <Container sx={{marginBlock: '1.5vh'}}>
                                        <Typography color='primary' sx={{fontSize: 22, fontWeight: 700, width: '100%'}}>
                                            NUESTRO COMPROMISO
                                        </Typography>
                                        <Typography sx={{fontSize: 18, fontWeight: 700, color: '#757575', width: '100%', textAlign: 'justify'}}>
                                            En Sindicato por México, nos comprometemos a construir 
                                            un futuro más justo y equitativo para todos los 
                                            trabajadores mexicanos
                                        </Typography>
                                    </Container>
                                </Box>
                            </Box>
                            {/* CAJA CON IMAGEN */}
                            <Box sx={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end'}}>
                                <img 
                                    src={src_url + 'familia.png'}
                                    style={{width: '90%'}}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </Box>
  )
}
