import React from 'react'
import { Box, Grid, Paper, Container, Typography, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { Link } from 'react-router-dom';
import { MenuAdmin } from './MenuAdmin';

export const Afiliados = () => {
  const isXsScreen = window.innerWidth < 600; // Cambia el valor según el tamaño de pantalla que consideres como xs

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}>
        <MenuAdmin isXsScreen={isXsScreen}/>
      </Grid>
      <Grid item xs={12} sm={10} sx={{display: 'flex', justifyContent: 'center', marginTop: '2%'}}>
          <Box sx={{width: '90%', height: '90vh'}}>
            <Paper elevation={3} sx={{width: '100%', height: '100%'}}>
              <Container sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', marginBlock: '2%', width: '100%', maxWidth: 500, 
                // backgroundColor: 'pink'
                }}>
                  <Typography fontWeight={700} variant='h3' color='#353535'>
                      Listado de empresas  
                  </Typography>
                </Box>
                <Paper sx={{width: '100%', borderRadius: '10px', backgroundColor: '#e9e9e9'}}>
                  <Container sx={{display: 'flex', justifyContent: 'end'}}>
                    <Link to='/altaEmpresas'>
                        <Button 
                          variant='contained'
                          color='success'
                          sx={{borderRadius: 8, marginBlock: '10%'}}
                          >
                          <Typography sx={{fontSize: 17, fontWeight: 500}}>
                            Agregar
                          </Typography>
                        </Button>
                    </Link>
                  </Container>
                </Paper>
                <Paper sx={{width: '100%', borderRadius: '10px', background: '#FF8030', marginBlock: '1%'}}>
                  <Container>
                    {/* <Typography sx={{fontSize: 17, fontWeight: 500, marginBlock: '1%'}}> */}
                    <Typography variant="h3" sx={{marginBlock: 2, color:'#FFFFFF', fontWeight: '700', fontSize:{xs: '.5rem', sm: '.8rem', md: '1.1rem' }}}>
                      Empresa
                    </Typography>
                  </Container>
                </Paper>
              </Container>
            </Paper>
          </Box>
        </Grid>
    </Grid>
  )
}
