import { Box } from '@mui/material'
import React from 'react'
import { NavBar_afiliacion } from '../preAfiliacion/NavBar_Preafiliacion'

export const ScreenAfiliacionExitosa = () => {
  return (
    <Box>
        <NavBar_afiliacion/>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Box
              style={{
                backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/afiliacion/afiliacion_exitosa_sinFondo.gif )", 
                width: "80%", 
                height: "80vh", 
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}
          />
        </Box>
    </Box>
  )
}
