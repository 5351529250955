import React, { useState } from "react";
import { TextField, Button, Box, Typography, Paper, Divider } from "@mui/material";
import { chat_GPT } from "../../../utils/xmex/services";

export const ChatAI = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    
    const toggleChat = () => setIsOpen(!isOpen);

    // const handleSend = (e) => {
    // if (e.key === "Enter" && userInput.trim()) {
    //     // Enviar el mensaje del usuario
    //     const newMessages = [...messages, { sender: "user", text: userInput }];
    //     setMessages(newMessages);
    //     setUserInput(""); // Limpiar campo de texto

    //     // Respuesta predeterminada del bot
    //     const botResponse = { sender: "bot", text: "Hola" };
    //     setMessages((prevMessages) => [...prevMessages, botResponse]);
    //     }
    // }; 

    const handleSend = async (e) => {
        if (e.key === "Enter" && userInput.trim()) {
          const userMessage = { sender: "user", text: userInput };
          setMessages((prevMessages) => [...prevMessages, userMessage]);
          setUserInput(""); // Limpiar campo de texto
      
          try {
            // Llamar a la API con el body correcto
            const response = await chat_GPT({ "Tema o pregunta": userInput });
      
            // Verificar si la API responde correctamente
            if (response && response.content) {
                const botMessage = { sender: "bot", text: response.content }; // Usamos response.content en lugar de response.respuesta
                setMessages((prevMessages) => [...prevMessages, botMessage]);
              } else {
                console.error("Error: La API no devolvió una respuesta válida.", response);
                setMessages((prevMessages) => [...prevMessages, { sender: "bot", text: "Error al obtener respuesta." }]);
              }
          } catch (error) {
            console.error("Error al comunicarse con la API:", error);
            setMessages((prevMessages) => [...prevMessages, { sender: "bot", text: "Hubo un error, intenta nuevamente." }]);
          }
        }
      };
      

    return (
      
        <div>
        {/* Botón flotante para abrir/cerrar el chat */}
        <button
          onClick={toggleChat}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "#FF8030",
            color: "#fff",
            fontSize: "24px",
            border: "none",
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          💬
        </button>
  
        {/* Contenedor del chat */}
        {isOpen && (
            <Box
              sx={{
                position: "fixed",
                bottom: "90px",
                right: "20px",
                width: "350px",
                height: "450px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "10px",
                maxWidth: "100%",
              }}
            >
                {/* Encabezado del chat */}
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ChatIA
                </Typography>
                <Divider sx={{ marginBottom: "10px" }} />

                    {/* Área de mensajes */}
                    <Box
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        marginBottom: "10px",
                        paddingRight: "10px",
                      }}
                    >
                        {messages.map((message, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                            //   flexDirection: message.sender === "bot" ? "row-reverse" : "row", // Si es 'user', se invierte la dirección.
                              marginBottom: "10px",
                              justifyContent: message.sender === "user" ? "flex-end" : "flex-start", // Alineación de los mensajes
                            }}
                          >
                            <Paper
                              sx={{
                                padding: "8px 16px",
                                backgroundColor: message.sender === "user" ? "#FF8030" : "#f1f1f1", // Azul para el usuario, gris para el bot
                                color: message.sender === "user" ? "#fff" : "#000", // Blanco para el usuario, negro para el bot
                                borderRadius: "20px",
                                maxWidth: "80%",
                                wordWrap: "break-word", // Asegura que el texto largo se ajuste
                                alignSelf: message.sender === "user" ? "flex-end" : "flex-start", // Se alinea correctamente
                              }}
                            >
                              {message.text}
                            </Paper>
                          </Box>
                        ))}
                    </Box>

                    {/* Componente de entrada para el mensaje */}
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      onKeyDown={handleSend}
                      placeholder="Escribe tu mensaje..."
                      sx={{
                        marginBottom: "10px",
                      }}
                    />
                </Box>
            )}
        </div>
    );
  };




  