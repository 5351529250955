// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t1{
    font-weight: 500;
    font-size: 1.5vw;
    color: rgb(68, 68, 68); 
    margin-left: 5%;
    font-family: 'Mukta Mahee', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/nom35/admin/reportes_dashboard1_admin/Reportes_dashboard1_admin.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,sCAAsC;AAC1C","sourcesContent":[".t1{\n    font-weight: 500;\n    font-size: 1.5vw;\n    color: rgb(68, 68, 68); \n    margin-left: 5%;\n    font-family: 'Mukta Mahee', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
