import React from 'react'
import { Afiliados } from '../../components/xmex/admin/Afiliados'

export const DashAfiliados = () => {
  return (
    <div>
      <Afiliados/>    
    </div>
  )
}
