import React from 'react'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Container } from '@mui/system';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/servicios/';

export const Servicios = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen && 
            <Box 
                id={'beneficios'}
                sx={{
                    backgroundColor: '#F5F5F5',
                    // backgroundColor: 'lightsalmon',
                    width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Container display={'flex-root'}>
                    {/* TARJETAS DE SERVICIOS */}
                    <Grid container spacing={2} display='flex' justifyContent='center' sx={{paddingBlock: '2vh'}}>
                        <Grid item xs={6}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_smi.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='http://smisistemas.com.mx/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_gr.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://asistenciasparatuhogar.mercadosindical.com/tienda/mercadosindical/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_proyectos.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/franquicias'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        }
        {isMediumScreen && 
            <Box 
                id={'beneficios'}
                sx={{
                    backgroundColor: '#F5F5F5',
                    // backgroundColor: 'lightsalmon',
                    width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Container display={'flex-root'}>
                    {/* TARJETAS DE SERVICIOS */}
                    <Grid container spacing={2} display='flex' justifyContent='center' sx={{paddingBlock: '2vh'}}>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_smi.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='http://smisistemas.com.mx/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_gr.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://asistenciasparatuhogar.mercadosindical.com/tienda/mercadosindical/'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_proyectos.png'}
                                        style={{width: '100px', height: '100px', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '35%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: 15}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/franquicias'
                                    >
                                        <Typography  sx={{fontSize: 18}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        }
        {isLargeScreen && 
            <Box
                id={'beneficios'}
                sx={{
                    backgroundColor: '#F5F5F5',
                    // backgroundColor: 'lightsalmon',
                    width: '100%', height: '100%', backgroundSize: "cover", paddingBottom: '2vh'}}>
                <Box display={'flex-root'}>
                    {/* TARJETAS DE SERVICIOS */}
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '22%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.8vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/'
                                    >
                                        <Typography  sx={{fontSize: '1.7vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '22%', marginLeft: '3%', marginRight: '1.5%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_smi.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.8vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='http://smisistemas.com.mx/'
                                    >
                                        <Typography sx={{fontSize: '1.7vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '22%', marginLeft: '1.5%', marginRight: '3%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_gr.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.8vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://asistenciasparatuhogar.mercadosindical.com/tienda/mercadosindical/'
                                    >
                                        <Typography sx={{fontSize: '1.7vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '22%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_proyectos.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.8vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/franquicias'
                                    >
                                        <Typography sx={{fontSize: '1.7vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isExtraLargeScreen && 
            <Box
                id={'beneficios'}
                sx={{
                    backgroundColor: '#F5F5F5',
                    // backgroundColor: 'lightsalmon',
                    width: '100%', height: '100%', backgroundSize: "cover", paddingBottom: '2vh'}}>
                <Box display={'flex-root'}>
                    {/* TARJETAS DE SERVICIOS */}
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '17%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.4vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/'
                                    >
                                        <Typography  sx={{fontSize: '1.4vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '17%', marginLeft: '3%', marginRight: '1.5%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_smi.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.4vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='http://smisistemas.com.mx/'
                                    >
                                        <Typography sx={{fontSize: '1.4vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '17%', marginLeft: '1.5%', marginRight: '3%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_gr.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.4vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://asistenciasparatuhogar.mercadosindical.com/tienda/mercadosindical/'
                                    >
                                        <Typography sx={{fontSize: '1.4vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                        {/* BOX DE UNA TARJETA */}
                        <Box sx={{width: '17%'}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <figure>
                                    <img
                                        src={src_url + 'servicio_proyectos.png'}
                                        style={{width: '11vw', borderRadius: '50%', border: '4px solid #A35709', objectFit: 'cover', background: 'white'}}
                                    />    
                                </figure>
                            </Box>
                            <Box sx={{marginTop: '-40%', paddingTop: '40%', paddingBottom: '15%', backgroundColor: 'rgba(217, 217, 217, 0.46)', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px'}}>
                                <Container>
                                    <Typography sx={{color: '#757575', textAlign: 'center', fontSize: '1.4vw'}}>
                                        Conectamos a los proveedores con los compradores
                                        sin intermediarios para garantizar los
                                        mejores precios en el mercado.
                                    </Typography>
                                </Container>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button 
                                        color='primary' 
                                        variant="contained" 
                                        sx={{borderRadius: 8, marginTop: '2vh'}}
                                        href='https://mercadosindical.com/franquicias'
                                    >
                                        <Typography sx={{fontSize: '1.4vw'}} style={{color: 'white'}}>
                                            SABER MÁS 
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </Box>
  )
}
