// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Roboto:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box_fin{
    width: 96%;
    height: 92vh;
    backgroundImage: "../img/Background_Login_Blue.svg";
    border-radius: 15px;
    margin-left: 2%;
    margin-top: 2%;
}

.text_fin{
    width: 100%;
    height: 90%;
    /* margin-left: 400px; */
    margin-top: -22%;
    overflow: scroll;
}

.titulo_fin{
    font-weight: 600;
    font-size: 36px;
    color:#339E47;
    font-family: 'Mukta Mahee', sans-serif;
}

.img_header{
    width: 100%;
    height: 30%;
}

.img_check{
    margin-top: 3%;
    height: 10%;
    width: 10%;
}

.div_img_check{
    text-align: center;
}

.img_iconos_fin{
    margin-right: 4%;
    height: 4%;
    width: 4%;
}

.img_icono_ultimo{
    height: 4%;
    width: 4%;
}`, "",{"version":3,"sources":["webpack://./src/components/nom35/cuestionario/Estilo_Fin_Cuestionario.css"],"names":[],"mappings":"AAGA;IACI,UAAU;IACV,YAAY;IACZ,mDAAmD;IACnD,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,wBAAwB;IACxB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Mukta+Mahee:wght@500&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Roboto:wght@300&display=swap');\n\n.box_fin{\n    width: 96%;\n    height: 92vh;\n    backgroundImage: \"../img/Background_Login_Blue.svg\";\n    border-radius: 15px;\n    margin-left: 2%;\n    margin-top: 2%;\n}\n\n.text_fin{\n    width: 100%;\n    height: 90%;\n    /* margin-left: 400px; */\n    margin-top: -22%;\n    overflow: scroll;\n}\n\n.titulo_fin{\n    font-weight: 600;\n    font-size: 36px;\n    color:#339E47;\n    font-family: 'Mukta Mahee', sans-serif;\n}\n\n.img_header{\n    width: 100%;\n    height: 30%;\n}\n\n.img_check{\n    margin-top: 3%;\n    height: 10%;\n    width: 10%;\n}\n\n.div_img_check{\n    text-align: center;\n}\n\n.img_iconos_fin{\n    margin-right: 4%;\n    height: 4%;\n    width: 4%;\n}\n\n.img_icono_ultimo{\n    height: 4%;\n    width: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
