import Cookies from 'universal-cookie';
//const API_URL = "https://nom35wapi.azurewebsites.net/";
// import { controllers } from "chart.js";

// const API_URL = "http://192.168.100.144:8000/";
//const API_URL = "https://jellyfish-app-tob3h.ondigitalocean.app/";
const API_URL = "https://xmex-erp-api-5fsjg.ondigitalocean.app/";
const ENDPOINT_USUARIOS_FILTER = "api/nom35/usuarios/";
const ENDPOINT_PERFILEMPLEADO_FILTER = "api/nom35/PerfilEmpleados/";
const ENDPOINT_USUARIOS = "api/nom35/usuarios/";
const ENDPOINT_USUARIOS_ID = "api/nom35/usuarios/";
const ENDPOINT_PERFILEMPLEADOS = "api/nom35/PerfilEmpleados/";
const ENDPOINT_RESPUESTAEMPLEADOS = "api/nom35/RespuestasEmpleados/";
const ENDPOINT_EMPRESAS = "api/nom35/Empresas/";
const ENDPOINT_EMPLEADOSDEEMPRESA = "api/nom35/usuarios/?EmpresaId=";
const ENDPOINT_EMAILPASS = 'api/nom35/enviar-correo/olvido-password/';
const ENDPOINT_EMAILREG = 'api/nom35/enviar-correo/registro/';
const ENDPOINT_EMAILPREREG = 'api/nom35/enviar-correo/preregistro/';
const GET_TOKEN = 'api/token/';
const REFRESH_TOKEN = 'api/token/refresh/';
const VERIFY_TOKEN = 'api/token/verify/';

// ------------------------------ TOKEN ------------------------------
// Obtener access token y refresh token
export const getToken = async () => {
    const cookies = new Cookies();
    console.log("Si entra a la funcion");
    const url = API_URL + GET_TOKEN;

    const AccessJSON = {
        username: "SindicatoXMEX",
        password: "xmexAPI",
      };
      
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(AccessJSON),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const data = await response.json();
    const accessToken = data.access;
    const refreshToken = data.refresh;
    // setTokens.AccessToken = accessToken;
    // setTokens.RefreshToken = refreshToken;

    cookies.set('accessToken', accessToken, {path: '/'});
    var cookieToken = cookies.get('accessToken');
    console.log('ESTE es el token: '+ cookieToken);
    console.log('Token de Acceso por COOKIE' + data.access);

    console.log("ESTOS SON LOS TOKENS:");
    console.log("Token de Acceso:", accessToken);
    console.log("Token de Refresco:", refreshToken);
};

//----- EMPRESA --------------------
//Obtiene datos de la empresa por empresaId
export const getEmpresaById = async (id) => {
    const url =
        API_URL + ENDPOINT_EMPRESAS + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache", 
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });

    return response.json();
};

//Crea empresa nueva
export const guardaEmpresa = async (jsonDatos) => {
    await getToken();
    const url = API_URL + ENDPOINT_EMPRESAS;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    console.log("jsonDatos: " + jsonDatos);
    console.log("url: " + url);
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(jsonDatos),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    console.log("jsonDatos: " + response);
    return response.json();
};

// Actualiza datos de la empresa
export const actualizaEmpresa = async (perfil) => {
    const url = API_URL + ENDPOINT_EMPRESAS + perfil.Id + '/';
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: "PUT",
        body: JSON.stringify(perfil),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};

//------- USUARIO ----------------
//Obtiene los usuarios
export const getUsuarios = async () => {
    const url =
        API_URL + ENDPOINT_USUARIOS + "/" ;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
        console.log("url: " + url);
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });

    return response.json();
};

// Crea usuario nuevo
export const guardaUsuario = (jsonDatos) => {
    const usuario = postUsuario(jsonDatos).then((data) => {
        if ( data.Id > 0 ){
            postPerfilUsuario(data.Id);
        }
        return data;
    });
    return usuario;
};
const postPerfilUsuario = async (usuarioId) => {
    const url = API_URL + ENDPOINT_PERFILEMPLEADOS;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const jsonDatos = {
        "UsuarioId": usuarioId,
        // "Usuario": null,
        "qId": "",
        // "AcontTrauSevero": false,
        // "AcontRecuerdo": false,
        // "AcontSueno": false,
        // "EsfuerzoSentimientos": false,
        // "EsfuerzoRecuerdos": false,
        // "EsfuerzoDificultad": false,
        // "EsfuerzoInteres": false,
        // "EsfuerzoAlejado": false,
        // "EsfuerzoExpresar": false,
        // "EsfuerzoImpresion": false,
        // "AfectacionDromir": false,
        // "AfectacionCoraje": false,
        // "AfectacionConcentrarse": false,
        // "AfectacionNervioso": false,
        // "AfectacionSobresaltado": false,
        // "RiesgoClientes": false,
        // "RiesgoJefe": false,
        // "EntornoClientes": false,
        // "EntornoJefe": false
    };
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(jsonDatos),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    return response.json();
};
const postUsuario = async (jsonDatos) => {
    const url = API_URL + ENDPOINT_USUARIOS;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(jsonDatos),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    return response.json();
};

// Actualiza datos del usuario
export const actualizaUsuario = async (perfil) => {
    const url = API_URL + ENDPOINT_USUARIOS + perfil.Id + '/';
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: "PUT",
        body: JSON.stringify(perfil),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};

//Obtiene datos del usuario por email y pass
export const getUserByEmailPass = async (email, pass) => {
    await getToken();
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    console.log('accessToken: ' + cookieToken);
    const url = API_URL + ENDPOINT_USUARIOS_FILTER + "?email=" + email + "&pass=" + pass;
    const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    return response.json();
};

//Obtiene datos del usuario por usuarioId
export const getUserById = async (id) => {
    const url =
        API_URL + ENDPOINT_USUARIOS_ID + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });

    return response.json();
};

//Obtiene datos del perfil del empleado por usuarioId
export const getPerfilEmpleadoByUsuarioId = async (id) => {
    await getToken();
    const url =
        API_URL + ENDPOINT_PERFILEMPLEADO_FILTER + "?usuarioId=" + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });

    return response.json();
};

//Actualia el perfil del empleado
export const putPerfilEmpleado = async (perfil) => {
    const url = API_URL + ENDPOINT_PERFILEMPLEADOS + perfil.Id + '/';
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: "PUT",
        body: JSON.stringify(perfil),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};

// Obtiene la lista de empleados por medio del idEmpresa
export const getEmpleadosByEmpresaId = async (id) => {
    const url =
        API_URL + ENDPOINT_EMPLEADOSDEEMPRESA + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');

    const response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type" : "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    return response.json();
};

// Eliminar empleado
export const deleteEmpleado = async (id, idPE) => {
    // Elimina el empleado
    const urlEE = API_URL + ENDPOINT_USUARIOS_ID + id;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch (urlEE, {
        method: "DELETE",
        cache: "no-cache",
        headers: 
        {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
    // Elimina el perfil del empleado
    const urlEPE = API_URL + ENDPOINT_PERFILEMPLEADOS + idPE;
    await fetch (urlEPE, {
        method: "DELETE",
        cache: "no-cache",
        headers:
        {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};

//------- RESPUESTAS ------
//Guarda respuesta de encuesta pregunta por pregunta
export const guardaRespuestas = (sesion, perfil, respuestas) => {
    var respuesta = {
        UsuarioId: perfil.usuarioId,
        ReferenciaId: sesion.referenciaId,
        SeccionId: sesion.seccionId,
        TipoPreguntaId: sesion.tipoPreguntaId,
        NumPregunta: 0,
        Respuesta: 0,
        EmpresaId: perfil.EmpresaId
    };

    putPerfilEmpleado(perfil);
    //putPerfilEmpleado(perfil);``

    respuestas.respuestas.forEach(resp => {
        respuesta.NumPregunta = resp.pregunta;
        respuesta.Respuesta = resp.respuesta;
        postRespuestaEmpleado(respuesta);
        console.log('UsuarioId: ' + respuesta.UsuarioId);
        console.log('ReferenciaId: ' + respuesta.ReferenciaId);
        console.log('SeccionId: ' + respuesta.SeccionId);
        console.log('TipoPreguntaId: ' + respuesta.TipoPreguntaId);
        console.log('NumPregunta: ' + respuesta.NumPregunta);
        console.log('Respuesta: ' + respuesta.Respuesta);
        console.log('EmpresaId: ' + respuesta.EmpresaId);
    });
};

const postRespuestaEmpleado = async (respuesta) => {
    const url = API_URL + ENDPOINT_RESPUESTAEMPLEADOS;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    console.log('url endpoint: ' + url);
    await fetch(url, {
        method: "POST",
        body: JSON.stringify(respuesta),
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};

//Guarda respuesta de encuesta 101
export const guardaRespuestas101 = (sesion, perfil, respuestas) => {
    console.log("Id de la emprsa en el services: "+perfil.EmpresaId);
    var respuesta = {
        UsuarioId: perfil.UsuarioId,
        ReferenciaId: sesion.referenciaId,
        SeccionId: sesion.seccionId,
        TipoPreguntaId: sesion.tipoPreguntaId,
        NumPregunta: 0,
        Respuesta: 0,
        EmpresaId: perfil.EmpresaId
    };
    putPerfilEmpleado(perfil);
    respuestas.respuestas.forEach(resp => {
        respuesta.NumPregunta = resp.Pregunta;
        respuesta.Respuesta = resp.Respuesta;
        respuesta.ReferenciaId = resp.ReferenciaId;
        respuesta.SeccionId = resp.SeccionId;
        postRespuestaEmpleado(respuesta);
        console.log('UsuarioId: ' + respuesta.UsuarioId);
        console.log('ReferenciaId: ' + sesion.referenciaId);
        console.log('SeccionId: ' + sesion.seccionId);
        console.log('TipoPreguntaId: ' + respuesta.TipoPreguntaId);
        console.log('NumPregunta: ' + respuesta.NumPregunta);
        console.log('Respuesta: ' + respuesta.Respuesta);
        console.log('EmpresaId: ' + respuesta.EmpresaId);
    });
};

// Email automatico
export const emailAutomaticoPass = async (jsonDatos) => {
    var url = API_URL + ENDPOINT_EMAILPASS;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};
export const emailAutomaticoPreReg = async (jsonDatos) => {
    var url = API_URL + ENDPOINT_EMAILPREREG;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};
export const emailAutomaticoReg = async (jsonDatos) => {
    var url = API_URL + ENDPOINT_EMAILREG;
    const cookies = new Cookies();
    const cookieToken = cookies.get('accessToken');
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(jsonDatos),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + cookieToken
        }
    });
};
















//Guarda respuesta de encuesta 101
// export const guardaRespuestas101 = (sesion, perfil, respuestas) => {
//     var respuesta = {
//         UsuarioId: perfil.UsuarioId,
//         SeccionId: sesion.SeccionId,
//         TipoPreguntaId: sesion.TipoPreguntaId,
//         // NumPregunta: 0,
//         NumPregunta: respuestas.NumPregunta,
//         // Respuesta: 0,
//         Respuesta: respuestas.Respuesta,
//         ReferenciaId: sesion.ReferenciaId
        
//         // UsuarioId: 403,
//         // SeccionId: 1,
//         // TipoPreguntaId: 1,
//         // NumPregunta: 1,
//         // Respuesta: 1,
//         // ReferenciaId: 1
//     };
//     console.log('respuesta: ' + respuesta);
//     putPerfilEmpleado(perfil);
//     respuestas.respuesta.forEach(resp => {
//         // respuesta.numPregunta = resp.NumPregunta;
//         respuesta.UsuarioId = resp.UsuarioId;
//         respuesta.SeccionId = resp.SeccionId;
//         respuesta.TipoPreguntaId = resp.TipoPreguntaId;
//         respuesta.NumPregunta = resp.NumPregunta;
//         respuesta.Respuesta = resp.Respuesta;
//         respuesta.ReferenciaId = resp.ReferenciaId;
//         postRespuestaEmpleado(respuesta);
//     });
// };
