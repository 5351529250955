import React from 'react'
import { useState } from 'react';
import './PublicPage.css'
import { Link } from "react-router-dom"
import  ScrollToTop  from  "react-scroll-to-top" ;
import Popup from "reactjs-popup";

// FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const PublicPage = () => {

/*export default function Carousel(props: any) {
    const images =["1.png","2.png"];
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(images[0]);
    
    const selectNewImage = (index: number, images: string[], next = true) => {
        const condition = next ? selectedIndex <images.length : selectedIndex > 0;
        const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : condition ? selectedIndex - 1 : images.length - 1;
        setSelectedImage (images[nextIndex]);
        setSelectedIndex(nextIndex);
    };

    const previous = () => {
        selectNewImage(selectedIndex, images, false);
    };

    const next = () => {
        selectNewImage(selectedIndex, images);
    };
*/

window.location.hash="no-back-button";
window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
window.onhashchange=function()
{
  window.location.hash="no-back-button";
}

const contentStyle = {
    maxWidth: "600px",
    width: "90%"
  };
    return (
        <div 
        className='back1'
            style=
                {
                    {
                        backgroundImage: "url(../img/pagina_publica/sin_imagen.png)", 
                        width: "100%",
                        marginTop:"-5%", 
                        // height: "100%",
                        height: "130vh", 
                        // backgroundRepeat: "no-repeat", 
                        backgroundSize: "cover",
                        // marginTop: "-100px",
                        paddingTop: "197px",
                        
                        // marginBottom: "-150px"
                    }
                } 
        >
             <ScrollToTop smooth top="20"/>
            <div
                className='div_top'
            >
                <img
                    className='img_logoPB'
                    src='../img/pagina_publica/LOGO-SMI-CALADO 1.png'
                />
                <Link to="/generales_administrador">
                    {/* <button className='btn_IniciarSesionPublicPage'>Ver mas</button> */}
                    <p 
                    className='Registrarse'
                    >
                        Registrarse 
                    </p>
                </Link>
                <Link to="/loginNom035">
                    <p className='lbl_IniciarSesion'>
                        <FontAwesomeIcon className='icon_User' icon={faUser}/>  
                            INICIAR SESIÓN
                    </p>
                </Link>
            </div>
            
    <div className='div_scroll_pb'>      
        <div
        
        >
            <div
                className='div_txt_pb'
            >
                {/* <button className='btn_IniciarSesionPublicPage'>Ir a regristro</button> */}
                <p className='txt_blanco'>
                    Cumple con la
                </p>
                <p className='txt_blue'>
                    NOM 035
                </p>
                <p className='txt_body'>
                    Su objetivo es establecer los elementos para identificar, analizar y<br></br>
                    prevenir los factores de riesgo psicosocial, así como para promover<br></br> 
                    un entorno organizacional favorable en los centros de trabajo. 
                </p>
               
                <img
                    src='../img/pagina_publica/Design.png'
                    className='img_laptop'
                />
                <Link to="/generales_administrador">
                    {/* <button className='btn_IniciarSesionPublicPage'>Ver mas</button> */}
                    <button className='btn_IniciarSesionPublicPage'>Quiero recibir información</button>
                </Link>
            </div>
            <div>
                
               
            </div>
            <div
            style=
                {
                    {
                        backgroundImage: "url(../img/pagina_publica/back_white.png)", 
                        width: "100%", 
                        // height: "100%",
                        height: "100vh", 
                        // backgroundRepeat: "no-repeat", 
                        backgroundSize: "cover",
                        // marginTop: "-100px",
                        // paddingTop: "100px",
                        // marginBottom: "-150px"
                        
                    }
                } 
        >
            <img
                    src='../img/pagina_publica/SS_admin.png'
                    className='img_ss'
            />
            <p className='txt_ss'>
                <p className='txt_ss1'>
                    El mejor y mas completo
                </p>
                <p className='txt_ss2'>
                    software para certificación 
                </p>
                <p className='txt_ss3'>
                    de la NOM 035
                </p>
            </p>
        </div>
        <div
        style=
        {
            {
                backgroundImage: "url(../img/pagina_publica/sin_imagen.png)", 
                width: "100%", 
                // height: "100%",
                height: "100vh", 
                // backgroundRepeat: "no-repeat", 
                backgroundSize: "cover",
                // marginTop: "-100px",
                // paddingTop: "100px",
                // marginBottom: "-150px"
                
            }
        } 
        >
            <img
                    src='../img/pagina_publica/white.png'
                    className='img_white'
                />
                <p className='text_costos'>
                    OLVIDATE DE LAS MULTAS DE MÁS DE
                    <p>
                        $500,000.00 MXN Y CERTIFICA TU EMPRESA
                    </p>
                    <p className='text_costosMXN'>
                        $5,000 MXN
                    </p>
                </p>
                <Link to="/generales_administrador">
                    {/* <button className='btn_IniciarSesionPublicPage'>Ver mas</button> */}
                    <button 
                        className='btn_RegistrarsePb'
                    >
                        Registrarse
                    </button>
                </Link>
                <p
                className='text_costos_pb'
                >
                    *PRECIO SUJETO A CAMBIOS
                </p>
        </div>
        <div
         style=
         {
             {
                 backgroundImage: "url(../img/pagina_publica/back_white2.png)", 
                 width: "100%", 
                 // height: "100%",
                 height: "100vh", 
                 // backgroundRepeat: "no-repeat", 
                 backgroundSize: "cover",
                 // marginTop: "-100px",
                 // paddingTop: "100px",
                 // marginBottom: "-150px"
                 
             }
         } 
        >
{/*<img
                    src='../img/pagina_publica/SMISistemas.gif'
                    className='img_ss4'
            />
        */}
            <section>
                <img
                    src='../img/pagina_publica/contactopb.png'
                />
                <img
                    src='../img/pagina_publica/iniciopb.png'
                />
                <img
                    src='../img/pagina_publica/registropb.png'
                />
            </section>         
        </div>
        <div
        style=
        {
            {
                backgroundImage: "url(../img/pagina_publica/sin_imagen.png)", 
                width: "100%", 
                // height: "100%",
                height: "100vh", 
                // backgroundRepeat: "no-repeat", 
                backgroundSize: "cover",
                // marginTop: "-100px",
                // paddingTop: "100px",
                // marginBottom: "-150px"
                
            }
        }
        >
            <img
                    src='../img/pagina_publica/ss_admin1.png'
                    className='img_ss3'
            />
        </div>
        <div
        className='div_contacto'
        >
        <img
                    className='img_logoPB'
                    src='../img/pagina_publica/LOGO-SMI-CALADO 1.png'
                />
                <div className='social_media'>
                    <link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'/>
                    <span>Redes</span>
                    <nav>
                        <a href='#'>
                            <i class="fa fa-facebook" 
                               arial-hidden="true"
                              
                            >
                            </i>
                        </a>
                        <a href='#'>
                            <i class="fa fa-twitter" 
                               arial-hidden="true">
                            </i>
                        </a>
                        <a href='#'>
                            <i class="fa fa-linkedin" 
                               arial-hidden="true">
                            </i>
                        </a>
                        <a href='#'>
                            <i class="fa fa-instagram" 
                               arial-hidden="true">
                            </i>
                        </a>

                    </nav>
                </div>
                <script src='scrip.js'></script>
        <div
        className='txt_contacto'
        >
            <p>
                <p className='txt_1c'>
                    Contáctanos
                </p>
                <br/>
                <a 
                    href='https://wa.me/525534667544' 
                    target="_blank"
                    className='wame'
                >
                    Tel. +52 5534667544
                    <br/>
                </a>
                <a 
                    href="mailto:test@gmail.com"
                    className='correo_cont'
                >
                    <p/>contacto@smisistemas.com.mx
                </a> 

                {/* BTN TEMPORAL PARA LA NOMINA */}
                <button className="btn_Ingresar" type="button">
                    Nómina
                </button>


               <p/> 
               <br/>
            </p>
        </div>

        {/*<div>
            <p
            className='txt_redes'
            >
                Siguenos
                <img
                    className='img_redesSociales'
                    src='../img/pagina_publica/Facebook.png'
                />
                <img
                    className='img_redesSociales'
                    src='../img/pagina_publica/Twitter.png'
                />
                <img
                    className='img_redesSociales'
                    src='../img/pagina_publica/in.png'
                />
                <img
                    className='img_redesSociales'
                    src='../img/pagina_publica/intagram.png'
                />
            </p>
        </div>
          
    */}
    <div
        className='txt_legal'
        >
    
            <td>
                
                <Popup className="pupUp_p"
      trigger={<button 
        className='pupUp_pb'
        
      >
        <p/> POLITICA DE COOCKIES 
       </button>}
      modal
      contentStyle={contentStyle}
    >
                    {
                      close => 
                      (            
                        <div className="popUp_editarEmpleados">
                          <p className='popUp_txt_editarEmpleado'>
                          POLITICA DE COOCKIES
                          
                          </p>
                          
                        </div>
                      )
                    }
                  </Popup>
            
            </td>
            
            <p
            >
                
                <Link to="/politica_priv">
                    <p className='lbl_IniciarSesion'>
                    POLITICA DE PRIVACIDAD
                            
                    </p>
                </Link>
            </p>
    
            <p>
            <Link to="/aviso_legal">
                    <p className='pb_av'>
                    AVISO LEGAL
                            
                    </p>
                </Link>
            </p>
        </div>  
        </div>

    </div>
    </div> 
        </div>
    )

}

