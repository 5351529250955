import React, { useState } from "react";
import { Header } from "../../header/Header";
import "./MBI.css";
import { PreguntaMBI } from "../elementos_cuestionarios/PreguntaMBI";
import { getUrlParameter } from "../../../../utils/jstools";
import { Button_CuestionarioSiguente } from "../elementos_cuestionarios/Button_CuestionarioSiguiente";

export const MBI = (props) => {
  
  const sigPag = "fin";  
  const pPerfId = getUrlParameter("perfId");
  const pUserId = getUrlParameter("userId");
  const pNombre = getUrlParameter("nombre");
  const pIdEmpresa = getUrlParameter('empresaId');
  
  const cantidadTotalPreguntas= 22;
  var numPreguntas= 0;

  const [deseableState, setDeseableState] = useState(true);

  const [perfil, setPerfil] = useState({
    perfId: parseInt(getUrlParameter("perfId")),  //Dato de parametro perfId
    userId: parseInt(getUrlParameter("userId")),  //Dato de parametro userId
    nombre: getUrlParameter("nombre")             //Dato estático
  });

  window.location.hash="no-back-button";
  window.location.hash="Again-No-back-button";    //esta linea es necesaria para chrome
  window.onhashchange=function()
  {
    window.location.hash="no-back-button";
  }

  const [sessionJson, setSessionJson] = useState({
    referenciaId: 4,    //Dato estático
    seccionId: 27,      //Dato estático
    tipoPreguntaId: 3,  //Dato estático
    preguntaInicial: 1, //Dato estático
    nombre: perfil.nombre,
    link: ""
  });
  
  const [perfilJson, setPerfilJson] = useState({
    Id: 0,
    usuarioId: 0,
    qId: 0,
    EmpresaId: 0
  });

  React.useEffect(() => {
    setSessionJson((prevState) => {
      return {
        ...prevState,
        link:
          "/cuestionario_" + 
          sigPag +
          "?userId=" +
          pUserId +
          "&perfId=" +
          pPerfId +
          "&empresaId=" +
          pIdEmpresa +
          "&nombre=" +
          pNombre
      };
    });

    setPerfilJson(() => {
      return {
        Id: pPerfId,
        usuarioId: pUserId,
        qId: sigPag,
        EmpresaId: pIdEmpresa
      };
    });

  }, []);

  const [respuestasJson, setRespuestasJson] = useState({
    respuestas: [
      { pregunta: 1, respuesta: 0 },
      { pregunta: 2, respuesta: 0 },
      { pregunta: 3, respuesta: 0 },
      { pregunta: 4, respuesta: 0 },
      { pregunta: 5, respuesta: 0 },
      { pregunta: 6, respuesta: 0 },
      { pregunta: 7, respuesta: 0 },
      { pregunta: 8, respuesta: 0 },
      { pregunta: 9, respuesta: 0 },
      { pregunta: 10, respuesta: 0 },
      { pregunta: 11, respuesta: 0 },
      { pregunta: 12, respuesta: 0 },
      { pregunta: 13, respuesta: 0 },
      { pregunta: 14, respuesta: 0 },
      { pregunta: 15, respuesta: 0 },
      { pregunta: 16, respuesta: 0 },
      { pregunta: 17, respuesta: 0 },
      { pregunta: 18, respuesta: 0 },
      { pregunta: 19, respuesta: 0 },
      { pregunta: 20, respuesta: 0 },
      { pregunta: 21, respuesta: 0 },
      { pregunta: 22, respuesta: 0 },
    ],
  });

  const updatePreg = (preg, resp, condPreguntaActiva) => {
    console.log(preg, resp);
    if(condPreguntaActiva==1){
      numPreguntas++;
    }
    if(numPreguntas==cantidadTotalPreguntas){
      setDeseableState(false);
    }
    const respuestas = { respuestasJson };
    respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
      pregunta: preg,
      respuesta: resp,
    };
    setRespuestasJson(respuestas.respuestasJson);
    console.log({ respuestasJson });
  };

  return (
    <div
      style={{
        backgroundImage: "url(../img/Background_Login_Blue.svg)",
        width: "100%",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "-100px",
        paddingTop: "100px",
      }}
    >
      <Header nombre={perfil.nombre} />

      <div className="cuestionario_div_MBI">
        <div className="div_svg_menu_MBI">
          <img
            src="./img/menu_cuestionarios/menu_cMBI.svg"
            className="img_svg_MBI"
          />
        </div>
        <div className="text_cuestionario_MBI">
          <img
            src="./img/barra_progreso/mbi/barra-progreso-mbi.svg"
            className="img_progreso_MBI"
          />
          <p className="titulo_cuestionario_MBI">
            <img src="./img/barra_progreso/iconos/burnout_icono_cuestionario.svg" />{" "}
            MASLACH BURNOUT INVENTORY
          </p>
          <p className="txt_MBI">
            Este cuestionario Maslach Burnout Inventory (MBI) está constituido
            por 22 ítems en forma de afirmaciones, sobre los sentimientos y
            actitudes del profesional y su función es medir el desgaste
            profesional.
          </p>
          <p className="txt_MBI">
            Seleccione el número que crea oportuno sobre la frecuencia con que
            siente los enunciados:
          </p>

          <div className="escala_respuestas_BMI">
            <p className="text_escalas">
              0= Nunca
            </p>
            <p className="text_escalas">
              1= Pocas veces al año
            </p>
            <p className="text_escalas">
              2= Una vez al mes o menos
            </p>
            <p className="text_escalas">
              3= Unas pocas veces al mes
            </p>
            <p className="text_escalas">
              4= Una vez a la semana
            </p>
            <p className="text_escalas">
              5= Unas pocas veces a la semana
            </p>
            <p className="text_escalas">
              6= Todos los días
            </p>
            
          </div>
          <div className="body_maslach">
            <div className="N_MBI">
              <table className="table_MBI">
                <tbody>
                  <tr>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        0
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        1
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        2
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        3
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        4
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        5
                      </p>
                    </td>
                    <td className="td_NumPreg_MBI">
                      <p className="box_titulos_escalas_MBI">
                        6
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table_MBI_2">
            <PreguntaMBI
              numPreg="1"
              texto="Me siento emocionalmente agotado/a por mi trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="2"
              texto="Me siento cansado al final de la jornada de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="3"
              texto="Cuando me levanto por la mañana y me enfrento a otra jornada de trabajo me siento
                    fatigado."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="4"
              texto="Tengo facilidad para comprender como se sienten mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="5"
              texto="Creo que estoy tratando a algunos compañeros de trabajo como si fueran objetos impersonales."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="6"
              texto="Siento que trabajar todo el día con mis compañeros de trabajo supone un gran esfuerzo y me cansa."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="7"
              texto="Creo que trato con mucha eficacia los problemas de mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="8"
              texto="Siento que mi trabajo me está desgastando. Me siento quemado por mi trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="9"
              texto="Creo que con mi trabajo estoy influyendo positivamente en la vida de mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="10"
              texto="Me he vuelto más insensible con la gente desde que ejerzo mi profesión."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="11"
              texto="Pienso que este trabajo me está endureciendo emocionalmente."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="12"
              texto="Me siento con mucha energía en mi trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="13"
              texto="Me siento frustrado/a en mi trabajo"
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="14"
              texto="Creo que trabajo demasiado."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="15"
              texto="No me preocupa realmente lo que les ocurra a algunos de mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="16"
              texto="Trabajar directamente con compañeros de trabajo que me produce estrés."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="17"
              texto="Siento que puedo crear con facilidad un clima agradable con mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="18"
              texto="Me siento motivado después de trabajar en contacto con mis compañeros de trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="19"
              texto="Creo que consigo muchas cosas valiosas en este trabajo."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="20"
              texto="Me siento acabado en mi trabajo, al límite de mis posibilidades."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="21"
              texto="En mi trabajo trato los problemas emocionalmente con mucha calma."
              update={updatePreg}
            />
            <PreguntaMBI
              numPreg="22"
              texto="Creo que los compañeros de trabajo me culpan de algunos de sus problemas."
              update={updatePreg}
            />
            </div>
          </div>

          <Button_CuestionarioSiguente
            className="btn_siguiente_maslach"
            sesion={sessionJson}
            perfil={perfilJson}
            respuestas={respuestasJson}
            disabled={deseableState}
            upDate={"guardaRespuestas"}
            condicionPreg='200Restantes'
          />

        </div>
      </div>
    </div>
  );
};
