import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Registro_empleados.css';

export const Registro_empleados = () => {
  
    // const [name, setName] = useState("");
    // const [selectedFile, setSelectedFile] = useState(null);

  return (
    <div
        style=
            {
                {
                    // backgroundImage: "url(../img/Background_Login2.svg)", 
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    height: "100vh", 
                    // backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover",
                    marginTop: "-1.5%",
                    paddingTop: "1.5%",
                    // marginBottom: "-150px"
                }
            }
    >
    <div className='box_RegistroEmpleados'>
        <img src="../img/LogoSMI_Login.svg" className='img_LogoSMI_RegistroEmpresa'/>
        <p className='txt_Principal_RegistroEmpresa'>REGISTRO DE EMPLEADOS - ALTA POR EXCEL</p>
        <p className='txt_Instrucciones_RegistroEmpresa'></p>
        <div className='div_Horizontal_RegistroEmpleados'>

                <div className='Texto_Registro'>
                    <p>
                    Estimado Administrador, en este apartado descargarás nuestro formato de Registro de empleados, así nuestro sistema podrá hacer la selección aleatoria de tus colaboradores a encuestar.
                    </p>
                    <p>
                    Este proceso es muy simple, primero descarga en el botón de descargar, una vez descargado , simplemente vacía la información correspondiente en las columnas indicadas. 
                    </p>
                    <p>
                    Es de suma importancia no realizar ninguna modificación con el acomodo de datos, una vez completado guarda el archivo y cargalo con el botón “seleccionar archivo”. Y eso es todo, nosotros realizamos el resto.
                    </p>
                </div>    

                <div className='input_RegistroEmpleados'>
                    {/* <div className='input1_RegistroEmpresa'> */}
                        <a href="http://189.216.10.93/descargas/xmexico/nom35_empleados.xls">
                            <button className='btn_DescargarFormato_RegistroEmpleados'>DESCARGAR FORMATO
                        </button>
                        </a>
                </div>
                <div className='input_RegistroEmpleados'>
                    {/* <div className='input2_RegistroEmpresa'> */}
                        <label className='btn_ImportarFormato_RegistroEmpleados'>
                            <input
                                type='file'
                                style=  
                                    {
                                        {
                                            fontSize: "18px",

                                        }
                                    }
                                // value={selectedFile}
                                // onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                        </label>
                </div>
        </div>  
        
       

        <Link to="/dashboard_admin">
            <button
                className='btn_Continuar_RegistroEmpleado'
                type='submit'
                // disabled={!validateForm()}
            >
                CONTINUAR
            </button>
        </Link>

    </div>
    </div>
    );
};
