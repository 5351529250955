import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";

import { guardaEmpresa } from '../../../../utils/services';
import { Button_siguente_admin } from '../../cuestionario/elementos_cuestionarios/Button_siguente_admin';
import './Registro_empresa.css';

export const Registro_empresa = () => {

    const id = {
        idd: "07"
    }

    const [regEmpresaInput, setRegEmpresaInput] = useState({
        Name:"",
        NickName:"",
        RasonSocial:"",
        Rfc:"",
        Riesgo:"Clase I",
        RegPatronal:"",
        Calle:"",
        NumExt:"",
        Cp:"",
        Colonia:"",
        Localidad:"",
        Municipio:"",
        GiroId: 1,
        Estado:"Aguascalientes",
        Referencia: "",
        // giro:""
    });

    const nomComerRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Name: event.target.value};
        });
    };
    const nicknameRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, NickName: event.target.value};
        });
    };
    const razonSocialRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, RasonSocial: event.target.value};
        });
    };
    const rfcRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Rfc: event.target.value};
        });
    };
    const gradoRiesgoRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Riesgo: event.target.value};
        });
    };
    const numRegPatronalRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, RegPatronal: event.target.value};
        });
    };
    const calleRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Calle: event.target.value};
        });
    };
    
    const numExtRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState,NumExt: event.target.value};
        });
    };

    const cpRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Cp: event.target.value};
        });
    };
    const coloniaRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Colonia: event.target.value};
        });
    };
    const localidadRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Localidad: event.target.value};
        });
    };
    const municipioRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Municipio: event.target.value};
        });
    };
    const giroEmpresaRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, GiroId: parseInt(event.target.value)};
        });
    };
    // const giroEmpEmpresaRegEmpresaChangeHandler =(event) => {
    //     setRegEmpresaInput((prevState) => {
    //         return { ...prevState, giro: event.target.value};
    //     });
    // };
    const estadoRegEmpresaChangeHandler =(event) => {
        setRegEmpresaInput((prevState) => {
            return { ...prevState, Estado: event.target.value};
        });
    };
    
    var dis = true;
    if (
       (regEmpresaInput.Name != "")  & (regEmpresaInput.NickName != "") &
       (regEmpresaInput.RasonSocial != "") & (regEmpresaInput.Rfc != "") &
       (regEmpresaInput.Riesgo != "") & (regEmpresaInput.RegPatronal != "") &
       (regEmpresaInput.Calle != "") & (regEmpresaInput.NumExt != "") &
       (regEmpresaInput.Cp != "") & (regEmpresaInput.Colonia != "") &
       (regEmpresaInput.Localidad != "") & (regEmpresaInput.Municipio != "") 
    //    & (regEmpresaInput.giroId != "")
    //    & (regEmpresaInput.giro != "")
       //   (regEmpresaInput.estado != "") & (regEmpresaInput.referencia != "") 
    ){
      dis = false;
    }
    else {
      dis = true;
    }

  return (
    <div
        style=
            {
                {
                    // backgroundImage: "url(../img/Background_Login2.svg)", 
                    backgroundImage: "url(../img/Background_Login_Blue.svg)", 
                    width: "100%", 
                    height: "100vh", 
                    // backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover",
                    marginTop: "-.4%",
                    paddingTop: ".4%",
                    // marginBottom: "-150px"
                }
            }
    >
        <Link to="/">
        <img
                    className='img_logoPB'
                    src='../img/pagina_publica/LOGO-SMI-CALADO 1.png'
                />
                </Link>
        
    <div className='box_RegistroEmpresa'>
            
            <p className='txt_Principal_RegistroEmpresa'>REGISTRO DE EMPRESA</p>
            <p className='txt_Instrucciones_RegistroEmpresa'>Estimado USUARIO, en este apartado registramos los datos para emitir el  reporte de certificado NOM 035 de tu empresa, por favor llena cuidadosamente cada campo. Si hay información que no tienes a la mano, podrás completarla o modificarla en tu panel de administración, hasta antes de iniciar los cuestionarios.</p>
       <div className='box_RegistroEmpresa1'>
        <div className='div_Horizontal_RegistroEmpresa'>    
            <div className=''>
                <div className='input_RegistroEmpresa'>
                    <div className='input1_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Nombre comercial</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Nombre comercial'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s,0-9]+"
                            maxLength="100"
                            value={regEmpresaInput.name}
                            onChange={nomComerRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='input2_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Nickname de empresa</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Nickname de empresa'
                            pattern='[a-zA-ZñÑáéíóúÁÉÍÓÚ\s,0-9]+'
                            maxLength="100"
                            value={regEmpresaInput.nickName}
                            onChange={nicknameRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='inpu3_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Razón social</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Razón social'
                            // pattern='[a-zA-ZñÑáéíóúÁÉÍÓÚ\s,0-9]+'
                            maxLength="100"
                            value={regEmpresaInput.rasonSocial}
                            onChange={razonSocialRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>  
        <div className='div_Horizontal_RegistroEmpresa'>    
            <div className=''>
                <div className='input_RegistroEmpresa'>
                    <div className='input1_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>RFC</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='RFC'
                            maxLength="13"
                            value={regEmpresaInput.rfc}
                            onChange={rfcRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='input2_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Grado de riesgo (IMSS)</p>
                        <select 
                            id="id_GiroEmpresa_RegistroEmpresa" 
                            name="name_GiroEmpresa_RegistroEmpresa" 
                            className='input_RegistroEmpresa_CSS'

                            value={regEmpresaInput.riesgo}
                            onChange={gradoRiesgoRegEmpresaChangeHandler}
                        >
                            <option 
                                // value="1"
                            >
                                Clase I
                            </option>
                            <option 
                                // value="2"
                            >
                                Clase II
                            </option>
                            <option 
                                // value="3"
                            >
                                Clase III
                            </option>
                            <option 
                                // value="4"
                            >
                                Clase IV
                            </option>
                            <option 
                                // value="5"
                            >
                                Clase V
                            </option>
                        </select>
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='inpu3_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Giro de la empresa</p>
                        <select 
                            id="id_GiroEmpresa_RegistroEmpresa" 
                            name="name_GiroEmpresa_RegistroEmpresa" 
                            className='input_RegistroEmpresa_CSS'

                            value={regEmpresaInput.giroId}
                            onChange={giroEmpresaRegEmpresaChangeHandler}
                            // value={regEmpresaInput.giro}
                            // onChange={giroEmpEmpresaRegEmpresaChangeHandler}
                        >
                            <option 
                                value="1"
                            >
                                Gobierno
                            </option>
                            <option 
                                value="2"
                            >
                                Productos
                            </option>
                            <option 
                                value="3"
                            >
                                Servicios
                            </option>
                            <option 
                                value="4"
                            >
                                Construcción
                            </option>
                        </select>
                    </div>
                </div>
               
            </div>
        </div>
        <div className='div_Horizontal_RegistroEmpresa'>    
            <div className=''>
                <div className='input_RegistroEmpresa'>
                    <div className='input1_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Número de regristro patronal</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Número de regristro patronal'
                            maxLength="100"
                            value={regEmpresaInput.regPatronal}
                            onChange={numRegPatronalRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='input2_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Calle</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Calle'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s,0-9]+"
                            maxLength="100"
                            value={regEmpresaInput.calle}
                            onChange={calleRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='inpu3_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Número exterior</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            pattern="[a-zA-Z,0-9\s]+"
                            placeholder='Número exterior'
                            maxLength="7"
                            value={regEmpresaInput.numExt}
                            onChange={numExtRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='div_Horizontal_RegistroEmpresa'>    
            <div className=''>
                <div className='input_RegistroEmpresa'>
                    <div className='input1_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Número interior</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            pattern="[a-zA-Z,0-9\s]+"
                            maxLength="7"
                            placeholder='Número interior'
                            // value={regEmpresaInput.numIntRegEmpresaChangeHandler}
                            // onChange={numIntRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='input2_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Código postal</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            pattern="[0-9]+"
                            maxLength="5"
                            placeholder='Código postal'
                            value={regEmpresaInput.cp}
                            onChange={cpRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='inpu3_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Colonia</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Colonia'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            maxLength="35"
                            value={regEmpresaInput.colonia}
                            onChange={coloniaRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='div_Horizontal_RegistroEmpresa'>    
            <div className=''>
                <div className='input_RegistroEmpresa'>
                    <div className='input1_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Localidad</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Localidad'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            maxLength="35"
                            value={regEmpresaInput.localidad}
                            onChange={localidadRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='input2_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Municipio</p>
                        <input 
                            className='input_RegistroEmpresa_CSS' 
                            type="text" 
                            titlle="" 
                            placeholder='Municipio'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            maxLength="35"
                            value={regEmpresaInput.municipio}
                            onChange={municipioRegEmpresaChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroEmpresa'>
                    <div className='inpu3_RegistroEmpresa'>
                        <p className='txt_InputTitle_RegistroEmpresa'>Estado</p>
                        <select 
                            id="id_Estado_RegistroEmpresa" 
                            name="name_Estado_RegistroEmpresa" 
                            className='input_RegistroEmpresa_CSS'

                            value={regEmpresaInput.estado}
                            onChange={estadoRegEmpresaChangeHandler}
                        >
                            <option>Aguascalientes</option>
                            <option>Baja California</option>
                            <option>Baja California Sur</option>
                            <option>Campeche</option>
                            <option>Chiapas</option>
                            <option>Chihuahua</option>
                            <option>Ciudad de México</option>
                            <option>Coahuila</option>
                            <option>Colima</option>
                            <option>Durango</option>
                            <option>México</option>
                            <option>Guanajuato</option>
                            <option>Guerrero</option>
                            <option>Hidalgo</option>
                            <option>Jalisco</option>
                            <option>Michoacán</option>
                            <option>Morelos</option>
                            <option>Nayarit</option>
                            <option>Nuevo León</option>
                            <option>Oaxaca</option>
                            <option>Puebla</option>
                            <option>Querétaro</option>
                            <option>Quintana Roo</option>
                            <option>San Luis Potosí</option>
                            <option>Sinaloa</option>
                            <option>Sonora</option>
                            <option>Tabasco</option>
                            <option>Tamaulipas</option>
                            <option>Tlaxcala</option>
                            <option>Veracruz</option>
                            <option>Yucatán</option>
                            <option>Zacatecas</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Button_siguente_admin
            cookieIdEmpresa="si"
            json={regEmpresaInput}
            update={guardaEmpresa}
            disabled={dis}
            windowAlert="empresa"
            link="/registro_administrador/"
        />

    </div>

    </div>
    );
};
