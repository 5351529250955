import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { getEmpresaById, getUsuarios } from '../../../../utils/services';
import { Header } from '../../header/Header';
import { Menu_lateral_analista } from '../menu_lateral_analista/Menu_lateral_analista';
import './Dashboard_analista.css';
import { Tarjeta_empresa } from './Tarjeta_empresa';

export const Dashboard_analista = () => {

  // instancia de cookies
  const cookies = new Cookies();

  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  // Lista de las empresas a mapear
  const [ listaEmpresasRegistradas, setListaEmpresasRegistradas ] = useState ([]);

  useEffect(() => {
    
    // Obtener los datos de las empresas registradas
    var empresasArray = [];
    getUsuarios().then((data) => {
      const array = data;
      array.forEach((admin) => {

        if ( admin.rolId == 1 ){
          getEmpresaById(admin.empresaId).then((data) => {    
            const empresaReg = {
              rasonSocial: data.rasonSocial,
              email: admin.email,
              nombre: admin.nombre
            };

        empresasArray.push(empresaReg);
        setListaEmpresasRegistradas(oldArray => [...oldArray, empresaReg]);

      });
      }
    });
  });
  },[]);  

  return (
    <div>
        <Header
          nombre={cookies.get('nameUser')}
          expirationDate={expirationDate}
        />
        <Menu_lateral_analista
          idUsuario={cookies.get('idUser')}
        />
        <div className='box_DashAnalist'>
          <div style=
            {
                {
                  backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                  width: "100%", 
                  height: "15%", 
                  backgroundSize: "cover",
                  marginTop: "1%",
                  display: "block",
                }
            }
          >
            <div className='div_txt_DashAnalist_template'>
              <p>ANALISTA</p>
              <p>LISTADO DE CLIENTES</p>
            </div>
          </div>
            
          <table className='table_top_Analista_VistaClientes'>
            <tbody>
              <tr>
                {/* <td className='td1_table_top_Analista_VistaClientes'>
                  <p>LOGO</p>
                </td> */}
                <td className='td2_table_top_Analista_VistaClientes'>
                  <p>RAZÓN SOCIAL</p>
                </td>
                <td className='td3_table_top_Analista_VistaClientes'>
                  <p>ADMINISTRADOR</p>
                </td>
                {/* <td className='td4_table_top_Analista_VistaClientes'>
                  <p>ESTATUS</p>
                </td> */}
                {/* <td className='td5_table_top_Analista_VistaClientes'>
                  <p>REGISTRAR PAGO</p>
                </td> */}
                <td className='td6_table_top_Analista_VistaClientes'>
                  <p>INFORMACIÓN</p>
                </td>
                <td className='td7_table_top_Analista_VistaClientes'>
                  <p>REPORTE</p>
                </td>
                <td className='td8_table_top_Analista_VistaClientes'>
                  <p>CONTACTO</p>
                </td>
              </tr>
            </tbody>  
          </table>
             
          <div className='div_mapeo_Empresas_Analista'>
            {
              listaEmpresasRegistradas.map((admin) => 
              <Tarjeta_empresa 
                admin={admin} 
              />
              )
            }
          </div>      

        </div>
    </div>
  )
}
