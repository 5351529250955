import React from 'react';
import { Nomina } from '../../components/xmex/admin/Nomina';

export const DashNomina = () => {
  return (
    <div>
        <Nomina/>
    </div>
  )
}
