import React, { useEffect } from 'react'
import './Header.css';
import { Link } from "react-router-dom";
import { validateCookieUserId } from '../../../cookie/cookies';
import Cookies from 'universal-cookie';

export const Header = (props) => {

    // Obtenemos tiempo de vida de la cookie segun el componente padre
    const expirationDate = props.expirationDate;
    console.log("HEADER expirationDate: " + expirationDate);
    // Cookie id
    const cookie = new Cookies();
    const valueCookie = cookie.get('idUser');
    console.log('cookie header: ' + valueCookie);
    
    
    // validateCookieUserId(valueCookie);


    // borrar cookie por tiempo
    useEffect(() => {
        cookie.set('idUser', valueCookie, {path: '/',  expires:  expirationDate});
    }, []);
    // borrar cookie por btn 
    const cerrarSesion = () => {
        cookie.set('idUser', null,  {path: '/'});
    };

    return (
        <div className="Header">
            <Link to='/'>
                    <img 
                        src="../img/LogoSMI_Login.svg" 
                        className="img_SMI_Header"
                        onClick={cerrarSesion}
                    />
            </Link>
            <p className="text_hola_Header">{props.nombre}</p>
            {/* <p className="text_hola_Header">Administrador</p> */}
            <img src="../img/user2.png" className="img_user_Header"/>
        </div>
    ) 
}
