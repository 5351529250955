import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import {AdminContextProvider} from "./context/AdminContext";

const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#FF8030',
      },
      secondary: {
        main: '#292564ff',
      },
      white: {
        main: '#ffff',
      },
      red: {
        main: '#991f1f',
      },
    },
  });

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AdminContextProvider>
                <App />
            </AdminContextProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);