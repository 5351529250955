import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import './PaginaPublica.css';

export const Principal = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
      {isSmallScreen &&
        <Box 
          id={'inicio'}
          style={{
            backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
            width: "100%", 
            height: "50vh", 
            backgroundSize: "150% 100%"
          }}
        />
      }
      {isMediumScreen &&
        <Box 
          id={'inicio'}
          style={{
            backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
            width: "100%", 
            height: "60vh", 
            backgroundSize: "140% 100%"
          }}
        />
      }
      {isLargeScreen &&
        <Box 
          id={'inicio'}
          style={{
            backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
            width: "100%", 
            height: "70vh", 
            backgroundSize: "cover",
          }}
        />
      }
      {isExtraLargeScreen &&
        <Box 
          id={'inicio'}
          style={{
            backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
            width: "100%", 
            height: "80vh", 
            backgroundSize: "cover",
          }}
        /> 
      }
    </Box>
  );
};
