import React from 'react';
import { Button, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';

export const JsonToExcel = ({ jsonData, fileName, disabled }) => {
  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Button
      color="success"
      variant="contained"
      disabled={disabled}
      startIcon={<DownloadIcon />}
      onClick={handleExport}
      sx={{ borderRadius: 3, marginLeft: '2%' }}
      style={{ color: '#FFFFFF' }}
    >
      <Typography>EXPORTAR</Typography>
    </Button>
  );
};