import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReporteNominaPDF } from './ReporteNominaPDF';
import DownloadIcon from '@mui/icons-material/Download';
import { JsonToExcel } from './JsonToExcel';

export const NominaActions = ({ jsonEmpresaData, jsonApoyoSindical, jsonAhorro, jsonDataExcel }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '2%' }}>
      <PDFDownloadLink
        document={
          <ReporteNominaPDF
            nameEmpresa={jsonEmpresaData.nameEmpresa}
            tipoPeriodo={jsonEmpresaData.tipoPeriodo}
            periodoFiscal={jsonEmpresaData.periodoFiscal}
            esquema={jsonEmpresaData.esquema}
            planSindical={jsonEmpresaData.planSindical}
            baseCalculo={jsonEmpresaData.baseCalculo}
            sueldoBaseNaranjaPorcentajeEmpresaLey={jsonEmpresaData.sueldoBaseNaranjaPorcentajeEmpresaLey}
            indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato={jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato}
            sumatoriaSat={jsonEmpresaData.sumatoriaSat}
            sumatoriaImss={jsonEmpresaData.sumatoriaImss}
            sumatoriaImpNomina={jsonEmpresaData.sumatoriaImpNomina}
            sumatoriaImssEmpresa={jsonEmpresaData.sumatoriaImssEmpresa}
            sumatoriaRcv={jsonEmpresaData.sumatoriaRcv}
            sumatoriaInfonavit={jsonEmpresaData.sumatoriaInfonavit}
            sumatoriaIsr={jsonEmpresaData.sumatoriaIsr}
            sumatoriaSueldos={jsonEmpresaData.sumatoriaSueldos}
            sumatoriaSueldosBase={jsonEmpresaData.sumatoriaSueldosBase}
            sumatoriaCTA={jsonEmpresaData.sumatoriaCTA}
            sumatoriaCTApoyoSindicalImss={jsonEmpresaData.sumatoriaCTApoyoSindicalImss}
            sumatoriaCTApoyoSindicalImssEmpresa={jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa}
            sumatoriaCTApoyoSindicalSat={jsonEmpresaData.sumatoriaCTApoyoSindicalSat}
            sumatoriaCTApoyoSindicalIsr={jsonEmpresaData.sumatoriaCTApoyoSindicalIsr}
            sumatoriaCTApoyoSindicalRcv={jsonEmpresaData.sumatoriaCTApoyoSindicalRcv}
            sumatoriaCTAS={jsonEmpresaData.sumatoriaCTAS}
            psIsrEmpleado={jsonApoyoSindical.isrEmpleado}
            psImssEmpleado={jsonApoyoSindical.imssEmpleado}
            psImpuestoNomina={jsonApoyoSindical.impuestoSobreNomina}
            psImssEmpresa={jsonApoyoSindical.imssEmpresa}
            psRcv={jsonApoyoSindical.rcv}
            psInfonavit={jsonApoyoSindical.infonavit}
            psTotal={jsonApoyoSindical.total}
            restaIsr={jsonAhorro.isrEmpleado}
            restaImss={jsonAhorro.imssEmpleado}
            restaImpuestoNomina={jsonAhorro.impuestoSobreNomina}
            restaImssEmpresa={jsonAhorro.imssEmpresa}
            restaRcv={jsonAhorro.rcv}
            restaInfonavit={jsonAhorro.infonavit}
            restaTotal={jsonAhorro.total}
            indemnizacionRiesgoLaboral={jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato}
            costoOperacion={jsonEmpresaData.costoOperacion}
            resultadoPorcentajeEmpresa={jsonEmpresaData.resultadoPorcentajeEmpresa}
            resultadoPorcentajeSindicato={jsonEmpresaData.resultadoPorcentajeSindicato}
            costoOperacionMensual={jsonEmpresaData.costoOperacionMensual}
            costoOperacionAnual={jsonEmpresaData.costoOperacionAnual}
            ahorroReal={jsonEmpresaData.ahorroReal}
            ahorroRealAnual={jsonEmpresaData.ahorroRealAnual}
          />
        }
        fileName={`${jsonEmpresaData.nameEmpresa}_${jsonEmpresaData.periodoFiscal}.pdf`}
      >
        <Button
          color="success"
          variant="contained"
          disabled={jsonEmpresaData.disBtnReporte}
          startIcon={<DownloadIcon />}
          sx={{ borderRadius: 3 }}
          style={{ color: '#FFFFFF' }}
        >
          <Typography>REPORTE</Typography>
        </Button>
      </PDFDownloadLink>
      <JsonToExcel
        jsonData={jsonDataExcel}
        fileName={`${jsonEmpresaData.nameEmpresa}_${jsonEmpresaData.periodoFiscal}`}
        disabled={jsonEmpresaData.disBtnReporte}
      />
    </Box>
  );
};