import React, { useEffect, useState } from "react";
import "./Estilo_Fin_Cuestionario.css";
import { getPerfilEmpleadoByUsuarioId } from "../../../utils/services";
import { getUrlParameter } from "../../../utils/jstools";

window.location.hash="no-back-button";
window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
window.onhashchange=function(){
  window.location.hash="no-back-button";
};

export const Fin_Cuestionario = () => {

// state para guardar los parametros que se sacan del URL
const [ getUrlName, setGetUrlName ] = useState ({
  nombreGetURL: ""
});

// Obtiene el nombre del empleado por medio del URL para mostrar en el template fin
useEffect(() => {
  
  setGetUrlName((prevState) => {
    return {
      ...prevState,
      nombreGetURL: getUrlParameter("nombre").toUpperCase()
    }
  });

  const id = parseFloat(getUrlParameter("userId"));

  getPerfilEmpleadoByUsuarioId(id).then((data) => {    
      const perfilEmp = data[0];
      const qIdEmp = parseFloat(perfilEmp.qId);
      const idEmp = parseFloat(perfilEmp.id);
      const nomEmp = getUrlParameter("nombre");

      switch ( perfilEmp.qId )
      {
        case "":
          window.location.href = 'https://my-app-57crz.ondigitalocean.app/preregistro_empleado';
        break;
        case "val":
          window.location.href = 'https://my-app-57crz.ondigitalocean.app/registro_empleado?userId=' + id;
        break;
        case "fin":
        break;
        default:
          window.location.href = 'https://my-app-57crz.ondigitalocean.app/cuestionario_' + qIdEmp + '?userId=' + id + '&perfId=' + idEmp + '&nombre=' + nomEmp;
        break;        
      }

    }
  );
}, []);

  return (
        
    <div 
      className="box_fin"
      style=
      {
        {
          backgroundImage: "url(../img/body_fin_cuestionario.png)",
          backgroundRepeat: "no-repeat", 
          backgroundSize: "cover"
        }
      }
    >
        <img src="./img/header_fin_cuestionario.png" className="img_header"/>

        <div className="div_img_check">
        <img src="./img/check_fin_cuestionario.png" className="img_check"/>

        <p className="titulo_fin">
            ¡FELICIDADES {getUrlName.nombreGetURL} <br/>
            HAZ CONCLUIDO LA ENCUESTA!
          </p>
          <img src="./img/barra_progreso/iconos/traumas_icono_cuestionario.svg" className="img_iconos_fin"/>
          <img src="./img/barra_progreso/iconos/psicosocial_icono_cuestionario.svg" className="img_iconos_fin"/>
          <img src="./img/barra_progreso/iconos/organizacional-icono_cuestionario.svg" className="img_iconos_fin"/>
          <img src="./img/barra_progreso/iconos/burnout_icono_cuestionario.svg" className="img_icono_ultimo"/> 
        </div>          
    </div>
  );
};