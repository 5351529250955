import React from 'react'
import { ScreenTerminosCondiciones } from '../../components/xmex/afiliacion/preAfiliacion/ScreenTerminosCondiciones'

export const TerminosCondicionesXMEX = () => {
  return (
    <div>
        <ScreenTerminosCondiciones/>
    </div>
  )
}
