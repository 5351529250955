import React, { useState } from "react";


const AdminContext = React.createContext({
  empresaId: 0,
  updateAdmin: (id) => {}
  });

export const AdminContextProvider = (props) => {
  const [empresaId, setEmpresaId] = useState(0);

  const updateAdminHandler = (id) => {
    setEmpresaId(id);
  };

  return (
    <AdminContext.Provider value = {{
      empresaId: empresaId,
      updateAdmin: updateAdminHandler
      }}
    >
      {props.children}
    </AdminContext.Provider>
  )
  };

export default AdminContext;