import { Box, Button, Typography, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/servicios/';

export const Beneficios = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '90%', paddingTop: '1vh', paddingBottom: '2vh'}}>
                            <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: 25, fontWeight: 700, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)'}}>
                                VARIOS BENEFICIOS INCREÍBLES
                            </Typography>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%', width: '100%', 
                            // backgroundColor: 'pink'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%', 
                                // backgroundColor: 'blue'
                                }}>
                                    <Typography sx={{fontSize: 17, color: '#757575', fontWeight: '600'}}>
                                        Tu despensa desde $740.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center', 
                                // backgroundColor: 'violet'
                                }}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '100%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: 17, color: '#757575', fontWeight: '600'}}>
                                        8 GB + llamadas y mensajes ilimitados por $100.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '100%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: 17, color: '#757575', fontWeight: '600'}}>
                                        Asistencia de viajes desde $800.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '100%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            AFILIARSE
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isMediumScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '90%', paddingTop: '1vh', paddingBottom: '2vh'}}>
                            <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: '3.2vw', fontWeight: 700, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)'}}>
                                VARIOS BENEFICIOS INCREÍBLES
                            </Typography>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%', width: '100%', 
                            // backgroundColor: 'pink'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%', 
                                // backgroundColor: 'blue'
                                }}>
                                    <Typography sx={{fontSize: '2.7vw', color: '#757575', fontWeight: '600'}}>
                                        Tu despensa desde $740.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center', 
                                // backgroundColor: 'violet'
                                }}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: '2.7vw', color: '#757575', fontWeight: '600'}}>
                                        8 GB + llamadas y mensajes ilimitados por $100.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: '2.7vw', color: '#757575', fontWeight: '600'}}>
                                        Asistencia de viajes desde $800.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: '2.7vw', fontWeight: 700}} style={{color: 'white'}}>
                                            AFILIARSE
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '85%', paddingTop: '1vh', paddingBottom: '2vh'}}>
                            <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: '3vw', fontWeight: 700, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)'}}>
                                VARIOS BENEFICIOS INCREÍBLES
                            </Typography>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%', width: '100%', 
                            // backgroundColor: 'pink'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%', 
                                // backgroundColor: 'blue'
                                }}>
                                    <Typography sx={{fontSize: '2.1vw', color: '#757575', fontWeight: '600'}}>
                                        Tu despensa desde $740.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center', 
                                // backgroundColor: 'violet'
                                }}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: '2.1vw', color: '#757575', fontWeight: '600'}}>
                                        8 GB + llamadas y mensajes ilimitados por $100.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '80%', height: '70%'}}>
                                    <Typography sx={{fontSize: '2.1vw', color: '#757575', fontWeight: '600'}}>
                                        Asistencia de viajes desde $800.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '70%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: '2.1vw', fontWeight: 700}} style={{color: 'white'}}>
                                            AFILIARSE
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
        {isExtraLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', backgroundSize: "cover"}}>
                <Box display={'flow-root'}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '85%', paddingTop: '1vh', paddingBottom: '2vh'}}>
                            <Typography color='primary' sx={{display: 'flex', justifyContent: 'center', fontSize: '2.2vw', fontWeight: 700, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)'}}>
                                VARIOS BENEFICIOS INCREÍBLES
                            </Typography>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%', width: '100%', 
                            // backgroundColor: 'pink'
                            }}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '90%', height: '70%', 
                                // backgroundColor: 'blue'
                                }}>
                                    <Typography sx={{fontSize: '1.7vw', color: '#757575', fontWeight: '600'}}>
                                        Tu despensa desde $740.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center', 
                                // backgroundColor: 'violet'
                                }}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '60%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '90%', height: '70%'}}>
                                    <Typography sx={{fontSize: '1.7vw', color: '#757575', fontWeight: '600'}}>
                                        8 GB + llamadas y mensajes ilimitados por $100.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '60%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BENEFICIO */}
                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2%'}}>
                                <Box sx={{display: 'flex', alignItems: 'flex-end', borderBottomStyle: 'solid', borderBottomColor: '#FF8303', borderWidth: '3px', width: '90%', height: '70%'}}>
                                    <Typography sx={{fontSize: '1.7vw', color: '#757575', fontWeight: '600'}}>
                                        Asistencia de viajes desde $800.00 MXN
                                    </Typography>
                                </Box>
                                <Box sx={{width: '20%', display: 'flex', justifyContent: 'center'}}>
                                    <img
                                        src={src_url + 'servicio_ms.png'}
                                        style={{
                                            width: '60%',
                                            borderRadius: '50%',
                                            border: '4px solid #A35709',
                                            objectFit: 'cover',
                                            background: 'white'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {/* BOTON */}
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='success' variant="contained" sx={{borderRadius: 8, marginTop: '10%'}}>
                                        <Typography sx={{fontSize: '1.5vw', fontWeight: 700}} style={{color: 'white'}}>
                                            AFILIARSE
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </Box>
  )
}
