
import React from 'react';
import { Typography, Container, List, ListItem, ListItemText, Box } from '@mui/material';

export const ScreenTerminosCondiciones = () => {
  return (
    <div>
        <Container maxWidth="md" sx={{ py: 4, borderRadius: '20px', backgroundColor: '#F0F0F0', marginBlock: '20px' }}>
            <Box sx={{ px: '20px'}}>
            <Typography variant="h4" gutterBottom>
              Protección de Datos y Aviso Legal
            </Typography>

            <Typography variant="h5" gutterBottom>
              1. Responsabilidad en el Tratamiento de Datos
            </Typography>
            <Typography paragraph>
              Sindicato por México (en adelante, "El Sindicato") se compromete a garantizar la seguridad y confidencialidad
              de los datos personales proporcionados por los usuarios a través del formulario de registro en este sitio web.
              Los datos personales recolectados serán tratados conforme a las disposiciones de la Ley Federal de Protección
              de Datos Personales en Posesión de los Particulares (LFPDPPP) y su Reglamento.
            </Typography>

            <Typography variant="h5" gutterBottom>
              2. Finalidad del Tratamiento de Datos
            </Typography>
            <Typography paragraph>
              Los datos personales recolectados serán utilizados únicamente para los siguientes fines:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="Gestión del proceso de afiliación y perfil de usuario en El Sindicato." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Confirmación de identidad, contacto y seguimiento de los servicios y beneficios ofrecidos a nuestros afiliados." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Comunicación y envío de información relevante al usuario relacionada con sus derechos, obligaciones y eventos organizados por El Sindicato." />
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              3. Almacenamiento y Protección de la Información
            </Typography>
            <Typography paragraph>
              La información personal proporcionada se almacenará en sistemas protegidos por las medidas de seguridad
              necesarias para prevenir accesos no autorizados, usos indebidos o cualquier alteración que comprometa
              la confidencialidad de la información. Solo el personal autorizado tendrá acceso a estos datos.
            </Typography>

            <Typography variant="h5" gutterBottom>
              4. Derechos del Usuario (ARCO)
            </Typography>
            <Typography paragraph>
              En cualquier momento, el usuario podrá ejercer sus derechos ARCO (Acceso, Rectificación, Cancelación y
              Oposición) sobre sus datos personales. Para realizar cualquier solicitud o aclaración relacionada con
              el tratamiento de sus datos, puede ponerse en contacto con nosotros a través del correo electrónico
              contacto@sindical.com.mx.
            </Typography>

            <Typography variant="h5" gutterBottom>
              5. Política de Cookies
            </Typography>
            <Typography paragraph>
              Este sitio web utiliza cookies para mejorar la experiencia del usuario y optimizar el rendimiento de nuestro
              sitio. Las cookies son pequeños archivos de texto que se almacenan en su dispositivo al navegar en nuestro
              sitio. Estas permiten recordar sus preferencias y analizar el uso del sitio web. A continuación, detallamos
              el tipo de cookies que utilizamos:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="Cookies de sesión: Necesarias para el correcto funcionamiento de la página." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cookies analíticas: Utilizadas para recopilar datos sobre el tráfico y el comportamiento de los usuarios en el sitio, con el fin de mejorar nuestros servicios." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cookies de terceros: Algunas secciones de nuestro sitio pueden utilizar cookies de terceros (por ejemplo, de redes sociales)." />
              </ListItem>
            </List>

            <Typography paragraph>
              El usuario tiene la opción de aceptar o rechazar el uso de cookies en nuestro sitio. Puede configurar su
              navegador para bloquear o eliminar las cookies si así lo desea; sin embargo, esto puede afectar la
              funcionalidad de algunas partes del sitio.
            </Typography>

            <Typography variant="h5" gutterBottom>
              6. Términos y Condiciones de Uso
            </Typography>
            <Typography paragraph>
              El acceso y uso de este sitio web implica la aceptación de los siguientes términos y condiciones:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="Uso del Sitio: El contenido de este sitio es para fines informativos y de afiliación a El Sindicato. Cualquier uso indebido, incluyendo el intento de acceder a áreas restringidas sin autorización, está prohibido." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Propiedad Intelectual: Todo el contenido del sitio (textos, gráficos, logotipos, imágenes y software) es propiedad de El Sindicato y está protegido por las leyes de propiedad intelectual. No está permitido su reproducción o distribución sin autorización previa." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Limitación de Responsabilidad: El Sindicato no se responsabiliza por daños o perjuicios derivados del uso de este sitio, incluyendo interrupciones en el servicio, virus u otros problemas técnicos." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Modificación de Términos: El Sindicato se reserva el derecho de actualizar estos términos en cualquier momento. El usuario debe revisar periódicamente esta sección para mantenerse informado sobre posibles cambios." />
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              7. Actualización y Cambios en la Política de Privacidad
            </Typography>
            <Typography paragraph>
              El Sindicato se reserva el derecho de actualizar o modificar esta política de privacidad en cualquier momento.
              Cualquier cambio en la misma será publicado en esta sección de nuestro sitio web, por lo que le recomendamos
              revisar periódicamente esta página para mantenerse informado.
            </Typography>
            </Box>
        </Container>
    </div>
  )
}
