import React, { useState, useEffect } from 'react';
import { Header } from '../../header/Header';
import { Menu_lateral_admin } from '../../../nom35/admin/menu_lateral_admin/Menu_lateral_admin';
import "./Registro_empleados_dashboard_admin.css";
import { getEmpresaById, getUserById, getEmpleadosByEmpresaId, getPerfilEmpleadoByUsuarioId, guardaUsuario, emailAutomaticoPreReg, emailAutomaticoReg } from '../../../../utils/services';
import { TarjetaRegEmpleados } from '../../../nom35/admin/pre_registro_dashboard1_admin/TarjetaRegEmpleados';
import Cookies from 'universal-cookie';
import { getUrlParameter } from '../../../../utils/jstools';

export const Registro_empleados_dashboard_admin = () => {

  // Se obtiene el empresaId para pasarselo al menu lateral como parametro
  const [ perfilEmpresa, setPerfilEmpresa ] = useState ({
    id: 0,
    nombre: "",
    rasonSocial: "",
    rfc: "",
    calle: "",
    numExt: "",
    colonia: "",
    cp: "",
    municipio: "",
    localidad: "",
    estado: "",
    referencia: ""
  });

  // instancia de cookies
  const cookies = new Cookies();
  const [habilitarBoton, setHabilitarBoton] = useState(true);

  // useEffect para coockies
  useEffect(() => {
    console.log('cookie IdEmpresa: ' + cookies.get('idEmpresa'));
    // getEmpresaById(cookies.get('idEmpresa')).then((data)=>{
    getEmpresaById(getUrlParameter('empresaId')).then((data)=>{
      setPerfilEmpresa((prevState) => {
        return { ...prevState,
          nombre: data.Name,
          rasonSocial: data.RasonSocial,
          rfc: data.Rfc,
          calle: data.Calle,
          numExt: data.NumExt,
          colonia: data.Colonia,
          cp: data.Cp,
          municipio: data.Municipio,
          localidad: data.Localidad,
          estado: data.Estado,
          referencia: data.Referencia
        };
      });
    });

    // Obtener los empleados registrados
    var empleadosArray = [];
    // getEmpleadosByEmpresaId(cookies.get('idEmpresa')).then((data) => {
    getEmpleadosByEmpresaId(getUrlParameter('empresaId')).then((data) => {
      const arreglo = data;
      console.log('idEmpresa: ' + perfilEmpresa.id);
      // Obtener el numero de los empleados registrados
      setNumEmpleadosrReg((prevState) => {
        return { ...prevState,
          numEmp: data.length -1
        }
      });
      const nE = data.length -1;
      console.log('nE: ' + nE);
      // Verificar la condición de todos los usuarios con qId igual a "val"
      let contador = 0; // Inicializar el contador
      arreglo.forEach((empleado) => {
        if ( empleado.RolId == 2 ) {
          getPerfilEmpleadoByUsuarioId(empleado.Id).then((dataPerf) =>{               
            const perfilEstatus = {
              id: empleado.Id, 
              numTrabajador: empleado.NumEmp,
              email: empleado.Email,
              estatus: dataPerf[0].qId,
              idPE: dataPerf[0].Id
            };
            console.log("empleados: " + perfilEstatus.email );
            empleadosArray.push(perfilEstatus);
            setListaEmpleadosRegistrados(oldArray => [...oldArray, perfilEstatus]);
            // Verificar si qId es igual a "val"
            if (perfilEstatus.estatus == "val") {
              contador++; // Aumentar el contador
              console.log('qId: val' + contador);
            }
            // Si el contador llega a la cantidad total de empleados, habilitar el botón
            if (contador == nE) {
              setHabilitarBoton(false);
              console.log('contador: '+ contador);
              console.log('numEmpleados: ' + nE);
            }
          });
        }
      });
    });
  }, [cookies.get('idEmpresa')]);


  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  // Se obtiene el useId para pasarselo al menu lateral como parametro
  const [ perfilUsuario, setPerfilUsuario ] = useState({
    id: cookies.get('idUser'),
    nombre: "",
    email_admin: ""
  });

  // Se obtiene el numero de empleados registrados
  const [ numEmpleadosrReg, setNumEmpleadosrReg ] = useState({
    numEmp: ""
  });

  // Lista de los empleados para mapear
  const [ listaEmpleadosRegistrados, setListaEmpleadosRegistrados ] = useState ([]);

  // Funcion para anadir un empleado por medio del popUp
  const [ anadirEmpleado, setAnadirEmpleado ] = useState({
    NumEmp: "",
    Email: "",
    Pass: "nom35",
    // EmpresaId: cookies.get('idEmpresa'),
    // EmpresaId: 0,
    EmpresaId: getUrlParameter('empresaId'),
    RolId: 2
  });

  // state para el checkBox de los trabajadores seleccionados
  const [ checkBoxTrabajadores, setCheckBoxTrabajadores ] = useState(false);
  
  //  useEffect para obtener los datos
  useEffect(() => {    
    getUserById(getUrlParameter('userId')).then((data) => {
      setPerfilUsuario((prevState) => {
        return { ...prevState,
          nombre: data.Nombre,
          email_admin: data.Email
        }
      });
    });

  },[]);

  // agregar empleados a la lista
  const agregarEmpAlGrid = (nuevoEmpleado) => {
    let nuevosEmpleados = new Array();
    listaEmpleadosRegistrados.map((empleado) =>
      {
        nuevosEmpleados.push(empleado);
      }
    )
    nuevosEmpleados.push(nuevoEmpleado);
    setListaEmpleadosRegistrados(nuevosEmpleados);
  };

  const clickHandlerAgregarEmp = () => {
    setNumEmpleadosrReg({
      ...numEmpleadosrReg,
      numEmp: numEmpleadosrReg.numEmp + 1
    });
    guardaUsuario(anadirEmpleado).then((data) => {
      if ( data.Id > 0 ){
        window.alert("Registro de empleado exitoso");
        agregarEmpAlGrid(
          {
            id: data.Id, 
            numTrabajador: anadirEmpleado.NumEmp,
            email: anadirEmpleado.Email,
            estatus: ""
          }
        );
      }
      else{
        window.alert("Registro de empleado incorrecto");
      }
    });
    emailAutomaticoPreReg({
      usuario: {
        nombre: 'contribuyente',
        email: anadirEmpleado.Email,
        email_admin: perfilUsuario.email_admin
      }
    })
    console.log('email admin: ' + perfilUsuario.email_admin);
  };

  const clickHandlerIniciarEncuestas = () => {
    for (const empleado of listaEmpleadosRegistrados) {
      const jsonDatos = {
        usuario: {
          nombre: 'contribuyente',
          email: empleado.email,
          email_admin: perfilUsuario.email_admin,
          id: empleado.id 
        }
      };
      emailAutomaticoReg(jsonDatos);
    }
  };

  // PopUp para anadir usuarios
  const btnAbirPopUp = document.querySelector("#btn_PopUp_AnadirUsuario");
  const inputNumTrab = document.querySelector("#inputNumTrab");
  const inputEmailTrab = document.querySelector("#inputEmailTrab");
  const popUp = document.querySelector("#popUp_anadirUsuario");
  const cerrar = document.querySelector("#btn_PopUp_Cerrar");
  const cerrarAgregar = document.querySelector("#btn_PopUp_Cerrar_Agregar");
  if (btnAbirPopUp) {
    btnAbirPopUp.addEventListener("click", () => {
      setAnadirEmpleado((prevState) => {
        return { ...prevState, Email: "", NumEmp: "" };
      });
      inputNumTrab.value="";
      inputEmailTrab.value="";
      popUp.show();
    })
  }
  if (cerrar) {
    cerrar.addEventListener("click", () => {
      popUp.close();
    })
  }
  if (cerrarAgregar) {
    cerrarAgregar.addEventListener("click", () => {
      popUp.close();
    })
  }

  // Condicion para el boton agregar popUp
  var dis = true;
  if (
    (anadirEmpleado.NumEmp != "") & (anadirEmpleado.Email != "")
  ){
    dis = false;
  }
  else {
    dis = true;
  }

  //  onChange de los input
  const numEmpAnadirEmpChangeHandler = (event) => {
    setAnadirEmpleado((prevState) => {
      return { ...prevState, NumEmp: event.target.value,  };
    });
  };
  const emailAnadirEmpChangeHandler = (event) => {
    setAnadirEmpleado((prevState) => {
      return { ...prevState, Email: event.target.value,  };
    });
  };

  return (
    <div>
      <Header
        // nombre={cookies.get('nameUser')}
        nombre={getUrlParameter('userName')}
        expirationDate={expirationDate}
      />
      <Menu_lateral_admin  
        // idUsuario={cookies.get('idUser')}
        idUsuario={getUrlParameter('userId')}
        // idEmpresa={cookies.get('idEmpresa')}
        idEmpresa={getUrlParameter('empresaId')}
        qId={getUrlParameter('qId')}
        nombre={getUrlParameter('userName')}
      />
      {/* Registro_empleados */}
      <div className='box_DashAdmin1'>
        <div
          style=
          {
            {
              backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
              width: "100%", 
              height: "15%", 
              backgroundSize: "cover",
              marginTop: "1%",
              display: "block",
            }
          }
        >
          <div className='div_txt_DashAdmin1_InfoEmpresa'>
            <p>ADMINISTRADOR</p>
            <p>REGISTRO DE EMPLEADOS</p>    
          </div>
          {/* PopUp anadir usuario */}
          <dialog 
            id='popUp_anadirUsuario'
            className='popUp_AnadirUsuario'
          >
            <p className='txt_anadirUsuario_PopUp_anadirEmpleado'>Añadir usuario</p>
            <div className='div1_popUp_RegistroEmpleados'>
              <p className='txt_NumTrabajador_PopUp_anadirEmpleado'>Número de empleado</p>
              <input
                className='input__NumTrabajador_PopUp_anadirEmpleado1'
                type="text"
                placeholder='Número de empleado'
                pattern='[0-9,a-z,A-Z]+'
                maxLength={20}
                value={anadirEmpleado.NumEmp}
                onChange={numEmpAnadirEmpChangeHandler}
                id="inputNumTrab"
              />
            </div>      
            <div className='div2_popUp_RegistroEmpleados'>
              <p className='txt_email_PopUp_anadirEmpleado'>
                Email
              </p>
              <input
                className='input_email_PopUp_anadirEmpleado1'
                type="email"
                placeholder='Email'
                value={anadirEmpleado.Email}
                onChange={emailAnadirEmpChangeHandler}
                id="inputEmailTrab"
              />
            </div>
            <div>
              <button
                className='btn_AnadirEmpleado_popUp'
                onClick={clickHandlerAgregarEmp}
                disabled={dis}
                id='btn_PopUp_Cerrar_Agregar'
              >
                Agregar
              </button>
            </div>
            <button
              id='btn_PopUp_Cerrar'
              className='btn_cerrar_popUp'
            >
              Cerrar
            </button>    
          </dialog>
          <div className='table1_top_Admin_RegEmpleados'>
            <table>
              <tbody>
                <tr>
                  <td className='td11_table_top_Admin_RegEmpleados'>
                    <img
                      src='../img/admin/Administrar_usuarios.png' 
                      className='img_Emp'
                    />
                  </td>
                  <td className='td12_table_top_Admin_RegEmpleados'>
                    <p>
                      REGISTRADOS: {numEmpleadosrReg.numEmp}
                      {/* EMPLEADOS REGISTRADOS: {numEmpleadosrReg} */}
                    </p>
                  </td>
                  {/* <td className='td12_table_top_Admin_RegEmpleados'>
                    <p>
                      EMPLEADOS SELECCIONADOS:
                    </p> 
                  </td> */}
                  {/* <td className='td13_table_top_Admin_RegEmpleados'>
                    <button 
                      className='btn_usuariosSeleccionados_regEmp'
                    >
                      <img
                         src='../img/admin/Vectoreliminar.png' 
                         className='img_eliminar'
                        />
                    </button>
                  </td> */}
                  {/* Btn anadir usuario */}
                  <td className='td13_table_top_Admin_RegEmpleados'>
                    <button
                      className='btn_anaadirUsuarios_regEmp'
                      id='btn_PopUp_AnadirUsuario'
                    >
                     <img
                       src='../img/admin/Vectorañadir.png' 
                       className='img_añadir'
                      />
                    </button>
                  </td>
                  {/* Descargar excel */}
                  <td className='td14_table_top_Admin_RegEmpleados'>
                    <button
                      className='btn_cargar_regEmp'
                    >
                      <img
                        src='../img/admin/download.png' 
                        className='img_download'
                        />
                    </button>
                  </td>
                  {/* Cargar excel */}
                  <td className='td15_table_top_Admin_RegEmpleados'>
                    <button
                      className='btn_descargar_regEmp'
                    >
                      <img
                        src='../img/admin/upload.png' 
                        className='img_upload'
                        />
                    </button>
                  </td>
                  {/* Iniciar encuestas */}
                  <td className='td13_table_top_Admin_RegEmpleados'>
                    <button
                      className='btn_anaadirUsuarios_regEmp'
                      onClick={clickHandlerIniciarEncuestas}
                      disabled={habilitarBoton}
                    >
                      <img
                        src='../img/admin/empezarEnc.png' 
                        className='img_empEnc'
                      />
                    </button>
                  </td>
                </tr>
              </tbody>  
            </table>
            </div>
            <table className='table_top_Admin_RegEmpleados'>
              <tbody>
                <tr>
                  {/* <td className='td1_table_top_Admin_RegEmpleados'>
                    // <p>LOGO</p>
                    <input 
                      className='numEmp'
                      type="checkbox" 
                      onClick={() => setCheckBoxTrabajadores(!checkBoxTrabajadores)}
                    />
                  </td> */}
                  <td className='td2_table_top_Admin_RegEmpleados'>
                    <p
                    className='txt_tbl'
                    >
                      NUMERO DE EMPLEADO
                    </p>
                  </td>
                  <td className='td3_table_top_Admin_RegEmpleados'>
                    <p
                    className='txt_tbl'
                    >
                      EMAIL
                    </p>
                  </td>
                  <td className='td4_table_top_Admin_RegEmpleados'>
                    <p
                    className='txt_tbl'
                    >
                      ESTATUS
                    </p>
                  </td>
                  <td className='td5_table_top_Admin_RegEmpleados'>
                    <p
                    className='txt_tbl'
                    >
                      EDITAR
                    </p>
                  </td>
                  <td className='td6_table_top_Admin_RegEmpleados'>
                    <p
                    className='txt_tbl'
                    >
                      ELIMINAR
                    </p>
                  </td>
                </tr>
              </tbody>  
            </table>
            <div className='div_emp_admin'>
              { 
                listaEmpleadosRegistrados.map((empleado) => 
                  <TarjetaRegEmpleados 
                    empleado={empleado} 
                    check={checkBoxTrabajadores}
                  /> 
                ) 
              }
          </div>   
        </div> 
      </div>
    </div>
  );
};
