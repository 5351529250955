import React, { useState } from "react";
import './Cuestionario21.css';
import './Estilo_Cuestionario_Escala.css';
import { PreguntaSiNoCond } from "./elementos_cuestionarios/PreguntaSiNoCond";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario315 = (props) => {
    const sigPag = "316";
    const pNombre = props.nombre;
    // const pUserId = props.usuario;
    const pUserId = getUrlParameter('userId');
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 1;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);

    const [perfilJson, setPerfilJson] = useState({
      Id: 0,
      UsuarioId: 0,
      qId: 0,
      EmpresaId: 0
    });

    const [sessionJson, setSessionJson] = useState({
      referenciaId: 3,      //Dato estático
      seccionId: 26,        //Dato estático
      tipoPreguntaId: 1,    //Dato estático
      preguntaInicial: 69,  //Dato estático
      nombre: props.nombre,
      link: ""
    });

      const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [ ]
      });

    const updatePerfil = (preg, resp, qIdCond, condPreguntaActiva) => {
        console.log(preg,resp,qIdCond);
        if(condPreguntaActiva>=1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }

        var arrayRespuestas;
        if ( resp == 0 ){
          arrayRespuestas = { respuestas:
            [
              { ReferenciaId: 3, SeccionId: 26, Pregunta: 69, Respuesta: 0 },
              { ReferenciaId: 3, SeccionId: 26, Pregunta: 70, Respuesta: 0 },
              { ReferenciaId: 3, SeccionId: 26, Pregunta: 71, Respuesta: 0 },
              { ReferenciaId: 3, SeccionId: 26, Pregunta: 72, Respuesta: 0 },
            ]
          };
        }else{
          arrayRespuestas = { respuestas: []};
        }
        setRespuestasJson(arrayRespuestas);

        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              qIdCond +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
      };

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            UsuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);

    return (
      <div className="box_menu_y_cuestionario_escala">
        <div className="cuestionario_div_escala">
          <div className="div_svg_menu_escala">
            <img src="./img/menu_cuestionarios/menu_c313.svg" className="img_svg_escala_entorno_org"/>
          </div>
          <div className="text_cuestionario_escala">
            <img src="./img/barra_progreso/entorno_organizacional/barra_de_progreso_313.svg" className="img_progreso_escala"/>
            <p className="titulo_cuestionario_escala"><img src="./img/barra_progreso/iconos/organizacional-icono_cuestionario.svg"/>  ENTORNO ORGANIZACIONAL</p>

            <PreguntaSiNoCond
              numPreg="69"
              texto="Soy jefe de otros trabajadores:"
              rSi="1"
              rNo="2"
              condSi="316"
              condNo="401"
              idSi="Si32"
              idNo="No32"
              update={updatePerfil}
            />

            <Button_CuestionarioSiguente
              className="btn_siguiente_cuestionario_binario"
              sesion={sessionJson}
              perfil={perfilJson}
              respuestas={respuestasJson}
              disabled={deseableState}
              upDate={"guardaRespuestas"}
              condicionPreg='101Cond'
            />

          </div>
        </div>
      </div>
    )
}