import React from "react";
import "../Estilo_Cuestionario_Binario.css";
import "../Estilo_Cuestionario_Escala.css";
var estiloBotonSi = "btn_si_cuestionario_binario";
var estiloBotonNo = "btn_no_cuestionario_binario";


export const Preguntas101SiNoCond = (props, preguntaActivada) => {

  var condPreguntaActiva = 0;

  preguntaActivada = condPreguntaActiva.preguntaActiva;

  const condicionSi = document.querySelector("button[id='"+props.idSi+"']");
  const condicionNo = document.querySelector("button[id='"+props.idNo+"']");
  
  const updateSi = (self) => {
    condPreguntaActiva++;
          condicionSi.className = 'btn_si_cuestionario_binario_activo';
          condicionNo.className = 'btn_no_cuestionario_binario';
    props.update(parseInt(props.numPreg), (props.rSi), props.condSi, condPreguntaActiva);
  };

  const updateNo = (self) => {
    condPreguntaActiva++;
          condicionSi.className = 'btn_si_cuestionario_binario';
          condicionNo.className = 'btn_no_cuestionario_binario_activo';
    props.update(parseInt(props.numPreg), (props.rNo), props.condNo, condPreguntaActiva);
  };

  return (
    <div>
      <table>
        <tbody>
        <tr>          
            <td className="td_botones_pregunta">
            <p className="preguntas_cuestionario_escala1">{props.texto}</p>
            <button
              name="btn_si"
              className= {estiloBotonSi}
              id={props.idSi}
              onClick={updateSi}
            >
              SI
            </button>
            <button
              name="btn_no"
              className= {estiloBotonNo}
              id={props.idNo}
              onClick={updateNo}
            >
              NO
            </button>
            </td>
          
        </tr>
        </tbody>
      </table>
    </div>
  );
};