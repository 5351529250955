import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Container, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import { NavBar_afiliacion } from './NavBar_Preafiliacion';
import { Btn_afiliarse } from '../Btn_afiliarse';
import { guardaAfiliado } from '../../../../utils/xmex/services';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

export const ScreenPreAfiliacion = () => {
  
  // Instancia para las cookies
  const cookie = new Cookies();

  // Tamaños de pantallas
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  // URL DEL SERVIDOR PARA IMAGENES
  const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

  const [afiliacion, setAfiliacion] = useState({
    apellido_p:'',
    apellido_m:'',
    nombre: '',
    celular: '',
    curp: '',
    email:'',
    password: ''
  });

  const [ passAfiliado, setPassAfiliado ] = useState ({
    pass: ''
  });

  const [isChecked, setIsChecked] = useState(false);

  // CHANGE HANDLERS
  const apellidoPaternoAfiliadoChangeHandler = (event) => {
    let valor = event.target.value;
  
    // Permitir letras y espacios, eliminar caracteres especiales y números
    valor = valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g, '');
  
    setAfiliacion((prevState) => {
      return { ...prevState, apellido_p: valor };
    });
  };
  
  const apellidoPaternoKeyDownHandler = (event) => {
    const tecla = event.key;
    const teclasPermitidas = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
  
    // Permitir letras, espacios y teclas de control
    if (!/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]$/.test(tecla) && !teclasPermitidas.includes(tecla)) {
      event.preventDefault();
    }
  };
  
  const apellidoMaternoAfiliadoChangeHandler = (event) => {
    let valor = event.target.value;
  
    // Permitir letras y espacios, eliminar caracteres especiales y números
    valor = valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g, '');
  
    setAfiliacion((prevState) => {
      return { ...prevState, apellido_m: valor };
    });
  };
  
  const apellidoMaternoKeyDownHandler = (event) => {
    const tecla = event.key;
    const teclasPermitidas = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
  
    // Permitir letras, espacios y teclas de control
    if (!/^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]$/.test(tecla) && !teclasPermitidas.includes(tecla)) {
      event.preventDefault();
    }
  };
  
  
  const nombreAfiliadoChangeHandler =(event) => {
    setAfiliacion((prevState) => {
      return {...prevState, nombre: event.target.value};
    });
  };
  
  const celularAfiliadoChangeHandler = (event) => {
    let valor = event.target.value;
  
    // Solo permite números
    valor = valor.replace(/[^0-9]/g, '');
  
    setAfiliacion((prevState) => {
      return { ...prevState, celular: valor };
    });
  };
  
  const celularKeyDownHandler = (event) => {
    const tecla = event.key;
    const teclasPermitidas = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
  
    if (!/^[0-9]$/.test(tecla) && !teclasPermitidas.includes(tecla)) {
      event.preventDefault();
    }
  };  

  const curpAfiliadoChangeHandler = (event) => {
    let valor = event.target.value;
  
    // Limitar a 18 caracteres, permitir solo letras mayúsculas y números, convertir a mayúsculas
    valor = valor.toUpperCase().replace(/[^A-Z0-9]/g, '').substring(0, 18);
  
    setAfiliacion((prevState) => {
      return { ...prevState, curp: valor };
    });
  };
  
  const emailAfiliadoChangeHandler = (event) => {
    let valor = event.target.value;
  
    // Solo permitir letras minúsculas
    valor = valor.toLowerCase();
  
    setAfiliacion((prevState) => {
      return { ...prevState, email: valor };
    });
  };

  const esCorreoValido = (email) => {
    // Validamos que tenga al menos un @ y un . 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  
  const passwordAfiliadoChangeHandler =(event) => {
    setAfiliacion((prevState) => {
      return {...prevState, password: event.target.value};
    });
  };
  const confPasswordChangeHandler =(event) => {
    setPassAfiliado((prevState) => {
      return {...prevState, pass: event.target.value};
    });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // onBlur
  const emailCookie = () => {
    cookie.set('cookieEmailAfiliacion', afiliacion.email, {path: '/'});
  }
  const nameCookie = () => {
    const nombreCompleto = afiliacion.nombre + ' ' + afiliacion.apellido_p + ' ' + afiliacion.apellido_m;
    cookie.set('cookieNombreCompleto', nombreCompleto, {path: '/'});
  }
  
  // Confirmacion de password
  var disabledBtn = true;

  const allFieldsFilled = 
  afiliacion.apellido_p !== '' &&
  afiliacion.apellido_m !== '' &&
  afiliacion.nombre !== '' &&
  afiliacion.celular !== '' &&
  afiliacion.curp !== '' &&
  afiliacion.email !== '' &&
  afiliacion.password !== '';
  
  const passwordsMatch = 
  afiliacion.password === passAfiliado.pass && 
  afiliacion.password !== '' && 
  passAfiliado.pass !== '';

  if (allFieldsFilled && passwordsMatch && isChecked) {
    disabledBtn = false;
  } else {
    disabledBtn = true;
  }

  return (
    <Box>
      <NavBar_afiliacion/>
      {isSmallScreen &&
        <Container>
          <Box sx={{marginTop: '3%', borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', paddingLeft: '1.5vw'}}>
            <Typography color='primary' sx={{fontSize: 22, fontWeight: 800}}>
              PREAFILIACIÓN SINDICATO POR MEXICO
            </Typography>
            <Typography sx={{fontSize: 17, fontWeight: 400, color: '#303030', textAlign: 'justify'}}>
              En Sindicato por México, nos comprometemos a construir un futuro más justo y equitativo para todos los trabajadores mexicanos.
            </Typography>
          </Box>
          <Box>
            <Box sx={{marginTop: '3vh'}}>
              <Typography color='primary' sx={{fontSize: 20, fontWeight: 800, width: '100%', display: 'flex', justifyContent: 'center'}}>
                ¿Qué beneficios obtendrás al afiliarte?
              </Typography>
              <Typography sx={{fontSize: 17, fontWeight: 500, color: '#303030', marginTop: '2%', marginLeft: '10%'}}>
                <li> Plan de pensiones sindicales </li>
                <li> Descuentos exclusivos </li>
                <li> Indemnización por riesgo laboral </li>
                <li> Seguro por muerte accidental </li>
                <li> Consulta médica gratuita </li>
                <li> Cajas de ahorro sindicales </li>
                <li> Previsión social </li>
              </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Box sx={{marginBlock: '3vh', width: '85%', background: '#F4F4F4', boxShadow: 7, borderRadius: 8, paddingBlock: '0.5vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <figure>
                    <img 
                      src={src_url + 'logo_spm.png'} 
                      style={{width: '10ch'}}
                      alt="Logo_SPM"
                    />
                  </figure>
                </Box>
                <Container sx={{marginBottom: '5%'}}>
                  <TextField 
                    type='text'
                    label='Apellido paterno' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '100%', marginBottom: '4%'}}
                    inputProps={{maxLength: 40}}
                    value={afiliacion.apellido_p}
                    onChange={apellidoPaternoAfiliadoChangeHandler}
                    onKeyDown={apellidoPaternoKeyDownHandler}
                    onBlur={nameCookie}
                  />
                  <TextField 
                    type='text'
                    label='Apellido materno' 
                    color='primary' 
                    variant='filled' 
                    sx={{width: '100%', marginBottom: '4%'}}
                    inputProps={{ maxLength: 40 }}
                    value={afiliacion.apellido_m}
                    onChange={apellidoMaternoAfiliadoChangeHandler}
                    onKeyDown={apellidoMaternoKeyDownHandler}
                    onBlur={nameCookie}
                  />
                  <TextField 
                    type='text'
                    label='Nombre(s)' 
                    color='primary' 
                    variant='filled' 
                    sx={{width: '100%', marginBottom: '4%'}}
                    onChange={nombreAfiliadoChangeHandler}
                    onKeyDown={apellidoMaternoKeyDownHandler}
                    onBlur={nameCookie}
                  />
                  <TextField 
                    type='text'
                    label='Celular' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '100%', marginBottom: '4%'}}
                    inputProps={{ maxLength: 10 }}
                    value={afiliacion.celular}
                    onChange={celularAfiliadoChangeHandler}
                    onKeyDown={celularKeyDownHandler}
                  />
                  <TextField 
                    type='text'
                    label='CURP' 
                    color='primary' 
                    variant='filled' 
                    sx={{width: '100%', marginBottom: '4%'}}
                    inputProps={{ maxLength: 18 }}
                    value={afiliacion.curp}
                    onChange={curpAfiliadoChangeHandler}
                  />
                  <TextField 
                    type='email'
                    label='Correo electrónico' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '100%', marginBottom: '4%'}}
                    inputProps={{ maxLength: 40 }}
                    value={afiliacion.email}
                    onChange={emailAfiliadoChangeHandler}
                    onBlur={emailCookie}
                  />
                  <TextField 
                    type='password'
                    label='Contraseña' 
                    color='primary' 
                    variant='filled' 
                    onChange={passwordAfiliadoChangeHandler}
                    sx={{width: '100%', marginBottom: '4%'}}
                  />
                  <TextField 
                    type='password'
                    label='Confirma contraseña' 
                    color='primary' 
                    variant='filled' 
                    onChange={confPasswordChangeHandler}
                    sx={{width: '100%'}}
                  />
                </Container>
                <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      Acepto los{' '}
                      <Link 
                        to="/terminos-y-condiciones" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: '#1976d2', textDecoration: 'underline' }}
                      >
                        términos y condiciones
                      </Link>
                    </Typography>
                  }
                />
              </Container>
                {/* BTN's */}
                <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                  {/* BTN  */}
                  <Box sx={{flexGrow: 1}}>
                    <Link to='/login'  style={{textDecoration: 'none'}}>
                      <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                        <Typography>
                          ¿Ya tienes cuenta?
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                  {/* BTN AFILIARSE */}
                  <Box>
                    <Btn_afiliarse
                      //link='/firmaDeAfiliacion'
                      json={afiliacion}
                      update={guardaAfiliado}
                      method='POST'
                      disabled={disabledBtn}
                      label='AFILIARSE'
                      signatureEmailName='val'
                      btnLogInIniciar='si'
                    />
                  </Box>
                </Container>
              </Box>
            </Box>
          </Box>
        </Container>
      }
      {isMediumScreen &&
        <Container>
          <Box sx={{marginTop: '3%', borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', paddingLeft: '1.5vw'}}>
            <Typography color='primary' sx={{fontSize: 25, fontWeight: 800}}>
              PREAFILIACIÓN SINDICATO POR MEXICO
            </Typography>
            <Typography sx={{fontSize: 20, fontWeight: 400, color: '#303030', textAlign: 'justify'}}>
              En Sindicato por México, nos comprometemos a construir un futuro más justo y equitativo para todos los trabajadores mexicanos.
            </Typography>
          </Box>
          <Box>
            <Box sx={{marginTop: '3vh'}}>
              <Typography color='primary' sx={{fontSize: 23, fontWeight: 800, width: '100%', display: 'flex', justifyContent: 'center'}}>
                ¿Qué beneficios obtendrás al afiliarte?
              </Typography>
              <Typography sx={{fontSize: 18, fontWeight: 500, color: '#303030', marginTop: '2%', marginLeft: '10%'}}>
                <li> Plan de pensiones sindicales </li>
                <li> Descuentos exclusivos </li>
                <li> Indemnización por riesgo laboral </li>
                <li> Seguro por muerte accidental </li>
                <li> Consulta médica gratuita </li>
                <li> Cajas de ahorro sindicales </li>
                <li> Previsión social </li>
              </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <Box sx={{marginBlock: '3vh', width: '80%', background: '#F4F4F4', boxShadow: 7, borderRadius: 8, paddingBlock: '0.5vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <figure>
                    <img 
                      src={src_url + 'logo_spm.png'} 
                      style={{width: '10ch'}}
                      alt="Logo_SPM"
                    />
                  </figure>
                </Box>
                <Container sx={{marginBottom: '5%', display:'flex', justifyContent:'space-between'}}>
                  <TextField 
                    type='text'
                    label='A. Paterno' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '32%'}}
                    inputProps={{maxLength: 40}}
                    onChange={apellidoPaternoAfiliadoChangeHandler}
                    onBlur={nameCookie}
                  />
                  <TextField 
                    type='text'
                    label='A. Materno' 
                    color='primary' 
                    variant='filled' 
                    sx={{width: '32%'}}
                    onChange={apellidoMaternoAfiliadoChangeHandler}
                    onBlur={nameCookie}
                  />
                  <TextField 
                    type='text'
                    label='Nombre(s)' 
                    color='primary' 
                    variant='filled' 
                    sx={{width: '32%'}}
                    onChange={nombreAfiliadoChangeHandler}
                    onKeyDown={apellidoMaternoKeyDownHandler}
                    onBlur={nameCookie}
                  />
                </Container>
                <Container sx={{marginBottom: '5%', display:'flex', justifyContent:'space-between'}}>
                  <TextField 
                    type='text'
                    label='Celular' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '49%'}}
                    inputProps={{ maxLength: 10 }}
                    value={afiliacion.celular}
                    onChange={celularAfiliadoChangeHandler}
                    onKeyDown={celularKeyDownHandler}
                  />
                  <TextField 
                    type='text'
                    label='CURP' 
                    color='primary' 
                    variant='filled' 
                    inputProps={{ maxLength: 18 }}
                    value={afiliacion.curp}
                    onChange={curpAfiliadoChangeHandler}
                    sx={{width: '49%'}}
                  />
                </Container>
                <Container sx={{marginBottom: '5%', display:'flex', justifyContent:'space-between'}}>
                  <TextField 
                    type='email'
                    label='Correo' 
                    color='primary' 
                    variant='filled'
                    sx={{width: '32%', marginBottom: '4%'}}
                    inputProps={{maxLength: 40}}
                    onChange={emailAfiliadoChangeHandler}
                    onBlur={emailCookie}
                  />
                  <TextField 
                    type='password'
                    label='Contraseña' 
                    color='primary' 
                    variant='filled' 
                    onChange={passwordAfiliadoChangeHandler}
                    sx={{width: '32%', marginBottom: '4%'}}
                  />
                  <TextField 
                    type='password'
                    label='Confirma contraseña' 
                    color='primary' 
                    variant='filled' 
                    onChange={confPasswordChangeHandler}
                    sx={{width: '32%'}}
                  />
                </Container>
                <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      Acepto los{' '}
                      <Link 
                        to="/terminos-y-condiciones" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: '#1976d2', textDecoration: 'underline' }}
                      >
                        términos y condiciones
                      </Link>
                    </Typography>
                  }
                />
                </Container>
                {/* BTN's */}
                <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                  {/* BTN  */}
                  <Box sx={{flexGrow: 1}}>
                    <Link to='/login'  style={{textDecoration: 'none'}}>
                      <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                        <Typography>
                          ¿Ya tienes cuenta?
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                  {/* BTN AFILIARSE */}
                  <Box>
                    <Btn_afiliarse
                      //link='/firmaDeAfiliacion'
                      json={afiliacion}
                      update={guardaAfiliado}
                      method='POST'
                      disabled={disabledBtn}
                      label='AFILIARSE'
                      signatureEmailName='val'
                      btnLogInIniciar='si'
                    />
                  </Box>
                </Container>
              </Box>
            </Box>
          </Box>
        </Container>
      }
      {isLargeScreen &&
        <Box>
          <Box sx={{marginLeft: '10%', marginTop: '3%', borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303'}}>
            <Typography color='primary' sx={{fontSize: 25, fontWeight: 800, width: '68%', marginLeft: '1%'}}>
              PREAFILIACIÓN SINDICATO POR MEXICO
            </Typography>
            <Typography sx={{fontSize: 18, fontWeight: 400, color: '#303030', width: '68%', marginLeft: '1%'}}>
              En Sindicato por México, nos comprometemos a construir un futuro más justo y equitativo para todos los trabajadores mexicanos.
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{marginLeft: '10%', marginTop: '3%', width: '40%'}}>
              <Typography color='primary' sx={{fontSize: 23, fontWeight: 800, width: '100%'}}>
                ¿Qué beneficios obtendrás al afiliarte?
              </Typography>
              <Typography sx={{fontSize: 18, fontWeight: 500, color: '#303030', marginTop: '2%', marginLeft: '10%'}}>
                <li> Plan de pensiones sindicales </li>
                <li> Descuentos exclusivos </li>
                <li> Indemnización por riesgo laboral </li>
                <li> Seguro por muerte accidental </li>
                <li> Consulta médica gratuita </li>
                <li> Cajas de ahorro sindicales </li>
                <li> Previsión social </li>
              </Typography>
            </Box>
            <Box sx={{marginLeft: '0%', marginTop: '3%', width: '40%', background: '#F4F4F4', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                    alt="Logo_SPM"
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '4%', display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='Apellido paterno' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '48%'}}
                  inputProps={{maxLength: 40}}
                  value={afiliacion.apellido_p}
                  onChange={apellidoPaternoAfiliadoChangeHandler}
                  onKeyDown={apellidoPaternoKeyDownHandler}
                  onBlur={nameCookie}
                />
                <TextField 
                  type='text'
                  label='Apellido materno' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '48%'}}
                  value={afiliacion.apellido_m}
                  onChange={apellidoMaternoAfiliadoChangeHandler}
                  onKeyDown={apellidoMaternoKeyDownHandler}
                  onBlur={nameCookie}
                />
              </Container>
              <Container sx={{marginBottom: '4%', display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='Nombre(s)' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '48%'}}
                  inputProps={{maxLength: 40}}
                  onChange={nombreAfiliadoChangeHandler}
                  onKeyDown={apellidoMaternoKeyDownHandler}
                  onBlur={nameCookie}
                  />
                <TextField 
                  type='text'
                  label='Celular' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '48%'}}
                  inputProps={{ maxLength: 10 }}
                  value={afiliacion.celular}
                  onChange={celularAfiliadoChangeHandler}
                  onKeyDown={celularKeyDownHandler}
                  />
              </Container>
              <Container sx={{marginBottom: '4%', display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='CURP' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '48%'}}
                  inputProps={{ maxLength: 18 }}
                  value={afiliacion.curp}
                  onChange={curpAfiliadoChangeHandler}
                />
                <TextField 
                  type='email'
                  label='Correo electrónico' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '48%', marginBottom: '4%'}}
                  inputProps={{ maxLength: 40 }}
                  value={afiliacion.email}
                  onChange={emailAfiliadoChangeHandler}
                  onBlur={emailCookie}
                />
              </Container>
              <Container sx={{marginBottom: '4%', display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '48%'}}
                />
                <TextField 
                  type='password'
                  label='Confirma contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={confPasswordChangeHandler}
                  sx={{width: '48%'}}
                />
              </Container>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      Acepto los{' '}
                      <Link 
                        to="/terminos-y-condiciones" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: '#1976d2', textDecoration: 'underline' }}
                      >
                        términos y condiciones
                      </Link>
                    </Typography>
                  }
                />
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to='/login'  style={{textDecoration: 'none'}}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        ¿Ya tienes cuenta?
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                {/* BTN AFILIARSE */}
                <Box>
                  <Btn_afiliarse
                    //link='/firmaDeAfiliacion'
                    json={afiliacion}
                    update={guardaAfiliado}
                    method='POST'
                    disabled={disabledBtn}
                    label='AFILIARSE'
                    signatureEmailName='val'
                    btnLogInIniciar='si'
                  />
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isExtraLargeScreen &&
        <Box>
          <Box sx={{marginLeft: '10%', marginTop: '3%', borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303'}}>
            <Typography color='primary' sx={{fontSize: 40, fontWeight: 800, width: '68%', marginLeft: '1%'}}>
              PREAFILIACIÓN SINDICATO POR MEXICO
            </Typography>
            <Typography sx={{fontSize: 20, fontWeight: 400, color: '#303030', width: '68%', marginLeft: '1%'}}>
              En Sindicato por México, nos comprometemos a construir un futuro más justo y equitativo para todos los trabajadores mexicanos.
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{marginLeft: '10%', marginTop: '2%', width: '33%'}}>
              <Typography color='primary' sx={{fontSize: 36, fontWeight: 800, width: '100%'}}>
                ¿Qué beneficios obtendrás al afiliarte?
              </Typography>
              <Typography sx={{fontSize: 24, fontWeight: 500, color: '#303030', marginTop: '2%', marginLeft: '10%'}}>
                <li> Plan de pensiones sindicales </li>
                <li> Descuentos exclusivos </li>
                <li> Indemnización por riesgo laboral </li>
                <li> Seguro por muerte accidental </li>
                <li> Consulta médica gratuita </li>
                <li> Cajas de ahorro sindicales </li>
                <li> Previsión social </li>
              </Typography>
            </Box>
            <Box sx={{marginLeft: '12%', marginTop: '0%', width: '35%', background: '#F4F4F4', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                    alt="Logo_SPM"
                  />
                </figure>
              </Box>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='Apellido paterno' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '49%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  value={afiliacion.apellido_p}
                  onChange={apellidoPaternoAfiliadoChangeHandler}
                  onKeyDown={apellidoPaternoKeyDownHandler}
                  onBlur={nameCookie}
                />
                <TextField 
                  type='text'
                  label='Apellido materno' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '49%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  value={afiliacion.apellido_m}
                  onChange={apellidoMaternoAfiliadoChangeHandler}
                  onKeyDown={apellidoMaternoKeyDownHandler}
                  onBlur={nameCookie}
                  />
              </Container>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='Nombre(s)' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '49%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  onChange={nombreAfiliadoChangeHandler}
                  onKeyDown={apellidoMaternoKeyDownHandler}
                  onBlur={nameCookie}
                />
                <TextField 
                  type='text'
                  label='Celular' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '49%', marginBottom: '4%'}}
                  inputProps={{ maxLength: 10 }}
                  value={afiliacion.celular}
                  onChange={celularAfiliadoChangeHandler}
                  onKeyDown={celularKeyDownHandler}
                />
              </Container>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='text'
                  label='CURP' 
                  color='primary' 
                  variant='filled' 
                  inputProps={{ maxLength: 18 }}
                  value={afiliacion.curp}
                  onChange={curpAfiliadoChangeHandler}
                  sx={{width: '49%', marginBottom: '4%'}}
                />
                <Box sx={{ width: '49%', marginBottom: '4%' }}>
                  <TextField
                    type='email'
                    label='Correo electrónico'
                    color='primary'
                    variant='filled'
                    sx={{ width: '100%' }}
                    inputProps={{ maxLength: 40 }}
                    value={afiliacion.email}
                    onChange={emailAfiliadoChangeHandler}
                    onBlur={emailCookie}
                  />
                  {!esCorreoValido(afiliacion.email) && afiliacion.email.length > 0 && (
                    <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                      Correo inválido
                    </Typography>
                  )}
                </Box>

              </Container>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '49%', marginBottom: '4%'}}
                />
                <TextField 
                  type='password'
                  label='Confirma contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={confPasswordChangeHandler}
                  sx={{width: '49%'}}
                />
              </Container>
              <Container sx={{display:'flex', justifyContent:'space-between'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      Acepto los{' '}
                      <Link 
                        to='/terminos_y_condiciones'
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: '#1976d2', textDecoration: 'underline' }}
                      >
                        términos y condiciones
                      </Link>
                    </Typography>
                  }
                />
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '4%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to='/login'  style={{textDecoration: 'none'}}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        ¿Ya tienes cuenta?
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                {/* BTN AFILIARSE */}
                <Box>
                  <Btn_afiliarse
                    //link='/firmaDeAfiliacion'
                    json={afiliacion}
                    update={guardaAfiliado}
                    method='POST'
                    disabled={disabledBtn}
                    label='AFILIARSE'
                    signatureEmailName='val'
                    btnLogInIniciar='si'
                  />
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
    </Box>  
  );
};
