import { Box, Grid, Paper, Container, Typography, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import React from 'react'
import { MenuAdmin } from './MenuAdmin'

export const AltaEmpresas = () => {    
  const isXsScreen = window.innerWidth < 600; // Cambia el valor según el tamaño de pantalla que consideres como xs

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
      <Grid container spacing={0}>
          <Grid item xs={12} sm={2}>
            <MenuAdmin isXsScreen={isXsScreen}/>
          </Grid>
          <Grid item xs={12} sm={10} sx={{display: 'flex', justifyContent: 'center', marginTop: '2%'}}>
            <Box sx={{width: '90%', height: '90vh'}}>
              <Paper elevation={3} sx={{width: '100%', height: '100%'}}>
                <Container sx={{display: 'flex', flexDirection: 'column'}}>
                  <Box sx={{display: 'flex', marginBlock: '2%', width: '100%', maxWidth: 500, 
                //   backgroundColor: 'pink'
                  }}>
                    <Typography fontWeight={700} variant='h3' color='#353535'>
                        Alta de empresas    
                    </Typography>
                  </Box>
                  <Box sx={{marginBlock: '2%', width: '100%', 
                //   backgroundColor: 'violet'
                  }}>
                    <Box sx={{display: 'flex', flexDirection: 'arrow', width: '100%', marginBottom: '2%', 
                    // backgroundColor: 'lightblue'
                    }}>
                        <TextField  
                            type='text'
                            label='Nombre comercial'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                        <TextField  
                            type='text'
                            label='Nickname de la empresa'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%', marginRight: '2%', marginLeft: '2%'}}
                        />
                        <TextField  
                            type='text'
                            label='Razón social'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'arrow', width: '100%', marginBottom: '2%', 
                    // backgroundColor: 'lightblue'
                    }}>
                        <TextField  
                            type='text'
                            label='RFC'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                        <FormControl fullWidth sx={{width:'32%', marginRight: '2%', marginLeft: '2%'}}>
                            <InputLabel 
                                id='grado-select-small-label' 
                                sx={{width: '100%'}}
                            >
                                Grado de riesgo (IMSS)
                            </InputLabel>
                            <Select  
                                labelId='grado-select-small-label'
                                id='grado-select-small'
                                value={age}
                                label='Grado'
                                onChange={handleChange}

                                // color='primary'
                                // variant='filled'
                                // sx={{width: '100%', marginRight: '2%', marginLeft: '2%'}}
                            >
                                <MenuItem value='Clase I'>
                                    Clase I
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Clase II
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Clase III
                                </MenuItem>
                                <MenuItem value='Clase IV'>
                                    Clase IV
                                </MenuItem>
                                <MenuItem value='Clase V'>
                                    Clase V
                                </MenuItem>
                            </Select>    
                        </FormControl> 
                        <TextField  
                            type='text'
                            label='Giro de la empresa'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'arrow', width: '100%', marginBottom: '2%', 
                    // backgroundColor: 'lightblue'
                    }}>
                        <FormControl fullWidth sx={{width:'32%'}}>
                            <InputLabel 
                                id='grado-select-small-label' 
                                sx={{width: '100%'}}
                            >
                                Giro de la empresa
                            </InputLabel>
                            <Select  
                                labelId='grado-select-small-label'
                                id='grado-select-small'
                                value={age}
                                label='Grado'
                                onChange={handleChange}
                            >
                                <MenuItem value='Clase I'>
                                    Gobierno
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Productos
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Servicios
                                </MenuItem>
                                <MenuItem value='Clase IV'>
                                    Contrucción
                                </MenuItem>
                            </Select>    
                        </FormControl> 
                        <TextField  
                            type='text'
                            label='Número de regristro patronal'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%', marginRight: '2%', marginLeft: '2%'}}
                        />
                        <TextField  
                            type='text'
                            label='Calle'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'arrow', width: '100%', marginBottom: '2%',
                    // backgroundColor: 'lightblue'
                    }}>
                        <TextField  
                            type='text'
                            label='Num. exterior'
                            color='primary'
                            variant='filled'
                            sx={{width: '24%', marginRight: '2%'}}
                        /> 
                        <TextField  
                            type='text'
                            label='Num. interior'
                            color='primary'
                            variant='filled'
                            sx={{width: '24%', marginRight: '2%'}}
                        /> 
                        <TextField  
                            type='text'
                            label='C.P'
                            color='primary'
                            variant='filled'
                            sx={{width: '24%', marginRight: '2%'}}
                        /> 
                        <TextField  
                            type='text'
                            label='Colonia'
                            color='primary'
                            variant='filled'
                            sx={{width: '24%'}}
                        />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'arrow', width: '100%', marginBottom: '2%',
                    // backgroundColor: 'lightblue'
                    }}>
                        <TextField  
                            type='text'
                            label='Localidad'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%'}}
                        />
                        <TextField  
                            type='text'
                            label='Municipio'
                            color='primary'
                            variant='filled'
                            sx={{width: '32%', marginRight: '2%', marginLeft: '2%'}}
                        />
                        <FormControl fullWidth sx={{width:'32%'}}>
                            <InputLabel 
                                id='grado-select-small-label' 
                                sx={{width: '100%'}}
                            >
                                Estado
                            </InputLabel>
                            <Select  
                                labelId='grado-select-small-label'
                                id='grado-select-small'
                                value={age}
                                label='Grado'
                                onChange={handleChange}

                                // color='primary'
                                // variant='filled'
                                // sx={{width: '100%', marginRight: '2%', marginLeft: '2%'}}
                            >
                                <MenuItem value='Clase I'>
                                    Clase I
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Clase II
                                </MenuItem>
                                <MenuItem value='Clase II'>
                                    Clase III
                                </MenuItem>
                                <MenuItem value='Clase IV'>
                                    Clase IV
                                </MenuItem>
                                <MenuItem value='Clase V'>
                                    Clase V
                                </MenuItem>
                            </Select>    
                        </FormControl>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <Button variant='contained'>
                            <Typography sx={{color: '#ffff'}}>
                                Registrar
                            </Typography>
                        </Button>
                    </Box>
                  </Box>
                </Container>
              </Paper>
            </Box>
          </Grid>
      </Grid>
  )
}
