import React from 'react'
import './MenuCuestionario.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain, faIndustry, faHeartBroken, faExclamation } from '@fortawesome/free-solid-svg-icons'

export const MenuCuestionario = () => {
    return (
        <div className="div_img_menu_cuestionario">
            <img src="./img/menu_cuestionarios/menu_c11.svg" className="img_menu_cuestionario"/>
        </div>
    )
}
