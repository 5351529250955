import React, { useContext, useState } from "react";
import "./Button_CuestionarioSiguiente.css";
import { Link } from "react-router-dom";
import AdminContext from "../../../../context/AdminContext";
import Cookies from 'universal-cookie';

export const Button_siguente_admin = (props) => {
  const cookies = new Cookies();
  const ctx = useContext(AdminContext);
  // const [idEmpresaURL, setIdEmpresaURL] = useState(0);
  // const [linkTo, setLinkTo] = useState('');

  const clickHandler = () => {
    console.log(ctx.empresaId);
    if (ctx.empresaId > 0) {
      props.json.empresaId = ctx.empresaId;
    }
    if(props.cookieIdEmpresa == 'no'){
      props.update(props.json).then((data) => {
        console.log(data);
        if ( data.Id > 0 ){
          window.alert("Registro de " + props.windowAlert + " exitoso");
          // if (props.cookieIdEmpresa == 'si'){
          //   const expirationDate = new Date();
          //   expirationDate.setHours(expirationDate.getHours() + 2);
          //   cookies.set("IdEmpresa", data.Id, {path: "/", expires: expirationDate});
          //   console.log('cookie IdEmpresa: ' + cookies.get('IdEmpresa'));
          // }
        }
        else{
          window.alert("Registro de " + props.windowAlert + " incorrecto");
        }
        if (ctx.empresaId == 0) {
          ctx.updateAdmin(data.id);
        }
      });
    } 
  };

  // Convierte el objeto JSON en una cadena y lo codifica para que sea seguro en una URL
  const encodedDataJson = encodeURIComponent(JSON.stringify(props.json));

  return (
    <Link to={props.link + `?data=${encodedDataJson}`}>
      <button
        className="btn_siguiente_cuestionario_componente"
        onClick={clickHandler}
        disabled={props.disabled}
      >
        Continuar
      </button>
    </Link>
  );
};
