import React from 'react'
import { Header } from '../../header/Header'
import { Menu_lateral_analista } from '../menu_lateral_analista/Menu_lateral_analista'
import './DatosEncuestas_DashAnalista.css';
// import './graficaCharts';
// import { Chart } from "chart.js"
import Cookies from 'universal-cookie';

//import graficaCharts from 'graficaCharts'

export const DatosEncuestas_DashAnalista = () => {

  // instancia de cookies
  const cookies = new Cookies();

  // Tiempo de vida para cookie idUser
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 1);

  const data={
    labels:['emp1','emp2','emp3'],
    datasets:[{
      data:[70 , 15 , 15],
      backgroundcolor:['red', 'blue', 'green']
    } ]
  };
  const printCharts = () => {

    renderModelsChart ()
}
//  const renderModelsChart = () => {
//     const data = {
//         labels: ['uno', 'dos', 'tres'],
//         datasets: [{
//             data:[10, 20, 30]
//             }]
//     }
//     new Chart ('modelsChart', { type: 'doughnut', data })
// }


  return (
    <div>
        <Header
          nombre={cookies.get('nameUser')}
          expirationDate={expirationDate}
        />
        <Menu_lateral_analista
          idUsuario={cookies.get('idUser')}
        />
        <div className='box_DashAnalist'>
        <div
            style=
            {
                {
                  backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                  width: "100%", 
                  height: "15%", 
                  backgroundSize: "cover",
                  marginTop: "1%",
                  display: "block",
                }
            }
          >
            <div className='div_txt_TittleDatosEncuestas_Analista'>
              <p>ANALISTA</p>
              <p>DATOS DE ENCUESTAS</p>
            </div>
            
              <h1>
                Graficas
              </h1>
              <canvas
              id="modelsChart"
              >
                
                
              </canvas>
           
          </div>
          </div>
    </div>
  )
}

