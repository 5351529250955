import React, { useState } from "react";
import './Cuestionario21.css';
import './Estilo_Cuestionario_Escala.css';
import { PreguntaEscalaSN } from "./elementos_cuestionarios/PreguntaEscalaSN";
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario26 = (props) => {

    const sigPag = "207";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 13;
    var numPreguntas= 0;

    const [deseableState, setDeseableState] = useState(true);

      const [sessionJson, setSessionJson] = useState({
        referenciaId: 2,     //Dato estático
        seccionId: 10,       //Dato estático
        tipoPreguntaId: 2,   //Dato estático
        preguntaInicial: 28, //Dato estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        usuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });

    const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 28, respuesta: 0 },
          { pregunta: 29, respuesta: 0 },
          { pregunta: 30, respuesta: 0 },
          { pregunta: 31, respuesta: 0 },
          { pregunta: 32, respuesta: 0 },
          { pregunta: 33, respuesta: 0 },
          { pregunta: 34, respuesta: 0 },
          { pregunta: 35, respuesta: 0 },
          { pregunta: 36, respuesta: 0 },
          { pregunta: 37, respuesta: 0 },
          { pregunta: 38, respuesta: 0 },
          { pregunta: 39, respuesta: 0 },
          { pregunta: 40, respuesta: 0 }
        ]
      });

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);
      
    const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg,resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
        const respuestas = {respuestasJson};
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson );
        console.log({ respuestasJson });
      };

    return (
        <div className="box_menu_y_cuestionario_escala">
            <div className="cuestionario_div_escala">
                <div className="div_svg_menu_escala">
                    <img src="./img/menu_cuestionarios/menu_c26.svg" className="img_svg_escala"/>
                </div>
                <div className="text_cuestionario_escala">
                    <img src="./img/barra_progreso/riesgo_psicosocial/barra_de_progreso_26.svg" className="img_progreso_escala"/>
                    <p className="titulo_cuestionario_escala"><img src="./img/barra_progreso/iconos/psicosocial_icono_cuestionario.svg"/>  RIESGO PSICOSOCIAL</p>

                    <p className="instrucciones_cuestionario_escala">Las preguntas siguientes se refieren a las relaciones con sus compañeros de trabajo y su jefe.</p>
                    <div className="body_escalas">
                    <div className="titulos_escalas">
                    <table>
                    <tbody>
                    <tr>
                        <td><p className="box_titulos_escalas">Siempre</p></td>
                        <td><p className="box_titulos_escalas">Casi<br></br>siempre</p></td>
                        <td><p className="box_titulos_escalas">Algunas<br></br>veces</p></td>
                        <td><p className="box_titulos_escalas_1">Casi<br></br>nunca</p></td>
                        <td><p className="box_titulos_escalas_2">Nunca</p></td>   
                    </tr>
                    </tbody>
                    </table>
                    </div>

                    <div className="preguntas_escala_sn">
                      
                      <PreguntaEscalaSN
                        numPreg="28"
                        texto="Mi jefe tiene en cuenta mis puntos de vista y opiniones"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                                    
                      <PreguntaEscalaSN
                        numPreg="29"
                        texto="Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="30"
                        texto="Puedo confiar en mis compañeros de trabajo"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="31"
                        texto="Cuando tenemos que realizar trabajo de equipo los compañeros colaboran"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="32"
                        texto="Mis compañeros de trabajo me ayudan cuando tengo dificultades"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="33"
                        texto="En mi trabajo puedo expresarme libremente sin interrupciones"
                        update={updatePreg}
                        rSiempre="0"
                        rCasiSiempre="1"
                        rAlgunasVeces="2"
                        rCasiNunca="3"
                        rNunca="4"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="34"
                        texto="Recibo críticas constantes a mi persona y/o trabajo"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="35"
                        texto="Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="36"
                        texto="Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de decisiones"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />

                      <PreguntaEscalaSN
                        numPreg="37"
                        texto="Se manipulan las situaciones de trabajo para hacerme parecer un mal trabajador"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="38"
                        texto="Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                      
                      <PreguntaEscalaSN
                        numPreg="39"
                        texto="Me bloquean o impiden las oportunidades que tengo para obtener ascenso o mejora en mi trabajo"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />
                    
                      <PreguntaEscalaSN
                        numPreg="40"
                        texto="He presenciado actos de violencia en mi centro de trabajo"
                        update={updatePreg}
                        rSiempre="4"
                        rCasiSiempre="3"
                        rAlgunasVeces="2"
                        rCasiNunca="1"
                        rNunca="0"
                      />

                  </div>
                  </div>
                    
                    <Button_CuestionarioSiguente
                      className="btn_siguiente_cuestionario_binario"
                      sesion={sessionJson}
                      perfil={perfilJson}
                      respuestas={respuestasJson}
                      disabled={deseableState}
                      upDate={"guardaRespuestas"}
                      condicionPreg='200Restantes'
                    />

                </div>
            </div>
        </div>
    )
}