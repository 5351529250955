import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { getUrlParameter } from '../../../../utils/jstools';
import { actualizaEmpresa, getUserById, getEmpresaById, actualizaUsuario, getPerfilEmpleadoByUsuarioId, putPerfilEmpleado } from '../../../../utils/services';
import { Header } from '../../header/Header';
import { Button_actualizaEmpresa } from '../btns/Button_actualizaEmpresa';
import { Menu_lateral_admin } from '../menu_lateral_admin/Menu_lateral_admin';
import './Perfil_empresa_dashboard1_admin.css';

export const Perfil_empresa_dashboard1_admin = () => {

    // instancia de cookies
    const cookies = new Cookies();

    // Tiempo de vida para cookie idUser
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);

    const [ perfilUsuario, setPerfilUsuario ] = useState({
        Id: cookies.get('idUser'),
        nombre: "",
        paterno: "",
        materno: "",
        email: "",
        telOficina: "",
        celular: "",
        numEmp: "",
        pass: "",
        rfc: "",
        escolaridad: "",
        fecNac:"",
        rolId:1,
        generoId: "",
        EmpresaId: cookies.get('idEmpresa'),
        qId: ""
    });

    const [perfilJson, setPerfilJson] = useState({
        qId: ""
      });

    const [perfilEmpresa, setPerfilEmpresa] = useState ({
        // Id: cookies.get('idEmpresa'),
        Id: parseInt(getUrlParameter('empresaId')),
        NickName:"",
        Name: "",
        RasonSocial: "",
        Rfc: "",
        Riesgo: "",
        RegPatronal:"",
        Calle: "",
        NumExt: "",
        Colonia: "",
        Cp: "",
        Municipio: "",
        Localidad: "",
        Estado: "",
        // Referencia: "",
        GiroId:"",
    });

    // state para ocultar botones depende del qId
    const [ validateVisibility, setValidateVisibility ] = useState( "visible" );

    useEffect(() => {
        

    },[]);

    useEffect(() => {
        console.log('idempresa::: ' + perfilEmpresa.Id);
        // getEmpresaById(perfilEmpresa.Id).then((data)=>{
        getEmpresaById(getUrlParameter('empresaId')).then((data)=>{
            setPerfilEmpresa((prevState) => {
                return { ...prevState,
                    Name: data.Name,
                    RasonSocial: data.RasonSocial,
                    Rfc: data.Rfc,
                    Calle: data.Calle,
                    NumExt: data.NumExt,
                    Colonia: data.Colonia,
                    Cp: data.Cp,
                    Municipio: data.Municipio,
                    Localidad: data.Localidad,
                    Estado: data.Estado,
                    // Referencia: data.Referencia,

                    NickName: data.NickName,
                    Riesgo: data.Riesgo,
                    RegPatronal: data.RegPatronal,
                    NumInt: data.NumInt,
                    GiroId: data.GiroId
                };
            });
        });

        getPerfilEmpleadoByUsuarioId(perfilUsuario.Id).then((data) =>
          {
            const perfilAdmin = data[0];
            switch (perfilAdmin.qId)
              {
                case "100":
                  setValidateVisibility("hidden");
                break;
                case "111":
                  setValidateVisibility("hidden");
                break;
              }
          }
        );

        // getUserById(perfilUsuario.Id).then((data) => {
        getUserById(getUrlParameter('userId')).then((data) => {
            setPerfilUsuario((prevState) => {
                return { ...prevState,
                    nombre: data.nombre,
                    paterno: data.paterno,
                    materno: data.materno,
                    email: data.email,
                    celular: data.celular,
                    pass: data.pass,
                    rfc: data.rfc,
                    telOficina: data.telOficina,
                    numEmp: data.numEmp,
                    generoId: data.generoId,
                    fecNac: data.fecNac
                };
            });
        });
    }, []);

    // Llenar el giro de la empresa
    var giro= "";
    switch ( perfilEmpresa.GiroId ) 
        {
            case 1:
                giro="Gobierno"
            break;
            case 2:
                giro="Productos"
            break;
            case 3:
                giro="Servicios"
            break;
            case 4:
                giro="Construccion"
            break;
        }

    // state para btn editar
    const [ dis, setDis ] = useState(true)

    // Condicion para boton guardar cambios
    var diss = true;
    if ( 
      (((perfilEmpresa.Name != "") & (perfilEmpresa.NickName != "") &
      (perfilEmpresa.RasonSocial != "") & (perfilEmpresa.Rfc != "") &
      (perfilEmpresa.Celular != "") & (perfilEmpresa.RegPatronal != "") & 
      (perfilEmpresa.Calle != "") & (perfilEmpresa.NumExt != "") & 
      (perfilEmpresa.NumInt != "") & (perfilEmpresa.Cp != "") & 
      (perfilEmpresa.Colonia != "") & (perfilEmpresa.Localidad != "") & 
      (perfilEmpresa.Municipio != "")) & ( dis==false ))
    ){
      diss = false;
    }
    else {
      diss = true;
    }

    // Handlers editar
    const nomComerChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Name: event.target.value 
            };
        });
        console.log('json empresa: ' + perfilEmpresa.Id);
    };
    const nickChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
            NickName: event.target.value 
            };
        });
    };
    const rasonChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              RasonSocial: event.target.value 
            };
        });
    };
    const rfcChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Rfc: event.target.value 
            };
        });
    };
    const gradoRiesgoChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Riesgo: event.target.value 
            };
        });
    };
    const giroChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              GiroId: event.target.value 
            };
        });
    };
    const regPatChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              RegPatronal: event.target.value 
            };
        });
    };
    const calleChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Calle: event.target.value 
            };
        });
    };
    const numExtChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              NumExt: event.target.value 
            };
        });
    };
    const numIntChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              NumInt: event.target.value 
            };
        });
    };
    const cpChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Cp: event.target.value 
            };
        });
    };
    const colChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Colonia: event.target.value 
            };
        });
    };
    const localidadChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Localidad: event.target.value 
            };
        });
    };
    const municipioChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Municipio: event.target.value 
            };
        });
    };
    const estadoChangeHandler = (event) => {
        setPerfilEmpresa((prevState) => {
          return { 
              ...prevState, 
              Estado: event.target.value 
            };
        });
    };

    // Link para el boton de guardar cambios
    const linkGuardarCambios = "/perfil-empresa_admin?userId="+ perfilUsuario.Id +"&empresaId="+ perfilEmpresa.Id

    // clickHander de btn validar
    const clickHandlerValidar = () => {
        actualizaUsuario(perfilUsuario).then((data) =>
          {
            console.log("data admin: "+data);
          }
        );
       
        getPerfilEmpleadoByUsuarioId(perfilUsuario.Id).then((data) =>
        // getPerfilEmpleadoByUsuarioId(getUrlParameter('userId')).then((data) =>
          {
            const perfilAdmin = data[0];
            const putPerfil = perfilJson;
            // switch ( perfilAdmin.qId )
            switch (getUrlParameter('qId'))
              {
                case "":
                  putPerfil.Id = perfilAdmin.Id;
                  putPerfil.usuarioId = perfilUsuario.Id;
                  putPerfil.qId = "001";
                  putPerfilEmpleado(putPerfil);
                  setValidateVisibility("hidden");
                break;
                case "100":
                  putPerfil.Id = perfilAdmin.Id;
                  putPerfil.usuarioId = perfilUsuario.Id;
                  putPerfil.qId = "111";
                  putPerfilEmpleado(putPerfil);
                  setValidateVisibility("hidden");
                break;
              }
                    
            window.alert("Validación de empresa exitosa.");

          }
        );
      };

    return (
    <div>
        <Header
            // nombre={cookies.get('nameUser')}
            nombre={getUrlParameter('userName')}
            expirationDate={expirationDate}
        />
        <Menu_lateral_admin
        // idUsuario={cookies.get('idUser')}
        idUsuario={getUrlParameter('userId')}
        // idEmpresa={cookies.get('idEmpresa')}
        idEmpresa={getUrlParameter('empresaId')}
        qId={getUrlParameter('qId')}
        nombre={getUrlParameter('userName')}
        />
        <div className='box_DashAdmin1'>
            <div
                style=
                {
                    {
                        backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                        width: "100%", 
                        height: "15%", 
                        backgroundSize: "cover",
                        marginTop: "1%",
                        display: "block",
                    }
                }
            >
                <div className='div_txt_DashAdmin1_InfoEmpresa'>
                    <p>ADMINISTRADOR</p>
                    <p>PERFIL EMPRESA</p>
                </div>
            </div> 
            {/* INPUTS */}
            <div className='div_scroll_DashAdmin_PerfilEmpresa'>
                <div className='div_Horizontal_RegistroEmpresa'>    
                    <div className=''>
                        <div className='PerfilEmpresa'>
                            <div className='input1_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Nombre comercial</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder={perfilEmpresa.Name} 
                                    disabled={dis}
                                    value={perfilEmpresa.Name}
                                    onChange={nomComerChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input2_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Nickname de empresa</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder={perfilEmpresa.NickName} 
                                    disabled={dis}
                                    value={perfilEmpresa.NickName}
                                    onChange={nickChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input3_RegistroEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Razón social</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder={perfilEmpresa.RasonSocial} 
                                    disabled={dis}
                                    value={perfilEmpresa.RasonSocial}
                                    onChange={rasonChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>  
                <div className='div_Horizontal_RegistroEmpresa'>    
                    <div className=''>
                        <div className='PerfilEmpresa'>
                            <div className='input1_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>RFC</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Rfc} 
                                    disabled={dis}
                                    value={perfilEmpresa.Rfc}
                                    onChange={rfcChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input2_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Grado de riesgo (IMSS)</p>
                                <select 
                                    id="id_GiroEmpresa_RegistroEmpresa" 
                                    name="name_GiroEmpresa_RegistroEmpresa" 
                            className='inp_perfilEmpresa'
                                    disabled={dis}
                                    value={perfilEmpresa.Riesgo}
                                    onChange={gradoRiesgoChangeHandler}
                                >
                                    <option value={perfilEmpresa.Riesgo}>
                                        {perfilEmpresa.Riesgo}
                                    </option>
                                    <option 
                                        // value={}
                                    >
                                        Clase I
                                    </option>
                                    <option 
                                        // value="2"
                                    >
                                        Clase II
                                    </option>
                                    <option 
                                        // value="3"
                                    >
                                        Clase III
                                    </option>
                                    <option 
                                        // value="4"
                                    >
                                        Clase IV
                                    </option>
                                    <option 
                                        // value="5"
                                    >
                                        Clase V
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='inpu3_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Giro de la empresa</p>
                                <select 
                                    id="id_GiroEmpresa_RegistroEmpresa" 
                                    name="name_GiroEmpresa_RegistroEmpresa" 
                            className='inp_perfilEmpresa' 
                                    disabled={dis}
                                    value={perfilEmpresa.GiroId}
                                    onChange={giroChangeHandler}
                                >
                                    <option value={giro}> {giro} </option>
                                    <option value={1}>Gobierno</option>
                                    <option value={2}>Productos</option>
                                    <option value={3}>Servicios</option>
                                    <option value={4}>Construcción</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='div_Horizontal_RegistroEmpresa'>    
                    <div className=''>
                        <div className='PerfilEmpresa'>
                            <div className='input1_PefilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Número de regristro patronal</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.RegPatronal} 
                                    disabled={dis}
                                    value={perfilEmpresa.RegPatronal}
                                    onChange={regPatChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input2_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Calle</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Calle} 
                                    disabled={dis}
                                    value={perfilEmpresa.Calle}
                                    onChange={calleChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='inpu3_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Número exterior</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.NumExt} 
                                    disabled={dis}
                                    value={perfilEmpresa.NumExt}
                                    onChange={numExtChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='div_Horizontal_RegistroEmpresa'>    
                    <div className=''>
                        <div className='PerfilEmpresa'>
                            <div className='input1_PerfilEmpresa'>
                                {/* <p className='txt_InputTitle_RegistroEmpresa'>Número interior</p>
                                <input 
                                    className='pa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.numInt} 
                                    disabled={dis}
                                    value={perfilEmpresa.numInt}
                                    onChange={numIntChangeHandler}
                                /> */}
                                {/* Pongo el estado aqui en lo que se agrega el campo en el API para el numero interior */}
                        <p className='txt_InputTitle_PerfilEmpresa'>Estado</p>
                                <select 
                                    id="id_Estado_RegistroEmpresa" 
                                    name="name_Estado_RegistroEmpresa" 
                            className='inp_perfilEmpresa' 
                                    disabled={dis}
                                    value={perfilEmpresa.Estado}
                                    onChange={estadoChangeHandler}
                                >
                                    <option value={perfilEmpresa.Estado}> {perfilEmpresa.Estado} </option>
                                    <option>Aguascalientes</option>
                                    <option>Baja California</option>
                                    <option>Baja California Sur</option>
                                    <option>Campeche</option>
                                    <option>Chiapas</option>
                                    <option>Chihuahua</option>
                                    <option>Ciudad de México</option>
                                    <option>Coahuila</option>
                                    <option>Colima</option>
                                    <option>Durango</option>
                                    <option>México</option>
                                    <option>Guanajuato</option>
                                    <option>Guerrero</option>
                                    <option>Hidalgo</option>
                                    <option>Jalisco</option>
                                    <option>Michoacán</option>
                                    <option>Morelos</option>
                                    <option>Nayarit</option>
                                    <option>Nuevo León</option>
                                    <option>Oaxaca</option>
                                    <option>Puebla</option>
                                    <option>Querétaro</option>
                                    <option>Quintana Roo</option>
                                    <option>San Luis Potosí</option>
                                    <option>Sinaloa</option>
                                    <option>Sonora</option>
                                    <option>Tabasco</option>
                                    <option>Tamaulipas</option>
                                    <option>Tlaxcala</option>
                                    <option>Veracruz</option>
                                    <option>Yucatán</option>
                                    <option>Zacatecas</option>
                                </select>
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input2_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Código postal</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    maxLength={5}
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Cp} 
                                    disabled={dis}
                                    value={perfilEmpresa.Cp}
                                    onChange={cpChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='inpu3_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Colonia</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Colonia} 
                                    disabled={dis}
                                    value={perfilEmpresa.Colonia}
                                    onChange={colChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='div_Horizontal_RegistroEmpresa'>    
                    <div className=''>
                        <div className='PerfilEmpresa'>
                            <div className='input1_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Localidad</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Localidad} 
                                    disabled={dis}
                                    value={perfilEmpresa.Localidad}
                                    onChange={localidadChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='PerfilEmpresa'>
                            <div className='input2_PerfilEmpresa'>
                        <p className='txt_InputTitle_PerfilEmpresa'>Municipio</p>
                                <input 
                            className='inp_perfilEmpresa' 
                                    type="text" 
                                    titlle="" 
                                    placeholder= {perfilEmpresa.Municipio} 
                                    disabled={dis}
                                    value={perfilEmpresa.Municipio}
                                    onChange={municipioChangeHandler}
                                />
                            </div>
                        </div>
                        <div className='input_RegistroEmpresa'>
                            <div className='inpu3_RegistroEmpresa'>
                                {/* <p className='txt_InputTitle_RegistroEmpresa'>Estado</p>
                                <select 
                                    id="id_Estado_RegistroEmpresa" 
                                    name="name_Estado_RegistroEmpresa" 
                                    className='pa' 
                                    disabled={dis}
                                    value={perfilEmpresa.estado}
                                    onChange={estadoChangeHandler}
                                >
                                    <option value={perfilEmpresa.estado}> {perfilEmpresa.estado} </option>
                                    <option>Aguascalientes</option>
                                    <option>Baja California</option>
                                    <option>Baja California Sur</option>
                                    <option>Campeche</option>
                                    <option>Chiapas</option>
                                    <option>Chihuahua</option>
                                    <option>Ciudad de México</option>
                                    <option>Coahuila</option>
                                    <option>Colima</option>
                                    <option>Durango</option>
                                    <option>México</option>
                                    <option>Guanajuato</option>
                                    <option>Guerrero</option>
                                    <option>Hidalgo</option>
                                    <option>Jalisco</option>
                                    <option>Michoacán</option>
                                    <option>Morelos</option>
                                    <option>Nayarit</option>
                                    <option>Nuevo León</option>
                                    <option>Oaxaca</option>
                                    <option>Puebla</option>
                                    <option>Querétaro</option>
                                    <option>Quintana Roo</option>
                                    <option>San Luis Potosí</option>
                                    <option>Sinaloa</option>
                                    <option>Sonora</option>
                                    <option>Tabasco</option>
                                    <option>Tamaulipas</option>
                                    <option>Tlaxcala</option>
                                    <option>Veracruz</option>
                                    <option>Yucatán</option>
                                    <option>Zacatecas</option>
                                </select> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='div_btns_DashAdmin1_PerfilEmpresa'>
                {/* btn guardar cambios empresa */}
                <div
                    style={{visibility: validateVisibility}}
                >
                    <Button_actualizaEmpresa
                        link={linkGuardarCambios}
                        json={perfilEmpresa}
                        update={actualizaEmpresa}
                        disabled={diss}
                        name_btn="Guardar cambios"
                        css="btn_siguiente_cuestionario_componente1"
                    />
                </div>
                {/* btn editar empresa */}
                <button 
                    className="btn_DashAdmin1_PerfilEmpresa_Editar"
                    onClick={() => setDis(!dis)}
                    style={{visibility: validateVisibility}}
                >
                    Editar
                </button>
                {/* btn valdiar empresa */}
                <button 
                    className="btn_DashAdmin1_PerfilEmpresa_Validar"
                    onClick={clickHandlerValidar}
                    style={{visibility: validateVisibility}}
                >
                    Validar
                </button>
            </div>
        </div>
    </div>
  );
};
