import React, { useRef, useState } from 'react';
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { Link } from 'react-router-dom';
import { NavBar_FirmaYAfiliacion } from '../NavBar_FirmaYAfiliacion';
import { actualizaAfiliado } from '../../../../utils/xmex/services';
import './ScreenFirmaAfiliacion.css';
import { Btn_afiliarse } from '../Btn_afiliarse';

export const ScreenFirmaAfiliacion = () => {
    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    // Hook y funcion para para borrar la firma
    const sigCanvas = useRef({});
    const borrarSigCanvas = () => sigCanvas.current.clear();

    // Obtener la firma y put        
    const [afiliacionFirma, setAfiliacionFirma] = useState({
        val_id: 1,
        img_firma: '',
        id: 0
    });
    const handleFirmaEnd = () => {
        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setAfiliacionFirma({ img_firma: signatureData });
    };

  return (
    <Box>
        <NavBar_FirmaYAfiliacion/>
        {isSmallScreen &&
            <Container sx={{marginBlock: '2vh'}}>
                <Container sx={{borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', marginBottom: '3%', paddingLeft: '3vw', marginBottom: '2vh'}}>
                    <Typography color='primary' sx={{fontSize: 22, fontWeight: 800, width: '100%'}}>
                        FIRMA DE AFILIACIÓN
                    </Typography>
                    <Typography sx={{fontSize: 16, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
                        Deseo pertenecer a esta organización sindical. 
                        Por medio de la presente, me permito hacer 
                        formal solicitud para ser admitido como miembro 
                        de la misma, con las obligaciones y derechos 
                        que los estatutos imponen a sus agremiados, 
                        apegándome a los acuerdos para todos los efectos 
                        legales que a derecho proceda. Aviso de privacidad.
                    </Typography>
                </Container>
                <Container sx={{marginBottom: '3%', width: '100%',border: '2px solid #D9D9D9',borderRadius: '8px'}}>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginTop: '1vh'}}>
                        <Typography sx={{fontWeight: 500, fontSize: 20}} style={{color: '#585858'}}>
                            FIRMA
                        </Typography>
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center'}}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            onEnd={() => handleFirmaEnd()}
                            penColor='#405e9d'
                            canvasProps={{className: 'signatureCanvas_FirmaAfiliacion_SmallScreen'}}
                            style={{display: 'flex', justifyContent: 'center'}}
                        />
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginBottom: '3%'}}>
                        <Button 
                            onClick={borrarSigCanvas}
                            color='primary' 
                            variant='contained' 
                            sx={{borderRadius: 3}
                        }> 
                            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
                                Borrar
                            </Typography>
                        </Button>
                    </Container>
                </Container>
                <Box sx={{textAlign: 'right', width: '100%',}}>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Button color='primary' variant='contained' sx={{borderRadius: 3, background: 'rgba(217,217,217,100)', marginRight: '3%'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
                                Cancelar
                            </Typography>
                        </Button>
                    </Link>
                    <Btn_afiliarse
                        link='/afiliacion'
                        json={afiliacionFirma}
                        update={actualizaAfiliado}
                        method='PUT'
                        disabled={false}
                        label='FIRMAR'
                        signatureEmailName='no'
                    />
                </Box>
            </Container>
        }    
        {isMediumScreen &&
            <Container sx={{marginBlock: '2vh'}}>
                <Container sx={{borderWidth: '6px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', marginBottom: '3%', paddingLeft: '3vw', marginBottom: '2vh'}}>
                    <Typography color='primary' sx={{fontSize: 25, fontWeight: 800, width: '100%'}}>
                        FIRMA DE AFILIACIÓN
                    </Typography>
                    <Typography sx={{fontSize: 18, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
                        Deseo pertenecer a esta organización sindical. 
                        Por medio de la presente, me permito hacer 
                        formal solicitud para ser admitido como miembro 
                        de la misma, con las obligaciones y derechos 
                        que los estatutos imponen a sus agremiados, 
                        apegándome a los acuerdos para todos los efectos 
                        legales que a derecho proceda. Aviso de privacidad.
                    </Typography>
                </Container>
                <Container sx={{marginBottom: '3%', width: '100%',border: '2px solid #D9D9D9',borderRadius: '8px'}}>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginTop: '1vh'}}>
                        <Typography sx={{fontWeight: 500, fontSize: 18}} style={{color: '#585858'}}>
                            FIRMA
                        </Typography>
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center'}}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            onEnd={() => handleFirmaEnd()}
                            penColor='#405e9d'
                            canvasProps={{className: 'signatureCanvas_FirmaAfiliacion_MediumScreen'}}
                            style={{display: 'flex', justifyContent: 'center'}}
                        />
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginBottom: '3%'}}>
                        <Button 
                            onClick={borrarSigCanvas}
                            color='primary' 
                            variant='contained' 
                            sx={{borderRadius: 3}
                        }> 
                            <Typography sx={{fontSize: 16, fontWeight: 500}} style={{color: 'white'}}>
                                Borrar
                            </Typography>
                        </Button>
                    </Container>
                </Container>
                <Box sx={{textAlign: 'right', width: '100%',}}>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Button color='primary' variant='contained' sx={{borderRadius: 3, background: 'rgba(217,217,217,100)', marginRight: '3%'}}>
                            <Typography sx={{fontSize: 16, fontWeight: 500}} style={{color: 'white'}}>
                                Cancelar
                            </Typography>
                        </Button>
                    </Link>
                    <Btn_afiliarse
                        link='/afiliacion'
                        json={afiliacionFirma}
                        update={actualizaAfiliado}
                        method='PUT'
                        disabled={false}
                        label='FIRMAR'
                        signatureEmailName='no'
                    />
                </Box>
            </Container>
        }    
        {isLargeScreen &&
            <Box sx={{marginLeft: '15%',marginTop: '3%', width: '70%', }}>
                <Container sx={{borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', marginBottom: '3%'}}>
                    <Typography color='primary' sx={{fontSize: 28, fontWeight: 800, width: '100%'}}>
                        FIRMA DE AFILIACIÓN
                    </Typography>
                    <Typography sx={{fontSize: 18, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
                        Deseo pertenecer a esta organización sindical. 
                        Por medio de la presente, me permito hacer 
                        formal solicitud para ser admitido como miembro 
                        de la misma, con las obligaciones y derechos 
                        que los estatutos imponen a sus agremiados, 
                        apegándome a los acuerdos para todos los efectos 
                        legales que a derecho proceda. Aviso de privacidad.
                    </Typography>
                </Container>
                <Container sx={{marginBottom: '3%', width: '100%',border: '2px solid #D9D9D9',borderRadius: '8px'}}>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginTop: '3%'}}>
                        <Typography sx={{fontWeight: 500, fontSize: 20}} style={{color: '#585858'}}>
                            FIRMA
                        </Typography>
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center'}}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            onEnd={() => handleFirmaEnd()}
                            // penColor='#AB444C'
                            penColor='#405e9d'
                            canvasProps={{className: 'signatureCanvas_FirmaAfiliacion_LargeScreen'}}
                            style={{display: 'flex', justifyContent: 'center'}}
                        />
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginBottom: '3%'}}>
                        <Button 
                            onClick={borrarSigCanvas}
                            color='primary' 
                            variant='contained' 
                            sx={{borderRadius: 3}}
                        > 
                            <Typography sx={{fontSize: 16, fontWeight: 500}} style={{color: 'white'}}>
                                Borrar
                            </Typography>
                        </Button>
                    </Container>
                </Container>
                <Box sx={{textAlign: 'right', width: '100%'}}>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Button color='primary' variant='contained' sx={{borderRadius: 3, background: 'rgba(217,217,217,100)', marginRight: '3%'}}>
                            <Typography sx={{fontSize: 16, fontWeight: 500}} style={{color: 'white'}}>
                                Cancelar
                            </Typography>
                        </Button>
                    </Link>
                    <Btn_afiliarse
                        link='/afiliacion'
                        json={afiliacionFirma}
                        update={actualizaAfiliado}
                        method='PUT'
                        disabled={false}
                        label='FIRMAR'
                        signatureEmailName='no'
                    />
                </Box>
            </Box>
        }   
        {isExtraLargeScreen &&
            <Box sx={{marginLeft: '15%',marginTop: '3%', width: '70%', }}>
                <Container sx={{borderWidth: '7px', borderLeftStyle: 'solid', borderLeftColor: '#FF8303', marginBottom: '3%'}}>
                    <Typography color='primary' sx={{fontSize: 40, fontWeight: 800, width: '100%'}}>
                        FIRMA DE AFILIACIÓN
                    </Typography>
                    <Typography sx={{fontSize: 20, fontWeight: 400, color: '#303030', width: '100%', textAlign: 'justify'}}>
                        Deseo pertenecer a esta organización sindical. 
                        Por medio de la presente, me permito hacer 
                        formal solicitud para ser admitido como miembro 
                        de la misma, con las obligaciones y derechos 
                        que los estatutos imponen a sus agremiados, 
                        apegándome a los acuerdos para todos los efectos 
                        legales que a derecho proceda. Aviso de privacidad.
                    </Typography>
                </Container>
                <Container sx={{marginBottom: '3%', width: '100%',border: '2px solid #D9D9D9',borderRadius: '8px'}}>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginTop: '3%'}}>
                        <Typography sx={{fontWeight: 500, fontSize: 24}} style={{color: '#585858'}}>
                            FIRMA
                        </Typography>
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center'}}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            onEnd={() => handleFirmaEnd()}
                            // penColor='#AB444C'
                            penColor='#405e9d'
                            canvasProps={{className: 'signatureCanvas_FirmaAfiliacion_ExtraLargeScreen'}}
                            style={{display: 'flex', justifyContent: 'center'}}
                        />
                    </Container>
                    <Container sx={{display: 'flex', justifyContent: 'center', marginBottom: '3%'}}>
                        <Button 
                            onClick={borrarSigCanvas}
                            color='primary' 
                            variant='contained' 
                            sx={{borderRadius: 3}}
                        > 
                            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
                                Borrar
                            </Typography>
                        </Button>
                    </Container>
                </Container>
                <Box sx={{textAlign: 'right', width: '100%'}}>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Button color='primary' variant='contained' sx={{borderRadius: 3, background: 'rgba(217,217,217,100)', marginRight: '3%'}}>
                            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
                                Cancelar
                            </Typography>
                        </Button>
                    </Link>
                    <Btn_afiliarse
                        link='/afiliacion'
                        json={afiliacionFirma}
                        update={actualizaAfiliado}
                        method='PUT'
                        disabled={false}
                        label='FIRMAR'
                        signatureEmailName='no'
                    />
                </Box>
            </Box>
        }   
    </Box>
  );
};
