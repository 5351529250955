import React, { useState } from "react";
import './Cuestionario21.css';
import './Estilo_Cuestionario_Escala.css';
import { PreguntaEscalaSN } from "./elementos_cuestionarios/PreguntaEscalaSN"; 
import { Button_CuestionarioSiguente } from "./elementos_cuestionarios/Button_CuestionarioSiguiente";
import { getUrlParameter } from "../../../utils/jstools";

export const Cuestionario311 = (props) => {

    const sigPag = "312";
    const pNombre = props.nombre;
    const pUserId = props.usuario;
    const pPerfId = props.perfil;
    const pIdEmpresa = getUrlParameter('empresaId');

    const cantidadTotalPreguntas= 10;
    var numPreguntas= 0;
    
    const [deseableState, setDeseableState] = useState(true);

    const [sessionJson, setSessionJson] = useState({
        referenciaId: 3,     //Dato estático
        seccionId: 23,       //Dato estático
        tipoPreguntaId: 2,   //Dato estático
        preguntaInicial: 47, //Dato estático
        nombre: props.nombre,
        link: ""
      });

      const [perfilJson, setPerfilJson] = useState({
        Id: 0,
        usuarioId: 0,
        qId: 0,
        EmpresaId: 0
      });

      const [respuestasJson, setRespuestasJson] = useState({
        respuestas: [
          { pregunta: 47, respuesta: 0 },
          { pregunta: 38, respuesta: 0 },
          { pregunta: 49, respuesta: 0 },
          { pregunta: 50, respuesta: 0 },
          { pregunta: 51, respuesta: 0 },
          { pregunta: 52, respuesta: 0 },
          { pregunta: 53, respuesta: 0 },
          { pregunta: 54, respuesta: 0 },
          { pregunta: 55, respuesta: 0 },
          { pregunta: 56, respuesta: 0 },
         ]
      });

      React.useEffect(() => {
        setSessionJson((prevState) => {
          return {
            ...prevState,
            link:
              "/cuestionario_" +
              sigPag +
              "?userId=" +
              pUserId +
              "&perfId=" +
              pPerfId +
              "&empresaId=" +
              pIdEmpresa +
              "&nombre=" +
              pNombre
          };
        });
    
        setPerfilJson(() => {
          return {
            Id: pPerfId,
            usuarioId: pUserId,
            qId: sigPag,
            EmpresaId: pIdEmpresa
          };
        });
      }, []);

      const updatePreg = (preg, resp, condPreguntaActiva) => {
        console.log(preg,resp);
        if(condPreguntaActiva==1){
          numPreguntas++;
        }
        if(numPreguntas==cantidadTotalPreguntas){
          setDeseableState(false);
        }
        const respuestas = {respuestasJson};
        respuestas.respuestasJson.respuestas[preg - sessionJson.preguntaInicial] = {
          pregunta: preg,
          respuesta: resp,
        };
        setRespuestasJson(respuestas.respuestasJson );
        console.log({ respuestasJson });
      };
      
    return (
      <div className="box_menu_y_cuestionario_escala">
        <div className="cuestionario_div_escala">
          <div className="div_svg_menu_escala">
            <img src="./img/menu_cuestionarios/menu_c311.svg" className="img_svg_escala_entorno_org"/>
          </div>
          <div className="text_cuestionario_escala">
            <img src="./img/barra_progreso/entorno_organizacional/barra_de_progreso_311.svg" className="img_progreso_escala"/>
            <p className="titulo_cuestionario_escala"><img src="./img/barra_progreso/iconos/organizacional-icono_cuestionario.svg"/>  ENTORNO ORGANIZACIONAL</p>

            <p className="instrucciones_cuestionario_escala">Las preguntas siguientes están relacionadas con la información que recibe sobre su rendimiento en el trabajo, el
            reconocimiento, el sentido de pertenencia y la estabilidad que le ofrece su trabajo.</p>
            <div className="body_escalas">
              <div className="titulos_escalas">
                <table>
                  <tbody>
                    <tr>
                      <td><p className="box_titulos_escalas">Siempre</p></td>
                      <td><p className="box_titulos_escalas">Casi<br></br>siempre</p></td>
                      <td><p className="box_titulos_escalas">Algunas<br></br>veces</p></td>
                      <td><p className="box_titulos_escalas_1">Casi<br></br>nunca</p></td>
                      <td><p className="box_titulos_escalas_2">Nunca</p></td>   
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="preguntas_escala_sn">
                  
                <PreguntaEscalaSN
                  numPreg="47"
                  texto="Me informan sobre lo que hago bien en mi trabajo"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />
                              
                <PreguntaEscalaSN
                  numPreg="48"
                  texto="La forma como evalúan mi trabajo en mi centro de trabajo me ayuda a mejorar mi desempeño"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />
                   
                <PreguntaEscalaSN
                  numPreg="49"
                  texto="En mi centro de trabajo me pagan a tiempo mi salario"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />
                
                <PreguntaEscalaSN
                  numPreg="50"
                  texto="El pago que recibo es el que merezco por el trabajo que realizo"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />

                 <PreguntaEscalaSN
                  numPreg="51"
                  texto="Si obtengo los resultados esperados en mi trabajo me recompensan o reconocen"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />

                <PreguntaEscalaSN
                  numPreg="52"
                  texto="Las personas que hacen bien el trabajo pueden crecer laboralmente"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />
                                  
                <PreguntaEscalaSN
                  numPreg="53"
                  texto="Considero que mi trabajo es estable"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />
               
                <PreguntaEscalaSN
                  numPreg="54"
                  texto="En mi trabajo existe continua rotación de personal"
                  update={updatePreg}
                  rSiempre="4"
                  rCasiSiempre="3"
                  rAlgunasVeces="2"
                  rCasiNunca="1"
                  rNunca="0"
                />
                  
                <PreguntaEscalaSN
                  numPreg="55"
                  texto="Siento orgullo de laborar en este centro de trabajo"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />

                <PreguntaEscalaSN
                  numPreg="56"
                  texto="Me siento comprometido con mi trabajo"
                  update={updatePreg}
                  rSiempre="0"
                  rCasiSiempre="1"
                  rAlgunasVeces="2"
                  rCasiNunca="3"
                  rNunca="4"
                />

              </div>
            </div>
                    
            <Button_CuestionarioSiguente
              className="btn_siguiente_cuestionario_binario"
              sesion={sessionJson}
              perfil={perfilJson}
              respuestas={respuestasJson}
              disabled={deseableState}
              upDate={"guardaRespuestas"}
              condicionPreg='200Restantes'
            />

          </div>
        </div>
      </div>
    )
}