import React, { useState, useContext, useEffect } from 'react'
import { Header } from '../../header/Header'
import { Menu_lateral_analista } from '../menu_lateral_analista/Menu_lateral_analista'
import './AdministrarUsuarios_DashAnalista.css';
import { getEmpleadosByEmpresaId, getEmpresaById, getPerfilEmpleadoByUsuarioId, getUserById, guardaUsuario } from '../../../../utils/services';
import { Tarjeta_usuario } from './Tarjeta_usuario';
import Popup from "reactjs-popup";
import { getUrlParameter } from '../../../../utils/jstools';
import AdminContext from '../../../../context/AdminContext';


// const listaEmpleados=[
//   {nombreUsuario :"Ruben",
//   correoUsuario:"Ruben035@smi.com"},
//   {nombreUsuario :"Jaime",
//   correoUsuario:"Jaime035@smi.com"},
// ]

export const AdministrarUsuarios_DashAnalista = () => {

  const [ listaEmpleadosAPI, setListaEmpleadosAPI ] = useState(listaEmpleados, listaEmpleadosRegistrados);
  const [ listaEmpleadosRegistrados, setListaEmpleadosRegistrados ] = useState ([]);
  // popUp
  const [ anadirEmpleado, setAnadirEmpleado ] = useState({
    nomAnl: "",
    email: "",
    rolId: "",
    pass: "nom35",
    empresaId: parseInt(getUrlParameter("empresaId")),
  });

  const [ perfilEmpresa, setPerfilEmpresa ] = useState ({
    id: getUrlParameter("empresaId"),
    nombre: "",
    rasonSocial: "",
    rfc: "",
    calle: "",
    numExt: "",
    colonia: "",
    cp: "",
    municipio: "",
    localidad: "",
    estado: "",
    referencia: "",
  });

  // Lista de los analistas para mapear
  const [ listaAnalistasRegistrados, setListaAnalistasRegistrados ] = useState ([]);

  useEffect(() => {
    getEmpresaById(perfilEmpresa.id).then((data)=>{
        setPerfilEmpresa((prevState) => {
            return { ...prevState,
                nombre: data.name,
                rasonSocial: data.rasonSocial,
                rfc: data.rfc,
                calle: data.calle,
                numExt: data.numExt,
                colonia: data.colonia,
                cp: data.cp,
                municipio: data.municipio,
                localidad: data.localidad,
                estado: data.estado,
                referencia: data.referencia
            };
        });
    });

  // Obtener los empleados registrados
  var analistasArray = [];
  getEmpleadosByEmpresaId("2").then((data) => {
    const array = data;
    array.forEach((analista) => {

      if ( analista.rolId == 3 ) {
        const analistas = {
          id: analista.id,
          numAnalista: analista.numEmp,
          email: analista.email
        };

        analistasArray.push(analistas);
        setListaAnalistasRegistrados(oldArray => [...oldArray, analistas]);

      };
    });
  });

},[]);

  const [ checkBoxAnalista, setCheckBoxAnalista ] = useState(false);
  var dis = true;
    if (
      (anadirEmpleado.nomAnl != "") & (anadirEmpleado.email != "")
    ){
      dis = false;
    }
    else {
      dis = true;
    }
  
    const nomAnlAnadirAnalistaChangeHandler = (event) => {
      setAnadirEmpleado((prevState) => {
        return { ...prevState, nomAnl: event.target.value,  };
      });
      // setanadirAnalista(event.target.value);
    };
    const emailAnadirAnalistaChangeHandler = (event) => {
      setAnadirEmpleado((prevState) => {
        return { ...prevState, email: event.target.value,  };
      });
      // setanadirAnalista(event.target.value);
    };
    const rolIdAnadirAnalistaChangeHandler = (event) => {
      setAnadirEmpleado((prevState) => {
        return { ...prevState, rolId: parseInt(event.target.value)};
      });
      // setanadirAnalista(event.target.value);
    };
    const agregarAnalista = (nuevoEmpleado) => {
      let nuevosEmpleados = new Array();
      listaEmpleadosAPI.map((empleado) =>
        {
          nuevosEmpleados.push(empleado);
        }
      )
      nuevosEmpleados.push(nuevoEmpleado);
      // console.log("Empresion",listaEmpleadosAPI);
      setListaEmpleadosAPI(nuevosEmpleados);
    };
    const ctx = useContext(AdminContext);
    const clickHandler = () => {
      guardaUsuario(anadirEmpleado).then((data) => {
        console.log(data);
        if (ctx.empresaId == 0) {
          ctx.updateAdmin(data.id);
        }
      });
    
      agregarAnalista(
        {
          nomAnalista: anadirEmpleado.nomAnl,
          email: anadirEmpleado.email
        }
      );
    // };
  
      window.alert("Registro de Analista exitoso");  
    };
  const contentStyle = {
    maxWidth: "600px",
    width: "90%"
  };  

  return (
    <div>
        <Header/>
        <Menu_lateral_analista/>
        <div className='box_DashAnalist'>
        <div
            style=
            {
                {
                  backgroundImage: "url(../img/admin/Background_DashAdmin1.png)", 
                  width: "100%", 
                  height: "35%", 
                  backgroundSize: "cover",
                  marginTop: "1%",
                  display: "block",
                }
            }
          >
            <div className='div_txt_DashAdmin1_InfoEmpresa'>
              <p className='txt_title_Administrador_DashAdmin1'>ANALISTA</p>
              <p className='txt_title_Administrador_DashAdmin1'>ADMINISTRACION DE USUARIOS</p>
            </div>
            <div className='table_top_Analista_AdminU'>
              <table>
                <tbody>
                  <tr>
                    <td>
                    <img
                       src='../img/admin/Administrar_usuarios.png' 
                       className='img_admin_us'
                      />
                    </td>
                    <td>
                      USUARIOS ACTIVOS
                    </td>
                    <td>
                      USUARIOS SELECCIONADOS  
                    </td>
                    <td //className='conteo'
                    >
                      
                    </td>
                    <td>
                    <button 
                    className='btn_usuariosSeleccionados_regEmp'
                  >
                    <img
                       src='../img/admin/Vectoreliminar.png' 
                       className='img_eliminar'
                      />
                      
                  </button>
                </td>
                <td className='td14_table_top_Admin_RegEmpleados'>
                 


                    {/* <Modal /> */}
   {/* const CustomModal  = () => ( */}
    <Popup className="pupUp_A"
      trigger={<button 
        className='btn_anaadirUsuarios_regEmp'
        id='btn_PopUp_AnadirAnalista'
      >
        <img
                       src='../img/admin/Vectorañadir.png' 
                       className='img_añadir'
                      /> 
       </button>}
      modal
      contentStyle={contentStyle}
    >
      {close => (
        <div className="modal">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="header"> Añadir analista </div>
          <div className="content">
          <p className='txt_NumTrabajador_PopUp_anadirAnalista'>
                        Nombre del analista 
                      </p>
                      <input
                        className='input__NumTrabajador_PopUp_anadirAnalista'
                        type="text"
                        placeholder='Número de trabajador'
                        pattern='[a-z,A-Z]+'
                        maxLength={30}
                        value={anadirEmpleado.nomAnl}
                        onChange={nomAnlAnadirAnalistaChangeHandler}
                      />
          </div>
          <div className='div2_popUp_RegistroEmpleados'>
                      <p className='txt_email_PopUp_anadirAnalista'>
                        Email
                      </p>
                      <input
                        className='input_email_PopUp_anadirAnalista'
                        type="email"
                        placeholder='Email'
                        value={anadirEmpleado.email}
                        onChange={emailAnadirAnalistaChangeHandler}
                      />
                      <p className='txt_InputTitle_DIV2_DashAdmin_PerfilAdmin'>
                        Rol del analista
                      </p>
                <select 
                    id="id_AnalistaRol" 
                    name="name_Analista_Rol" 
                    className='cn_Analista_Rol' 
                    value={anadirEmpleado.rolId}
                    onChange={rolIdAnadirAnalistaChangeHandler}
                >
                    <option 
                      value={4}
                    >
                      Analista 1
                    </option>
                    <option 
                      value={5}
                    >
                      Analista 2
                    </option>
                    <option 
                      value={6}
                    >
                      Analista 3
                    </option>
                </select>
                      <button
                          className='btn_anadirAnalista_popUp'
                          onClick={clickHandler}
                          id='btn_PopUp_Cerrar_Agregar'
                          disabled={dis}
                      >
                          Agregar
                      </button>
                      
                    </div>
          
        </div>
      )}
    </Popup>
  {/* ); */}




                </td>
               
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='table_titulos'>
              <table>
                <tbody>
                  <tr>
                    
                    <td>
                      <input  type="checkbox" 
                      onClick={() => setCheckBoxAnalista(!checkBoxAnalista)}
                      />
                    </td>
                    <td>
                      NOMBRE
                    </td>
                    <td>
                      CORREO ELECTRONICO
                    </td>
                    <td>
                      ULTIMA VEZ ACTIVO
                    </td>
                    <td>
                      ESTATUS
                    </td>
                    <td>
                      EDITAR DATOS
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            {
              listaEmpleadosRegistrados.map((empleado) => 
                <Tarjeta_usuario 
                  empleado={empleado}
                  check={checkBoxAnalista} 
                />
            )}
          </div>
          </div>
    </div>
  )
}
