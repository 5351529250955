import React, { useState, useEffect, useContext } from "react";
import { guardaEmpresa, guardaUsuario } from "../../../../utils/services";
import { Button_siguente_admin } from "../../cuestionario/elementos_cuestionarios/Button_siguente_admin";
import "./Registro_admin.css";
import Cookies from 'universal-cookie';
import { useLocation } from "react-router-dom";

export const Registro_admin = (props) => {
  const cookies = new Cookies();
    

// const [idEmpresaFromCookie, setIdEmpresaFromCookie] = useState(""); // Estado local para guardar el valor de la cookie
// useEffect(() => {
//   setIdEmpresaFromCookie(parseInt(cookies.get('IdEmpresa'))); // Actualiza el estado local con el valor de la cookie
//   console.log('idEmpresaFromCookie: ' + parseInt(cookies.get('IdEmpresa', 10)));
// }, []);
// useEffect(() => {
//   console.log('idEmpresaFromCookie en useEffect: ' + idEmpresaFromCookie); // Verifica el valor de idEmpresaFromCookie
//   setRegAdminInput((prevInput) => ({
//     ...prevInput,
//     EmpresaId: idEmpresaFromCookie,
//   }));
// }, [idEmpresaFromCookie]);



  // instancia de cookies
  // const cookies = new Cookies();
  // console.log('idEmpresa: ' + cookies.get('IdEmpresa'));
  
  // useEffect(() => {
  //   setRegAdminInput((prevState) => ({
  //     ...prevState,
  //     EmpresaId: cookies.get('IdEmpresa'),
  //     }));
  //     console.log('idEmpresa: ' + cookies.get('IdEmpresa'));
  // }, []);

  const [regAdminInput, setRegAdminInput] = useState({
    Nombre: "",
    Paterno: "",
    Materno: "",
    Email: "",
    Celular: "",
    Pass: "",
    Rfc: "",
    TelOficina: "",
    NumEmp: "",
    FecNac: "",
    RolId: 1,
    GeneroId: 1,
    // EmpresaId: cookies.get('IdEmpresa'),
    EmpresaId: 0,
    // EmpresaId: getUrlParameter('IdEmpresa'),
  });

  // var idEmpresaCookie = cookies.get('IdEmpresa');
  // idEmpresaCookie = cookies.get('IdEmpresa');
  // idEmpresaCookie = cookies.get('IdEmpresa');
  // setRegAdminInput((prevState) => ({
  //     ...prevState,
  //     // EmpresaId: cookies.get('IdEmpresa')
  //     EmpresaId: idEmpresaCookie
  //   })
  //   );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedDataJson = queryParams.get('data');
  useEffect(() => {
    if (encodedDataJson){
      const decodedDataJson = JSON.parse(decodeURIComponent(encodedDataJson));
      console.log(decodedDataJson);
      guardaEmpresa(decodedDataJson).then((data) => {
        setRegAdminInput((prevState) => {
          return { ...prevState,
            EmpresaId: data.Id
          }
        })
        console.log(data.Id);
      });
    }
    // console.log('idEmpresa var: ' + idEmpresaCookie);
    // console.log('cookie IdEmpresa: ' + cookies.get('IdEmpresa'));
  // }, [cookies.get('IdEmpresa')]);
  }, [encodedDataJson]);
    
  



                              
  const [confPass, setConfPass] = useState({
    confirmarPass:""
  });

  const confirmarRegAdminChangeHandler = (event) => {
    setConfPass((prevState) => {
      return { ...prevState, confirmarPass: event.target.value,  };
    });
  };

  const nomRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Nombre: event.target.value,  };
    });
  };

  const apRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Paterno: event.target.value };
    });
  };
  const amRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Materno: event.target.value };
    });
  };

  const emailRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Email: event.target.value };
    });
  };
  const numcelRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Celular: event.target.value };
    });
  };

  const passRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Pass: event.target.value };
    });
  };
  const rfcRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, Rfc: event.target.value };
    });
  };

  const numOfnaRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, TelOficina: event.target.value };
    });
  };
  const numempleRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, NumEmp: event.target.value };
    });
  };

  const generoRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, GeneroId: parseInt(event.target.value) };
    });
  };
  

  const fecNacRegAdminChangeHandler = (event) => {
    setRegAdminInput((prevState) => {
      return { ...prevState, FecNac: event.target.value };
    });
  };

  // Condicion para el boton continuar
  var dis = true;
    if (
      (regAdminInput.Nombre != "") & (regAdminInput.Paterno != "") &
      (regAdminInput.Paterno != "") & (regAdminInput.Email != "") &
      (regAdminInput.Celular != "") & (regAdminInput.Pass == confPass.confirmarPass) &
      (regAdminInput.Rfc != "") & (regAdminInput.TelOficina != "") &
      (regAdminInput.NumEmp != "") & (regAdminInput.FecNac != "") 
    ){
      dis = false;
    }
    else {
      dis = true;
    }
  
  // Link para dashboard del admin
  // const link = "/dashboard1_administrador?userId=" + props.idUsuario + "&empresaId=" + props.idEmpresa

  return (
    <div
      style={{
        backgroundImage: "url(../img/Background_Login_Blue.svg)",
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        marginTop: "-.5%",
        paddingTop: ".5%",
      }}
    >
      
      <div className="box_RegistroAdmin">
        <img
          src="../img/LogoSMI_Login.svg"
          className="img_LogoSMI_RegistroAdmin"
        />
        <p className="txt_Principal_RegistroAdmin">REGISTRO DE ADMINISTRADOR</p>
       <div className="box_RegistroAdmin1">
        <div className='div_campos_1'>
                          <div className="">
                            <div className="input_RegistroAdmin">
                              <div className="input_RegistroAdmin_nombre">
                                <p className="txt_preregistro_datos_correo">Nombre (s)</p>
                                  <input
                                     className="input_RegistroAdminN"
                                     type="text"
                                     title=""
                                     placeholder='Nombre (s)'
                                     pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                                     maxLength="20"
                                     value={regAdminInput.nombre}
                                    onChange={nomRegAdminChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_APaterno'>
                        <p className='txt_preregistro_datos_correo'>Apellido paterno</p>
                        <input 
                            className='input_RegistroAdminN' 
                            type="text" 
                            titlle="" 
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            placeholder='Apellido paterno'
                            maxLength="20"
                            value={regAdminInput.paterno}
                            onChange={apRegAdminChangeHandler}
                        />
                    </div>
                </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_AMaterno'>
                        <p className='txt_preregistro_datos_correo'>Apellido materno</p>
                        <input 
                            className='input_RegistroAdminN' 
                            type="text" 
                            titlle="" 
                            placeholder='Apellido materno'
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+"
                            maxLength="20"
                            value={regAdminInput.materno}
                            onChange={amRegAdminChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroAdmin">
                              <div className="input_RegistroAdmin_Correo">
                                <p className="txt_preregistro_datos_correo">Correo electrónico</p>
                                  <input
                                   className='input_RegistroAdminC' 
                                   type="email" 
                                   titlle="" 
                                   placeholder='Correo electrónico'
                                   maxLength="45"
                                   value={regAdminInput.email}
                                   onChange={emailRegAdminChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_izq'>
                        <p className='txt_preregistro_datos_correo'>Número telefonico de oficina</p>
                        <input 
                            className='input_RegistroAdminC' 
                            type="text" 
                            titlle="" 
                            pattern="[0-9]+"
                            maxLength="10"
                            placeholder='Número de oficina'
                            value={regAdminInput.telOficina}
                            onChange={numOfnaRegAdminChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroAdmin">
                              <div className="input_RegistroAdmin_celular">
                                <p className="txt_preregistro_datos_correo">Celular</p>
                                  <input
                                   className='input_RegistroAdminC' 
                                   type="text"
                                   pattern="[0-9]+"
                                   maxLength="10"
                                   titlle="" 
                                   placeholder='Celular'
                                   value={regAdminInput.celular}
                                   onChange={numcelRegAdminChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_izq'>
                        <p className='txt_preregistro_datos_correo'>Número de empleado</p>
                        <input 
                            className='input_RegistroAdminC' 
                            type="text" 
                            titlle="" 
                            pattern="[a-zA-Z,0-9\s]+"
                            placeholder='Número de empleado'
                            maxLength="15"
                            value={regAdminInput.numEmp}
                            onChange={numempleRegAdminChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroAdmin">
                              <div className="input_RegistroAdmin_password">
                                <p className="txt_preregistro_datos_correo">Password</p>
                                  <input
                                   className='input_RegistroAdminC' 
                                   type="password" 
                                   titlle="" 
                                   placeholder='Password'
                                   value={regAdminInput.pass}
                                   onChange={passRegAdminChangeHandler}
                                   />
                              </div>
                            </div>
                <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_izq'>
                        <p className='txt_preregistro_datos_correo'>Confirmar password</p>
                        <input 
                            className='input_RegistroAdminC' 
                            type="password" 
                            titlle="" 
                            placeholder='Confirmar password'
                            id="pass2"
                            value={regAdminInput.confPass}
                            onChange={confirmarRegAdminChangeHandler}
                        />
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
                          <div className="">
                            <div className="input_RegistroAdmin">
                              <div className="input_RegistroAdmin_rfc">
                                <p className="txt_preregistro_datos_correo">RFC</p>
                                  <input
                                   className='input_RegistroAdminC' 
                                   type="text" 
                                   titlle="" 
                                   placeholder='RFC'
                                   maxLength="13"
                                   value={regAdminInput.rfc}
                                   onChange={rfcRegAdminChangeHandler}
                                   />
                              </div>
                            </div>
                            <div className='input_RegistroAdmin'>
                    <div className='input_RegistroAdmin_izq'>
                        <p className='txt_preregistro_datos_correo'>Género</p>
                        <select
                    id="id_sexo_RegistroEmpleado" 
                    name="name_sexo_RegistroEmpleado" 
                    className='input_RegistroAdminC'
                    value={regAdminInput.generoId}
                    onChange={generoRegAdminChangeHandler}
                   >
                       <option 
                        value="1"
                    >
                        Masculino
                    </option>
                    <option 
                        value="2"
                    >
                        Femenino
                    </option>
                    <option 
                        value="3"
                    >
                        Personalizado
                    </option>
                </select> 
                    </div>
                </div>
               
            </div>
        </div>  
        <div className='div_campos_3'>
          <div className="">
            <div className="input_RegistroAdmin">
              <div className="input_RegistroAdmin_fechaN">
                <p className="txt_preregistro_datos_correo">Fecha de nacimiento</p>
                  <input
                    className='input_RegistroAdminC' 
                    type="date" 
                    titlle="" 
                    placeholder='Fecha de nacimiento'
                    maxLength="13"
                    value={regAdminInput.FecNac}
                    onChange={fecNacRegAdminChangeHandler}
                  />
              </div>
            </div>
          </div>
        </div>   
        </div>
        <Button_siguente_admin
          link="/loginNom035"
          json={regAdminInput}
          update={guardaUsuario}
          disabled={dis}
          windowAlert="administrador"
          cookieIdEmpresa="no"
          // setRegAdminInput={setRegAdminInput}
        />
      </div>
    </div>
  );
};
