import { Box, Container, TextField, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { actualizaAfiliado } from '../../../utils/xmex/services';
import { Btn_afiliarse } from '../afiliacion/Btn_afiliarse';
import { NavBar_Login } from './NavBar_Login';
import { getUrlParameter } from '../../../utils/jstools';

export const BoxRecuperarPass = () => {  
      
  // URL DEL SERVIDOR PARA IMAGENES
  const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

  // Responsive
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  // Manejador para cambiar a la vista de recuperación de contraseña
  const handlePasswordResetClick = () => {
    setIsPasswordReset(true);
  };

  // Manejador para volver a la vista de inicio de sesión
  const handleBackToLoginClick = () => {
    setIsPasswordReset(false);
  };

  const [jsonCredenciales, setJsonCredenciales] = useState({
    password: '',
    repitepass: ''
  });

  const [jsonActualizaAfiliado, setJsonActualizaAfiliado] = useState({
    id: getUrlParameter('id'),
    password: ''
  });

  const [user, setUser] = useState([]);

  // CHANGE HANDLERS
  const passwordAfiliadoChangeHandler =(event) => {
    setJsonCredenciales((prevState) => {
      return {...prevState, password: event.target.value};
    });
    setJsonActualizaAfiliado((prevState) => {
      return {...prevState, password: event.target.value};
    });
  };
  const repitepassAfiliadoChangeHandler =(event) => {
    setJsonCredenciales((prevState) => {
      return {...prevState, repitepass: event.target.value};
    });
  };

  // mensaje de error
  const [errorMsg, setErrorMsg] = useState('');
  // color de mensaje 
  const [colorMsg, setColorMsg] = useState('');
  // useNavigate hook
  const navigate = useNavigate();
// login
const validateForm = () => {
  if (jsonCredenciales.password !== '' && jsonCredenciales.repitepass !== '') {
    if(jsonCredenciales.password == jsonCredenciales.repitepass) {
      console.log('Validando el formulario...');
      console.log('password: ' + jsonCredenciales.password);
      console.log('repite: ' + jsonCredenciales.repitepass);
      // setJsonActualizaAfiliado((prevState) => {
      //   return {...prevState, password: jsonCredenciales.password};
      // });

      actualizaAfiliado(jsonActualizaAfiliado).then((response) => {
        if(jsonActualizaAfiliado.id){
          setErrorMsg('Se ha cambiado tu contraseña');
          setColorMsg('green');
          window.alert('Se ha cambiado tu contraseña correctamente. Redirigiendo al login...');
          navigate('/loginXmex');
        } else {
          setErrorMsg('Hubo un problema, intenta más tarde');
          setColorMsg('red');
        }
      }).catch((error) => {
          console.error('Error en la validación:', error);
          setErrorMsg('Hubo un problema, intenta más tarde');
          setColorMsg('red');
      });
    } else {
      setErrorMsg('Las contraseñas no coinciden');
      setColorMsg('red');
    }
  } else {
      setErrorMsg('Por favor, completa los campos');
      setColorMsg('red');
  }
};

  return (
    <div>
      <NavBar_Login/>
      {isSmallScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "250% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '85%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='password'
                  label='Nueva contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <TextField 
                  type='password'
                  label='Repetir contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={repitepassAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Cambiar contraseña
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isMediumScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "200% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '55%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='password'
                  label='Nueva contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <TextField 
                  type='password'
                  label='Repetir contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={repitepassAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Cambiar contraseña
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isLargeScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "150% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '45%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='password'
                  label='Nueva contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <TextField 
                  type='password'
                  label='Repetir contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={repitepassAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Cambiar contraseña
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isExtraLargeScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "100% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '35%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='password'
                  label='Nueva contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <TextField 
                  type='password'
                  label='Repetir contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={repitepassAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Cambiar contraseña
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
    </div>
  )
}
