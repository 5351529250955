import React, { useState } from "react";
import { Header } from "../../nom35/header/Header";
// import "../Homepage_trabajador.css";
import './Homepage_trabajador.css';
import { Link } from "react-router-dom";
import { getUrlParameter } from "../../../utils/jstools";
import { getUserById, getPerfilEmpleadoByUsuarioId } from "../../../utils/services";

export const Homepage_trabajador = () => {
  const [chkValue, setChkValue] = useState(true);

  const [user, setUser] = useState({
    name: "",
    id: "",
    qid: "",
    link: "",
  });

  React.useEffect(() => {
    const userId = getUrlParameter("userId");

    var nombre = "";
    getUserById(userId).then((data) => {
      console.log(data);
      nombre = data.nombre + " " + data.paterno + " " + data.materno;
      setUser((prevState) => {
        return {
          ...prevState,
          name: nombre,
          id: data.id,
        };
      });
    });

    getPerfilEmpleadoByUsuarioId(userId).then((data) => {
      console.log("getPerfilEmpleadoByUsuarioId",data[0]);
      const perfil = data[0];
      setUser((prevState) => {
        return {
          ...prevState,
          qid: perfil.qId,
          link: "/terminosCondiciones?userId=" + getUrlParameter('userId') + "&perfId=" + perfil.Id + "&empresaId=" + getUrlParameter('empresaId') + "&qId=" + getUrlParameter('qId') + "&userName=" + getUrlParameter('userName'),
                //  /terminosCondiciones?userId=403                              &perfId=undefinedNaN265                                                    &qId=101                           &userName=Agos6Emp030%20Agos6Emp030%20Agos6Emp030
          // link: "/terminosCondiciones?userId=" + perfil.usuarioId + "&empresaId=" + getUrlParameter('empresaId') + "&qId=" + perfil.qId + "&userName=" + userName;
          // "?userId="+perfil.usuarioId+
          // "&perfId="+perfil.id+
          // "&nombre="+nombre,
        };
      });
    });

  }, []);

  return (
    <div>
      <Header 
        // nombre={user.name} 
        nombre={getUrlParameter('userName')}
      />
      <div className="box">
        <img
          src="../img/trabajdor/Background_HomeHageTrabajador.png"
          className="img_HPTrabajador"
        />
        {/* <img src="../img/Background_HomeHageTrabajador.png" className="img_HPTrabajador"/> */}
        <p className="txt_HMTrabajador_HolaUsuario">Bienvenido {user.name} </p>
        <hr className="hr_HMTrabajador" />

        <p className="p1">
          La Nom 035 Tiene como objetivo establecer los elementos para{" "}
          <span className="txt_HMTrabajador_Subrayado">
            identificar, analizar y prevenir
          </span>{" "}
          los factores de riesgo psicosocial, así como para promover un entorno
          organizacional favorable en los{" "}
          <span className="txt_HMTrabajador_Subrayado">
            centros de trabajo.
          </span>
        </p>

        <p className="p1">
          Es un{" "}
          <span className="txt_HMTrabajador_Subrayado">
            instrumento que permite
          </span>{" "}
          a los centros de trabajo{" "}
          <span className="txt_HMTrabajador_Subrayado">
            identificar y analizar
          </span>{" "}
          de forma general, los factores de riesgo psicosocial en los centros de
          trabajo, así como evaluar el entorno organizacional en el que los
          trabajadores desarrollan sus actividades.
        </p>

        <p className="pI">INSTRUCCIONES</p>
        <p className="p1">
        Estimado colaborador, por favor contesta las siguientes preguntas, recuerda que tus respuestas son confidenciales, si llegas a tener algún fallo de conexión a internet solo vuelve a ingresar en el momento que sea posible y tu cuestionario seguirá activo hasta la última sección finalizada, tu progreso se guardará cada que oprimas el botón SIGUIENTE.<br></br>
       <br></br>
        Gracias por tu participación.
        </p>
        

       

        <Link to={user.link}>
          <button className="btn_HMTrabajador_Siguiente" >
            SIGUIENTE
          </button>
        </Link>
      </div>
    </div>
  );
};
