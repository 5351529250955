import React, { useState } from "react";
import "../Estilo_Cuestionario_Binario.css";

export const PreguntaSiNoCond = (props) => {

  const condicionSi = document.querySelector("button[id='"+props.idSi+"']");
  const condicionNo = document.querySelector("button[id='"+props.idNo+"']");

  var condPreguntaActiva = 0;
    
    const updateSi = (self) => {
      condPreguntaActiva++;
      condicionSi.className = 'btn_si_cuestionario_binario_activo';
      condicionNo.className = 'btn_no_cuestionario_binario';      
      props.update(parseInt(props.numPreg), 1, props.condSi, condPreguntaActiva);
    };
  
    const updateNo = (self) => {
      condPreguntaActiva++;
      condicionSi.className = 'btn_si_cuestionario_binario';
      condicionNo.className = 'btn_no_cuestionario_binario_activo';
      props.update(parseInt(props.numPreg), 0, props.condNo, condPreguntaActiva);
    };
  
    return (
      <div>
        <table>
          <tbody>
          <tr>
            {/* <td className="td_no_pregunta">
              <p className="preguntas_cuestionario_escala">{props.numPreg}</p>
            </td> */}
            <td className="td_botones_pregunta">
              <p className="preguntas_cuestionario_escala">{props.texto}</p>
              <button
                className="btn_si_cuestionario_binario"
                id={props.idSi}
                onClick={updateSi}
              >
                SI
              </button>
              <button
                className="btn_no_cuestionario_binario"
                id={props.idNo}
                onClick={updateNo}
              >
                NO
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  };